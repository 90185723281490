import React from 'react';
import styles from './index.module.css';
import EyeInput from '../../Common/ClientTextField/EyeInput';
import AccordianData from '../AccordianData';
import { useForm } from 'react-hook-form';
import { Button } from '@mui/material';
import { TextFirstlineRightIcon } from '@hugeicons/react';
const RoundForm = ({ isThird, style, des, RoundTitle, inputId }) => {
	return (
		<div style={{ ...style }} className={styles.frameParent}>
			<div className={styles.enterDetailsAboutYourProduParent}>
				{!des && (
					<div className={styles.enterDetailsAbout}>
						<span>
							{/* <TextFirstlineRightIcon
                size={24}
                color={"#3F628B"}
                variant={"stroke"}
              /> */}
						</span>
						{RoundTitle}
					</div>
				)}
				<form className={styles.formContainer}>
					<EyeInput id={inputId} title='Name of the Product or Company' />
					<AccordianData isThird={isThird} />
				</form>
			</div>
		</div>
	);
};

export default RoundForm;
