import { useCopyToClipboard } from "usehooks-ts"
import { toast } from 'react-toastify';
export const UseCopy=()=>{
    const [, copy] = useCopyToClipboard()
    const copyText=(text)=>{
        copy(text)
        toast.success('Text Copied Succefully');
    }
    return {copyText}



}