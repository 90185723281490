import { createContext, useEffect, useReducer, useState } from 'react';
import {
	RESET_ROUNDS_FORM,
	SET_API_LOADING,
	SET_FOUNDATION,
	SET_FOUNDATION_ONLY,
	SET_FOUNDATION_ONLY_RESET,
	SET_GRAPH_DATA,
	SET_OUTPOINT_DATA,
	SET_OUTPOINT_LOADING,
	SET_PATH_NAME,
	SET_ROUNDS_FORM,
	SET_SINGLE_ROUND,
	TEMP_FOUNDATION,
	UPDATE_ROUNDS_DASHBOARD,
	UPDATE_ROUNDS_FORM,
} from './action';
import { getOutpoint } from '../../services';
import { stepperData } from '../../Components/StepperNewRound/stepperData';

export const OutpointContext = createContext();

const initialState = {
	data: {},
	loading: true,
	pathName: '/',
	roundsForm: {
		round_id: null,
		foundation: {
			foundation_name: '',
			foundation_mode: 1,
			url: [],
			scrape_mode: 'singlepage',
			additional_details: '',
			description: '',
		},
		competitor: {
			competitor_name: '',
			competitor_mode: 1,
			url: [],
			scrape_mode: 'singlepage',
			additional_details: '',
			description: '',
		},
		analysis: {},
		pages: {},
	},
	apiLoading: false,
	temp_foundation: {},
	foundation_page: {
		foundation_name: '',
		foundation_mode: 1,
		url: [],
		scrape_mode: 'singlepage',
		additional_details: '',
		description: '',
	},
	graph_data: [],
	round_dash_update: 0,
	foundation_dash_update: 0,
	foundationData: {},
	singleRound: {},
};
const reducer = (state, action) => {
	switch (action.type) {
		case SET_OUTPOINT_LOADING: {
			return { ...state, loading: action.payload };
		}
		case SET_OUTPOINT_DATA: {
			return { ...state, data: action.payload };
		}
		case TEMP_FOUNDATION: {
			return { ...state, temp_foundation: action.payload };
		}
		case SET_FOUNDATION_ONLY: {
			return {
				...state,
				foundation_page: { ...state.foundation_page, ...action.payload },
			};
		}
		case SET_FOUNDATION_ONLY_RESET: {
			return {
				...state,
				foundation_page: action.payload,
			};
		}
		case SET_PATH_NAME: {
			return {
				...state,
				pathName: action.payload,
			};
		}
		case SET_FOUNDATION: {
			return {
				...state,
				foundationData: action.payload,
			};
		}
		case SET_SINGLE_ROUND: {						
			return {
				...state,
				singleRound: action.payload,
			};
		}
		case SET_GRAPH_DATA: {
			return {
				...state,
				graph_data: action.payload,
			};
		}
		case SET_ROUNDS_FORM: {
			return {
				...state,
				roundsForm: { ...state.roundsForm, ...action.payload },
			};
		}
		// case UPDATE_ROUNDS_FORM: {
		//   return { ...state, apiLoading:action.payload };
		// }
		case RESET_ROUNDS_FORM: {
			return { ...state, roundsForm: action.payload };
		}
		case SET_API_LOADING: {
			console.log(action.payload);
			return { ...state, apiLoading: action.payload };
		}
		case UPDATE_ROUNDS_DASHBOARD: {
			return { ...state, round_dash_update: state.round_dash_update + 1 };
		}
		case UPDATE_FOUNDATIONS_DASHBOARD: {
			return {
				...state,
				foundation_dash_update: state.foundation_dash_update + 1,
			};
		}

		default:
			return state;
	}
};

export const OutpointProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialState);
	const [selectedStep, setCurrentStep] = useState(stepperData[0]);
	const getData = async (value = 0) => {
		dispatch({ type: SET_OUTPOINT_LOADING, payload: true });
		try {
			let data = await getOutpoint(value);
			if (data?.data) {
				dispatch({ type: SET_OUTPOINT_DATA, payload: data });
			}
		} catch (err) {
			console.log(err);
		}
		dispatch({ type: SET_OUTPOINT_LOADING, payload: false });
	};

	useEffect(() => {
		let isGet = true;
		if (isGet) {
			getData();
		}

		return () => {
			isGet = false;
		};
	}, [state.round_dash_update, state.foundation_dash_update]);

	const value = { state, dispatch, setCurrentStep, selectedStep, getData };

	return (
		<OutpointContext.Provider value={value}>
			{children}
		</OutpointContext.Provider>
	);
};
