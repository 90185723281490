import { useState } from 'react';
import {
	Box,
	Select,
	MenuItem,
	Radio,
	RadioGroup,
	FormControlLabel,
	FormControl,
} from '@mui/material';
import './index.css';
import SelectCompetitor from '../SelectCompetitor';
import { ColorPattern } from '../../../constant/Color';
import { useTheme } from '../../../Context/ThemContext/ThemeContext';

const UserSavedFoundation = ({
	data,
	foundationLoading,
	getFoundationData,
}) => {
	const { colorMode } = useTheme();
	return (
		<div className='savedFoundationWrapper' style={{ marginRight: '30px' }}>
			<p style={{ color: ColorPattern[colorMode].textColor }}>
				Select a Saved Foundation:{' '}
			</p>
			<div>
				<SelectCompetitor
					getFoundationData={getFoundationData}
					foundationLoading={foundationLoading}
					foundationData={data}
					onSavedFoundation
				/>
			</div>
		</div>
	);
};

export default UserSavedFoundation;
