const DarkModeIcon = ({ fill = '#A0A8BE' }) => {
    return (
        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.554688" y="0.347656" width="22.3" height="22.3043" rx="11.15" fill="#0069E5" />
            <path d="M18.2056 12.9801C17.3942 13.4134 16.4675 13.6589 15.4835 13.6589C12.2871 13.6589 9.69604 11.0678 9.69604 7.87151C9.69604 6.88747 9.94164 5.96078 10.3749 5.14941C7.49782 5.82369 5.35547 8.40599 5.35547 11.4886C5.35547 15.0845 8.27048 17.9995 11.8664 17.9995C14.949 17.9995 17.5313 15.8571 18.2056 12.9801Z" fill="white" />
        </svg>

    )
}

export default DarkModeIcon;