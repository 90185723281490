import React, { useState, useRef } from "react";
import NoAnalysis from "../NoAnalysis/index.module.css";
import NoAnalyisIcon from "../../../assets/icons/blockers/noanalysis.svg";
import TickIcon from "../../../assets/icons/tick-02.svg";
import { Button, CircularProgress } from "@mui/material";
import {
  generateAttributeAnalysis,
  getAnalysisDetail,
} from "../../../services";
import { ColorPattern } from "../../../constant/Color";
import { useTheme } from "../../../Context/ThemContext/ThemeContext.jsx";
import { toast } from "react-toastify";

const buttonBaseStyle = {
  position: "relative",
  marginTop: "0",
  padding: "4px 15px",
  fontSize: "13px",
  textTransform: "none",
  borderRadius: "5px",
  borderWidth: "1px !important",
  display: "flex",
  gap: "6px",
};

const saveButtonStyle = {
  ...buttonBaseStyle,
  backgroundColor: "#0069E51A",
  color: "#0069E5",
  border: "0.5px solid #0069E5",
};
const progressStyle = {
  color: "blue",
  height: "14px !important",
  width: "14px !important",
  marginRight: "7px",
};
const NoAnalysisBlocker = ({
  analysisData,
  setAnalysisData,
  inputRef,
  tableRef,
}) => {
  const [showAddAttribute, setShowAddAttribute] = useState(false); // Fixed typo
  const [isLoading, setIsLoading] = useState(false);
  // const [analysisData, setAnalysisData] = useState({}); // Initial empty state
  //   const inputRef = useRef(null);
  //   const tableRef = useRef(null);
  const { colorMode } = useTheme();

  const handleAddItem = async () => {
    setIsLoading(true);
    // Update data
    if (inputRef.current.value.trim()?.length == 0) {
      toast?.error("Please enter a valid attribute name");
      setIsLoading(false);
      return;
    }
    const res = await generateAttributeAnalysis(
      inputRef.current.value,
      analysisData.analysis?.analysis_id
    );
    if (!res?.data) {
      toast?.error(res?.detail ?? "Unable to generate analysis");
      setIsLoading(false);
      return;
    }
    // Get analysis data after update
    const response = await getAnalysisDetail(
      analysisData.analysis?.analysis_id
    );
    if (response?.data) setAnalysisData(response?.data);
    setIsLoading(false);
    inputRef.current.value = "";
    setTimeout(() => {
      tableRef.current?.scroll({
        top: tableRef.current.scrollHeight - tableRef.current.clientHeight,
        behavior: "smooth",
      });
    }, 100);
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div
        className={
          NoAnalysis.intel_rounds_container + " max-w-[calc(100%-24px)] mx-auto"
        }
      >
        <div className={NoAnalysis.no_analysis_container}>
          <div className={NoAnalysis.no_analysis_icon_wrapper}>
            <img
              src={NoAnalyisIcon}
              alt="No Analysis"
              className={NoAnalysis.no_analysis_icon}
              width={95}
              height={95}
            />
          </div>
          <div className={NoAnalysis.no_analysis_message_wrapper}>
            <h5 className={NoAnalysis.no_analysis_message}>
              Add the first feature attribute to initiate the comparison table
            </h5>
          </div>
          <p className={NoAnalysis.paraText}>
            Sharbo AI generates analysis and comparisons for each attribute
            between the foundation and competitor products.
          </p>
        </div>

        <div className={NoAnalysis["add_attribute_wrapper"]}>
          <div
            className={NoAnalysis["add_attribute_wrapper__left"]}
            style={{ marginRight: "6px" }}
          >
            <input
              ref={inputRef}
              placeholder="Enter Feature Attribute (e.g., Enterprise features)"
            />
          </div>
          <div
            className="add_attribute_wrapper__right"
            style={{
              marginTop: "5px",
              color: ColorPattern[colorMode].blue,
              border: `1px solid ${ColorPattern[colorMode].blue}`,
              borderRadius: "4px",
            }}
          >
            <Button
              id="addFeatureComparisonAttribute"
              onClick={handleAddItem}
              variant="outlined"
              sx={saveButtonStyle}
              disabled={isLoading}
            >
              {isLoading ? (
                <span>
                  <CircularProgress sx={progressStyle} />
                </span> // Placeholder for a spinner
              ) : (
                <img
                  src={TickIcon}
                  style={{ marginRight: "5px" }}
                  alt="Generate"
                />
              )}
              Generate Feature Analysis
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoAnalysisBlocker;
