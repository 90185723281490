import React from "react";
import styles from "./index.module.css";
import { CustomSwitch } from "../../../../Components/Common/Switch";
const EmailCard = ({
  title,
  lable,
  isAuto,
  dataState,
  setDataState,
  setSaveButton,
}) => {
  return (
    <div className={styles.whenANewScheduledTrackJobParent}>
      <div className={styles.whenANew}>{title}</div>
      <div className={styles.toggleOnParent}>
        <CustomSwitch
          onChange={(e) => {
            setSaveButton(true);

            setDataState({
              ...dataState,
              [lable]: !dataState?.[lable],
            });
          }}
          disabled={isAuto}
          checked={isAuto ? true : dataState?.[lable]}
        />
        <div className={styles.enabled}>
          {isAuto
            ? "Auto-Enabled"
            : dataState?.[lable]
            ? "Enabled"
            : "Disabled"}
        </div>
      </div>
    </div>
  );
};

export default EmailCard;
