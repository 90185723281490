import { getAccessToken } from '../utils/Storage';
export const BASE_URL = import.meta.env.VITE_API_URL;
let jwt_token = getAccessToken();
export const headers = {
	Authorization: `Bearer ${jwt_token}`,
	'Content-Type': 'application/json',
};

export const verifySession = async (id, token) => {
	try {
		const response = await fetch(`${BASE_URL}/auth/verify-session/${id}`, {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});

		const result = await response.json();

		jwt_token = result?.access_token;
		if (!getAccessToken()) {
			headers['Authorization'] = `Bearer ${result?.access_token}`;
		} else {
			headers['Authorization'] = `Bearer ${getAccessToken()}`;
		}

		return result;
	} catch (error) {
		console.error('Login failed:', error);
	}
};

// Get outpoints

export const getFoundation = async (sort_by) => {
	try {
		let res = await fetch(
			`${BASE_URL}/foundations/all?sort_by=${sort_by}&display_locked=${false}`,
			{
				method: 'GET',
				headers,
			}
		);

		const result = await res.json();
		return result;
	} catch (err) {
		console.log(err);
	}
};

// Get outpoints

export const getOutpoint = async (sort_by) => {
	try {
		let res = await fetch(`${BASE_URL}/rounds/all?sort_by=${sort_by}`, {
			method: 'GET',
			headers,
		});

		const result = await res.json();
		return result;
	} catch (err) {
		console.log(err);
	}
};
export const getCompetitorByFoundationId = async (foundationId, sort_by) => {
	try {
		let res = await fetch(
			`${BASE_URL}/competitors/foundation/${foundationId}?sort_by=${sort_by}`,
			{
				method: 'GET',
				headers,
			}
		);

		const result = await res.json();
		return result;
	} catch (err) {
		console.log(err);
	}
};
export const getSingleRound = async (round_id) => {
	try {
		let res = await fetch(`${BASE_URL}/rounds/${round_id}`, {
			method: 'GET',
			headers,
		});

		const result = await res.json();
		return result;
	} catch (err) {
		console.log(err);
	}
};

// graph data
export const getGraphData = async (id) => {
	console.log(id);
	try {
		let res = await fetch(
			`${BASE_URL}/rounds/active_rounds_graph_data?graph_id=${id}`,
			{
				method: 'GET',
				headers,
			}
		);

		const result = await res.json();
		console.log(result);
		return result.data;
	} catch (err) {
		console.log(err);
	}
};

//foundation
export const createFoundation = async (data, flag, round_id = null) => {
	try {
		let url;
		if (round_id) {
			url = `${BASE_URL}/foundations/create?round_flag=${false}&round_id=${round_id}`;
		} else {
			url = `${BASE_URL}/foundations/create?round_flag=${flag}`;
		}
		const response = await fetch(url, {
			method: 'PUT',
			headers: headers,
			body: JSON.stringify(data),
		});

		const result = await response.json();
		return result;
	} catch (err) {
		console.log('Error', err);
		return err;
	}
};
export const createRoundWithFoundation = async (
	foundationId,
	round_id = null
) => {
	try {
		let url = `${BASE_URL}/rounds/create?foundation_id=${foundationId}`;
		if (round_id) {
			url = `${BASE_URL}/rounds/create?foundation_id=${foundationId}&round_id=${round_id}`;
		}
		const response = await fetch(url, {
			method: 'PUT',
			headers: headers,
		});
		console.log(response);

		const result = await response.json();
		return result;
	} catch (err) {
		console.log('Error', err);
		return err;
	}
};

// post track
export const createTrackAndUpdate = async (round_id, data) => {
	try {
		const response = await fetch(`${BASE_URL}/updates/create/${round_id}`, {
			method: 'POST',
			headers: headers,
		});

		const result = await response.json();
		return result;
	} catch (err) {
		console.log('Error', err);
		return err;
	}
};
export const runTrackAndUpdate = async (round_id) => {
	try {
		const response = await fetch(`${BASE_URL}/updates/run/${round_id}`, {
			method: 'POST',
			headers: headers,
		});

		const result = await response.json();
		return result;
	} catch (err) {
		console.log('Error', err);
		return err;
	}
};

export const fetchRoundSignals = async (foundationId) => {
	try {
		const url = new URL(`${BASE_URL}/rounds/generate-signals`);
		url.searchParams.append('foundation_id', foundationId);

		let res = await fetch(url, {
			method: 'POST',
			headers,
		});

		const result = await res.json();
		return result;
	} catch (err) {
		console.error('Error fetching round signals:', err);
		throw err;
	}
};

export const generateCompetitors = async (
	foundationId,
	signals,
	loadMore = false
) => {
	try {
		const url = new URL(`${BASE_URL}/rounds/generate-competitors`);
		url.searchParams.append('foundation_id', foundationId);
		url.searchParams.append('load_more', loadMore);

		// Check if signals array is empty
		if (!signals || signals.length === 0) {
			console.log('No signals provided. Skipping competitor generation.');
			return { competitors: [] }; // Return an empty array of competitors
		}

		const response = await fetch(url, {
			method: 'POST',
			headers: {
				...headers,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(signals),
		});

		const result = await response.json();
		return result;
	} catch (err) {
		console.error('Error generating competitors:', err);
		throw err;
	}
};

export const createCompetitor = async (data, id) => {
	try {
		const response = await fetch(
			`${BASE_URL}/competitors/create?foundation_id=${id}`,
			{
				method: 'POST',
				headers: headers,
				body: JSON.stringify(data),
			}
		);

		const result = await response.json();
		return result;
	} catch (err) {
		console.log('Error', err);
		return err;
	}
};
export const updateCompetitor = async (data, comp_id, id) => {
	try {
		const response = await fetch(
			`${BASE_URL}/competitors/update/${comp_id}?foundation_id=${id}`,
			{
				method: 'PUT',
				headers: headers,
				body: JSON.stringify(data),
			}
		);

		const result = await response.json();
		return result;
	} catch (err) {
		console.log('Error', err);
		return err;
	}
};
export const updateFoudation = async (foundation_id, data) => {
	try {
		const response = await fetch(
			`${BASE_URL}/foundations/update/${foundation_id}`,
			{
				method: 'PUT',
				headers: headers,
				body: JSON.stringify(data),
			}
		);

		const result = await response.json();
		return result;
	} catch (err) {
		console.log('Error', err);
		return err;
	}
};

export const createSteps = async (steps, id) => {
	try {
		const response = await fetch(
			`${BASE_URL}/rounds/step/${steps}?round_id=${id}`,
			{
				method: 'PATCH',
				headers: headers,
			}
		);

		const result = await response.json();
		return result;
	} catch (err) {
		console.log('Error', err);
		return err;
	}
};
export const updateRound = async (round_id, data) => {
	try {
		const response = await fetch(`${BASE_URL}/rounds/${round_id}/round`, {
			method: 'PATCH',
			headers: headers,
			body: JSON.stringify(data),
		});

		const result = await response.json();
		return result;
	} catch (err) {
		console.log('Error', err);
		return err;
	}
};


export const createSchedule = async (round_id, data) => {
	try {
		const response = await fetch(`${BASE_URL}/updates/create/${round_id}`, {
			method: 'POST',
			headers: headers,
			body: JSON.stringify(data),
		});

		const result = await response.json();
		return result;
	} catch (err) {
		console.log('Error', err);
		return err;
	}
};


// Get competitor from foundation id

export const getCompetitor_from_foundation_id = async (foundation_id) => {
	try {
		let res = await fetch(
			`${BASE_URL}/competitors/foundation/${foundation_id}`,
			{
				method: 'GET',
				headers,
			}
		);

		const result = await res.json();
		return result;
	} catch (err) {
		console.log(err);
	}
};
export const getUpdateAndTrack = async (round_id) => {
	try {
		let res = await fetch(`${BASE_URL}/updates/${round_id}`, {
			method: 'GET',
			headers,
		});

		const result = await res.json();
		return result;
	} catch (err) {
		console.log(err);
		return err;
	}
};

// Analysis

export const generateAnalysis = async (roundId, competitorId) => {
	try {
		const url = new URL(`${BASE_URL}/analysis/generate-all-analysis`);
		url.searchParams.append('round_id', roundId);
		url.searchParams.append('competitor_id', competitorId);

		const response = await fetch(url, {
			method: 'POST',
			headers: headers,
		});

		const result = await response.json();
		return result;
	} catch (err) {
		console.error('Error fetching all analysis:', err);
		throw err;
	}
};

export const getAnalysisDetail = async (analysisId) => {
	try {
		const url = new URL(`${BASE_URL}/analysis/${analysisId}/details`);

		const response = await fetch(url, {
			method: 'GET',
			headers: headers,
		});

		const result = await response.json();
		return result;
	} catch (err) {
		console.error('Error fetching all analysis:', err);
		return err;
	}
};

export const changeAttributeMode = async (
	attrId,
	foundation_data,
	competitor_data,
	feature_bool
) => {
	try {
		const url = new URL(
			`${BASE_URL}/analysis/feature-comparison/${attrId}?competitor_data=${competitor_data}&foundation_data=${foundation_data}&feature_bool=${feature_bool}`
		);

		const response = await fetch(url, {
			method: 'PATCH',
			headers: headers,
		});

		const result = await response.json();
		return result;
	} catch (err) {
		console.error('Error fetching all analysis:', err);
		throw err;
	}
};

export const generateAttributeAnalysis = async (attrValue, id) => {
	try {
		const url = new URL(
			`${BASE_URL}/analysis/feature-comparison/${attrValue}?analysis_id=${id}&generated_by_ai=false`
		);

		const response = await fetch(url, {
			method: 'POST',
			headers: headers,
		});

		const result = await response.json();
		return result;
	} catch (err) {
		console.error('Error fetching all analysis:', err);
		return err;
	}
};

export const deleteAttributeAnalysis = async (attrId) => {
	try {
		const url = new URL(`${BASE_URL}/analysis/feature-comparison/${attrId}`);

		const response = await fetch(url, {
			method: 'DELETE',
			headers: headers,
		});

		const result = await response.json();
		return result;
	} catch (err) {
		console.error('Error fetching all analysis:', err);
		throw err;
	}
};

export const reorderAttributeAnalysis = async (attrId, display_order) => {
	try {
		const url = new URL(
			`${BASE_URL}/analysis/feature-comparison/reorder/${attrId}?new_display_order=${display_order + 1
			}`
		);

		const response = await fetch(url, {
			method: 'PATCH',
			headers: headers,
		});

		const result = await response.json();
		return result;
	} catch (err) {
		console.error('Error fetching all analysis:', err);
		throw err;
	}
};

export const regenerateAttributeAnalysis = async (analyisId, attrVal, byAi) => {
	try {
		const url = new URL(`${BASE_URL}/analysis/feature-comparison/${attrVal}`);

		const response = await fetch(url, {
			method: 'PUT',
			headers: headers,
		});

		const result = await response.json();
		return result;
	} catch (err) {
		console.error('Error fetching all analysis:', err);
		throw err;
	}
};

export const generateCustomAnalysis = async (
	analysisId,
	customSectionTitle,
	command_id,
	command_text
) => {
	let url = `${BASE_URL}/analysis/custom-analysis?analysis_id=${analysisId}&CustomSectionTitle=${customSectionTitle}`;
	if (command_id) {
		url = `${BASE_URL}/analysis/custom-analysis?analysis_id=${analysisId}&CustomSectionTitle=${customSectionTitle}&command_id=${command_id}`;
	}
	if (command_text) {
		url = `${BASE_URL}/analysis/custom-analysis?analysis_id=${analysisId}&CustomSectionTitle=${customSectionTitle}&command_text=${command_text}`;
	}
	try {
		const url = new URL(`${BASE_URL}/analysis/custom-analysis`);
		url.searchParams.append('analysis_id', analysisId);
		url.searchParams.append('CustomSectionTitle', customSectionTitle);

		const response = await fetch(url, {
			method: 'PUT',
			headers: headers,
		});

		const result = await response.json();
		return result;
	} catch (err) {
		console.error('Error generating custom analysis:', err);
		throw err;
	}
};

//Generate Analysis
export const RegenerateAnalysisSection = async (
	name,
	analysisId,
	command_id,
	command_text
) => {
	let url = `${BASE_URL}/analysis/${name}?analysis_id=${analysisId}`;
	if (command_id) {
		url = `${BASE_URL}/analysis/${name}?analysis_id=${analysisId}&command_id=${command_id}`;
	}
	if (command_text) {
		url = `${BASE_URL}/analysis/${name}?analysis_id=${analysisId}&command_text=${command_text}`;
	}
	try {
		let response = await fetch(url, {
			method: 'POST',
			headers,
		});

		const result = await response.json();
		return result;
	} catch (err) {
		console.error('Error generating custom analysis:', err);
		return err;
	}
};

export const updateAboutFoundation = async (analyisId, data) => {
	let url = `${BASE_URL}/analysis/save/about-foundation/${analyisId}`;
	try {
		const response = await fetch(url, {
			method: 'PUT',
			headers: headers,
			body: JSON.stringify({ analysis_content: data }),
		});

		const result = await response.json();
		return result;
	} catch (err) {
		console.error('Error generating custom analysis:', err);
		return err;
	}
};

export const updateAboutCompetitor = async (analyisId, data) => {
	let url = `${BASE_URL}/analysis/save/about-competitor/${analyisId}`;
	try {
		const response = await fetch(url, {
			method: 'PUT',
			headers: headers,
			body: JSON.stringify({ analysis_content: data }),
		});

		const result = await response.json();
		return result;
	} catch (err) {
		console.error('Error generating custom analysis:', err);
		return err;
	}
};

export const updateAnalysisContent = async (url, content) => {
	let finalURL = `${BASE_URL}${url}`;
	try {
		const response = await fetch(finalURL, {
			method: 'PUT',
			headers: headers,
			body: JSON.stringify(content),
		});
		const result = await response.json();
		return result;
	} catch (err) {
		console.error(err);
	}
};

export const updateFeatureComparison = async (
	id,
	foundationValue,
	competitorValue
) => {
	let url = `${BASE_URL}/analysis/save/feature-comparison/${id}`;
	try {
		const response = await fetch(url, {
			method: 'PUT',
			headers: headers,
			body: JSON.stringify({
				foundation_data: foundationValue,
				competitor_data: competitorValue,
			}),
		});
		const result = await response.json();
		return result;
	} catch (err) {
		console.error(err);
	}
};

export const postImageFile = async (file) => {
	let url = `${BASE_URL}/analysis/return-image-url`;
	const formData = new FormData();
	formData.append('file', file);
	const tempHeaders = {
		Authorization: headers.Authorization,
	};

	try {
		const response = await fetch(url, {
			method: 'POST',
			headers: tempHeaders,
			body: formData,
		});

		const result = await response.json();
		return result;
	} catch (err) {
		console.error('Error generating custom analysis:', err);
		return err;
	}
};

export const deleteCustomAnalysis = async (custom_section_id) => {
	try {
		const url = new URL(
			`${BASE_URL}/analysis/custom-analysis/${custom_section_id}`
		);

		const response = await fetch(url, {
			method: 'DELETE',
			headers: headers,
		});

		const result = await response.json();
		return result;
	} catch (err) {
		console.error('Error fetching all analysis:', err);
		return err;
	}
};
//Generate Page
export const generatePage = async (round_id) => {
	try {
		let response = await fetch(
			`${BASE_URL}/page/analysis-sections?round_id=${round_id}`,
			{
				method: 'POST',
				headers,
			}
		);

		const result = await response.json();
		return result;
	} catch (err) {
		console.error('Error generating custom analysis:', err);
		return err;
	}
};
//get analysis Page
export const getAnalysisSection = async (round_id) => {
	try {
		let response = await fetch(
			`${BASE_URL}/page/analysis-sections?round_id=${round_id}`,
			{
				method: 'GET',
				headers,
			}
		);

		const result = await response.json();
		return result;
	} catch (err) {
		console.error('Error generating custom analysis:', err);
		return err;
	}
};

//multiple update Page

export const multipleUpdatePage = async (round_id) => {
	try {
		let response = await fetch(
			`${BASE_URL}/page/analysis-sections-multiple-update?round_id=${round_id}`,
			{
				method: 'POST',
				headers,
			}
		);

		const result = await response.json();
		return result;
	} catch (err) {
		console.error('Error updating custom analysis:', err);
		return err;
	}
};

// update page config

export const updatePageConfiguration = async (
	round_id,
	navigationList,
	lastUp,
	display_mode
) => {
	try {
		let response = await fetch(
			`${BASE_URL}/page/page-configurations?round_id=${round_id}&navigation_list=${navigationList}&last_updated_bool=${lastUp}&display_mode=${display_mode}`,
			{
				method: 'PATCH',
				headers,
			}
		);

		const result = await response.json();
		return result;
	} catch (err) {
		console.error('Error generating custom analysis:', err);
		return err;
	}
};
//Generate Page
export const createPageUpdate = async (round_id, data) => {
	try {
		let response = await fetch(
			`${BASE_URL}/page/analysis-sections-multiple-update?round_id=${round_id}`,
			{
				method: 'POST',
				headers,
				body: JSON.stringify(data),
			}
		);

		const result = await response.json();
		return result;
	} catch (err) {
		console.error('Error generating custom analysis:', err);
		return err;
	}
};

// update Track
export const updateTrack = async (round_id, data) => {
	try {
		let response = await fetch(`${BASE_URL}/rounds/${round_id}/round`, {
			method: 'PATCH',
			headers,
			body: JSON.stringify(data),
		});

		const result = await response.json();
		return result;
	} catch (err) {
		console.error('Error generating custom analysis:', err);
		return err;
	}
};
// update Track
export const getFrequencyMetaData = async () => {
	try {
		let response = await fetch(`${BASE_URL}/metadata/track-frequency-lookup`, {
			method: 'GET',
			headers,
		});

		const result = await response.json();
		return result;
	} catch (err) {
		console.error('Error generating custom analysis:', err);
		return err;
	}
};
export const getAnalysisQuickCommands = async () => {
	try {
		let response = await fetch(`${BASE_URL}/metadata/analysis-quick-commands`, {
			method: 'GET',
			headers,
		});

		const result = await response.json();
		return result;
	} catch (err) {
		console.error('Error generating custom analysis:', err);
		return err;
	}
};

export const getAdditionalOptionsCommands = async (section) => {
	try {
		let response = await fetch(
			`${BASE_URL}/metadata/analysis-quick-commands?section=${section}`,
			{
				method: 'GET',
				headers,
			}
		);

		const result = await response.json();
		return result;
	} catch (err) {
		console.error('Error generating custom analysis:', err);
		return err;
	}
};

export const deleteRound = async (round_id) => {
	try {
		const url = new URL(`${BASE_URL}/rounds/${round_id}`);

		const response = await fetch(url, {
			method: 'DELETE',
			headers: headers,
		});

		const result = await response.json();
		return result;
	} catch (err) {
		console.error('Error fetching all analysis:', err);
		return err;
	}
};

export const deleteFoundation = async (foundation_id) => {
	try {
		const url = new URL(`${BASE_URL}/foundations/${foundation_id}`);

		const response = await fetch(url, {
			method: 'DELETE',
			headers: headers,
		});

		const result = await response.json();
		return result;
	} catch (err) {
		console.error('Error fetching all analysis:', err);
		return err;
	}
};

// update updateandtrack
export const updateTrackAndUpdate = async (round_id, status) => {
	try {
		const response = await fetch(
			`${BASE_URL}/updates/round-track/${round_id}?pause_tracking=${status}`,
			{
				method: 'PATCH',
				headers: headers,
			}
		);

		const result = await response.json();
		return result;
	} catch (err) {
		console.log('Error', err);
		return err;
	}
};

export const getPreviewUpdate = async (round_id) => {
	try {
		let res = await fetch(
			`${BASE_URL}/updates/preview-updated-analysis/${round_id}`,
			{
				method: 'GET',
				headers,
			}
		);

		const result = await res.json();
		return result;
	} catch (err) {
		console.log(err);
		return err;
	}
};
export const savePreviewUpdate = async (round_id) => {
	try {
		let res = await fetch(`${BASE_URL}/updates/update-analysis/${round_id}`, {
			method: 'POST',
			headers,
		});

		const result = await res.json();
		return result;
	} catch (err) {
		console.log(err);
		return err;
	}
};

export const getActiveRound_from_foundation_id = async (
	foundation_id,
	sort_by = 0
) => {
	try {
		let res = await fetch(
			`${BASE_URL}/rounds/foundation/${foundation_id}?sort_by=${sort_by}`,
			{
				method: 'GET',
				headers,
			}
		);

		const result = await res.json();
		return result;
	} catch (err) {
		console.log(err);
	}
};
export const getLogos = async (round_id) => {
	try {
		let res = await fetch(`${BASE_URL}/rounds/logos/${round_id}`, {
			method: 'GET',
			headers,
		});

		const result = await res.json();
		return result;
	} catch (err) {
		console.log(err);
	}
};
export const getPageScrape = async (round_id) => {
	try {
		let res = await fetch(
			`${BASE_URL}/page/analysis-sections-with-scraping?round_id=${round_id}`,
			{
				method: 'GET',
				headers,
			}
		);

		const result = await res.json();
		return result;
	} catch (err) {
		console.log(err);
	}
};
export const downloadAnalysis = async (analysis_id) => {
	try {
		let res = await fetch(
			`${BASE_URL}/analysis/export_analysis?analysis_id=${analysis_id}`,
			{
				method: 'POST',
				headers,
			}
		);
		// Check if the response is okay
		if (!res.ok) {
			throw new Error('Network response was not ok');
		}
		// Convert the response to a blob

		return res;
	} catch (err) {
		console.log(err);
		return err;
	}
};

export const getAttributeList = async (analysis_id) => {
	try {
		let response = await fetch(
			`${BASE_URL}/analysis/attributes?analysis_id=${analysis_id}`,
			{
				method: 'POST',
				headers,
			}
		);

		const result = await response.json();
		return result;
	} catch (err) {
		console.error('Error generating custom analysis:', err);
		return err;
	}
};
export const getFeatureComparison = async (analysis_id, data) => {
	try {
		let response = await fetch(
			`${BASE_URL}/analysis/feature-comparison?analysis_id=${analysis_id}`,
			{
				method: 'POST',
				headers,
				body: JSON.stringify(data),
			}
		);

		const result = await response.json();
		return result;
	} catch (err) {
		console.error('Error generating custom analysis:', err);
		return err;
	}
};

export const getNotification = async () => {
	try {
		let res = await fetch(`${BASE_URL}/notifications/settings`, {
			method: 'GET',
			headers,
		});

		const result = await res.json();
		return result;
	} catch (err) {
		console.log(err);
	}
};
export const postNotification = async (data) => {
	try {
		let res = await fetch(`${BASE_URL}/notifications/settings`, {
			method: 'POST',
			headers,
			body: JSON.stringify(data),
		});

		const result = await res.json();
		return result;
	} catch (err) {
		console.log(err);
	}
};

export const getSEO = async (round_id) => {
	try {
		let res = await fetch(
			`${BASE_URL}/page/analysis-sections-seo-keywords?round_id=${round_id}`,
			{
				method: 'GET',
				headers,
			}
		);

		const result = await res.json();
		return result;
	} catch (err) {
		console.log(err);
		return err;
	}
};
export const getPageTitle = async (round_id) => {
	try {
		let res = await fetch(
			`${BASE_URL}/page/analysis-sections-page-title?round_id=${round_id}`,
			{
				method: 'GET',
				headers,
			}
		);

		const result = await res.json();
		return result;
	} catch (err) {
		console.log(err);
		return err;
	}
};
export const getMetaDescription = async (round_id) => {
	try {
		let res = await fetch(
			`${BASE_URL}/page/analysis-sections-meta-decription?round_id=${round_id}`,
			{
				method: 'GET',
				headers,
			}
		);

		const result = await res.json();
		return result;
	} catch (err) {
		console.log(err);
		return err;
	}
};

// subscriptions meta data

export const getSubscriptionsMetaData = async () => {
	try {
		let res = await fetch(`${BASE_URL}/metadata/subscriptions`, {
			method: 'GET',
			headers,
		});

		const result = await res.json();
		return result;
	} catch (err) {
		console.log(err);
		return err;
	}
};

// current subscriptions

export const getCurrentSubscriptions = async (return_url) => {
	try {
		console.log('api called');
		const response = await fetch(
			`${BASE_URL}/subscriptions/get-current-subscription`,
			{
				method: 'POST',
				headers,
				body: JSON.stringify({
					return_url: return_url,
				}),
			}
		);
		const result = await response.json();
		return result;
	} catch (error) {
		console.error('Error fetching subscription:', error);
		throw error;
	}
};
export const getUserLimit = async (feature) => {
	try {
		const url = `${BASE_URL}/subscriptions/verify/${feature}`;
		const response = await fetch(url, {
			method: 'GET',
			headers,
		});
		const result = await response.json();
		return result;
	} catch (err) {
		console.error(err);
		return err;
	}
};

export const validateCronExpression = async (str) => {
	try {
		const url = `${BASE_URL}/rounds/validate-cron-expression`;
		const response = await fetch(url, {
			method: 'POST',
			headers,
			body: JSON.stringify({
				cron_expression: str,
			}),
		});
		const result = await response.json();
		return result;
	} catch (err) {
		console.error(err);
		return err;
	}
};
