import React, { useContext, useEffect, useState, useRef } from 'react';
import styles from './index.module.css';
import Info from '../../../assets/icons/info-circle.svg';
import Layers from '../../../assets/icons/layers.svg';
import sharboAi from '../../../assets/icons/sharbo-ai.svg';
import siri from '../../../assets/icons/siri.svg';
import { PrimaryModal } from '../../Common/PrimaryModal';
import RoundForm from '../RoundForm';
import {
	Button,
	CircularProgress,
	Tooltip,
	Typography,
	Box,
} from '@mui/material';
import SearchSignals from '../SearchSignal';
import SavedFoundations from '../SearchSignal/savedFoundations';
import SelectCompetitor from '../SelectCompetitor';
import UseUrl from '../UseUrl';
import ViewSignal from '../../../assets/icons/viewSignal.svg';
import Stepper from '../../Stepper/index.jsx';
import { OutpointContext } from '../../../Context/OutpointsContext/Index';
import { stepperData } from '../../StepperNewRound/stepperData';
import { toast } from 'react-toastify';
import { useTheme } from '../../../Context/ThemContext/ThemeContext';
import { ColorPattern } from '../../../constant/Color';

const Competitor = ({ handleRoundFormPage, handleCloseParentModal }) => {
	const [open, setOpen] = useState(false);
	const selectCompetitorRef = useRef(false);
	const selectCompetitorDataRef = useRef(false);
	const { colorMode } = useTheme();

	const {
		state: { apiLoading },
		setCurrentStep,
		selectedStep,
	} = useContext(OutpointContext);
	const [currentModal, setCurrentModal] = useState('');
	const [moreCompetitor, setMoreCompetitor] = useState(false);
	const [disableBtn, setDisableBtn] = useState(false);
	const [finalCompetitorModal, setFinalCompetitorModal] = useState(false);
	const [signalStatus, setSignalStatus] = useState(false);
	const [refreshCompetitor, setRefreshCompetitor] = useState(0);

	const [signalChips, setSignalChips] = useState([]);
	const [aiSignalChips, setAiSignalChips] = useState([]);

	const {
		state: { roundsForm },
	} = useContext(OutpointContext);

	useEffect(() => {
		console.log('Current Rounds Form:', roundsForm);
	}, [roundsForm]);

	const CompetitorData = [
		{
			id: 1,
			title: 'Enter a Known Competitor',
			img: siri,
			isInfo: false,
		},
		{
			id: 2,
			title: 'Use Sharbo AI to Discover Competitors',
			img: sharboAi,
			isInfo: false,
		},
		...(roundsForm.foundation.scraped
			? [
					{
						id: 3,
						title: 'Competitors Saved to the Foundation',
						img: Layers,
						isInfo: true,
					},
			  ]
			: []),
	];

	const handleCurrentModal = (id) => {
		setCurrentModal(id);
		setOpen(true);
	};

	const style = {
		height: '85%',
	};

	// Which option is selected to add competitor
	const handleNext = () => {
		switch (currentModal) {
			case 1: {
				handleRoundFormPage();
				break;
			}
			case 2: {
				setMoreCompetitor(true);
				break;
			}
			case 3: {
				if (selectCompetitorDataRef.current.getSelectValue()) {
					setFinalCompetitorModal(true);
				} else {
					toast.info('Please select any one competitor');
				}
				break;
			}
			default: {
				return;
			}
		}
	};
	const handleCompetitorNext = () => {
		if (selectCompetitorRef.current.getSelectValue()) {
			setFinalCompetitorModal(true);
		} else {
			toast.info('Please select any one competitor');
		}
	};

	const isButtonText =
		currentModal == 1
			? 'Confirm & Next'
			: currentModal == 2
			? 'Discover Competitor'
			: 'Confirm';

	const ViewUsedSignalsComponent = ({ usedSignals }) => {
		if (!usedSignals || usedSignals.length === 0) {
			return null;
		}

		return (
			<Tooltip
				title={
					<Box>
						<Typography variant='subtitle2'>Used Signals:</Typography>
						<ul>
							{usedSignals.map((signal, index) => (
								<li key={index}>{signal}</li>
							))}
						</ul>
					</Box>
				}>
				<div className={styles.leftToRightListStarParent}>
					<img
						className={styles.leftToRightListStarIcon}
						alt=''
						src={ViewSignal}
					/>
					<div className={styles.viewUsedSignals}>View Used Signals</div>
				</div>
			</Tooltip>
		);
	};

	return (
		<>
			{/* Last modal => Edit selected competitor information */}
			<PrimaryModal
				primaryModalChildrenClass={styles.addEnrichmentChildren}
				title={'New Round'}
				parentModalCloseFunc={handleCloseParentModal}
				open={finalCompetitorModal}
				setOpen={setFinalCompetitorModal}>
				<div className={styles.main}>
					<Stepper
						steps={stepperData}
						selectedStep={selectedStep}
						setCurrentStep={setCurrentStep}
					/>
				</div>
				<div className={styles.currentModal}>
					<div style={{ maxHeight: '85%' }} className={styles.frameParent}>
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
								width: '100%',
							}}>
							<div className={styles.configureSelectedCompetitorParent}>
								<div className={styles.configureSelectedCompetitorContainer}>
									<span
										className={
											styles.configureSelectedCompetitor
										}>{`Configure Selected Competitor `}</span>
									<span>(Optional)</span>
								</div>
								<div className={styles.youCanAdd}>
									You can add or edit competitor details before analysis. This
									step is optional, as Sharbo AI has already input initial
									information.
								</div>
							</div>
							<ViewUsedSignalsComponent usedSignals={signalChips} />
						</div>
						<UseUrl title={true} />
					</div>
					<div className={styles.buttonActions}>
						<Button
							onClick={() => setFinalCompetitorModal(false)}
							variant='outlined'
							sx={{
								margin: '10px',
								color: ColorPattern[colorMode].blue,
							}}
							className='hover:scale-105'>
							Back
						</Button>
						<Button
							onClick={() => handleRoundFormPage()}
							variant='contained'
							sx={{
								margin: '10px',
								textTransform: 'none',
								color: '#fff',
							}}
							disabled={apiLoading}
							className='hover:scale-105'>
							{apiLoading && (
								<CircularProgress
									sx={{
										// color: "white",
										border: '0.5px solid #0069e5',
										// color: "#ffffff !important",
										height: '10px !important',
										color: '#0069e5 !important',
										width: '10px !important',
									}}
								/>
							)}
							Save & Next
						</Button>
					</div>
				</div>
			</PrimaryModal>
			{/* 2nd stage of Choosing option 2 => Use Sharbo AI to discover competitor => button is clicked  */}
			{/* Select generated competitors and Editing signals */}
			<PrimaryModal
				primaryModalChildrenClass={styles.addEnrichmentChildren}
				title={'New Round'}
				parentModalCloseFunc={handleCloseParentModal}
				open={moreCompetitor}
				setOpen={setMoreCompetitor}>
				<div className={styles.main}>
					<Stepper
						steps={stepperData}
						selectedStep={selectedStep}
						setCurrentStep={setCurrentStep}
					/>
				</div>
				<div className={styles.currentModal}>
					<div className='flex w-full h-[95%]'>
						<SelectCompetitor
							currentModal={currentModal}
							ref={selectCompetitorRef}
							showInput
							showMore
							showHeading
							showHeaderText={true}
							searchSignals={signalChips}
							refreshCompetitor={refreshCompetitor}
							containerWidth='60%'
							notShowSelect={true}
						/>
						<SearchSignals
							selectCompetitor
							refreshCompetitor={refreshCompetitor}
							setRefreshCompetitor={setRefreshCompetitor}
							signalChips={signalChips}
							setSignalChips={setSignalChips}
							aiSignalChips={aiSignalChips}
							setAiSignalChips={setAiSignalChips}
						/>
					</div>

					<div className={styles.buttonActions}>
						<Button
							onClick={() => setMoreCompetitor(false)}
							variant='outlined'
							sx={{ margin: '10px' }}
							className='hover:scale-105'>
							{' '}
							Back{' '}
						</Button>
						<Button
							id='createCompetitorNextStepBtn'
							onClick={handleCompetitorNext}
							variant='contained'
							sx={{ margin: '10px' }}
							className='hover:scale-105'>
							Confirm
						</Button>
					</div>
				</div>
			</PrimaryModal>
			{/* Default modal that opens */}
			<PrimaryModal
				primaryModalChildrenClass={styles.addEnrichmentChildren}
				title={'New Round'}
				open={open}
				parentModalCloseFunc={handleCloseParentModal}
				setOpen={setOpen}>
				<div className={styles.main}>
					<Stepper
						steps={stepperData}
						selectedStep={selectedStep}
						setCurrentStep={setCurrentStep}
					/>
				</div>
				<div className={styles.currentModal}>
					{currentModal === 1 && (
						<RoundForm
							style={style}
							isThird={false}
							RoundTitle='Enter details about the competitor product or company'
						/>
					)}
					{currentModal === 2 && (
						<SearchSignals
							refreshCompetitor={refreshCompetitor}
							setRefreshCompetitor={setRefreshCompetitor}
							signalChips={signalChips}
							setSignalChips={setSignalChips}
							aiSignalChips={aiSignalChips}
							setAiSignalChips={setAiSignalChips}
							setDisableBtn={setDisableBtn}
						/>
					)}
					{currentModal === 3 && (
						<SelectCompetitor
							refreshCompetitor={refreshCompetitor}
							showInput
							onCompetitorModel3
							currentModal={currentModal}
							ref={selectCompetitorDataRef}
							showHeading
							showHeaderText={false}
							containerWidth='90%'
						/>
					)}

					<div className={styles.buttonActions}>
						<Button
							onClick={() => setOpen(false)}
							variant='outlined'
							sx={{
								margin: '10px',
								border: '0.5px solid #0069e5',
							}}
							className='hover:scale-105'>
							{' '}
							Back{' '}
						</Button>
						<Button
							id='createCompetitorNextStepBtn'
							onClick={handleNext}
							variant='contained'
							className='hover:scale-105'
							sx={{
								margin: '10px',
								textTransform: 'none',
								border: '0.5px solid #0069e5',
								color: '#ffffff !important',
							}}
							disabled={apiLoading || disableBtn}>
							{apiLoading && (
								<CircularProgress
									sx={{
										color: 'white',
										height: '10px !important',
										width: '10px !important',
									}}
								/>
							)}
							{isButtonText}
						</Button>
					</div>
				</div>
			</PrimaryModal>
			<div className={styles.selectOneOfTheseParent}>
				<div className={styles.selectOneOf}>Select one of these:</div>
				<div className={styles.pickParent}>
					{CompetitorData?.map((e) => (
						<div
							id={'selectCompetitorCreationType' + e.id}
							onClick={() => handleCurrentModal(e.id)}
							key={e.id}
							className={styles.pick}>
							<img className={styles.siriIcon} alt='' src={e.img} />
							<div className={styles.enterAKnown}>{e.title}</div>
							{e.isInfo && (
								<img
									className={styles.informationCircleIcon}
									alt=''
									src={Info}
								/>
							)}
						</div>
					))}
				</div>
			</div>
		</>
	);
};

export default Competitor;
