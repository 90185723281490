import React, { useState, useContext, useEffect } from 'react';
import styles from './index.module.css';
import About from './About';
import { PrimaryModal } from '../../Common/PrimaryModal';
import RegenerateButton from './RegenerateButton';
import FeatureComparison from './FeatureComparison';
import TabSectionTemplate from './TabSectionTemplate';
import AnalysisAniamtion from './AnalysisLoaderAnimation.json';
import { OutpointContext } from '../../../Context/OutpointsContext/Index';
import {
	downloadAnalysis,
	generateCustomAnalysis,
	getFeatureComparison,
	getPreviewUpdate,
	RegenerateAnalysisSection,
	savePreviewUpdate,
} from '../../../services';
import { CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';
import AnalysisLoader from './AnalysisLoader';
import AdditionalOptions from './AdditoinalOptions';
import RegenrateModal from './RegenerateModal';
import {
	Download05Icon,
	InformationDiamondIcon,
	MoreVerticalCircle02Icon,
} from '@hugeicons/react';
import { useTheme } from '../../../Context/ThemContext/ThemeContext';
import UpdateReportModal from './UpdateReportModal/index';
import enigmaAnimation from './enigmaLoader.json';
import Lottie from 'lottie-react';

const Analysis = ({
	setPreviewState,
	previewState,
	setUpdateSingleRound,
	setTrackUpdatePayload,
	previewDetails,
	setPreviewDetails,
}) => {
	const { colorMode } = useTheme();
	const { state, dispatch } = useContext(OutpointContext);
	const analysisData = state?.singleRound?.analysis;
	const [previewLoading, setPreviewLoading] = useState(false);
	const [originalAnalysisData, setOriginalAnalysisData] = useState(null);
	const [originalTabList, setOriginalTabList] = useState([]);
	const [showUpdateReportModal, setShowUpdateReportModal] = useState(false);

	const setAnalysisData = (data = {}) => {
		console.log('In Setting analysis data:', data);
		dispatch({
			type: 'SET_SINGLE_ROUND',
			payload: {
				...state.singleRound,
				analysis: { ...analysisData, ...data },
			},
		});
		console.log('After Setting analysis data:', state?.singleRound?.analysis);
	};

	const tabDataArray = [
		{
			id: 1,
			title: 'About',
		},
		{
			id: 2,
			title: 'Feature Comparison',
		},
		{
			id: 3,
			title: 'Key Distinctions',
		},
		{
			id: 4,
			title: `Benefits of using (${
				state?.singleRound?.foundation?.foundation_name || 'Foundation Name'
			})`,
		},
		{
			id: 5,
			title: `Limitations of using (${
				state?.singleRound?.competitor?.competitor_name || 'Competitor Name'
			})`,
		},
		{
			id: 6,
			title: 'Custom Section',
		},
	];
	const [metaChecked, setMetaChecked] = useState({
		command_id: null,
		command_text: '',
	});
	const [tabData, setTabData] = useState(tabDataArray);
	const [activeTab, setActiveTab] = useState(tabData[0].title);
	const [regenerateModal, setRegenerateModal] = useState(false);
	const [additoinalModal, setAdditionalModal] = useState(false);
	const [currentLoading, setCurrentLoading] = useState([]);
	const [regenerateType, setRegenerateType] = useState('');
	const [reLoading, setReLoading] = useState(false);
	const [showLoaderText, setShowLoaderText] = useState(true);
	const [previewApply, setPreviewApply] = useState(false);
	const [downloadLoading, setDownLoadLoading] = useState(false);

	const downloadExportAnalysis = async () => {
		setDownLoadLoading(true);
		try {
			let response = await downloadAnalysis(analysisData.analysis.analysis_id);
			const blob = await response.blob();

			// Extract the filename from the content-disposition header
			console.log(response);
			const contentDisposition = response.headers.get('content-disposition');

			let filename = 'downloaded_file.csv'; // Default file name

			if (contentDisposition) {
				// Adjusted regex to correctly extract the filename
				const filenameMatch = contentDisposition.match(/filename=([^;]+)$/);
				if (filenameMatch && filenameMatch[1]) {
					filename = filenameMatch[1].trim();
				}
			}
			const link = document.createElement('a');
			link.href = window.URL.createObjectURL(blob);
			link.setAttribute('download', filename);
			document.body.appendChild(link);
			link.click();
			link.remove();
		} catch (err) {
			toast.error(err);
			console.log(err);
		}
		setDownLoadLoading(false);
	};

	// useEffect(() => {
	//   // This will run whenever state.singleRound or analysisData changes
	//   if (state.singleRound && analysisData) {
	//     // console.log(
	//     //   "Single round or analysis data updated:",
	//     //   state.singleRound.analysis,
	//     //   analysisData
	//     // );
	//     // Additional UI refresh logic here
	//   }
	// }, [state.singleRound, analysisData]);

	useEffect(() => {
		let isGet = true;
		if (isGet) {
			let custTab = [];
			if (analysisData?.custom_section?.custom_section)
				for (let key of analysisData?.custom_section?.custom_section) {
					custTab.push({
						title: key.customsectiontitle,
						type: 'custom',
					});
				}

			setTabData((prev) => {
				let lastElement = prev[prev.length - 1];
				let newarr = [...prev];
				newarr.pop();
				return [...newarr, ...custTab, lastElement];
			});
		}
		return () => {
			isGet = false;
		};
	}, []);

	const handlePreview = async () => {
		setPreviewLoading(true);
		try {
			if (
				!previewDetails?.data ||
				previewDetails?.update_id != previewState?.id
			) {
				setOriginalAnalysisData(state?.singleRound?.analysis);
				let res = await getPreviewUpdate(state?.singleRound?.round_id);
				if (res?.data) {
					setPreviewDetails({ ...res, update_id: previewState?.id });
					setAnalysisData(res.data);
				} else {
					toast.error(res?.detail?.[0]?.msg);
				}
				setPreviewApply(true);
				// Hide Custom section
				
			} else {
				setAnalysisData(previewDetails?.data);
				setPreviewApply(true);
			}
			await	setOriginalTabList(tabData);
			setTabData((prev) => (prev.filter((e) => e.id !== 6)));
				// if feature comparison is empty, hide tab
				console.log()
				if (analysisData?.feature_comparison.length <= 0) {
					setTabData((prev) => (prev.filter((e) => e.id !== 2)));
				}

		} catch (err) {
			console.log(err);
		}
		setPreviewLoading(false);
	};

	const handleBackToOriginal = () => {
		if (originalAnalysisData) setAnalysisData(originalAnalysisData);
		if(originalTabList) setTabData(originalTabList);
		setPreviewApply(false);
		setOriginalAnalysisData(null);
		setOriginalTabList([]);
	};

	const handleSave = async () => {
    setPreviewLoading(true);
    try {
      let res = await savePreviewUpdate(state?.singleRound?.round_id);
      if (res?.data) {
        setUpdateSingleRound((prev) => prev + 1);
        setTrackUpdatePayload((prev) => prev + 1);
        setPreviewState({});
        setPreviewDetails({});
      } else {
        toast.error(res?.detail ?? "Error in saving updates");
        setPreviewState({});
        setPreviewDetails({});
      }
    } catch (err) {
      console.log(err);
      toast.error("Error in saving updates");
      setPreviewState({});
      setPreviewDetails({});
    }
    setPreviewLoading(false);
  };

	const onFeatureComparisonContainerClick = (id) => {
		setActiveTab(id);
	};
	const handleRegenerateModal = (type = '') => {
		setRegenerateModal(true);
		setRegenerateType(type);
	};

	const handleRegenerate = async (isOnly) => {
		setRegenerateModal(false);
		setAdditionalModal(false);
		if (regenerateType == 'regenerateall') handleRengenarateAll(isOnly);
		else handleRegenerateSection(isOnly);
	};

	const handleRegenerateSection = async (isOnly) => {
		let id = metaChecked?.command_id;
		let text = metaChecked?.command_text;
		if (!id) {
			(id = null), (text = '');
		}
		setReLoading(true);
		if (regenerateType == 'custom') {
			setCurrentLoading(`${activeTab}`);
			let result = await generateCustomAnalysis(
				analysisData.analysis.analysis_id,
				activeTab,
				id,
				text
			);
			if (result?.data) {
				setAnalysisData({
					...analysisData,
					custom_section: {
						...analysisData.custom_section,
						custom_section: analysisData.custom_section.custom_section.map(
							(e) => (e.customsectiontitle === activeTab ? result.data : e)
						),
					},
				});
			}
			setRegenerateType('');
			setCurrentLoading('');
			setReLoading(false);
			setAdditionalModal(false);
			setRegenerateModal(false);
			return;
		}
		if (regenerateType) {
			setCurrentLoading(`${activeTab}-${regenerateType}`);
		} else {
			setCurrentLoading(`${activeTab}`);
		}

		function getcurrentTabName() {
			let name;

			switch (activeTab) {
				case 'About': {
					name = 'about';
					break;
				}
				case `Benefits of using (${
					state.singleRound.foundation?.foundation_name || 'Foundation Name'
				})`: {
					name = 'benefits';
					break;
				}

				case `Limitations of using (${
					state.singleRound.competitor?.competitor_name || 'Competitor Name'
				})`: {
					name = 'limitations';
					break;
				}
				case 'Key Distinctions': {
					name = 'key';
					break;
				}

				default:
					return;
			}
			return name;
		}

		let tabName = getcurrentTabName();
		let dataName = getcurrentTabName();
		if (regenerateType) {
			tabName = `${tabName}-${regenerateType}`;
			dataName = `${dataName}_${regenerateType}`;
		}
		if (tabName === 'benefits') {
			dataName = `${dataName}foundation`;
		}
		if (tabName === 'limitations') {
			dataName = `${dataName}competitor`;
		}
		if (tabName === 'key') {
			tabName = `${tabName}-distinctions`;
			dataName = `${dataName}_distinction`;
		}

		let res = await RegenerateAnalysisSection(
			tabName,
			analysisData.analysis.analysis_id,
			id,
			text
		);
		if (res?.data) {
			setAnalysisData({
				...analysisData,
				analysis: {
					...analysisData.analysis,
					[dataName]: res.data,
				},
			});
		}

		setRegenerateType('');
		setCurrentLoading('');
		setReLoading(false);
		setAdditionalModal(false);
		setRegenerateModal(false);
		setMetaChecked({});
	};

	async function handleRengenarateAll(isOnly, slc = 0, attributeList = []) {
		let newData = {};
		let oldCustomData = analysisData.custom_section.custom_section;
		let newCustomData = [];
		let newFeatureComparisonData = [];

		try {
			setCurrentLoading((prevLoading) => [
				...prevLoading,
				...linkData.slice(slc).map((key) => key.loading),
				'Feature Comparison',
				...tabData
					.filter((key) => key.type === 'custom')
					.map((key) => key.title),
			]);

			let resA = analysisData.feature_comparison.map((e) => e.attribute_data);
			if (attributeList.length > 0) {
				resA = attributeList;
			}

			if (resA.length <= 0) {
				setCurrentLoading((prevLoading) =>
					prevLoading?.filter((e) => e !== 'Feature Comparison')
				);
			}

			const customPromises = tabData
				.filter((key) => key.type === 'custom')
				.map(async (key) => {
					const res = await generateCustomAnalysis(
						analysisData.analysis.analysis_id,
						key.title,
						null,
						null
					);
					if (res?.data) {
						newCustomData = [...newCustomData, res.data];
						for (let i = 0; i < newCustomData.length; i++) {
							let index = oldCustomData.findIndex(
								(e) =>
									e.customsectiontitle === newCustomData[i].customsectiontitle
							);
							if (index > -1) oldCustomData[index] = newCustomData[i];
						}
						setAnalysisData({
							feature_comparison: newFeatureComparisonData,
							analysis: {
								...analysisData.analysis,
								...newData,
							},
							custom_section: {
								...analysisData.custom_section,
								custom_section: oldCustomData,
							},
						});
						toast.success(key?.title + ' is now available.');
					}

					setCurrentLoading((prevLoading) =>
						prevLoading?.filter((e) => e !== key.title)
					);
				});
			const predefinedPromises = linkData.slice(slc).map(async (key) => {
				const res = await RegenerateAnalysisSection(
					key.tabName,
					analysisData.analysis.analysis_id,
					null,
					null
				);
				if (res?.data) {
					toast.success(key?.loading + ' is now available.');
					newData = { ...newData, [key.dataName]: res.data };
					setAnalysisData({
						feature_comparison: newFeatureComparisonData,
						analysis: {
							...analysisData.analysis,
							...newData,
						},
						custom_section: {
							...analysisData.custom_section,
							custom_section: oldCustomData,
						},
					});
				}
				setCurrentLoading((prevLoading) =>
					prevLoading?.filter((e) => e !== key.loading)
				);
			});

			const featureComparisonPromise =
				resA.length > 0
					? getFeatureComparison(analysisData.analysis.analysis_id, resA).then(
							(res) => {
								if (res?.data) {
									newFeatureComparisonData = res.data;
									setAnalysisData({
										feature_comparison: newFeatureComparisonData,
										analysis: {
											...analysisData.analysis,
											...newData,
										},
										custom_section: {
											...analysisData.custom_section,
											custom_section: oldCustomData,
										},
									});
									toast.success('Feature Comparison is now available.');
								}
								setCurrentLoading((prevLoading) =>
									prevLoading.filter((e) => e !== 'Feature Comparison')
								);
							}
					  )
					: Promise.resolve();

			await Promise.allSettled([
				...customPromises,
				...predefinedPromises,
				featureComparisonPromise,
			]);

			setRegenerateType('');
			setReLoading(false);
			setAdditionalModal(false);
			setRegenerateModal(false);
		} catch (error) {
			console.error('Error in regeneration:', error);
		}
	}

	const handleAddtionalOptions = () => {
		setAdditionalModal(true);
	};

	const linkData = [
		{
			tabName: 'about-foundation',
			dataName: 'about_foundation',
			loading: 'About-foundation',
		},
		{
			tabName: 'about-competitor',
			dataName: 'about_competitor',
			loading: 'About-competitor',
		},
		{
			tabName: 'key-distinctions',
			dataName: 'key_distinction',
			loading: 'Key Distinctions',
		},
		{
			tabName: 'limitations',
			dataName: 'limitationscompetitor',
			loading: `Limitations of using (${
				state.singleRound.competitor?.competitor_name || 'Competitor Name'
			})`,
		},
		{
			tabName: 'benefits',
			dataName: 'benefitsfoundation',
			loading: `Benefits of using (${
				state.singleRound.foundation?.foundation_name || 'Foundation Name'
			})`,
		},
	];

	const renderValue = () => {
		switch (activeTab) {
			case 'About':
				return (
					<About
						previewEnabled={previewApply}
						existingRound={true}
						activeTab='About'
						handleRegenerate={handleRegenerateModal}
						setShowLoaderText={setShowLoaderText}
						analysisData={analysisData}
					/>
				);

			case 'Feature Comparison':
				return (
					<FeatureComparison
						previewEnabled={previewApply}
						existingRound={true}
						setAnalysisData={setAnalysisData}
						handleRegenerate={handleRegenerateModal}
						analysisData={analysisData}
					/>
				);

			case `Benefits of using (${
				state?.singleRound?.foundation?.foundation_name || 'Foundation Name'
			})`:
				return (
					<TabSectionTemplate
						previewEnabled={previewApply}
						existingRound={true}
						section_id='benefits_of_using_foundation'
						handleRegenerate={handleRegenerateModal}
						setShowLoaderText={setShowLoaderText}
						analysisData={analysisData}
					/>
				);

			case `Limitations of using (${
				state?.singleRound?.competitor?.competitor_name || 'Competitor Name'
			})`:
				return (
					<TabSectionTemplate
						previewEnabled={previewApply}
						existingRound={true}
						section_id='limitations_of_using_competitor'
						handleRegenerate={handleRegenerateModal}
						setShowLoaderText={setShowLoaderText}
						analysisData={analysisData}
					/>
				);
			case 'Key Distinctions':
				return (
					<TabSectionTemplate
						previewEnabled={previewApply}
						existingRound={true}
						section_id='key_distinction'
						handleRegenerate={handleRegenerateModal}
						setShowLoaderText={setShowLoaderText}
						analysisData={analysisData}
					/>
				);

			case 'Custom Section':
				return (
					<TabSectionTemplate
						previewEnabled={previewApply}
						existingRound={true}
						key='custom'
						setActiveTab={setActiveTab}
						tabData={tabData}
						heading='Add Custom Analysis Sections (Max 5)'
						custom
						setTabData={setTabData}
						analysisData={analysisData}
						section_id='custom'
						setAnalysisData={setAnalysisData}
						handleRegenerate={handleRegenerateModal}
						setUpdateSingleRound={setUpdateSingleRound}
					/>
				);

			default:
				return (
					<TabSectionTemplate
						previewEnabled={previewApply}
						existingRound={true}
						activeTab={activeTab}
						key={activeTab}
						setAnalysisData={setAnalysisData}
						type='custom'
						setActiveTab={setActiveTab}
						tabData={tabData}
						custom
						setTabData={setTabData}
						section_id='customgen'
						setShowLoaderText={setShowLoaderText}
						analysisData={analysisData}
						handleRegenerate={handleRegenerateModal}
					/>
				);
		}
	};

	return (
		<>
			<PrimaryModal
				primaryModalChildrenClass={styles.addEnrichmentChildren}
				title={' '}
				open={additoinalModal}
				setOpen={setAdditionalModal}
				modalStyleClass='w-[750px] h-[500px] m-auto top-1/2 transform -translate-y-1/2'>
				<AdditionalOptions
					metaChecked={metaChecked}
					setMetaChecked={setMetaChecked}
					activeTab={activeTab}
					setAdditionalModal={setAdditionalModal}
					handleRegenerate={handleRegenerate}
					loading={reLoading}
					showWarning={regenerateType === 'regenerateall'}
				/>
			</PrimaryModal>
			<PrimaryModal
				primaryModalChildrenClass={styles.addEnrichmentChildren}
				title={' '}
				open={regenerateModal}
				setOpen={setRegenerateModal}
				modalStyleClass='w-[750px] h-[500px] m-auto top-1/2 transform -translate-y-1/2'>
				<RegenrateModal
					activeTab={activeTab}
					loading={reLoading}
					handleRegenerate={handleRegenerate}
					handleAddtionalOptions={handleAddtionalOptions}
					showWarning={regenerateType === 'regenerateall'}
				/>
			</PrimaryModal>
			<UpdateReportModal
				open={showUpdateReportModal}
				onClose={() => setShowUpdateReportModal(false)}
				data={previewDetails}
			/>

			{previewLoading ? (
				<div className={'w-40 relative mx-auto my-16 text-[--dark-text]'}>
					<Lottie
						style={{
							height: '150px',
							width: '150px',
						}}
						animationData={AnalysisAniamtion}
						loop={true}
					/>
				</div>
			) : (
				<div className={styles.frameParent + ' mt-0'}>
					<div className={styles.foundationNameVsCompetitoParent}>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								width: '100%',
								justifyContent: 'space-between',
							}}>
							<div style={{ display: 'flex', gap: '15px' }}>
								{previewApply ? (
									<button
										onClick={() => setShowUpdateReportModal(true)}
										className='h-[30px] px-2.5 rounded border border-[--sec-btn-color] justify-center items-center gap-1 inline-flex'>
										<MoreVerticalCircle02Icon
											size={16}
											color={colorMode == 'light' ? '#3F628B' : '#5092E0'}
											variant={'stroke'}
											type='rounded'
										/>
										<span className="text-[--sec-btn-color] text-xs font-medium font-['Inter']">
											View Detected Updates Report from Track Job
										</span>
									</button>
								) : (
									<>
										<RegenerateButton
											id='regenerateAllBtn'
											type='regenerateall'
											dark={false}
											title='Regenerate All'
											handleRegenerate={handleRegenerateModal}
										/>
										<RegenerateButton
											id='exportAnalysisBtn'
											handleRegenerate={downloadExportAnalysis}
											analysis={analysisData}
											loading={downloadLoading}
											icon={
												<Download05Icon
													size={18}
													color={'#3F628B'}
													variant={'stroke'}
												/>
											}
											dark={false}
											title='Export Analysis'
										/>
									</>
								)}
							</div>
							<div className={styles.rightside_btn} style={{ width: '30%' }}>
								{(previewApply && (
									<>
										<button
											onClick={() => {
												handleBackToOriginal();
											}}
											className={styles['ignorebtn']}
											style={{ marginLeft: '13px' }}>
											<span> Revert to Previous Version</span>
										</button>
										<button
											onClick={async () => {
												await handleSave();
												setPreviewApply(false);
												className = 'hover:scale-105';
											}}
											style={{
												marginLeft: '13px',
												backgroundColor: '#ABD1FF',
												color: '#1E1E1E',
											}}>
											<span>Save Updated Analysis</span>
										</button>
									</>
								)) ||
									(previewState?.status && (
										<>
											<p style={{ marginLeft: '10px' }}>
												New Updates Available from Tracking!
											</p>
											<button
												onClick={async () => {
													await handlePreview();
												}}
												disabled={previewLoading}
												className='hover:scale-105'
												style={{ marginLeft: '13px' }}>
												{previewLoading && (
													<Lottie
														style={{
															height: '200px',
															width: '200px',
														}}
														animationData={enigmaAnimation}
														loop={true}
													/>
												)}
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width='17'
													height='16'
													viewBox='0 0 17 16'
													fill='none'>
													<path
														d='M8.33932 14.4296C11.8901 14.4296 14.7685 11.5512 14.7685 8.00046C14.7685 4.44972 11.8901 1.57129 8.33932 1.57129C4.78859 1.57129 1.91016 4.44972 1.91016 8.00046C1.91016 11.5512 4.78859 14.4296 8.33932 14.4296Z'
														stroke='white'
														stroke-width='0.86'
													/>
													<path
														d='M6.73438 7.48575V8.51467C6.73438 9.49145 6.73438 9.97981 7.02739 10.1773C7.32039 10.3746 7.72112 10.1562 8.52265 9.71943L9.46658 9.20491C10.4311 8.67926 10.9133 8.41643 10.9133 8.00021C10.9133 7.58398 10.4311 7.32116 9.46658 6.79551L8.52265 6.28101C7.72112 5.84419 7.32039 5.62578 7.02739 5.82318C6.73438 6.02058 6.73438 6.50896 6.73438 7.48575Z'
														fill='white'
													/>
												</svg>
												<span>Preview and Apply Latest Updates</span>
											</button>
											<button
												onClick={() => {
													setPreviewDetails({});
													setPreviewState({});
												}}
												className={styles['ignorebtn'] + ' hover:scale-105'}
												style={{ marginLeft: '13px' }}>
												<span>Ignore Update</span>
											</button>
										</>
									))}
							</div>
						</div>
					</div>

					<div className={styles.frameGroup}>
						<div className={styles.aboutParent + ' scrollbar-none'}>
							{tabData?.map((e) => (
								<div
									key={e.id}
									onClick={() => onFeatureComparisonContainerClick(e.title)}
									style={
										colorMode == 'light'
											? {
													backgroundColor:
														activeTab === e.title
															? 'rgba(30, 30, 30, 0.20)'
															: '',
													borderColor: activeTab === e.title ? '#1E1E1E' : '',
											  }
											: {
													backgroundColor:
														activeTab === e.title
															? 'rgba(225, 225, 225, 0.20)'
															: '',
													borderColor: activeTab === e.title ? '#E1E1E1' : '',
											  }
									}
									className={
										activeTab === e.title
											? styles.about
											: styles.featureComparison
									}>
									{previewApply &&
									previewDetails?.sections?.includes(e.title) ? (
										<div
											className={` w-max flex items-center gap-1
                        ${
													activeTab === e.title
														? styles.about1
														: styles.keyDistinctions
												}
                      `}>
											<InformationDiamondIcon
												size={16}
												color={'#0069E5'}
												variant={'stroke'}
												type='rounded'
											/>
											{e.title}
										</div>
									) : (
										<div
											className={
												activeTab === e.title
													? styles.about1 + ' w-max'
													: styles.keyDistinctions + ' w-max'
											}>
											{e.title}
										</div>
									)}
								</div>
							))}
						</div>
						{currentLoading.includes(activeTab) ||
						(activeTab.includes('About') &&
							currentLoading?.length > 0 &&
							typeof currentLoading != 'string' &&
							currentLoading?.some((itr) => itr?.includes('About'))) ? (
							<div className='w-full h-400'>
								<AnalysisLoader showLoaderText={showLoaderText} />
							</div>
						) : (
							renderValue()
						)}
					</div>
				</div>
			)}
		</>
	);
};

export default Analysis;
