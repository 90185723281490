import React, { useEffect, useRef, useState } from 'react';
import '../AnalysisPage/css/MainContent.css';
import FeatureComparison from '../AnalysisPage/FeatureComparison';
import ContentSection from './ContentSection';
import Sidebar from '../AnalysisPage/Sidebar';
import { Container, Grid } from '@mui/material';
import { Helmet } from 'react-helmet';
import { ResponsiveContainer } from 'recharts';

const MainContent = ({
	analysisContent = [],
	lastUpdated,
	lastUpdatedBool,
	navigationList,
	darkMode,
	pageTitle,
	metaDescription,
	styles,
}) => {
	const hasNoData = !analysisContent || analysisContent.length === 0;
	if (hasNoData) return null;

	const [activeSection, setActiveSection] = useState('');
	const [visibleSections, setVisibleSections] = useState([]);
	const sectionRefs = useRef({});
	const mainContentRef = useRef(null);
	const scrollTriggeredByClick = useRef(false);

	const shouldDisplaySection = (section) => {
		return (
			section.section_description &&
			section.section_description.length > 0 &&
			section.visibility === true
		);
	};

	useEffect(() => {
		const filteredSections = analysisContent.filter(shouldDisplaySection);
		setVisibleSections(filteredSections);
		if (filteredSections.length > 0) {
			const firstSectionId = filteredSections[0].analysis_section_id.toString();
			setActiveSection(firstSectionId);
			scrollToSection(firstSectionId);
		}
	}, [analysisContent]);

	useEffect(() => {
		const handleScroll = () => {
			if (!scrollTriggeredByClick.current) {
				updateActiveSection();
			}
		};

		const updateScrollTriggeredByClick = () => {
			scrollTriggeredByClick.current = false;
		};

		const mainContentElement = mainContentRef.current;
		if (mainContentElement) {
			mainContentElement.addEventListener('scroll', handleScroll);
			mainContentElement.addEventListener(
				'scrollend',
				updateScrollTriggeredByClick
			);
		}

		return () => {
			if (mainContentElement) {
				mainContentElement.removeEventListener('scroll', handleScroll);
				mainContentElement.removeEventListener(
					'scrollend',
					updateScrollTriggeredByClick
				);
			}
		};

	}, []);

	useEffect(() => {
		if (scrollTriggeredByClick.current) {
			scrollToSection(activeSection);
		}
	}, [activeSection]);

	const updateActiveSection = () => {
		const mainContentElement = mainContentRef.current;
		if (!mainContentElement) return;

		const scrollPosition = mainContentElement.scrollTop;
		const windowHeight = mainContentElement.clientHeight;

		let currentActiveSection = '';

		Object.entries(sectionRefs.current).forEach(([id, ref]) => {
			if (ref) {
				const sectionTop = ref.offsetTop - mainContentElement.offsetTop;
				const sectionBottom = sectionTop + ref.offsetHeight;

				if (
					scrollPosition >= sectionTop - windowHeight / 3 &&
					scrollPosition < sectionBottom - windowHeight / 3
				) {
					currentActiveSection = id;
				}
			}
		});

		if (currentActiveSection !== activeSection) {
			setActiveSection(currentActiveSection);
		}
	};

	const scrollToSection = (sectionId) => {
		const sectionElement = sectionRefs.current[sectionId];
		const mainContentElement = mainContentRef.current;

		if (sectionElement && mainContentElement) {
			const sectionTop =
				sectionElement.offsetTop - mainContentElement.offsetTop;
			mainContentElement.scrollTo({
				top: sectionTop,
				behavior: 'smooth',
			});
		}
	};

	const renderSection = (section) => {
		if (!section.visibility) return null;
		if (section.section_name === 'Feature Comparison') {
			return (
				<FeatureComparison
					key={section.analysis_section_id}
					sectionData={section}
					styles={styles}
				/>
			);
		}

		const content = Array.isArray(section.section_description)
			? section.section_description
			: section.section_description.split('\n');

		return (
			<ContentSection
				key={section.analysis_section_id}
				id={section.analysis_section_id.toString()}
				title={section.section_name}
				content={content}
				styles={styles}
			/>
		);
	};
	const visibleSectionsForSidebar = visibleSections.filter(
		(section) => section.visibility
	);

	return (
		<>
			{pageTitle && (
				<Helmet>
					<title>{pageTitle}</title>
					{metaDescription && (
						<meta name='description' content={metaDescription} />
					)}
				</Helmet>
			)}
			<Container sx={{ padding: ' 0 10px' }}>
				<Grid container spacing={2}>
					{navigationList && visibleSectionsForSidebar.length > 0 && (
						<Grid
							item
							xs={12}
							md={3}
							className='sidebar-container sticky-sidebar'>
							<Sidebar
								sideBarData={visibleSectionsForSidebar}
								activeSection={activeSection}
								setActiveSection={(id) => {
									scrollTriggeredByClick.current = true;
									setActiveSection(id);
								}}
								darkMode={darkMode}
								styles={styles}
							/>
						</Grid>
					)}
					<Grid item xs={12} md={navigationList ? 9 : 12}>
						<div
							className={`main-content ${
								darkMode ? 'dark-mode' : 'light-mode'
							}`}
							style={{
								height: 'calc(100vh - 50px)',
								overflowY: 'auto',
								scrollbarWidth: 'none',
							}}
							ref={mainContentRef}>
							{visibleSections.map((section) => (
								<div
									key={section.analysis_section_id}
									ref={(el) =>
										(sectionRefs.current[section.analysis_section_id] = el)
									}>
									{renderSection(section)}
								</div>
							))}
							{lastUpdatedBool && lastUpdated && (
								<div className='last-updated'>
									<span className='update-text'>
										Last Updated: {lastUpdated}
									</span>
								</div>
							)}
						</div>
					</Grid>
				</Grid>
			</Container>
		</>
	);
};

export default MainContent;
