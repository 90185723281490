import React, { useContext, useState } from 'react';
import styles from './index.module.css';
import Dot from '../../assets/icons/dot.svg';
import { deleteRound } from '../../services';
import { OutpointContext } from '../../Context/OutpointsContext/Index';
import { CircularProgress } from '@mui/material';
import { UPDATE_ROUNDS_DASHBOARD } from '../../Context/OutpointsContext/action';
import { toast } from 'react-toastify';
import { PrimaryModal } from '../Common/PrimaryModal';
import { DeleteConfigurationModal } from '../NewRound/SuccessRoundModal';
import AccessBlocked from '../Common/AccessBlocked';
import { useTheme } from '../../Context/ThemContext/ThemeContext';
import { SubscriptionContext } from '../../Context/SubscriptionContext';

const DraftFoundationCard = ({ item, handleStepperModal }) => {
	const [deleteLoading, setDeleteLoading] = useState(false);
	const { dispatch } = useContext(OutpointContext);
	const [deteleModal, setDeleteModal] = useState(false);
	const { fetchSubscriptionData } = useContext(SubscriptionContext);

	const handleDelete = async () => {
		setDeleteModal(true);
		
	};

	const handleDeleteRound = async () => {
		setDeleteLoading(true);
		try {
			let res = await deleteRound(item.round_id);
			if (res?.message) {
				toast.success(res.message);
				setDeleteModal(false);
				dispatch({
					type: UPDATE_ROUNDS_DASHBOARD,
					payload: 1,
				});
			}
			if (res?.detail[0]?.msg) {
				toast.error(res.detail[0].msg);
			}
		} catch (err) {
			console.log(err);
		}
		setDeleteLoading(false);
		fetchSubscriptionData();
	};

	const { colorMode } = useTheme();

	return (
		<>
			<PrimaryModal
				primaryModalChildrenClass={styles.addEnrichmentChildren}
				title={' '}
				open={deteleModal}
				modalStyleClass='w-[750px] h-[500px] m-auto top-1/2 transform -translate-y-1/2'
				setOpen={setDeleteModal}>
				<DeleteConfigurationModal
					setDeleteModal={setDeleteModal}
					// setDeleteSuccessModal={setDeleteSuccessModal}
					handleDeleteRound={handleDeleteRound}
					deleteLoading={deleteLoading}
					roundName={item.foundation.foundation_name}
				/>
			</PrimaryModal>

			<div
				className={`${styles.frameParent} ${colorMode === 'dark' ? styles.darkShadow : styles.lightShadow
					}`}>
				<AccessBlocked locked={item?.subscription_lock} />
				<div className={styles.frameWrapper}>
					<div className={styles.foundationNameParent}>
						<div className={styles.foundationName}>
							{item?.foundation?.foundation_name}
						</div>
						<div className={styles.vectorParent}>
							<img className={styles.vectorIcon} alt='' src={Dot} />
							<img className={styles.vectorIcon} alt='' src={Dot} />
							<img className={styles.vectorIcon} alt='' src={Dot} />
						</div>
					</div>
				</div>
				<div className={styles.frameGroup}>
					<div className={styles.frameContainer}>
						<div className={styles.createdOnParent}>
							<div className={styles.createdOn}>Created on:.</div>
							<div className={styles.july4th2024}>{item?.created_on}</div>
						</div>
					</div>
					<div className={styles.frameDiv}>
						<div
							style={{
								cursor: 'pointer',
							}}
							onClick={() => handleStepperModal(true, item)}
							className={`${styles.resumeWrapper} hover:scale-105`}>
							<div className={styles.resume}>Resume</div>
						</div>
						<div
							style={{
								cursor: 'pointer',
								opacity: deleteLoading == item.round_id ? '0.5' : '1',
								pointerEvents: deleteLoading == item.round_id ? 'none' : 'auto',
								position: 'relative',
							}}
							className={styles.deleteDraftWrapper}
							onClick={() => handleDelete()}>
							<div className={`${styles.resume} hover:scale-105`}>
								{' '}
								Delete Draft
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default DraftFoundationCard;
