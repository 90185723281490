import { Modal, Grow } from '@mui/material';
import './index.css';
import CancelIcon from '../../../assets/icons/cancel.svg';
import { useTheme } from '../../../Context/ThemContext/ThemeContext';
import { ColorPattern } from '../../../constant/Color';

export const PrimaryModal = (props) => {
	const {
		hideCloseIcon = false,
		open,
		setOpen,
		children,
		title,
		titleContainerClass,
		sx = {},
		primaryModalContainerClass,
		primaryModalChildrenClass,
		disableEscapeKeyDown = false,
		modalStyleClass = '',
		parentModalCloseFunc,
	} = props;
	const { colorMode } = useTheme();
	return (
		<Modal
			disableEscapeKeyDown={disableEscapeKeyDown}
			open={open}
			onClose={() => {
				setOpen(false);
				if (parentModalCloseFunc) {
					parentModalCloseFunc();
				}
			}}
			sx={{
				'& .MuiBackdrop-root': {
					background: ColorPattern[colorMode].bgColor,
					opacity: '0.8 !important',
				},
				...sx,
			}}>
			<Grow in={open}>
				<div
					style={{
						outline: 'none',
						// opacity: open ? 1 : 0,
						// transition: 'opacity 1s',
					}}
					className={`primary-modal-container  ${
						primaryModalContainerClass ?? ''
					}`}>
					<div
						style={{
							translateY: '50%',
						}}
						className={'primary-modal-contents ' + modalStyleClass}>
						<div className='primary-modal-header'>
							{title && (
								<div className={titleContainerClass ?? ''}>{title}</div>
							)}
							{!hideCloseIcon && (
								<img
									className='close-icon-primary-modal'
									onClick={() => {
										if (parentModalCloseFunc) {
											parentModalCloseFunc();
										}
										setOpen(false);
									}}
									src={CancelIcon}
								/>
							)}
						</div>
						<div className={primaryModalChildrenClass ?? 'modal-children'}>
							{children}
						</div>
					</div>
				</div>
			</Grow>
		</Modal>
	);
};
