import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './index.module.css';
import EmailPage from './SidePage/EmailPage';
import SubscriptionPage from './SidePage/SubscriptionPage';
import AccountPage from './SidePage/AccountPage';
import { getNotification } from '../../services';
import RightArrow from '../../assets/icons/SidePanel/arrow-left.svg';
import Subscriptions from '../../assets/icons/SidePanel/subscription-icon.svg';
import EmailIcon from '../../assets/icons/SidePanel/email-icon.svg';
import AccountIcon from '../../assets/icons/SidePanel/account-setting-icon.svg';

const Settings = () => {
	const [currentPage, setCurrentPage] = useState('account');
	const [loading, setLoading] = useState(null);
	const [updateEmailState, setUpdateEmailState] = useState(0);
	const [dataState, setDataState] = useState({
		track_job_completed: true,
		track_job_activtaed: true,
		track_job_paused_disabled: true,
		usage_limit_reached: true,
		neweletter_subscription: true,
		marketing_email_subscription: true,
	});

	const location = useLocation();
	const navigate = useNavigate(); // useNavigate to update the URL

	// Effect to set current page based on URL param
	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);
		const tab = searchParams.get('tab');
		if (tab) {
			setCurrentPage(tab);
		}
	}, [location]);

	// Handle tab change and update URL
	const handleTabChange = (tabTitle) => {
		navigate(`?tab=${tabTitle}`); // Update the URL with the new tab
	};

	const data = [
		{
			name: 'Account',
			title: 'account',
			icon: AccountIcon,
		},
		{
			name: 'Subscription & Billing',
			title: 'subscription',
			icon: Subscriptions,
		},
		{
			name: 'Email Notifications',
			title: 'email',
			icon: EmailIcon,
		},
	];

	// Fetch notification data
	const getNotificationData = async () => {
		setLoading('email');
		try {
			let res = await getNotification();
			if (res?.data) {
				setDataState({ ...dataState, ...res.data });
			}
		} catch (err) {
			console.log(err);
		}
		setLoading(null);
	};

	useEffect(() => {
		getNotificationData();
	}, [updateEmailState]);

	return (
		<div className={styles.container}>
			<div className={styles.containerLayout}>
				<div className={styles.settingsFrame}>
					<div className={styles.frameParent}>
						<div className={styles.frameGroup}>
							{data.map((e) => (
								<div
									key={e.title}
									onClick={() => handleTabChange(e.title)}
									className={
										e.title === currentPage
											? styles.mail01Parent
											: styles.accountSetting01Parent
									}>
									<img
										className={styles.accountSetting01Icon}
										alt={e.name}
										src={e.icon}
										width={30}
										height={30}
										style={{ marginBottom: '6px' }}
									/>
									<div
										className={
											e.title === currentPage
												? styles.emailNotifications
												: styles.account
										}>
										{e.name}
									</div>
									{e.title === currentPage && (
										<img
											className={styles.arrowLeft01Icon}
											alt='Right Arrow'
											src={RightArrow}
										/>
									)}
								</div>
							))}
						</div>
					</div>
				</div>
				<div className={styles.settingsContainer}>
					{currentPage === 'email' && (
						<EmailPage
							loading={loading}
							setUpdateEmailState={setUpdateEmailState}
							dataState={dataState}
							setDataState={setDataState}
						/>
					)}
					{currentPage === 'subscription' && <SubscriptionPage />}
					{currentPage === 'account' && <AccountPage />}
				</div>
			</div>
		</div>
	);
};

export default Settings;
