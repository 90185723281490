import { CircularProgress } from "@mui/material";
import React from "react";

const LoadingPage = ({ width, minHeight }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: width ?? "300px",
        minHeight: minHeight ?? "50px",
        justifyContent: "center",
      }}
    >
      <CircularProgress
        sx={{
          color: "blue",
          height: "30px !important",
          width: "30px !important",
        }}
      />
    </div>
  );
};

export default LoadingPage;
