import NoDrafts from '../NoDrafts/index.module.css';
import ErrorIcon from '../../../assets/icons/blockers/erroricon.svg';
import { Link } from 'react-router-dom';
// import NoDraftsIcon from "../../../assets/icons/no-drafts.svg";

const ErrorBlockerPage = () => {
	return (
		<div>
			<div className={NoDrafts.intel_rounds_container}>
				<div className={NoDrafts.drafts_container}>
					<div className={NoDrafts.drafts_icon_wrapper}>
						<img
							src={ErrorIcon}
							alt={ErrorIcon}
							className={NoDrafts.drafts_icon}
							width={100}
							height={100}
						/>
					</div>
					<div className={NoDrafts.drafts_message_wrapper}>
						<div className={NoDrafts.no_drafts_message}>
							We apologize, but this section is currently not available.
						</div>
					</div>
				</div>
				<div className={NoDrafts.active_rounds_button_wrapper}>
					<div className={NoDrafts.active_rounds_button}>
						<Link to='/' className={NoDrafts.button_text}>
							Return Back
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};
export default ErrorBlockerPage;
