import React, { useContext, useEffect, useState } from "react";
import stylestwo from "../test.module.css";
import Image1 from "../../../assets/icons/image 1.png";
import Arrow from "../../../assets/icons/arrow-left-double.svg";
import { CircularProgress } from "@mui/material";
import { getUpdateAndTrack, runTrackAndUpdate } from "../../../services";
import TrackandUpdateModal from "./TrackAndUpdateModal";
import Lottie from "lottie-react";
import runningDot from "./runningDot.json";
import { useTheme } from "../../../Context/ThemContext/ThemeContext";
import ReactPaginate from "react-paginate";
import TrackRunningModal from "./TrackRunningModal";
import { useLocation } from "react-router-dom";
import { OutpointContext } from "../../../Context/OutpointsContext/Index";

const trackModalType = {
  WARN: "warn",
  RUNNING: "running",
};

const Trackandupdate = ({
  setSuccessModal,
  trackingFeatureEnabled,
  trackStatusPaused,
  setResumeModal,
  setPauseModal,
  data,
  trackLoading,
  handlePauseActive,
  pauseLoading,
  setPreviewState,
  setActive,
  reloadData,
  getTrackAndUpdateData,
}) => {
  const { state } = useContext(OutpointContext);
  const [currentPage, setCurrentPage] = useState(0); // Changed to 0-based for ReactPaginate
  const [showModal, setShowModal] = useState(false);
  const [runLoading, setRunLoading] = useState(false);
  const rowsPerPage = 3;
  const location = useLocation();
  const roundId = new URLSearchParams(location.search).get("round_id");

  // Get current items for the page
  const currentData = data?.data?.slice(
    currentPage * rowsPerPage,
    (currentPage + 1) * rowsPerPage
  );

  // console.log("currentData", currentData);

  // console.log("currentData", (currentData?.length === 0 || currentData === undefined || (currentData?.length > 0 && currentData[0]?.status !== "Running")));

  useEffect(() => {
    reloadData((prev) => prev + 1);
  }, []);

  const handleRun = async () => {
    setRunLoading(true);
    try {
      let res = await runTrackAndUpdate(roundId);
      if (res?.success == true) {
        getTrackAndUpdateData();
        setShowModal(trackModalType.RUNNING);
      } else toast.error(res?.detail);
    } catch (err) {
      console.log(err);
    }
    setRunLoading(false);
  };

  // Updated page change handler for ReactPaginate
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handlePause = async () => {
    if (trackStatusPaused) {
      await handlePauseActive();
    } else {
      setPauseModal(true);
    }
  };

  const handleUpdateAnalysis = () => {
    setPreviewState({ status: true, id: data?.data[0]?.update_id });
    setActive(1);
  };

  const { colorMode } = useTheme();

  // Calculate total page count
  const pageCount = Math.ceil((data?.data?.length || 0) / rowsPerPage);

  console.log(currentData);

  return (
    <>
      <>
        {trackLoading ? (
          <div className="w-full h-60 flex items-center justify-center">
            <CircularProgress />
          </div>
        ) : (
          <>
            {
              <div className={stylestwo.bottomSectionTab1}>
                <div className={stylestwo.bottomTextContainer}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    Track Job History{" "}
                    {trackingFeatureEnabled && (
                      <span style={{ color: "#0069E5", marginLeft: "5px" }}>
                        ({data?.data?.length || 0})
                      </span>
                    )}
                    <svg
                      style={{ marginLeft: "7px" }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                    >
                      <path
                        d="M14.7201 7.99967C14.7201 4.31777 11.7353 1.33301 8.05339 1.33301C4.37149 1.33301 1.38672 4.31777 1.38672 7.99967C1.38672 11.6815 4.37149 14.6663 8.05339 14.6663C11.7353 14.6663 14.7201 11.6815 14.7201 7.99967Z"
                        stroke="#556E8C"
                      />
                      <path
                        d="M8.21745 11.333V7.99967C8.21745 7.68541 8.21745 7.52827 8.11978 7.43061C8.02218 7.33301 7.86505 7.33301 7.55078 7.33301"
                        stroke="#556E8C"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8.05078 5.33301H8.05098"
                        stroke="#556E8C"
                        stroke-width="1.6"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
                {trackingFeatureEnabled ? (
                  <>
                    {/* Rest of your existing JSX code remains the same until the pagination */}
                    <div className="text-[#797b7e] text-xs font-normal font-['Inter'] leading-[15px] mt-4 ">
                      Next Track Job: {state?.singleRound?.next_trigger_date}
                    </div>
                    <div
                      className={
                        stylestwo.bottomSe +
                        " flex justify-between flex-wrap gap-2 relative"
                      }
                    >
                      <div>
                        <span>
                          {data?.competitor_source_count} Tracked Sources
                          <span style={{ color: "#797B7E" }}>
                            (from the Competitor)
                          </span>
                        </span>
                        <span className={stylestwo.divider}></span> 
                        <span>
                          {data?.foundation_source_count} Tracked Sources{" "}
                          <span style={{ color: "#797B7E" }}>
                            (from the Foundation)
                          </span>
                        </span>
                      </div>
                      {/* {(currentData?.length == 0 ||
                        (currentData?.length > 0 &&
                          !!currentData[0]?.status)) && ( */}

                      {(data?.data?.length == 0 ||
                        data?.data === undefined ||
                        (data?.data?.length > 0 &&
                          data?.data[0]?.status !== "Running")) && (
                          <div className="w-full xl:w-auto flex justify-end">
                            <button
                              style={{
                                border: "solid 0.5px #3F628B",
                                marginRight: "15px",
                                position: "relative",
                              }}
                              disabled={pauseLoading}
                              onClick={handlePause}
                              className={`${stylestwo.btnthree} hover:scale-105 flex gap-2`}
                            >
                              {pauseLoading ? (
                                <CircularProgress
                                  sx={{
                                    color: "white",
                                    height: "16px !important",
                                    width: "16px !important",
                                    position: "relative",
                                  }}
                                />
                              ) : trackStatusPaused ? (
                                <>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="17"
                                    height="17"
                                    viewBox="0 0 17 17"
                                    fill="none"
                                  >
                                    <path
                                      d="M6.38672 7.96686V9.03379C6.38672 10.0467 6.38672 10.5531 6.69056 10.7578C6.99439 10.9625 7.40992 10.736 8.24105 10.2831L9.21985 9.74953C10.22 9.20446 10.7201 8.93193 10.7201 8.50033C10.7201 8.06873 10.22 7.79619 9.21985 7.25113L8.24105 6.71762C7.40992 6.26466 6.99439 6.03818 6.69056 6.24287C6.38672 6.44757 6.38672 6.95399 6.38672 7.96686Z"
                                      fill="#3F628B"
                                    />
                                  </svg>
                                  <span
                                    style={{ color: "#3F628B", fontSize: "12px" }}
                                  >
                                    Activate Tracking
                                  </span>
                                </>
                              ) : (
                                <>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="17"
                                    height="17"
                                    viewBox="0 0 17 17"
                                    fill="none"
                                  >
                                    <path
                                      d="M2.72266 5.16699C2.72266 4.22419 2.72266 3.75278 3.01555 3.45989C3.30844 3.16699 3.77985 3.16699 4.72266 3.16699C5.66546 3.16699 6.13687 3.16699 6.42976 3.45989C6.72266 3.75278 6.72266 4.22419 6.72266 5.16699V11.8337C6.72266 12.7765 6.72266 13.2479 6.42976 13.5408C6.13687 13.8337 5.66546 13.8337 4.72266 13.8337C3.77985 13.8337 3.30844 13.8337 3.01555 13.5408C2.72266 13.2479 2.72266 12.7765 2.72266 11.8337V5.16699Z"
                                      stroke="#3F628B"
                                    />
                                    <path
                                      d="M9.38672 5.16699C9.38672 4.22419 9.38672 3.75278 9.67959 3.45989C9.97252 3.16699 10.4439 3.16699 11.3867 3.16699C12.3295 3.16699 12.8009 3.16699 13.0939 3.45989C13.3867 3.75278 13.3867 4.22419 13.3867 5.16699V11.8337C13.3867 12.7765 13.3867 13.2479 13.0939 13.5408C12.8009 13.8337 12.3295 13.8337 11.3867 13.8337C10.4439 13.8337 9.97252 13.8337 9.67959 13.5408C9.38672 13.2479 9.38672 12.7765 9.38672 11.8337V5.16699Z"
                                      stroke="#3F628B"
                                    />
                                  </svg>
                                  <span
                                    style={{ color: "#3F628B", fontSize: "12px" }}
                                  >
                                    Pause Tracking
                                  </span>
                                </>
                              )}
                            </button>
                            <button
                              style={{
                                border: "solid 0.5px #3F628B",
                                backgroundColor: "#005FCE",
                              }}
                              onClick={() => setShowModal(trackModalType.WARN)}
                              // onClick={handleRun}
                              className={`${stylestwo.btnthree} hover:scale-105`}
                            >
                              {runLoading && (
                                <CircularProgress
                                  sx={{
                                    color: "black",
                                    height: "20px !important",
                                    width: "20px !important",
                                    position: "absolute",
                                    top: "5px",
                                  }}
                                />
                              )}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="17"
                                height="17"
                                viewBox="0 0 17 17"
                                fill="none"
                              >
                                <path
                                  d="M10.7227 3.16634V1.83301M12.7227 3.82816L13.7947 2.75609M13.3893 5.83301H14.7227"
                                  stroke="white"
                                  stroke-width="1.2"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M13.3867 9.16699C13.3867 12.4807 10.7005 15.167 7.38672 15.167C4.07301 15.167 1.38672 12.4807 1.38672 9.16699C1.38672 5.85329 4.07301 3.16699 7.38672 3.16699"
                                  stroke="white"
                                  stroke-width="1.2"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M1.69922 10.5111C3.57057 10.4599 7.3686 9.82073 9.8528 6.77295M7.37873 6.50391L10.0637 6.50407V9.14973"
                                  stroke="white"
                                  stroke-width="1.2"
                                />
                              </svg>
                              <span style={{ color: "white", fontSize: "12px" }}>
                                Run a Track Job Now
                              </span>
                            </button>
                          </div>
                        )}
                    </div>

                    {/* Display the current items */}
                    <div className={stylestwo.cardcontainer}>
                      {currentData?.map((item, index) => (
                        <div
                          key={index}
                          className={`${stylestwo.card} ${colorMode === "dark"
                            ? stylestwo.darkShadow
                            : stylestwo.lightShadow
                            }`}
                        >
                          {item?.status ? (
                            <>
                              <p>Running a Track Job Now</p>
                              <span className="updates-detected">
                                Sharbo AI will email you when the Track Job is
                                complete. Please return to this tab later to
                                check the status.
                              </span>
                            </>
                          ) : (
                            <>
                              <p>
                                {item.competitor_count} Updates Detected from
                                the Competitor Sources
                              </p>
                              <span className="updates-detected">
                                {item.foundation_count} Updates Detected from
                                the Foundation Sources
                              </span>
                            </>
                          )}
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <span
                              style={{ color: "#556E8C", fontSize: "12px" }}
                            >
                              Tracked on: {item.tracked_on}
                            </span>
                            {!!item?.status ? (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "flex-end",
                                }}
                              >
                                <div className={stylestwo.noImplementation}>
                                  {item?.status}
                                </div>
                                <Lottie
                                  style={{
                                    height: "70px",
                                    width: "70px",
                                    marginBottom: "-30px",
                                    marginLeft: "-20px",
                                  }}
                                  animationData={runningDot}
                                  loop={true}
                                />
                              </div>
                            ) : item.update_id == data?.data[0]?.update_id &&
                              !item.implemented ? (
                              item?.competitor_count > 0 ||
                                item.foundation_count > 0 ? (
                                <button
                                  onClick={handleUpdateAnalysis}
                                  style={{ border: "solid 0.429px #0069E5" }}
                                  className={`${stylestwo.btnthree} hover:scale-105`}
                                >
                                  <span>Implement Updates to Analysis</span>
                                  <img src={Arrow} alt="arrow" />
                                </button>
                              ) : (
                                <div
                                  className={
                                    stylestwo.implementUpdatesToAnalysisParent
                                  }
                                >
                                  <div className={stylestwo.implementUpdatesTo}>
                                    Implement Updates to Analysis:
                                  </div>
                                  <div className={stylestwo.noImplementation}>
                                    {item.implemented
                                      ? "implemented"
                                      : "No implementation"}
                                  </div>
                                </div>
                              )
                            ) : (
                              <div
                                className={
                                  stylestwo.implementUpdatesToAnalysisParent
                                }
                              >
                                <div className={stylestwo.implementUpdatesTo}>
                                  Implement Updates to Analysis:
                                </div>
                                <div className={stylestwo.noImplementation}>
                                  {item.implemented
                                    ? "implemented"
                                    : "No implementation"}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                ) : (
                  <div className={stylestwo.disabledbox}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="62"
                      height="60"
                      viewBox="0 0 62 60"
                      fill="none"
                    >
                      <path
                        d="M12.8633 13.0391L47.0233 46.9596"
                        stroke="#A0A8BE"
                        stroke-width="4.56075"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M54.343 29.9995C54.343 16.6182 43.4186 5.77051 29.943 5.77051C16.4672 5.77051 5.54297 16.6182 5.54297 29.9995C5.54297 43.3807 16.4672 54.2284 29.943 54.2284C43.4186 54.2284 54.343 43.3807 54.343 29.9995Z"
                        stroke="#A0A8BE"
                        stroke-width="4.56075"
                      />
                    </svg>
                    <p>Tracking feature is currently disabled</p>
                    <span>
                      To enable it, go to "
                      <span
                        className="cursor-pointer text-[#0069E5] underline"
                        onClick={() => setActive(3)}
                      >
                        Configurations
                      </span>
                      " in this dashboard
                    </span>
                  </div>
                )}
              </div>
            }
          </>
        )}
      </>
      <TrackandUpdateModal
        open={showModal && showModal == trackModalType.WARN}
        closeModal={() => setShowModal(false)}
        onClick={handleRun}
      />
      <TrackRunningModal
        open={showModal && showModal == trackModalType.RUNNING}
        closeModal={() => setShowModal(false)}
      />
      {data?.data?.length > 3 && (
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          breakLabel={"..."}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageChange}
          containerClassName={stylestwo.pagination}
          pageClassName={stylestwo.pageItem}
          pageLinkClassName={stylestwo.pageLink}
          previousClassName={stylestwo.pageItem}
          previousLinkClassName={stylestwo.pageLink}
          nextClassName={stylestwo.pageItem}
          nextLinkClassName={stylestwo.pageLink}
          breakClassName={stylestwo.pageItem}
          breakLinkClassName={stylestwo.pageLink}
          activeClassName={stylestwo.activePage}
          disabledClassName={stylestwo.disabled}
          forcePage={currentPage}
        />
      )}
    </>
  );
};

export default Trackandupdate;
