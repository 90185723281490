import React, { useContext, useEffect, useState } from "react";
import styles from "./index.module.css";
import { toast } from "react-toastify";
import LoadingPage from "../Common/Loading";
import { CircularProgress } from "@mui/material";
import { OutpointContext } from "../../Context/OutpointsContext/Index";
import { SET_PATH_NAME } from "../../Context/OutpointsContext/action";
import AccessBlocked from "../Common/AccessBlocked";
import { useTheme } from "../../Context/ThemContext/ThemeContext";
import { PrimaryModal } from "../Common/PrimaryModal";

const FoundationCard = ({
  foundation,
  handleOpenModal,
  handleCurrentModal,
  data,
  compLoading,
  handleCreateRound,
  createLoading,
  activeRoundObj,
  setDeleteModal,
  setCurrentDeleteFoundation,
}) => {
  const { colorMode } = useTheme();

  console.log(activeRoundObj);

  return (
    // <div className={styles.frameParent}>
    <div
      className={`${styles.frameParent} ${
        colorMode === "dark" ? styles.darkShadow : styles.lightShadow
      }`}
    >
      <AccessBlocked
        locked={foundation?.subscription_lock}
        source="Foundation"
      />
      <div className={styles.frameWrapper}>
        <div className={styles.foundationNameParent}>
          <div className={styles.foundationName}>
            {foundation.foundation_name || "Foundation Name"}
          </div>
          {/* <div
            style={{
              cursor: "pointer",
            }}
            onClick={() => handleCurrentModal(2, foundation)}
            className={styles.frameContainer}
          > */}
          <div
            style={{
              cursor:
                activeRoundObj?.[foundation?.foundation_id]?.count > 0
                  ? "pointer"
                  : "not-allowed",
              opacity:
                activeRoundObj?.[foundation?.foundation_id]?.count > 0
                  ? 1
                  : 0.5,
            }}
            onClick={() => {
              if (activeRoundObj?.[foundation?.foundation_id]?.count > 0) {
                handleCurrentModal(2, foundation);
              }
            }}
            className={styles.frameContainer}
          >
            <div className={styles.activeIntelRoundsWrapper}>
              <div className={styles.activeIntelRounds}>
                {activeRoundObj?.[foundation?.foundation_id]?.count} Active
                Rounds
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.frameGroup}>
        <div className={styles.frameDiv}>
          <div
            style={{
              cursor: "pointer",
            }}
            onClick={() => handleOpenModal(true, foundation)}
            className={`${styles.editSourcesWrapper} hover:scale-105`}
          >
            <div className={styles.editSources}>Edit Sources</div>
          </div>
          <div
            style={{
              cursor: "pointer",
              pointerEvents:
                data?.[foundation?.foundation_id]?.count === 0 || compLoading
                  ? "none"
                  : "auto",
            }}
            onClick={() => handleCurrentModal(1, foundation)}
            className={`${styles.savedCompetitors20Wrapper} hover:scale-105`}
          >
            {compLoading ? (
              <CircularProgress
                sx={{
                  color: "blue",
                  height: "10px !important",
                  width: "10px !important",
                }}
              />
            ) : (
              <div className={styles.foundationName}>
                Saved Competitors ({data?.[foundation?.foundation_id]?.count})
              </div>
            )}
          </div>
          {!(foundation?.active_rounds + foundation?.draft_rounds > 0) && (
            <button
              onClick={() => {
                setCurrentDeleteFoundation({
                  foundation_name: foundation.foundation_name,
                  foundation_id: foundation.foundation_id,
                });
                setDeleteModal(true);
              }}
              className={`${styles.deletebtn} hover:scale-105`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
              >
                <path
                  d="M12.5921 4.32227L12.1937 10.767C12.0919 12.4135 12.041 13.2368 11.6283 13.8288C11.4242 14.1214 11.1615 14.3684 10.8568 14.554C10.2406 14.9294 9.41572 14.9294 7.76595 14.9294C6.11408 14.9294 5.28813 14.9294 4.67147 14.5533C4.36659 14.3674 4.10379 14.1199 3.8998 13.8268C3.48721 13.2339 3.43744 12.4095 3.3379 10.7606L2.94922 4.32227"
                  stroke="#F24F4F"
                  stroke-width="0.964286"
                  stroke-linecap="round"
                />
                <path
                  d="M1.98438 4.32227H13.5558M10.3773 4.32227L9.93851 3.41695C9.64698 2.81558 9.50117 2.51489 9.24975 2.32736C9.19402 2.28576 9.13494 2.24876 9.07316 2.21672C8.79474 2.07227 8.46058 2.07227 7.79227 2.07227C7.10717 2.07227 6.76466 2.07227 6.4816 2.22277C6.41887 2.25613 6.359 2.29463 6.30263 2.33787C6.04829 2.533 5.90621 2.84469 5.62205 3.46808L5.23268 4.32227"
                  stroke="#F24F4F"
                  stroke-width="0.964286"
                  stroke-linecap="round"
                />
              </svg>
              <span>Delete Foundation</span>
            </button>
          )}
        </div>
        <div
          id="createRoundViaFoundation"
          role="button"
          style={{
            cursor: "pointer",
            pointerEvents: createLoading ? "none" : "auto",
          }}
          disabled={createLoading}
          onClick={() => handleCreateRound(foundation)}
          className={`${styles.showAvailabilityOnlyexpande} hover:scale-105`}
        >
          <div className={styles.cancel01} />
          <div className={styles.cancel011} />
          <div className={styles.cancel012} />
          <div className={styles.cancel013} />
          <div className={styles.cancel014} />
          <div className={styles.cancel015} />
          <div className={styles.multiplicationSign} />
          <div className={styles.multiplicationSign1} />
          <div className={styles.multiplicationSign2} />
          <div className={styles.cancel016} />
          {createLoading == foundation.foundation_id && (
            <CircularProgress
              sx={{
                color: "blue",
                height: "10px !important",
                width: "10px !important",
              }}
            />
          )}
          <div className={styles.createANew}>
            Create a New Round with this Foundation
          </div>
        </div>
      </div>
    </div>
  );
};

export default FoundationCard;
