import React from 'react';
import { UserProfile } from '@clerk/clerk-react';
import { useTheme } from '../../../../Context/ThemContext/ThemeContext';
import { dark } from '@clerk/themes';
import ErrorBoundary from '../../../../Components/Blockers/ErrorBoundary';

const AccountPage = () => {
	const { colorMode } = useTheme();

	const appearance = {
		baseTheme: colorMode === 'dark' ? dark : 'light',
	};

	return (
		<div
			style={{
				width: '100%',
				overflowX: 'scroll',
			}}>
			<div
				style={{
					width: 'fit-content',
					padding: '5px 20px 50px',
				}}>
				<ErrorBoundary>
					<UserProfile appearance={appearance} />
				</ErrorBoundary>
			</div>
		</div>
	);
};

export default AccountPage;
