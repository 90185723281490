export const stepperData = [
  {
    id: 0,
    label: "Foundation",
    value:"foundation"
  },
  {
    id: 1,
    label: "Competitor",
    value:"competitor"
  },
  {
    id: 2,
    label: "Analysis",
    value:"analysis"
  },
  {
    id: 3,
    label: "Page",
    value:"pages"
  },
  {
    id: 4,
    label: "Track",
    value:"track"
  },
];
