import React from "react";
import { useTheme } from "../../../Context/ThemContext/ThemeContext";

const TrackAccessModal = ({ closeModal }) => {
  const { colorMode } = useTheme();
  return (
    <div
      className={`fixed w-[750px] min-h-[500px] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 ${colorMode == "dark" ? "bg-[#131314]" : "bg-white"
        } flex flex-col justify-center items-center gap-5 p-[7.2rem]`}
    >
      <button
        onClick={closeModal}
        className="absolute top-8 right-8 cursor-pointer"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="23"
          height="23"
          viewBox="0 0 23 23"
          fill="none"
        >
          <path
            d="M18.1415 4.77441L4.77344 18.1424M4.77344 4.77441L18.1415 18.1424"
            stroke="#797B7E"
            stroke-width="1.43229"
            stroke-linejoin="round"
          />
        </svg>
      </button>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="61"
        height="61"
        viewBox="0 0 61 61"
        fill="none"
      >
        <path
          d="M13.8043 24.7073C19.8385 14.03 22.8556 8.69127 26.9958 7.31702C29.2733 6.56099 31.7268 6.56099 34.0043 7.31702C38.1445 8.69127 41.1615 14.03 47.1958 24.7073C53.23 35.3846 56.247 40.7233 55.342 45.0733C54.844 47.4666 53.6173 49.6371 51.8375 51.2743C48.6025 54.2501 42.5685 54.2501 30.5 54.2501C18.4316 54.2501 12.3974 54.2501 9.16241 51.2743C7.38271 49.6371 6.15598 47.4666 5.65806 45.0733C4.75298 40.7233 7.77008 35.3846 13.8043 24.7073Z"
          className={
            colorMode == "dark" ? "stroke-[#BD8721]" : "stroke-[#FFB421]"
          }
          stroke-width="3.75"
        />
        <path
          d="M30.4844 40.5H30.5069"
          className={
            colorMode == "dark" ? "stroke-[#BD8721]" : "stroke-[#FFB421]"
          }
          stroke-width="5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M30.5 33.0001V23"
          className={
            colorMode == "dark" ? "stroke-[#BD8721]" : "stroke-[#FFB421]"
          }
          stroke-width="3.75"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <p
        className={`text-center ${colorMode == "dark" ? "text-[#e1e1e1]" : "text-[#1e1e1e]"
          } text-lg font-normal tracking-tight`}
      >
        <span className="font-semibold">
          You cannot enable the Tracking function
        </span>
        <span className="font-normal">
          {" "}
          for this Round because your current plan does not include the Tracking
          Function. Please upgrade your plan in{" "}
        </span>
        <span className="font-semibold">"Settings"</span>
        <span className="font-normal"> to access this function.</span>
      </p>
      <button
        onClick={closeModal}
        className="w-[6.25rem] h-9 px-2.5 mt-10 bg-transparent rounded-md border border-[#3f628b] justify-center items-center flex text-[#3e618a] text-xs font-medium hover:scale-105"
      >
        Close
      </button>
    </div>
  );
};

export default TrackAccessModal;
