import React from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "../../../Context/ThemContext/ThemeContext";

const UsageLimitModal = ({ closeModal }) => {
  const { colorMode } = useTheme();
  const nav = useNavigate();

  return (
    <div
      className={`fixed w-[750px] min-h-[500px] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 ${colorMode == "dark" ? "bg-[#131314]" : "bg-white"
        } flex flex-col justify-center items-center gap-5 p-[6.5rem]`}
    >
      <button
        onClick={closeModal}
        className="absolute top-8 right-8 cursor-pointer"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="23"
          height="23"
          viewBox="0 0 23 23"
          fill="none"
        >
          <path
            d="M18.1415 4.77441L4.77344 18.1424M4.77344 4.77441L18.1415 18.1424"
            stroke="#797B7E"
            stroke-width="1.43229"
            stroke-linejoin="round"
          />
        </svg>
      </button>
      <svg
        width="60"
        height="60"
        viewBox="0 0 61 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M38 26.25C38 30.3923 34.6423 33.75 30.5 33.75C26.3577 33.75 23 30.3923 23 26.25C23 22.1079 26.3577 18.75 30.5 18.75C34.6423 18.75 38 22.1079 38 26.25Z"
          className={
            colorMode == "dark" ? "stroke-[#BD8721]" : "stroke-[#FFB421]"
          }
          stroke-width="3.75"
        />
        <path
          d="M50.5338 31.0083C51.8485 30.613 52.506 30.4153 52.753 30.0833C53 29.7513 53 29.2173 53 28.1495V24.3507C53 23.2829 53 22.749 52.753 22.4169C52.506 22.0848 51.8485 21.8872 50.5338 21.4921C46.3765 20.243 43.7727 15.9312 44.7067 11.7596C45.0147 10.3835 45.1687 9.69543 45.001 9.31781C44.833 8.94018 44.3642 8.67564 43.427 8.14651L39.9878 6.20529C39.0733 5.68906 38.616 5.43096 38.2095 5.47968C37.803 5.52838 37.311 5.98884 36.327 6.90976C33.0873 9.94224 27.9162 9.94211 24.6764 6.90956C23.6926 5.98863 23.2007 5.52819 22.7941 5.47946C22.3876 5.43074 21.9303 5.68886 21.0157 6.20506L17.5766 8.14629C16.6392 8.67539 16.1706 8.93991 16.0027 9.31749C15.8348 9.69506 15.9887 10.3832 16.2967 11.7594C17.2301 15.9312 14.6244 20.2431 10.4664 21.4922C9.15145 21.8872 8.494 22.0847 8.247 22.4168C8 22.7489 8 23.2828 8 24.3507V28.1495C8 29.2173 8 29.7513 8.24697 30.0833C8.49395 30.4155 9.15143 30.613 10.4664 31.008C14.6236 32.2573 17.2273 36.569 16.2933 40.7408C15.9852 42.1168 15.8312 42.8048 15.999 43.1825C16.1669 43.56 16.6356 43.8245 17.573 44.3538L21.0122 46.295C21.9268 46.8113 22.3841 47.0693 22.7907 47.0205C23.1973 46.9718 23.6892 46.5113 24.6728 45.5903C27.9143 42.5553 33.0893 42.555 36.3308 45.5903C37.3145 46.511 37.8063 46.9715 38.2128 47.0203C38.6195 47.069 39.0768 46.811 39.9913 46.2948L43.4305 44.3535C44.368 43.8243 44.8367 43.5598 45.0045 43.182C45.1725 42.8045 45.0183 42.1165 44.71 40.7405C43.7755 36.569 46.3773 32.2573 50.5338 31.0083Z"
          className={
            colorMode == "dark" ? "stroke-[#BD8721]" : "stroke-[#FFB421]"
          }
          stroke-width="3.75"
          stroke-linecap="round"
        />
        <path
          d="M55.5 45C55.5 47.325 55.5 48.4875 55.2445 49.4413C54.551 52.0293 52.5293 54.051 49.9413 54.7445C48.9875 55 47.825 55 45.5 55H15.5C13.1751 55 12.0126 55 11.0588 54.7445C8.47067 54.051 6.44905 52.0293 5.75555 49.4413C5.5 48.4875 5.5 47.325 5.5 45"
          className={
            colorMode == "dark" ? "stroke-[#BD8721]" : "stroke-[#FFB421]"
          }
          stroke-width="3.75"
          stroke-linecap="round"
        />
      </svg>
      <div
        className={`text-center ${colorMode == "dark" ? "text-[#e1e1e1]" : "text-[#1e1e1e]"
          } text-2xl font-bold tracking-wide`}
      >
        You have reached the usage limit for this feature with your current
        plan!
      </div>
      <div
        className={`text-center ${colorMode == "dark" ? "text-[#e1e1e1]" : "text-[#1e1e1e]"
          } text-lg font-normal tracking-tight`}
      >
        To access additional usage for this feature, a subscription plan upgrade
        is required.
      </div>
      <div className="w-[302px] h-9 flex justify-between items-center mt-10">
        <button
          onClick={closeModal}
          className="w-[6.25rem] h-full px-2.5 bg-transparent rounded-md border border-[#3f628b] justify-center items-center flex text-[#3e618a] text-xs font-medium hover:scale-105"
        >
          Close
        </button>
        <button
          onClick={() => nav("/settings?tab=subscription")}
          className="h-full px-2.5 bg-[#0069e4] rounded-md shadow justify-center items-center gap-1.5 inline-flex text-white text-xs font-medium font-['Inter'] hover:scale-105"
        >
          <svg
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="arrow-reload-horizontal">
              <path
                id="Vector"
                d="M15.875 4.625H7.625C4.84004 4.625 2.75 6.63877 2.75 9.5"
                stroke="white"
                stroke-width="1.2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                id="Vector_2"
                d="M3.125 14.375H11.375C14.16 14.375 16.25 12.3612 16.25 9.5"
                stroke="white"
                stroke-width="1.2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                id="Vector_3"
                d="M14.375 2.75C14.375 2.75 16.25 4.13092 16.25 4.62502C16.25 5.11912 14.375 6.5 14.375 6.5"
                stroke="white"
                stroke-width="1.2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                id="Vector_4"
                d="M4.62499 12.501C4.62499 12.501 2.75001 13.8819 2.75 14.376C2.74999 14.8701 4.625 16.251 4.625 16.251"
                stroke="white"
                stroke-width="1.2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
          </svg>
          Go to Subscription Plans
        </button>
      </div>
    </div>
  );
};

export default UsageLimitModal;
