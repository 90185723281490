import "./style.css";
import {
  AtomicBlockUtils,
  ContentBlock,
  EditorState,
  Modifier,
  SelectionState,
  ContentState,
  CharacterMetadata,
  genKey,
  CompositeDecorator,
  KeyBindingUtil,
  getDefaultKeyBinding,
} from "draft-js";
import React, { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { stateFromMarkdown } from "draft-js-import-markdown";
import { stateToMarkdown } from "draft-js-export-markdown";
import bold from "../../../assets/icons/rteToolbar/bold.svg";
import italic from "../../../assets/icons/rteToolbar/italic.svg";
import link from "../../../assets/icons/rteToolbar/link.svg";
import unlink from "../../../assets/icons/rteToolbar/unlink.svg";
import bullet from "../../../assets/icons/rteToolbar/list-bullet.svg";
import number from "../../../assets/icons/rteToolbar/list-number.svg";
import image from "../../../assets/icons/rteToolbar/image.svg";
import { postImageFile } from "../../../services";
import { List, Repeat } from "immutable";

const customStateFromMarkdown = (markdown) => {
  const blocks = [];
  let lastIndex = 0;
  const imageRegex = /!\[.*?\]\((.*?)\)/g;
  const linkRegex = /\[([^\]]+)\]\(([^)]+)\)/g;

  let match;

  // Handle images
  while ((match = imageRegex.exec(markdown)) !== null) {
    const imageUrl = match[1];
    const textBeforeImage = markdown.slice(lastIndex, match.index);

    // Add text before the image
    if (textBeforeImage) {
      const textState = stateFromMarkdown(textBeforeImage);
      blocks.push(...textState.getBlockMap().toArray());
    }

    // Insert atomic block for image
    let contentStateWithEntity = ContentState.createFromText(""); // Resetting here might help
    contentStateWithEntity = contentStateWithEntity.createEntity(
      "IMAGE",
      "IMMUTABLE",
      { src: imageUrl }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    const imageBlock = new ContentBlock({
      key: genKey(),
      type: "atomic",
      text: " ",
      characterList: List(
        Repeat(CharacterMetadata.create({ entity: entityKey }), 1)
      ),
    });

    blocks.push(imageBlock);
    lastIndex = imageRegex.lastIndex;
  }

  // Handle remaining text and links
  let remainingText = markdown.slice(lastIndex);
  while ((match = linkRegex.exec(remainingText)) !== null) {
    const linkText = match[1];
    const linkUrl = match[2];
    const textBeforeLink = remainingText.slice(0, match.index);

    if (textBeforeLink) {
      const textState = stateFromMarkdown(textBeforeLink);
      blocks.push(...textState.getBlockMap().toArray());
    }

    let contentStateWithEntity = ContentState.createFromText(""); // Reset here as well
    contentStateWithEntity = contentStateWithEntity.createEntity(
      "LINK",
      "MUTABLE",
      { url: linkUrl }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    const linkBlock = new ContentBlock({
      key: genKey(),
      type: "unstyled",
      text: linkText,
      characterList: List(
        Repeat(CharacterMetadata.create({ entity: entityKey }), linkText.length)
      ),
    });

    blocks.push(linkBlock);
    lastIndex = linkRegex.lastIndex;
    remainingText = remainingText.slice(match.index + match[0].length); // Update remaining text correctly
  }

  // Add any remaining text after last link
  if (remainingText.length > 0) {
    const remainingTextState = stateFromMarkdown(remainingText);
    blocks.push(...remainingTextState.getBlockMap().toArray());
  }

  return ContentState.createFromBlockArray(blocks);
};

const findLinkEntities = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === "LINK"
    );
  }, callback);
};

const ImageComponent = (props) => {
  const entity = props.contentState.getEntity(props.block.getEntityAt(0));
  const { src } = entity.getData();
  return <img src={src} alt="Markdown Image" style={{ maxWidth: "100%" }} />;
};

const LinkComponent = (props) => {
  const { url } = props.contentState.getEntity(props.entityKey).getData();
  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      {props.children}
    </a>
  );
};

const decorator = new CompositeDecorator([
  {
    strategy: findLinkEntities,
    component: LinkComponent,
  },
]);

const blockRenderer = (block) => {
  if (block.getType() === "atomic") {
    return {
      component: ImageComponent,
      editable: false,
    };
  }
  return null;
};

const removeImageEntity = (editorState) => {
  try {
    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();
    const blockKey = selectionState.getAnchorKey();
    const block = contentState.getBlockForKey(blockKey);

    // Check if the block is atomic and contains an image entity
    if (block.getType() === "atomic") {
      const entityKey = block.getEntityAt(0);
      const entity = contentState.getEntity(entityKey);
      if (entity && entity.getType() === "IMAGE") {
        // Remove the block with the image
        const blockMap = contentState.getBlockMap().delete(blockKey);
        const newContentState = contentState.merge({
          blockMap,
        });

        return EditorState.push(editorState, newContentState, "remove-range");
      }
    }
    return editorState;
  } catch (error) {
    console.error("Error removing image entity:", error);
  }
};

const RichTextEditor = ({ data, setData }) => {
  const [editor, setEditor] = useState(() => {
    if (data) {
      try {
        const contentState = customStateFromMarkdown(data);
        return EditorState.createWithContent(contentState, decorator);
      } catch (error) {
        console.error("Error initializing editor state from markdown:", error);
        return EditorState.createEmpty(decorator);
      }
    } else {
      return EditorState.createEmpty(decorator);
    }
  });

  const handleKeyCommand = (command, editorState) => {
    if (command === "delete-image") {
      const newState = removeImageEntity(editorState);
      setEditor(newState);
      return "handled";
    }
    return "not-handled";
  };

  const keyBindingFunction = (event) => {
    if (event.key === "Backspace" || event.key === "Delete") {
      const selection = editor.getSelection();
      const contentState = editor.getCurrentContent();
      const blockKey = selection.getAnchorKey();
      const block = contentState.getBlockForKey(blockKey);

      // If the block is atomic, trigger the delete image command
      if (block.getType() === "atomic") {
        return "delete-image";
      }
    }
    return getDefaultKeyBinding(event);
  };

  const insertImage = (editorState, src) => {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      "IMAGE",
      "IMMUTABLE",
      { src }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    const newEditorState = AtomicBlockUtils.insertAtomicBlock(
      editorState,
      entityKey,
      " "
    );

    return EditorState.forceSelection(
      newEditorState,
      newEditorState.getCurrentContent().getSelectionAfter()
    );
  };

  const handleChange = (state) => {
    const contentState = state.getCurrentContent();
    let markdownString = stateToMarkdown(contentState);

    // // Add a space before and after bold and italic text in Markdown format
    // markdownString = markdownString.replace(/\*\*([^\*]+?)\*\*/g, " **$1** ");
    // markdownString = markdownString.replace(/__([^_]+?)__/g, " __$1__ ");

    // // Check if markdown has changed before setting the editor state
    // if (markdownString !== data) {
      setEditor(state);
      setData(markdownString);
    // }
  };

  const handleFileUpload = (file) => {
    return new Promise((resolve, reject) => {
      postImageFile(file)
        .then((data) => {
          // const newState =
          insertImage(editor, data?.data); // Insert image as atomic block
          // setEditor(newState);
          resolve({ data: { link: data?.data } });
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const toolbar = {
    options: ["inline", "list", "link", "image"],
    inline: {
      inDropdown: false,
      className: "inline-group",
      options: ["bold", "italic"],
      bold: { icon: bold, className: "option bold" },
      italic: {
        icon: italic,
        className: "option italic",
      },
    },
    list: {
      inDropdown: false,
      className: "list-group",
      options: ["unordered", "ordered"],
      unordered: {
        icon: bullet,
        className: "option unordered",
      },
      ordered: {
        icon: number,
        className: "option ordered",
      },
    },
    link: {
      inDropdown: false,
      className: "link-group",
      popupClassName: "link-popup",
      dropdownClassName: "link-dropdown",
      showOpenOptionOnHover: false,
      defaultTargetOption: "_blank",
      options: ["link", "unlink"],
      link: { icon: link, className: "option linkBtn" },
      unlink: { icon: unlink, className: "option unlinkBtn" },
    },
    image: {
      icon: image,
      className: "option image",
      uploadEnabled: true,
      popupClassName: "image-popup",
      uploadCallback: handleFileUpload,
      previewImage: true,
      inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
    },
  };

  return (
    data && (
      <Editor
        editorState={editor}
        onEditorStateChange={handleChange}
        toolbarClassName="editorToolbar"
        wrapperClassName="w-full h-full relative flex flex-col gap-1 overflow-y-auto pr-2"
        editorClassName="editorClassName !break-keep text-wrap"
        toolbar={toolbar}
        blockRendererFn={blockRenderer}
        handleKeyCommand={handleKeyCommand}
        keyBindingFn={keyBindingFunction}
      />
    )
  );
};

export default RichTextEditor;
