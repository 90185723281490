import React, { useContext, useState } from "react";
import styles from "./index.module.css";
import EmailCard from "./EmailCard";
import { CircularProgress } from "@mui/material";
import { Button } from "@mui/material";
import { postNotification } from "../../../../services";
import { toast } from "react-toastify";
import { OutpointContext } from "../../../../Context/OutpointsContext/Index";
import { SET_API_LOADING } from "../../../../Context/OutpointsContext/action";
const EmailPage = ({
  setUpdateEmailState,
  dataState,
  setDataState,
  loading,
}) => {
  const {
    state,
    state: { apiLoading },
    dispatch,
  } = useContext(OutpointContext);
  const [saveButton, setSaveButton] = useState(false);
  const saveDiscard = async () => {
    dispatch({ type: SET_API_LOADING, payload: true });
    try {
      let res = await postNotification(dataState);
      if (res?.message) {
        toast.success(res.message);
        setUpdateEmailState((prev) => prev + 1);
        setSaveButton(false)
      } else {
        toast.error(res?.detail || res.detail?.[0]?.msg);
      }
    } catch (err) { }
    dispatch({ type: SET_API_LOADING, payload: false });
  };
  return (
    <>
      {loading == "email" ? (
        <div
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            padding: "300px 0px",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div className={styles.frameParent}>
          <div className={styles.frameGroup}>
            <div className={styles.trackingUpdatesFromRoundsWrapper}>
              <div className={styles.trackingUpdatesFrom}>
                Tracking Updates from Rounds (If you enabled Tracking function
                for a Round)
              </div>
            </div>
            <div className={styles.frameContainer}>
              <EmailCard
                title="When a new scheduled Track Job is completed"
                lable="track_job_completed"
                dataState={dataState}
                setDataState={setDataState}
                setSaveButton={setSaveButton}
              />
              <EmailCard
                title="When Tracking function for a Round is activated"
                lable="track_job_activtaed"
                dataState={dataState}
                setDataState={setDataState}
                setSaveButton={setSaveButton}
              />
              <EmailCard
                title="When Tracking function for a Round is paused or disabled"
                lable="track_job_paused_disabled"
                dataState={dataState}
                setDataState={setDataState}
                setSaveButton={setSaveButton}
              />
            </div>
          </div>
          <div className={styles.frameChild} />
          <div className={styles.frameGroup}>
            <div className={styles.trackingUpdatesFromRoundsWrapper}>
              <div
                className={styles.subscriptionBilling}
              >{`Subscription & Billing (If you subscribed to a paid plan)`}</div>
            </div>
            <div className={styles.frameContainer}>
              <EmailCard
                title="When you reach the usage limit with your current subscription plan"
                lable="usage_limit_reached"
                dataState={dataState}
                setDataState={setDataState}
                setSaveButton={setSaveButton}
              />
              <EmailCard
                title={`Current Plan Renewal & Billing Notifications`}
                dataState={dataState}
                setDataState={setDataState}
                lable=""
                isAuto={true}
                setSaveButton={setSaveButton}
              />
              <EmailCard
                title={`Downgrade notifications for your subscription`}
                dataState={dataState}
                setDataState={setDataState}
                setSaveButton={setSaveButton}
                lable=""
                isAuto={true}
              />

              <EmailCard
                title={`When your next billing cycle overduen`}
                dataState={dataState}
                setDataState={setDataState}
                setSaveButton={setSaveButton}
                lable=""
                isAuto={true}
              />
            </div>
          </div>
          <div className={styles.frameChild} />
          {/* <div className={styles.frameContainer}> */}
          <div className={styles.frameGroup}>
            <EmailCard
              title={`Account Updates & Alerts`}
              lable=""
              isAuto={true}
              setSaveButton={setSaveButton}
            />
            <EmailCard
              title="Newsletters from Sharbo and Sharbo Community"
              lable="neweletter_subscription"
              dataState={dataState}
              setDataState={setDataState}
              setSaveButton={setSaveButton}
            />
            <EmailCard
              title="Marketing Emails from Sharbo"
              lable="marketing_email_subscription"
              dataState={dataState}
              setDataState={setDataState}
              setSaveButton={setSaveButton}
            />
          </div>
          <div className={styles.buttonsContainer}>
            {saveButton && (
              <div className={styles.saveDiv}>
                <div>
                  <Button
                    onClick={() => {
                      setUpdateEmailState((prev) => prev + 1);
                      setSaveButton(false);
                    }}
                    variant="outlined"
                    sx={{ margin: "10px", textTransform: "none" }}
                    className="hover:scale-105"
                  >
                    Discard changes
                  </Button>
                </div>
                <div>
                  <Button
                    onClick={() => saveDiscard()}
                    variant="contained"
                    sx={{ margin: "10px", textTransform: "none" }}
                    className="hover:scale-105"
                  // disabled={apiLoading}
                  >
                    {apiLoading && (
                      <CircularProgress
                        sx={{
                          color: "white",
                          height: "10px !important",
                          width: "10px !important",
                        }}
                      />
                    )}
                    Save changes
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
        // </div>
      )}
    </>
  );
};

export default EmailPage;
