import React, { useContext, useEffect, useState } from 'react';
import EyeInput from '../../Common/ClientTextField/EyeInput';
import './index.css';
import { Button, IconButton, MenuItem, Select } from '@mui/material';
import { PrimaryModal } from '../../Common/PrimaryModal';
import RemoveCircle from '../../../assets/icons/clearbtn.svg';
import { Add } from '@mui/icons-material';
import TextAreaDescription from '../AccordianData/TextAreaDescription';
import { OutpointContext } from '../../../Context/OutpointsContext/Index';
import useSaveRoundsForm from '../../../hooks/useSaveRoundsForm';
import { useLocation } from 'react-router-dom';
import { ColorPattern } from '../../../constant/Color';
import { useTheme } from '../../../Context/ThemContext/ThemeContext';

const dropDownOptions = [
	{
		label: 'Analyze single page from URL only',
		value: 'singlepage',
		id: 'target defined',
	},
	{
		label: 'Analyze all pages in the website',
		value: 'allpage',
		id: 'enricher namespace',
	},
];

const RemoveIcon = () => (
	<svg
		width='15'
		height='15'
		viewBox='0 0 15 15'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'>
		<g id='remove-circle'>
			<path
				id='Vector'
				fill-rule='evenodd'
				clip-rule='evenodd'
				d='M1.22919 7.58333C1.22919 11.0466 4.03674 13.8542 7.50002 13.8542C10.9633 13.8542 13.7709 11.0466 13.7709 7.58333C13.7709 4.12005 10.9633 1.3125 7.50002 1.3125C4.03674 1.3125 1.22919 4.12005 1.22919 7.58333ZM10.4167 7.58333C10.4167 7.90551 10.1555 8.16667 9.83335 8.16667H5.16669C4.84452 8.16667 4.58335 7.90551 4.58335 7.58333C4.58335 7.26116 4.84452 7 5.16669 7H9.83335C10.1555 7 10.4167 7.26116 10.4167 7.58333Z'
				fill='#E55E5E'
			/>
		</g>
	</svg>
);

const UseUrl = ({ title }) => {
	const { colorMode, toggleColorMode } = useTheme();

	const [mode, setMode] = useState(dropDownOptions[0]);
	const {
		state: { roundsForm, foundation_page, pathName },
		selectedStep,
	} = useContext(OutpointContext);
	let onlyFoundation = pathName === '/foundations';
	function checkKey() {
		let id = onlyFoundation ? 'foundation' : selectedStep.value;
		let key;
		switch (id) {
			case 'foundation': {
				return (key = 'foundation_name');
			}
			case 'competitor': {
				return (key = 'competitor_name');
			}
			default: {
				return (key = '');
			}
		}
	}

	const { handleRoundsForm } = useSaveRoundsForm();

	const handleOptionChange = (e) => {
		setMode(dropDownOptions.find((item) => item.label === e.target.value));
	};
	const [siteUrls, setSiteUrls] = useState(
		(onlyFoundation
			? foundation_page?.url?.slice(1)
			: roundsForm[selectedStep.value]?.url?.slice(1)) || []
	);

	const handleChange = (index, event, clear) => {
		if (index === 'add') {
			handleRoundsForm({
				additional_details: clear ? '' : event.target.value,
			});
			return;
		}

		if (index === true) {
			handleRoundsForm({
				url: [event.target.value, ...siteUrls],
			});
			return;
		}
		const newInputs = [...siteUrls];
		newInputs[index] = event.target.value;
		setSiteUrls(newInputs);
	};

	const handleAddInput = () => {
		console.log('update ');
		setSiteUrls(['', ...siteUrls]);
	};

	const handleDeleteInput = (index) => {
		const newInputs = siteUrls.filter((_, i) => i !== index);
		setSiteUrls(newInputs);
	};
	useEffect(() => {
		handleRoundsForm({
			scrape_mode: mode.value,
		});
	}, [mode]);

	useEffect(() => {
		handleRoundsForm({
			url: [
				onlyFoundation
					? foundation_page?.url?.[0]
					: roundsForm[selectedStep.value]?.url?.[0],
				...siteUrls,
			],
		});
	}, [siteUrls]);

	return (
		<div className='parentContainer'>
			{title && (
				<div className='titleContainer'>
					{/* title */}
					<div
						className='title'
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'flex-start',
						}}>
						Name of the Product or Company{' '}
					</div>

					<div className='input_container'>
						<input
							type='text'
							value={
								onlyFoundation
									? foundation_page[checkKey()]
									: roundsForm[selectedStep.value][checkKey()]
							}
							onChange={(e) => {
								let key = checkKey();
								handleRoundsForm({
									[key]: e.target.value,
								});
							}}
							className='primary-input'
						/>
					</div>
				</div>
			)}

			<div className='titleContainer'>
				{/* title */}
				<div className='title'>Enter the Main Website URL</div>
				<div className='input_container'>
					<input
						placeholder='https://'
						type='text'
						onChange={(e) => handleChange(true, e)}
						className='primary-input'
						style={{ height: '40px', width: '60%', maxWidth: '600px' }}
						min={1}
						max={1024}
						value={
							onlyFoundation
								? foundation_page?.url?.[0]
								: roundsForm[selectedStep.value]?.url?.[0]
						}
					/>
					<span className='analyse-text'>
						Sharbo AI analyzes data from the URL's target page
					</span>
				</div>
			</div>
			<div className='titleContainer'>
				{/* title */}
				<div className='title'>
					Additional Details{' '}
					<span style={{ color: 'rgba(160, 168, 190, 1)' }}>(Optional)</span>
				</div>
				<div style={{ alignItems: 'start' }} className='input_container'>
					{/* <div className="input_container-item"> */}
					<textarea
						type='text'
						style={{
							width: '60%',
							maxWidth: '600px',
							resize: 'none',
							fontSize: '12px',
						}}
						value={
							onlyFoundation
								? foundation_page?.additional_details
								: roundsForm?.[selectedStep.value]?.additional_details
						}
						onChange={(event) => handleChange('add', event)}
						className='primaryTextArea'
						placeholder='Add any details or instructions to help Sharbo Al perform the analysis'
						// value={item}
					/>
					<img
						onClick={(e) => {
							handleChange('add', null, true);
						}}
						style={{ cursor: 'pointer', width: '75px', height: '44px' }}
						src={RemoveCircle}
					/>
				</div>
			</div>
			<div className='titleContainer'>
				{/* title */}
				<div className='title'>
					Additional URLs{' '}
					<span style={{ color: 'rgba(160, 168, 190, 1)' }}>(Optional)</span>
				</div>
				<div style={{ alignItems: 'start' }} className='input_container'>
					<div className='input_container-item'>
						<div className='input_container-item-list'>
							{siteUrls?.map((item, i) => (
								<div
									style={{
										margin: '0px 10px 15px 10px',
										position: 'relative',
									}}>
									<input
										id={`${i}`}
										className='primary-input site-urls-input'
										type='url'
										key={i}
										value={item}
										required
										onChange={(event) => handleChange(i, event)}
									/>

									<svg
										onClick={() => handleDeleteInput(i)}
										style={{
											position: 'absolute',
											top: '50%',
											right: '4px',
											transform: 'translateY(-50%)',
										}}
										width='17'
										height='17'
										viewBox='0 0 17 17'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'>
										<path
											fill-rule='evenodd'
											clip-rule='evenodd'
											d='M1.22919 7.58333C1.22919 11.0466 4.03674 13.8542 7.50002 13.8542C10.9633 13.8542 13.7709 11.0466 13.7709 7.58333C13.7709 4.12005 10.9633 1.3125 7.50002 1.3125C4.03674 1.3125 1.22919 4.12005 1.22919 7.58333ZM10.4167 7.58333C10.4167 7.90551 10.1555 8.16667 9.83335 8.16667H5.16669C4.84452 8.16667 4.58335 7.90551 4.58335 7.58333C4.58335 7.26116 4.84452 7 5.16669 7H9.83335C10.1555 7 10.4167 7.26116 10.4167 7.58333Z'
											fill='#E55E5E'
										/>
									</svg>
								</div>
							))}
						</div>
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								padding: ' 0 10px',
							}}>
							<Button
								variant='outlined'
								className='hover:scale-105'
								sx={{
									marginTop: '0',
									padding: '3px 15px',
									position: 'relative',
									fontSize: '13px',
									color: 'rgba(0, 105, 229, 1)',
									textTransform: 'none',
									borderRadius: '5px',
									border: '1.5px solid #0069E5',
									borderWidth: '1.5px !important',
								}}
								onClick={handleAddInput}>
								<svg
									style={{ marginRight: '10px', width: '12px', height: '12px' }}
									xmlns='http://www.w3.org/2000/svg'
									width='9'
									height='9'
									viewBox='0 0 9 9'
									fill='none'>
									<path
										fill-rule='evenodd'
										clip-rule='evenodd'
										d='M4.65709 0.925781C4.65709 0.649639 4.43323 0.425781 4.15709 0.425781C3.88094 0.425781 3.65709 0.649639 3.65709 0.925781V4.0827H0.5C0.223858 4.0827 0 4.30656 0 4.5827C0 4.85884 0.223858 5.0827 0.5 5.0827H3.65709V8.24007C3.65709 8.51621 3.88094 8.74007 4.15709 8.74007C4.43323 8.74007 4.65709 8.51621 4.65709 8.24007V5.0827H7.81429C8.09043 5.0827 8.31429 4.85884 8.31429 4.5827C8.31429 4.30656 8.09043 4.0827 7.81429 4.0827H4.65709V0.925781Z'
										fill='#0069E5'
									/>
								</svg>
								Add Additional URLs
							</Button>
							{siteUrls?.length > 0 && (
								<Button
									variant='outlined'
									className='hover:scale-105'
									sx={{
										float: 'right',
										marginRight: '0',
										marginTop: '0',
										position: 'relative',
										padding: '3px 12px 3px 10px',
										fontSize: '13px',
										color: 'rgba(229, 94, 94, 1)',
										textTransform: 'none',
										borderRadius: '5px',
										border: '1.5px solid rgba(229, 94, 94, 1) !important',
										borderWidth: '1.5px !important',
									}}
									onClick={() => setSiteUrls([])}>
									<svg
										style={{ marginRight: '6px' }}
										width='17'
										height='17'
										viewBox='0 0 17 17'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'>
										<path
											fill-rule='evenodd'
											clip-rule='evenodd'
											d='M1.22919 7.58333C1.22919 11.0466 4.03674 13.8542 7.50002 13.8542C10.9633 13.8542 13.7709 11.0466 13.7709 7.58333C13.7709 4.12005 10.9633 1.3125 7.50002 1.3125C4.03674 1.3125 1.22919 4.12005 1.22919 7.58333ZM10.4167 7.58333C10.4167 7.90551 10.1555 8.16667 9.83335 8.16667H5.16669C4.84452 8.16667 4.58335 7.90551 4.58335 7.58333C4.58335 7.26116 4.84452 7 5.16669 7H9.83335C10.1555 7 10.4167 7.26116 10.4167 7.58333Z'
											fill='#E55E5E'
										/>
									</svg>
									Remove All
								</Button>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default UseUrl;
