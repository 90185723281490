import React, { useContext } from "react";
import { OutpointContext } from "../Context/OutpointsContext/Index";
import {
  SET_FOUNDATION_ONLY,
  SET_ROUNDS_FORM,
  TEMP_FOUNDATION,
} from "../Context/OutpointsContext/action";
import { stepperData } from "../Components/StepperNewRound/stepperData";
import { useLocation } from "react-router-dom";

const useSaveRoundsForm = () => {
  const {
    dispatch,
    state: { roundsForm, pathName },
    selectedStep,
  } = useContext(OutpointContext);

  let onlyFoundation = pathName === "/foundations";

  const handleRoundsForm = (target, id = "", pathVar = "") => {
    if (pathVar) {
      onlyFoundation = pathVar === "/foundations"
    }
    // From foundation page 
    if (onlyFoundation) {
      dispatch({
        type: SET_FOUNDATION_ONLY,
        payload: target,
      });
      return;
    }
    // Replace entire roundForm object with target object
    if (id == "round_id") {
      dispatch({
        type: SET_ROUNDS_FORM,
        payload: target,
      });
      return;
    }
    // Replace roundForm entry with key = id to target object
    if (id) {
      dispatch({
        type: SET_ROUNDS_FORM,
        payload: {
          [id]: { ...roundsForm[id], ...target },
        },
      });
      return;
    }
    // Merge target object with current roundForm object
    dispatch({
      type: SET_ROUNDS_FORM,
      payload: {
        [selectedStep.value]: { ...roundsForm[selectedStep.value], ...target },
      },
    });
  };

  return {
    handleRoundsForm,
  };
};

export default useSaveRoundsForm;
