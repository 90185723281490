import React from 'react';
import '../CurrentPlanAndUsage/index.css';
import ProgressItem from './ProgressItem';
import { Box } from '@mui/material';
import BillingInfo from '../BillingInfo/index';
import StarterImage from '../../../assets/icons/Subscriptions/rocket-starter.svg';
import AdvancedImage from '../../../assets/icons/Subscriptions/advanced.svg';
import ElevateImage from '../../../assets/icons/Subscriptions/elevate.svg';
import UltraImage from '../../../assets/icons/Subscriptions/ultra.svg';
import EnterpriseImage from '../../../assets/icons/Subscriptions/enterprise.svg';
import { useTheme } from '../../../Context/ThemContext/ThemeContext';

const planImages = {
	Starter: StarterImage,
	Advanced: AdvancedImage,
	Elevate: ElevateImage,
	Ultra: UltraImage,
	Enterprise: EnterpriseImage,
};

const CurrentPlanAndUsage = ({
	planName,
	progressItems,
	subscriptionButtonText,
	isStarterPlan,
	billingCycle,
	nextPayment,
	manageUrl,
	upgradeUrl,
}) => {
	const getImageForPlan = (planName) => {
		const normalizedPlanName = planName.trim().toLowerCase();
		const exactMatch = Object.keys(planImages).find(
			(key) => key.toLowerCase() === normalizedPlanName
		);
		if (exactMatch) return planImages[exactMatch];

		const partialMatch = Object.keys(planImages).find((key) =>
			normalizedPlanName.includes(key.toLowerCase())
		);
		return partialMatch ? planImages[partialMatch] : planImages['Starter'];
	};

	const planImage = getImageForPlan(planName);

	const { colorMode } = useTheme();

	return (
		<div
			style={{ marginBottom: '12px' }}
			className={`currentPlanCard ${
				colorMode === 'dark' ? 'darkShadow' : 'lightShadow'
			}`}>
			<div className='cardHeader'>
				<h3>Current Plan & Usage</h3>
			</div>
			<div className='cardContainer'>
				<div className='planName'>
					<img
						src={planImage}
						alt={`${planName} icon`}
						width={25}
						height={25}
						style={{ display: 'inline-block', verticalAlign: 'middle' }}
					/>
					<h4
						style={{
							display: 'inline-block',
							verticalAlign: 'middle',
							marginLeft: '10px',
						}}>
						{planName}
					</h4>
				</div>
				{progressItems &&
					progressItems.map((item, index) => (
						<div key={index} className='totalRound'>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'space-evenly',
								}}>
								<h4
									style={{
										width: '200px',
									}}>
									{item.label}
								</h4>
								<Box width='250px' padding='0' marginBottom='0'>
									<ProgressItem
										label={item.label}
										value={item.value}
										max={item.max}
									/>
								</Box>
							</div>
						</div>
					))}
				<div>
					{isStarterPlan ? (
						<a href={upgradeUrl} className='upgradePlanButton'>
							{subscriptionButtonText}
						</a>
					) : (
						<BillingInfo
							billingCycle={billingCycle}
							nextPayment={nextPayment}
							manageUrl={manageUrl}
							upgradeUrl={upgradeUrl}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default CurrentPlanAndUsage;
