import React, { useContext, useEffect, useState } from 'react';
import styles from './index.module.css';
import {
	CircularProgress,
	FormControlLabel,
	MenuItem,
	Modal,
	Radio,
	RadioGroup,
	Select,
	Switch,
} from '@mui/material';
import { CustomSwitch } from '../../Common/Switch';
import useSaveRoundsForm from '../../../hooks/useSaveRoundsForm';
import {
	createTrackAndUpdate,
	getAnalysisSection,
	getCurrentSubscriptions,
	getFrequencyMetaData,
	getUserLimit,
	validateCronExpression,
} from '../../../services';
import { useTheme } from '../../../Context/ThemContext/ThemeContext';
import { ColorPattern } from '../../../constant/Color';
import { SubscriptionFeatures } from '../../../constant/constants';
import TrackAccessModal from '../../Common/TrackAccessModal';
import { OutpointContext } from '../../../Context/OutpointsContext/Index';
import { SET_API_LOADING } from '../../../Context/OutpointsContext/action';

const validateBtnStates = {
	DEFAULT: 'Validate',
	LOADING: (
		<span className='flex gap-2 items-center'>
			<CircularProgress size={15} color='inherit' /> Verifying
		</span>
	),
	SUCCESS: 'Valid',
	ERROR: 'Invalid',
};

const TrackPage = ({ roundId }) => {
	const { colorMode } = useTheme();
	const { state, dispatch } = useContext(OutpointContext);
	const [isUltra, setIsUltra] = useState(false);
	const [scheduleMethod, setScheduleMethod] = useState('scheduled');
	const [cronVerifyStatus, setCronVerifyStatus] = useState(
		validateBtnStates.DEFAULT
	);
	const [cronText, setCronText] = useState('');
	const [nextDate, setNextDate] = useState(null);

	const [frequencyChoices, setFrequencyChoices] = useState([]);
	const [openModal, setOpenModal] = useState(false);
	const [trackSwitch, setTrackSwitch] = useState(false);
	const [trackAccess, setTrackAccess] = useState(false);
	const [foundationSwitch, setFoundationSwitch] = useState(false);
	const [select, setSelect] = useState(frequencyChoices?.[0]);
	const [roundName, setRoundName] = useState('');
	const { handleRoundsForm } = useSaveRoundsForm();

	const getSubscriptionPlan = async () => {
		const res = await getCurrentSubscriptions();
		if (
			!['starter', 'advanced', 'elevate'].includes(
				String(res?.data?.current_tier)?.toLowerCase()
			)
		)
			setIsUltra(true);
	};

	const checkFinalBtnDisabled = () => {
		if (trackSwitch) {
			if (scheduleMethod == 'scheduled')
				dispatch({ type: SET_API_LOADING, payload: false });
			else {
				if (cronVerifyStatus == validateBtnStates.SUCCESS)
					dispatch({ type: SET_API_LOADING, payload: false });
				else dispatch({ type: SET_API_LOADING, payload: true });
			}
		} else {
			dispatch({ type: SET_API_LOADING, payload: false });
		}
	};

	const handleVerifyCron = async () => {
		setCronVerifyStatus(validateBtnStates.LOADING);
		const res = await validateCronExpression(cronText);
		if (res?.next_trigger_date) {
			setNextDate(res?.next_trigger_date);
			handleRoundsForm({ cron_expression: cronText }, 'round_id');
			console.log(state?.roundsForm);
			await setCronVerifyStatus(validateBtnStates.SUCCESS);
			dispatch({ type: SET_API_LOADING, payload: false });
		} else {
			setCronVerifyStatus(validateBtnStates.ERROR);
		}
	};

	const handleOptionChange = (e) => {
		setSelect(
			frequencyChoices.find((item) => item.frequency_enum === e.target.value)
		);
	};
	const getFrequency = async () => {
		try {
			let res = await getFrequencyMetaData();
			console.log(res);
			setFrequencyChoices(res?.data);
			setSelect(res?.data?.[0]);
		} catch (err) {
			console.log(err);
		}
	};
	useEffect(() => {
		console.log('Track status changed:', trackSwitch);
		window.trackEnabled = trackSwitch;
		handleRoundsForm({ track_competitor: trackSwitch }, 'round_id');
	}, [trackSwitch]);

	const handleTrackApiCall = async () => {
		console.log('Attempting track API call for round:', roundId);
		console.log('Current track status:', trackSwitch);
		if (trackSwitch) {
			try {
				console.log('Calling createTrackAndUpdate API...');
				const response = await createTrackAndUpdate(roundId);
				console.log('Track API response:', response);
				return response;
			} catch (error) {
				console.error('Track API error:', error);
				throw error;
			}
		} else {
			console.log('Track API not called - tracking is disabled');
			return null;
		}
	};
	useEffect(() => {
		window.handleTrackApiCall = handleTrackApiCall;

		return () => {
			delete window.handleTrackApiCall;
		};
	}, [trackSwitch, roundId]);

	const getTrackingAccess = async () => {
		const access = await getUserLimit(SubscriptionFeatures.TRACK);
		setTrackAccess(access);
		checkFinalBtnDisabled();
		if (!access) setTrackSwitch(false);
	};

	const handleGetUpdatedData = async () => {
		try {
			let response = await getAnalysisSection(roundId);
			console.log('updated res', response);
		} catch (err) {
			console.log('erro in  getting updated data', err);
		}
	};

	useEffect(() => {
		getSubscriptionPlan();
		handleGetUpdatedData();
		getFrequency();
		getTrackingAccess();
	}, []);
	useEffect(() => {
		handleRoundsForm({ track_competitor: trackSwitch }, 'round_id');
	}, [trackSwitch]);

	useEffect(() => {
		handleRoundsForm({ track_foundation: foundationSwitch }, 'round_id');
	}, [foundationSwitch]);
	useEffect(() => {
		handleRoundsForm({ track_frequency: select?.frequency_id }, 'round_id');
	}, [select]);
	useEffect(() => {
		handleRoundsForm({ round_name: roundName }, 'round_id');
	}, [roundName]);
	useEffect(() => {
		if (cronVerifyStatus == validateBtnStates.SUCCESS)
			handleRoundsForm({ cron_expression: cronText }, 'round_id');
		else handleRoundsForm({ cron_expression: '' }, 'round_id');
		console.log(state?.roundsForm);
	}, [cronText, cronVerifyStatus]);

	return (
		<div className={styles.frameParent}>
			<Modal
				styles={'width:300px'}
				open={openModal}
				onClose={() => setOpenModal(false)}>
				<TrackAccessModal closeModal={() => setOpenModal(false)} />
			</Modal>
			<div className={styles.frameGroup}>
				<div className={styles.frameContainer}>
					{/* <div className={styles.frameChild} /> */}
					<div className={styles.roundNameParent}>
						<div className={styles.setTrackingFrequencyContainer}>
							Round Name
						</div>
						<div className={styles.alertCircle}>
							<img className={styles.vectorIcon} alt='' src='Vector.svg' />
							<img className={styles.vectorIcon1} alt='' src='Vector.svg' />
						</div>
						<div className={styles.enterTextParent}>
							<input
								required
								id='roundNameInput'
								value={roundName}
								onChange={(e) => setRoundName(e.target.value)}
								className={styles.enterText}
								placeholder='Enter a name to identify this Round later'
								autocomplete='off'
							/>
						</div>
					</div>
					<div className={styles.setTrackingFrequencyForComParent}>
						<div className={styles.setTrackingFrequencyContainer}>
							<span>
								<span>Set Tracking Frequency for Competitor Sources</span>
								<span className={styles.span}>{` `}</span>
							</span>
							<span className={styles.span}>
								<span>(Optional)</span>
							</span>
						</div>
						<div
							className={`flex flex-row items-center justify-start gap-2.5 text-center text-[11px] text-[#141b34]`}>
							<CustomSwitch
								id='trackCompetitorToggle'
								checked={trackAccess ? trackSwitch : false}
								onChange={() => {
									if (trackAccess) {
										setTrackSwitch(!trackSwitch);

										checkFinalBtnDisabled();
									} else setOpenModal(true);
								}}
							/>
							<div className={styles.enabled}>
								{trackSwitch ? 'Enabled' : 'Disabled'}
							</div>
						</div>
					</div>
					<div className={styles.sharboAiTracks}>
						Sharbo AI tracks competitor URL updates and generates new analyses
						upon changes. You can choose to update the analysis on your embedded
						page accordingly.
					</div>

					{trackSwitch && (
						<>
							{isUltra && (
								<RadioGroup
									defaultValue={scheduleMethod}
									name='radio-buttons-group'
									sx={{
										flexDirection: 'row',
										marginBottom: '15px',
										gap: '40px',
										fontSize: '13px !important',
										// " .css-ahj2mt-MuiTypography-root ": {
										//   fontSize: '13px !important'
										// }
										'&.css-1xpshec-MuiFormControlLabel-root .MuiFormControlLabel-label css-ahj2mt-MuiTypography-root':
											{
												fontSize: '13px !important',
											},
									}}
									onChange={(e) => {
										setScheduleMethod(e.target.value);
										if (e.target.value == 'scheduled') {
											handleRoundsForm(
												{ track_configuration_mode: 1 },
												'round_id'
											);
											dispatch({ type: SET_API_LOADING, payload: false });
										} else {
											handleRoundsForm(
												{ track_configuration_mode: 2 },
												'round_id'
											);

											if (cronVerifyStatus == validateBtnStates.SUCCESS)
												dispatch({ type: SET_API_LOADING, payload: false });
											else dispatch({ type: SET_API_LOADING, payload: true });
										}
									}}>
									{['Scheduled', 'Cron'].map((opt) => (
										<>
											<FormControlLabel
												value={opt.toLowerCase()}
												control={
													<Radio
														sx={{
															color: colorMode === 'dark' ? '#fff' : '#1f1f1f',
															'& .MuiSvgIcon-root': {
																fontSize: '13px !important',
															},
														}}
														size='small'
													/>
												}
												label={opt}
												sx={{
													fontSize: '13px !important',
													fontFamily: 'Inter',
													fontWeight: '600',
													'& .MuiFormControlLabel-label': {
														fontSize: '13px !important',
													},
												}}
											/>
										</>
									))}
								</RadioGroup>
							)}
							{scheduleMethod == 'scheduled' ? (
								<>
									<Select
										sx={{
											width: '20%',
											maxWidth: '171px',
											height: '40px',
											fontFamily: 'inter',
											fontSize: '14px',
											color: ColorPattern[colorMode].textColor,

											'&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
												{
													borderColor: ColorPattern[colorMode].borderColor, // on hover
												},
											'& .MuiOutlinedInput-notchedOutline': {
												borderColor: ColorPattern[colorMode].borderColor, // in general
											},
											'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
												border: `1px solid ${ColorPattern[colorMode].borderColor}`, // when focused
											},
											'& .MuiSelect-icon': {
												color: ColorPattern[colorMode].textColor, // Change the icon color
											},
										}}
										// IconComponent={ArrowDownIcon}
										value={select?.frequency_enum}
										onChange={handleOptionChange}>
										{frequencyChoices?.map((currentItem) => {
											return (
												<MenuItem
													key={currentItem.frequency_id}
													value={currentItem.frequency_enum}>
													{currentItem.frequency_enum}
												</MenuItem>
											);
										})}
									</Select>
									<p className="text-[#797b7e] text-xs font-medium font-['Inter'] mb-4">
										Next Track Job: {select?.next_trigger_date}
									</p>
								</>
							) : (
								<div className='w-full flex flex-col mb-4 gap-4'>
									<div className='flex items-center gap-2.5'>
										<input
											value={cronText}
											onChange={(e) => {
												setCronVerifyStatus(validateBtnStates?.DEFAULT);
												setCronText(e.target.value);
												checkFinalBtnDisabled();
											}}
											style={{
												border: `1px solid ${ColorPattern[colorMode].borderColor}`,
											}}
											className='h-10 w-64 px-2.5 py-3 bg-transparent rounded border border-black/10 placeholder:text-[#797B7E] outline-none'
											placeholder='Cron expression input'
										/>
										<button
											disabled={
												cronVerifyStatus == validateBtnStates?.ERROR ||
												cronVerifyStatus == validateBtnStates?.LOADING
											}
											onClick={handleVerifyCron}
											className={`h-10 w-32 flex items-center justify-center px-2.5 bg-transparent rounded border disabled:!cursor-default disabled:opacity-80 ${
												cronVerifyStatus == validateBtnStates?.ERROR
													? 'border-[#F24F4F] text-[#F24F4F]'
													: cronVerifyStatus == validateBtnStates?.SUCCESS
													? 'border-green-600 text-green-600'
													: 'border-[#0069E5] text-[#0069E5]'
											} text-xs font-medium font-['Inter']`}>
											{cronVerifyStatus}
										</button>
									</div>
									{nextDate && (
										<p className="text-[#797b7e] text-[11px] font-medium font-['Inter']">
											Next Track Job: {nextDate}
										</p>
									)}
								</div>
							)}
						</>
					)}
					{trackSwitch && (
						<div className={styles.setTrackingFrequencyForComParent}>
							<div className={styles.setTrackingFrequencyContainer}>
								<span>
									<span>Also track your Foundation sources?</span>
								</span>
								<span>
									<span>{` `}</span>
									<span className={styles.applicableOnlyWith}>
										(Applicable only with provided URLs)
									</span>
								</span>
							</div>
							<div className={styles.toggleOnParent}>
								<CustomSwitch
									id='trackFoundationToggle'
									checked={foundationSwitch}
									onChange={() => setFoundationSwitch(!foundationSwitch)}
								/>
								<div className={styles.enabled}>
									{foundationSwitch ? 'Enabled' : 'Disabled'}
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default TrackPage;
