import React, { useContext, useEffect } from 'react';
import './ClientField.css';
import AlertCircle from '../../../assets/icons/alertCircle.svg';
import View from '../../../assets/icons/view.svg';
import { OutpointContext } from '../../../Context/OutpointsContext/Index';
import { SET_ROUNDS_FORM } from '../../../Context/OutpointsContext/action';
import useSaveRoundsForm from '../../../hooks/useSaveRoundsForm';
import { useLocation } from 'react-router-dom';
const EyeInput = ({ title, id = '' }) => {
	const {
		state: { roundsForm, foundation_page, pathName },
		selectedStep,
	} = useContext(OutpointContext);
	let onlyFoundation = pathName === '/foundations';
	function checkKey() {
		let id = onlyFoundation ? 'foundation' : selectedStep.value;
		let key;
		switch (id) {
			case 'foundation': {
				return (key = 'foundation_name');
			}
			case 'competitor': {
				return (key = 'competitor_name');
			}
			default: {
				return (key = '');
			}
		}
	}

	const { handleRoundsForm } = useSaveRoundsForm();
	const handleChange = (e) => {
		let key = checkKey();
		handleRoundsForm({
			[key]: e.target.value,
		});
	};

	return (
		<div className='item'>
			<div className='field-item-label'>{title}</div>
			<div className='input-box'>
				<input
					id={id}
					placeholder='e.g. Stripe APl, Figma'
					type='text'
					className='primary-eye-input'
					onChange={handleChange}
					value={
						onlyFoundation
							? foundation_page[checkKey()]
							: roundsForm?.[selectedStep.value]?.[checkKey()]
					}
				/>
				{/* <img className="icon" alt="" src={View} /> */}
			</div>
		</div>
	);
};

export default EyeInput;
