const SettingsIconNew = ({ fill = '#A0A8BE' }) => {
    return (
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="settings-03">
                <path id="Vector" d="M10.3893 8.00033C10.3893 9.28899 9.34466 10.3337 8.05599 10.3337C6.76732 10.3337 5.72266 9.28899 5.72266 8.00033C5.72266 6.71166 6.76732 5.66699 8.05599 5.66699C9.34466 5.66699 10.3893 6.71166 10.3893 8.00033Z" stroke="#A0A8BE" />
                <path id="Vector_2" d="M13.9138 6.10134C14.4513 7.02787 14.7201 7.49107 14.7201 8C14.7201 8.50893 14.4513 8.97213 13.9138 9.89867L12.6313 12.1092C12.0959 13.032 11.8283 13.4935 11.388 13.7467C10.9478 14 10.4135 14 9.34492 14H6.76185C5.69327 14 5.15899 14 4.71877 13.7467C4.27855 13.4935 4.01085 13.032 3.47546 12.1092L2.193 9.89867C1.65548 8.97213 1.38672 8.50893 1.38672 8C1.38672 7.49107 1.65548 7.02787 2.193 6.10133L3.47546 3.89081C4.01085 2.96797 4.27855 2.50656 4.71877 2.25328C5.15899 2 5.69327 2 6.76185 2H9.34492C10.4135 2 10.9478 2 11.388 2.25328C11.8283 2.50656 12.0959 2.96798 12.6313 3.89081L13.9138 6.10134Z" stroke="#A0A8BE" />
            </g>
        </svg>

    )
}

export default SettingsIconNew