import React, { useEffect, useState } from 'react';
import '../AllPlanFeatures/index.css';
import PlansComponent from '../PlansComponent';
import CustomComponent from '../CustomComponent';
import StarterImage from '../../../assets/icons/Subscriptions/rocket-starter.svg';
import AdvancedImage from '../../../assets/icons/Subscriptions/advanced.svg';
import ElevateImage from '../../../assets/icons/Subscriptions/elevate.svg';
import UltraImage from '../../../assets/icons/Subscriptions/ultra.svg';
import EnterpriseImage from '../../../assets/icons/Subscriptions/enterprise.svg';
import { getSubscriptionsMetaData } from '../../../services';
import { CircularProgress } from '@mui/material';
import { useTheme } from '../../../Context/ThemContext/ThemeContext';

const planImages = {
	Starter: StarterImage,
	Advanced: AdvancedImage,
	Elevate: ElevateImage,
	Ultra: UltraImage,
	Enterprise: EnterpriseImage,
};

const AllPlanFeatures = () => {
	const [subscriptionsMetaData, setSubscriptionsMetaData] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);

	useEffect(() => {
		const fetchMetaData = async () => {
			try {
				const response = await getSubscriptionsMetaData();
				if (response && response.data) {
					setSubscriptionsMetaData(response.data);
				} else {
					throw new Error('Invalid data structure');
				}
			} catch (err) {
				setError('Failed to fetch subscription data');
				console.error('Error fetching subscription data:', err);
			} finally {
				setIsLoading(false);
			}
		};

		fetchMetaData();
	}, []);

	if (isLoading)
		return (
			<div style={{ padding: '300px' }}>
				<CircularProgress />
			</div>
		);
	if (error) return <div>Error: {error}</div>;

	const getImageForPlan = (planName) => {
		const trimmedPlanName = planName.trim();
		return planImages[trimmedPlanName] || planImages['Starter'];
	};
	const handleCustomButtonClick = (customButtonLink) => {
		console.log(
			'Custom Enterprise Plan button clicked. Link:',
			customButtonLink
		);
		// window.open(customButtonLink, '_blank');
	};

	const { colorMode } = useTheme();

	return (
		<div className={`AllPlanFeatures ${colorMode}`}>
			<div className='cardHeader'>
				<h3>All Plans & Features</h3>
			</div>
			{subscriptionsMetaData.map((plan, index) => (
				<React.Fragment key={plan.name}>
					{/* <div className='borderBetweenBottom'></div> */}
					<div>
						{plan.name.trim() === 'Enterprise' ? (
							<CustomComponent
								image={getImageForPlan('Enterprise')}
								alt={`${plan.name} Image`}
								Starter={plan.name}
								Free={plan.price}
								para1={plan.features[0]}
								customButton={plan.custom_button_text}
								onCustomButtonClick={() =>
									handleCustomButtonClick(plan.custom_button_link)
								}
							/>
						) : (
							<PlansComponent
								image={getImageForPlan(plan.name)}
								alt={`${plan.name} Image`}
								Starter={plan.name}
								Free={plan.price}
								para1={plan.features[0]}
								para2={plan.features[1]}
								para3={plan.features[2]}
								para4={plan.features[3]}
								para5={plan.features[4] || ''}
							/>
						)}
					</div>
				</React.Fragment>
			))}
		</div>
	);
};

export default AllPlanFeatures;
