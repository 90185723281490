import React from 'react';

function PieChart({ percentage }) {
	// Ensure percentage is between 0 and 100
	const validPercentage = Math.min(100, Math.max(0, percentage));

	// Calculate the stroke-dashoffset based on the percentage
	const radius = 10; // Smaller radius for a smaller circle
	const circumference = 2 * Math.PI * radius;
	const offset = circumference - (validPercentage / 100) * circumference;

	return (
		<svg width='30' height='30' viewBox='0 0 30 30' className='pie-chart'>
			{/* Background circle (gray) */}
			<circle
				cx='15'
				cy='15'
				r={radius}
				stroke='gray'
				strokeWidth='4'
				fill='transparent'
			/>
			{/* Foreground circle (#0069E5) */}
			<circle
				cx='15'
				cy='15'
				r={radius}
				stroke='#0069E5' // Custom blue color
				strokeWidth='4'
				fill='transparent'
				strokeDasharray={circumference}
				strokeDashoffset={offset}
				transform='rotate(-90 15 15)' // Rotate to start from the top
				style={{ transition: 'stroke-dashoffset 0.35s' }} // Smooth transition
			/>
		</svg>
	);
}

export default PieChart;
