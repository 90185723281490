import React, { useEffect, useContext, useRef, useState } from "react";
import StructureIcon from "../../../../assets/icons/tableicon-1.svg";
import RegeneratIcon from "../../../../assets/icons/tableicon-2.svg";
import DeleteIcon from "../../../../assets/icons/tableicon-3.svg";
import styles from "./index.module.css";
import TickIcon from "../../../../assets/icons/tick-02.svg";
import { Button, useMediaQuery } from "@mui/material";
import { OutpointContext } from "../../../../Context/OutpointsContext/Index";
import {
  generateAttributeAnalysis,
  deleteAttributeAnalysis,
  getAnalysisDetail,
  changeAttributeMode,
  regenerateAttributeAnalysis,
  reorderAttributeAnalysis,
  updateFeatureComparison,
} from "../../../../services";
import CircularProgress from "@mui/material/CircularProgress";
import { ColorPattern } from "../../../../constant/Color";
import { useTheme } from "../../../../Context/ThemContext/ThemeContext";
import NOAnalysisBlocker from "../../../Blockers/NoAnalysis/Index";
import { toast } from "react-toastify";

const buttonBaseStyle = {
  position: "relative",
  marginTop: "0",
  padding: "4px 15px",
  fontSize: "13px",
  textTransform: "none",
  borderRadius: "5px",
  borderWidth: "1px !important",
};

const saveButtonStyle = {
  ...buttonBaseStyle,
  backgroundColor: "#0069E51A",
  color: "#0069E5",
  border: "0.5px solid #0069E5",
};

const addAttributeButtonStyle = {
  position: "relative",
  marginTop: "15px",
  padding: "4px 15px",
  fontSize: "14px",
  color: "rgba(0, 105, 229, 1)",
  textTransform: "none",
  borderRadius: "5px",
  border: "1px solid #0069E5",
  borderWidth: "1px !important",
};

const progressStyle = {
  color: "blue",
  height: "14px !important",
  width: "14px !important",
  marginRight: "7px",
};

const DraggableTableRow = ({
  row,
  index,
  id,
  col1,
  col2,
  col3,
  isBoolean,
  handleDragStart,
  handleDragEnter,
  handleDragEnd,
  isBeingDragged,
  analysisData,
  setAnalysisData,
  previewEnabled = false,
}) => {
  if (!row?.id) return null;
  let isCheckboxType = isBoolean === true ? true : undefined; // this will come parent
  const { colorMode } = useTheme();
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [draggedIndex, setDraggedIndex] = useState(null);
  const [isDeleting, setIsDeleting] = useState();
  const [changeMode, setChangeMode] = useState();
  const [editMode, setEditMode] = useState(false);
  const [foundationValue, setFoundationValue] = useState(col2);
  const [competitorValue, setCompetitorValue] = useState(col3);
  const [firstcheckbox, setFirstcheckbox] = useState(
    isCheckboxType ? (col2 === "true" ? true : false) : true
  );
  const [secondcheckbox, setSecondcheckbox] = useState(
    isCheckboxType ? (col3 === "true" ? true : false) : true
  );
  const [isSaving, setIsSaving] = useState(false);
  const [isSwitchinTotext, setIsSwitchinTotext] = useState(false);

  const handleAvailibility = () => {
    setChangeMode(true);
  };

  const isLessThan1300px = useMediaQuery(`(max-width:1300px)`);

  const buttonStyles = {
    default: {
      height: "34px",
      width: "unset",
      padding: "0 13px",
      color: "#0069E5",
    },
    delete: {
      height: "35px",
      width: "unset",
      padding: "0 13px",
      color: "#F24F4F",
      backgroundColor: "rgba(229, 94, 94, 0.20)",
      border: "none",
    },
    cancel: {
      height: "34px",
      width: "unset",
      padding: "0 13px",
      color: ColorPattern[colorMode].textColor,
      borderColor: ColorPattern[colorMode].textColor,
    },
    switchMode: {
      borderColor: "#0069E5",
      height: "34px",
      width: "unset",
      padding: "0 13px",
      color: "#0069E5",
      backgroundColor: "rgba(0, 105, 229, 0.10)",
    },
  };

  const textAreaStyles = {
    width: "100%",
    padding: "8px",
    border: "1px solid ",
    borderColor: ColorPattern[colorMode].textColor,
    borderRadius: "4px",
    fontSize: "14px",
    fontFamily: "Inter",
    backgroundColor: ColorPattern[colorMode].bgColor,
  };
  const dragRowStyles = {
    default: {
      backgroundColor: draggedIndex === index ? "#f0f0f0" : "white",
    },
    draggable: {
      cursor: previewEnabled ? "auto" : "move",
    },
  };

  let acTionButtons;

  if (isDeleting) {
    acTionButtons = (
      <>
        <button
          disabled={isDeleteLoading}
          onClick={() => setIsDeleting(false)}
          style={buttonStyles.cancel}
          className="hover:scale-105"
        >
          Cancel
        </button>
        <button
          disabled={isDeleteLoading}
          style={buttonStyles.delete}
          className="hover:scale-105"
          onClick={() => {
            handleDeleteItem(id);
          }} // function to delete
        >
          {isDeleteLoading ? (
            <CircularProgress
              sx={{
                color: "rgb(242, 79, 79)",
                height: "15px !important",
                width: "15px !important",
                marginRight: "8px",
              }}
            />
          ) : (
            <img style={{ marginRight: "6px" }} src={DeleteIcon} alt="delete" />
          )}
          Confirm Delete
        </button>
      </>
    );
  } else {
    // if it is checkbox type
    if (isCheckboxType) {
      if (!changeMode) {
        acTionButtons = (
          <>
            <button
              style={buttonStyles.default}
              onClick={handleAvailibility}
              className="hover:scale-105"
            >
              Edit Availability
            </button>
            <button
              onClick={() => setIsDeleting(true)}
              className="hover:scale-105"
            >
              <img src={DeleteIcon} alt="delete" />
            </button>
          </>
        );
      } else {
        acTionButtons = (
          <>
            <button
              onClick={() =>
                handleChangeModeToBoolean(
                  id,
                  firstcheckbox,
                  secondcheckbox,
                  true
                )
              }
              style={buttonStyles.default}
              className="hover:scale-105"
            >
              {isSaving ? (
                <CircularProgress
                  sx={{
                    color: "rgb(0, 105, 229)",
                    height: "14px !important",
                    width: "14px !important",
                    marginRight: "8px",
                  }}
                />
              ) : (
                <img src={TickIcon} style={{ marginRight: "6px" }} />
              )}{" "}
              Save
            </button>
            <button
              onClick={() => {
                console.log("switching to text");
                handleChangeModeToBoolean(
                  id,
                  firstcheckbox,
                  secondcheckbox,
                  false
                );
              }}
              style={buttonStyles.switchMode}
              className="hover:scale-105"
            >
              {isSwitchinTotext ? (
                <>
                  <CircularProgress
                    sx={{
                      color: "rgb(0, 105, 229)",
                      height: "14px !important",
                      width: "14px !important",
                      marginRight: "8px",
                    }}
                  />
                  Switching to Sharbo
                </>
              ) : (
                "Switch to Sharbo AI Analysis"
              )}
            </button>
          </>
        );
      }
    } else {
      // else it is text type
      if (changeMode) {
        acTionButtons = (
          <>
            <button
              onClick={async () => {
                await handleChangeModeToBoolean(
                  id,
                  firstcheckbox,
                  secondcheckbox,
                  true
                );
              }}
              style={buttonStyles.default}
              className="hover:scale-105"
            >
              {isSaving ? (
                <CircularProgress
                  sx={{
                    color: "rgb(0, 105, 229)",
                    height: "14px !important",
                    width: "14px !important",
                    marginRight: "8px",
                  }}
                />
              ) : (
                <img
                  src={TickIcon}
                  style={{ marginRight: isLessThan1300px ? "0px" : "6px" }}
                />
              )}
              {isLessThan1300px ? "" : "Save"}
            </button>
            <button
              onClick={() => {
                handleChangeModeToBoolean(
                  id,
                  firstcheckbox,
                  secondcheckbox,
                  false
                );
              }}
              style={buttonStyles.switchMode}
              className="hover:scale-105"
            >
              {isSwitchinTotext ? (
                <CircularProgress
                  sx={{
                    color: "rgb(0, 105, 229)",
                    height: "14px !important",
                    width: "14px !important",
                    marginRight: "8px",
                  }}
                />
              ) : null}
              {isLessThan1300px ? (
                <img src={StructureIcon} />
              ) : (
                "Switch to Sharbo AI Analysis"
              )}
            </button>
          </>
        );
      } else {
        acTionButtons = (
          <>
            <button
              disabled={isSaving}
              onClick={() => setChangeMode(true)}
              className="hover:scale-105"
            >
              <img src={StructureIcon} />
            </button>
            <button
              onClick={async () => {
                setIsSaving(true);
                await handleRegenerate(id);
                setIsSaving(false);
              }}
              className="hover:scale-105"
            >
              {isSaving ? (
                <CircularProgress
                  sx={{
                    color: "#0069E5",
                    height: "15px !important",
                    width: "15px !important",
                  }}
                />
              ) : (
                <img src={RegeneratIcon} alt="regenerate" />
              )}
            </button>
            <button
              disabled={isSaving}
              onClick={() => setIsDeleting(true)}
              className="hover:scale-105"
            >
              <img src={DeleteIcon} alt="delete" />
            </button>
          </>
        );
      }
    }
  }

  useEffect(() => {
    resetContent();
  }, [col1, col2, col3]);

  const resetContent = () => {
    setIsSaving(true);
    setFoundationValue(col2);
    setCompetitorValue(col3);
    setEditMode(false);
    setIsSaving(false);
  };

  const handleContentUpdate = async () => {
    setIsSaving(true);
    const res = await updateFeatureComparison(
      id,
      foundationValue,
      competitorValue
    );
    if (res?.data) {
      setFoundationValue(res?.data?.foundation_data);
      setCompetitorValue(res?.data?.competitor_data);
      toast.success("Edit updated successfully");
      setEditMode(false);
    }
    setIsSaving(false);
  };

  const handleDeleteItem = async (attrId) => {
    setIsDeleteLoading(true);
    await deleteAttributeAnalysis(attrId);

    let response = await getAnalysisDetail(analysisData.analysis.analysis_id);

    if (response?.data) {
      setAnalysisData(response.data);
      setIsDeleteLoading(false);
      setIsDeleting(false);
    }
  };

  const handleRegenerate = async (attrVal) => {
    try {
      await regenerateAttributeAnalysis(
        analysisData.analysis.analysis_id,
        attrVal
      );
      const response = await getAnalysisDetail(
        analysisData.analysis.analysis_id
      );
      if (response?.data) setAnalysisData(response?.data);
      else throw new Error("Error in regenerating attribute");
    } catch (e) {
      console.error(e);
    }
  };

  const handleChangeModeToBoolean = async (
    attrId,
    competitor_data,
    foundation_data,
    feature_bool
  ) => {
    if (feature_bool) setIsSaving(true);
    else setIsSwitchinTotext(true);

    console.log("changing mode");
    await changeAttributeMode(
      attrId,
      competitor_data,
      foundation_data,
      feature_bool
    );
    let response = await getAnalysisDetail(analysisData.analysis.analysis_id);
    if (response?.data) setAnalysisData(response.data);
    setIsSaving(false);
    setChangeMode(false);
    setIsSwitchinTotext(false);
    return true;
  };

  return (
    <tr
      draggable={!previewEnabled}
      onDragStart={(e) => handleDragStart(e, index)}
      onDragEnter={(e) => handleDragEnter(e, index)}
      onDragEnd={handleDragEnd}
      onDragOver={(e) => e.preventDefault()}
      style={{
        cursor: previewEnabled ? "auto" : "move",
        // opacity: isBeingDragged ? 0.5 : 1,
        backgroundColor: isBeingDragged ? "transparent" : "transparent",
      }}
    >
      {!previewEnabled ? <td className={styles.dragHandle}>⋮⋮</td> : <td></td>}
      <td>
        <span
          style={{
            display: "inline-block",
            width: "100%",
            wordBreak: "keep-all",
          }}
        >
          {col1}
        </span>
      </td>
      <td>
        {changeMode ? (
          <>
            <input
              onChange={() => setFirstcheckbox((prev) => !prev)}
              style={{ marginRight: "7px", marginLeft: "0" }}
              checked={firstcheckbox}
              id="column2"
              type="checkbox"
            />
            <label htmlFor="column2">
              {firstcheckbox ? "Available" : "Not Available"}
            </label>
          </>
        ) : editMode ? (
          <textarea
            style={textAreaStyles}
            rows={5}
            name="foundation"
            value={foundationValue}
            onChange={(e) => setFoundationValue(e.target.value)}
          />
        ) : (
          <span
            className="markdownDiv"
            onDoubleClick={() => (!previewEnabled ? setEditMode(true) : null)}
            style={{
              display: "inline-block",
              width: "100%",
              wordBreak: "keep-all",
            }}
          >
            {isCheckboxType
              ? firstcheckbox
                ? "Available"
                : "Not Available"
              : foundationValue}
          </span>
        )}
      </td>
      <td>
        {changeMode ? (
          <>
            <input
              onChange={() => setSecondcheckbox((prev) => !prev)}
              style={{ marginRight: "7px", marginLeft: "0" }}
              checked={secondcheckbox}
              id="column3"
              type="checkbox"
            />
            <label htmlFor="column3">
              {secondcheckbox ? "Available" : "Not Available"}
            </label>
          </>
        ) : editMode ? (
          <textarea
            style={textAreaStyles}
            rows={5}
            name="competitor"
            value={competitorValue}
            onChange={(e) => setCompetitorValue(e.target.value)}
          />
        ) : (
          <span
            className="markdownDiv"
            onDoubleClick={() => (!previewEnabled ? setEditMode(true) : null)}
            style={{
              display: "inline-block",
              width: "100%",
              wordBreak: "keep-all",
            }}
          >
            {isCheckboxType
              ? secondcheckbox
                ? "Available"
                : "Not Available"
              : competitorValue}
          </span>
        )}
      </td>
      <td>
        <div
          style={{
            opacity: previewEnabled ? 0.4 : 1,
            pointerEvents: previewEnabled ? "none" : "all",
            cursor: previewEnabled ? "not-allowed" : "pointer",
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            flexDirection: "row",
            flexWrap: "wrap",
            width: "100%",
            gap: "15px",
          }}
        >
          {editMode > 0 ? (
            <>
              <button
                onClick={handleContentUpdate}
                className={`${styles.saveEditBtn} hover:scale-105`}
              >
                {isSaving ? (
                  <CircularProgress
                    sx={{
                      color: "rgb(0, 105, 229)",
                      height: "14px !important",
                      width: "14px !important",
                      marginRight: "8px",
                    }}
                  />
                ) : null}
                {isLessThan1300px ? "Save" : "Save manual edits"}
              </button>{" "}
              <button
                onClick={resetContent}
                className={`${styles.discardBtn} hover:scale-105`}
              >
                {isSaving ? (
                  <CircularProgress
                    sx={{
                      color: "rgb(0, 105, 229)",
                      height: "14px !important",
                      width: "14px !important",
                      marginRight: "8px",
                    }}
                  />
                ) : null}
                {isLessThan1300px ? "Discard" : "Discard Edits"}
              </button>
            </>
          ) : (
            acTionButtons
          )}
        </div>
      </td>
    </tr>
  );
};

const FeatureComparison = ({
  analysisData,
  setAnalysisData,
  existingRound = false,
  previewEnabled = false,
}) => {
  const { state } = useContext(OutpointContext);
  const { colorMode } = useTheme();

  const tableRef = useRef();
  const inputRef = useRef();
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showAddattribute, setShowAddattribute] = useState(false);
  const [draggedIndex, setDraggedIndex] = useState(null);
  const [isDragging, setIsDragging] = React.useState(false);
  const [dragStart, setDragstart] = React.useState(null);

  const cancelButtonStyle = {
    ...buttonBaseStyle,
    color: ColorPattern[colorMode].textColor,
    marginRight: "13px",
    border: "0.5px solid",
    borderColor: ColorPattern[colorMode].textColor,
  };

  const handleAddItem = async () => {
    if (!inputRef.current?.value?.trim()) {
      toast.error("Please enter an attribute");
      return;
    }
    setIsLoading(true);
    try {
      await generateAttributeAnalysis(
        inputRef.current.value,
        analysisData?.analysis?.analysis_id
      );

      const response = await getAnalysisDetail(
        analysisData?.analysis?.analysis_id
      );

      if (response?.data) {
        setAnalysisData(response.data);
        setShowAddattribute(false);
        inputRef.current.value = "";
        setTimeout(() => {
          tableRef.current?.scroll({
            top: tableRef.current.scrollHeight,
            behavior: "smooth",
          });
        }, 100);
      } else {
        toast.error("Error in adding attribute");
      }
    } catch (error) {
      toast.error("Failed to add attribute");
    } finally {
      setIsLoading(false);
    }
  };
  const handleDragStart = (e, index) => {
    setDraggedIndex(index);
    e.dataTransfer.effectAllowed = "move";
  };

  const handleDragEnter = (e, index) => {
    e.preventDefault();
    if (draggedIndex === index) return;

    const newRows = [...rows];
    const draggedItem = newRows[draggedIndex];
    if (draggedItem) {
      newRows.splice(draggedIndex, 1);
      newRows.splice(index, 0, draggedItem);
      setRows(newRows);
      setDraggedIndex(index);
    }
  };

  const handleDragEnd = async () => {
    const validRows = rows.filter((row) => row?.id);
    const reorderedIds = validRows.map((row, index) => ({
      id: row.id,
      order: index,
    }));

    try {
      await Promise.all(
        reorderedIds.map(({ id, order }) => reorderAttributeAnalysis(id, order))
      );
      const response = await getAnalysisDetail(
        analysisData?.analysis?.analysis_id
      );
      // if (response?.data) {
      // 	setAnalysisData(response.data);
      // }
    } catch (error) {
      toast.error("Failed to reorder attributes");
      const response = await getAnalysisDetail(
        analysisData?.analysis?.analysis_id
      );
      if (response?.data) {
        setAnalysisData(response.data);
      }
    }
    setDraggedIndex(null);
  };

  useEffect(() => {
    if (analysisData?.feature_comparison) {
      const validFeatures = analysisData.feature_comparison.filter(
        (item) => item?.attribute_id
      ); // Filter out invalid items
      const updatedRows = validFeatures.map((item) => ({
        id: item.attribute_id?.toString(),
        col1: item.attribute_data,
        col2: item.foundation_data,
        col3: item.competitor_data,
        display_order: item.display_order,
        isBoolean: item.feature_bool,
      }));

      const sortedRows = updatedRows.sort(
        (a, b) => (a.display_order || 0) - (b.display_order || 0)
      );
      setRows(sortedRows);
    }
  }, [analysisData]);

  if (!analysisData?.feature_comparison?.length) {
    return (
      <div
        id="analysis-feature"
        className="w-full relative flex flex-col items-center justify-center mt-10"
      >
        <table className={styles.featuretable}>
          <thead>
            <tr>
              <th style={{ width: "50px" }}></th>
              <th>Attribute</th>
              <th>
                {existingRound
                  ? state?.singleRound?.foundation?.foundation_name
                  : state?.roundsForm?.foundation?.foundation_name}
              </th>
              <th>
                {existingRound
                  ? state?.singleRound?.competitor?.competitor_name
                  : state.roundsForm?.competitor?.competitor_name}
              </th>
              <th></th>
            </tr>
          </thead>
        </table>
        <NOAnalysisBlocker
          analysisData={analysisData}
          setAnalysisData={setAnalysisData}
          inputRef={inputRef}
          tableRef={tableRef}
        />
      </div>
    );
  }

  return (
    <section
      style={{
        transition: "opacity  .1s ",
        pointerEvents: isDragging ? "none" : "",
        opacity: isDragging || dragStart ? ".5" : "",
      }}
      className={styles.tablewrapper}
    >
      <div
        ref={tableRef}
        id="table_inner_wrapper"
        className={styles.innertablewrapper}
      >
        <table className={styles.featuretable}>
          <thead>
            <tr>
              <th style={{ width: "50px" }}></th>
              <th>Attribute</th>
              <th>
                {existingRound
                  ? state?.singleRound?.foundation?.foundation_name
                  : state?.roundsForm?.foundation?.foundation_name}
              </th>
              <th>
                {existingRound
                  ? state?.singleRound?.competitor?.competitor_name
                  : state?.roundsForm?.competitor?.competitor_name}
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row, index) =>
              row?.id ? (
                <DraggableTableRow
                  previewEnabled={previewEnabled}
                  analysisData={analysisData}
                  setAnalysisData={setAnalysisData}
                  key={row.id}
                  row={row}
                  id={row.id}
                  col1={row.col1}
                  col2={row.col2}
                  col3={row.col3}
                  index={index}
                  isBoolean={false}
                  handleDragStart={handleDragStart}
                  handleDragEnter={handleDragEnter}
                  handleDragEnd={handleDragEnd}
                  isBeingDragged={draggedIndex === index}
                />
              ) : null
            )}
          </tbody>
        </table>
      </div>

      {!previewEnabled &&
        (showAddattribute ? (
          <div className={styles.add_attribute_wrapper}>
            <div className={styles.add_attribute_wrapper__left}>
              <input
                ref={inputRef}
                placeholder="Enter Custom Attribute Here"
                className={styles.attributeInput}
              />
            </div>
            <div className={styles.add_attribute_wrapper__right}>
              <Button
                onClick={() => setShowAddattribute(false)}
                disabled={isLoading}
                variant="outlined"
                sx={cancelButtonStyle}
                className="hover:scale-105"
              >
                Cancel
              </Button>
              <Button
                onClick={handleAddItem}
                disabled={isLoading}
                variant="outlined"
                sx={saveButtonStyle}
                className="hover:scale-105 disabled:opacity-70"
                classes={{ disabled: saveButtonStyle }}
              >
                {!isLoading ? (
                  <img
                    src={TickIcon}
                    style={{ marginRight: "5px" }}
                    alt="tick"
                  />
                ) : (
                  <CircularProgress sx={progressStyle} />
                )}
                Save & Generate Feature Analysis
              </Button>
            </div>
          </div>
        ) : (
          <Button
            id="addFeatureComparisonAttribute"
            onClick={() => setShowAddattribute(true)}
            variant="outlined"
            sx={addAttributeButtonStyle}
            className="hover:scale-105"
          >
            <svg
              style={{ marginRight: "10px", width: "10px", height: "10px" }}
              xmlns="http://www.w3.org/2000/svg"
              width="9"
              height="9"
              viewBox="0 0 9 9"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.65709 0.925781C4.65709 0.649639 4.43323 0.425781 4.15709 0.425781C3.88094 0.425781 3.65709 0.649639 3.65709 0.925781V4.0827H0.5C0.223858 4.0827 0 4.30656 0 4.5827C0 4.85884 0.223858 5.0827 0.5 5.0827H3.65709V8.24007C3.65709 8.51621 3.88094 8.74007 4.15709 8.74007C4.43323 8.74007 4.65709 8.51621 4.65709 8.24007V5.0827H7.81429C8.09043 5.0827 8.31429 4.85884 8.31429 4.5827C8.31429 4.30656 8.09043 4.0827 7.81429 4.0827H4.65709V0.925781Z"
                fill="#0069E5"
              />
            </svg>
            Add Attribute
          </Button>
        ))}
    </section>
  );
};

export default FeatureComparison;
