import React, { useState, useEffect, useContext } from 'react';
import styles from './index.module.css';
import FoundationCard from '../../Components/FoundationCard';
import Add from '../../assets/icons/add.svg';
import { PrimaryModal } from '../../Components/Common/PrimaryModal';
import RoundForm from '../../Components/NewRound/RoundForm';
import { Button, CircularProgress, Modal } from '@mui/material';
import SavedCompetitorViewOnly from '../../Components/SaveCompetitorViewOnly';
import SearchIcon from '../../assets/icons/search.svg';
import {
	BASE_URL,
	createFoundation,
	createRoundWithFoundation,
	getActiveRound_from_foundation_id,
	getCompetitorByFoundationId,
	getUserLimit,
	updateFoudation,
	deleteFoundation,
} from '../../services';
import { getAccessToken } from '../../utils/Storage';
import { OutpointContext } from '../../Context/OutpointsContext/Index';
import {
	RESET_ROUNDS_FORM,
	SET_API_LOADING,
	SET_FOUNDATION_ONLY,
	SET_FOUNDATION_ONLY_RESET,
	SET_PATH_NAME,
	UPDATE_ROUNDS_DASHBOARD,
} from '../../Context/OutpointsContext/action';
import { toast } from 'react-toastify';
import { Link, useLocation } from 'react-router-dom';
import { stepperData } from '../../Components/StepperNewRound/stepperData';
import useSaveRoundsForm from '../../hooks/useSaveRoundsForm';
import SuccessRoundModal, {
	DeleteFoundationModal,
} from '../../Components/NewRound/SuccessRoundModal';
import NewRound from '../../Components/NewRound';
import NoDraftsBlocker from '../../Components/Blockers/NoDrafts/Index';
import UsageLimitModal from '../../Components/Common/UsageLimitModal';
import { SubscriptionFeatures } from '../../constant/constants';
import ReactPaginate from 'react-paginate';
import { ChartRadarIcon } from '@hugeicons/react';
import NoFoundationsBlocker from '../../Components/Blockers/NoFoundations/Index';
import { SubscriptionContext } from '../../Context/SubscriptionContext';

const Foundations = () => {
	const {
		state,
		state: { pathName, foundation_page, apiLoading },
		dispatch,
	} = useContext(OutpointContext);
	const style = {
		height: '85%',
	};
	const { fetchSubscriptionData } = useContext(SubscriptionContext);
	const [edit, setEdit] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [openUsageModal, setOpenUsageModal] = useState(false);
	const [currentModal, setCurrentModal] = useState('');
	const [savedModal, setSavedModal] = useState(false);
	const [foundationsData, setFoundationsData] = useState([]);
	const [saveEditCount, setSaveEditCount] = useState(0);
	const [foundationObj, setFoundationObj] = useState({});
	const [compLoading, setCompLoading] = useState(false);
	const [compData, setCompData] = useState({});
	const [createLoading, setCreateLoading] = useState(null);
	const [activeRoundObj, setActiveRoundObj] = useState({});
	const [loading, setLoading] = useState(false);
	const [currentPage, setCurrentPage] = useState(0);
	const [searchTerm, setSearchTerm] = useState('');
	const [deleteLoading, setDeleteLoading] = useState(false);
	const [deleteModal, setDeleteModal] = useState(false);
	const itemsPerPage = 4;
	const [currentDeleteFoundation, setCurrentDeleteFoundation] = useState({});

	const activeRound = async (data) => {
		let obj = {};

		try {
			const promises = data?.map((key) =>
				getActiveRound_from_foundation_id(key.foundation_id, 0).then(
					(result) => ({
						foundation_id: key.foundation_id,
						count: result.data?.length || 0,
						data: result?.data || [],
					})
				)
			);

			const results = await Promise.all(promises);

			results.forEach((item) => {
				obj[item.foundation_id] = { count: item.count, data: item.data };
			});

			setActiveRoundObj(obj);
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		const fetchCompetitors = async () => {
			setCompLoading(true);
			let obj = {};

			try {
				const promises = foundationsData.map((key) =>
					getCompetitorByFoundationId(key.foundation_id, 0).then((result) => ({
						foundation_id: key.foundation_id,
						count: result.data?.length || 0,
						data: result?.data || [],
					}))
				);

				const results = await Promise.all(promises);

				results.forEach((item) => {
					obj[item.foundation_id] = { count: item.count, data: item.data };
				});

				setCompData(obj);
			} catch (err) {
				console.log(err);
			}

			setCompLoading(false);
		};

		if (foundationsData?.length > 0) {
			fetchCompetitors();
		}

		console.log(foundationsData);
	}, [foundationsData]);

	const handleCurrentModal = (id, item) => {
		setCurrentModal(id);
		setFoundationObj(item);
		setSavedModal(true);
	};

	const handleDeleteFoundation = async (foundation_id) => {
		setDeleteLoading(true);
		try {
			let res = await deleteFoundation(foundation_id);
			if (res?.message) {
				toast.success(res.message);
				setSaveEditCount(saveEditCount + 1);
				setDeleteModal(false);
			}
			if (res?.detail[0]?.msg) {
				toast.error(res.detail[0].msg);
			}
		} catch (err) {
			console.log(err);
		}
		setDeleteLoading(false);
		window.dispatchEvent(new Event('subscription-updated'));
		fetchSubscriptionData();
	};

	const handleOpenModal = async (editFoundation, item) => {
		setEdit(editFoundation);

		if (editFoundation) {
			dispatch({
				type: SET_FOUNDATION_ONLY,
				payload: item,
			});
		} else {
			dispatch({
				type: SET_FOUNDATION_ONLY_RESET,
				payload: {
					foundation_name: '',
					foundation_mode: 1,
					url: [],
					scrape_mode: 'singlepage',
					additional_details: '',
					description: '',
				},
			});
		}
		setOpenModal(true);
		fetchSubscriptionData();
	};

	const getFoundationsData = async () => {
		setLoading(true);
		try {
			const jwt_token = getAccessToken();
			const headers = {
				Authorization: `Bearer ${jwt_token}`,
				'Content-Type': 'application/json',
			};
			const response = await fetch(`${BASE_URL}/foundations/all?sort_by=0`, {
				method: 'GET',
				headers: headers,
			});

			if (!response.ok) {
				throw new Error(`Failed to fetch data: ${response.statusText}`);
			}

			const responseData = await response.json();
			console.log(responseData);
			await activeRound([...responseData]);
			setFoundationsData(Array.isArray(responseData) ? responseData : []);
		} catch (error) {
			console.error('Error fetching foundations data:', error);
			setFoundationsData([]);
		}
		setLoading(false);
	};

	useEffect(() => {
		getFoundationsData();
	}, [saveEditCount]);

	const editTitle = () => {
		const activeRoundCount =
			activeRoundObj[foundationObj?.foundation_id]?.count || 0;
		return (
			<div className={styles.editFoundationParent}>
				<div className={styles.editFoundation}>Edit Foundation</div>
				<div className={styles.thisFoundationIsBeingUsedWrapper}>
					<div className={styles.thisFoundationIs}>
						{/* This Foundation is being used in 5 Active Rounds */}
						This Foundation is being used in {activeRoundCount} Active Round
						{activeRoundCount !== 1 ? 's' : ''}
					</div>
				</div>
			</div>
		);
	};

	const handleSaveEdit = async () => {
		dispatch({ type: SET_API_LOADING, payload: true });
		try {
			if (pathName === '/foundations') {
				let obj = { ...foundation_page };

				if (obj.foundation_mode == 1) {
					obj.description = null;
				} else {
					obj.url = null;
					obj.scrape_mode = null;
					obj.additional_details = null;
				}
				let data;
				if (!obj?.foundation_id) {
					data = await createFoundation(obj, false);
				} else {
					data = await updateFoudation(obj.foundation_id, obj);
				}

				if (data?.data) {
					toast.success(data.message);
					setSaveEditCount(saveEditCount + 1);
					setOpenModal(false);
				} else {
					toast.error(data?.detail?.[0]?.msg);
				}
			}
		} catch (err) {
			console.log(err);
		}
		dispatch({ type: SET_API_LOADING, payload: false });
		window.dispatchEvent(new Event('subscription-updated'));
	};

	const { handleRoundsForm } = useSaveRoundsForm();

	const [openDatapoints, setOpenDatapoints] = useState(false);
	const location = useLocation();

	const {
		setCurrentStep,
		state: { round_dash_update },
	} = useContext(OutpointContext);

	const [successModal, setSuccessModal] = useState(false);

	const handleStepperModal = async (item) => {
		setCreateLoading(item?.foundation_id);
		try {
			const access = await getUserLimit(SubscriptionFeatures.FOUNDATION);
			if (access) {
				let data = await createRoundWithFoundation(item?.foundation_id);
				if (data?.data) {
					dispatch({ type: SET_PATH_NAME, payload: '/' });
					handleRoundsForm(data?.data, 'round_id', '/');
					handleRoundsForm(item, 'foundation', '/');
					setCurrentStep(stepperData[1]);
					setOpenDatapoints(true);
				}
			} else setOpenUsageModal(true);
		} catch (err) {
			console.log(err);
		}

		setCreateLoading(null);
	};

	const handleCloseModal = (id) => {
		dispatch({
			type: UPDATE_ROUNDS_DASHBOARD,
			payload: 1,
		});
		setOpenDatapoints(id);
		dispatch({
			type: RESET_ROUNDS_FORM,
			payload: {
				foundation: {
					foundation_name: '',
					foundation_mode: 1,
					url: [],
					scrape_mode: 'singlepage',
					additional_details: '',
					description: '',
				},
				competitor: {
					competitor_name: '',
					competitor_mode: 1,
					url: [],
					scrape_mode: 'singlepage',
					additional_details: '',
					description: '',
				},
				analysis: {},
			},
		});

		dispatch({ type: SET_PATH_NAME, payload: location.pathname });
	};
	const handleSuccessCloseModal = (id) => {
		dispatch({
			type: UPDATE_ROUNDS_DASHBOARD,
			payload: 1,
		});
		setActive(true);
		setSuccessModal(id);
		dispatch({
			type: RESET_ROUNDS_FORM,
			payload: {
				foundation: {
					foundation_name: '',
					foundation_mode: 1,
					url: [],
					scrape_mode: 'singlepage',
					additional_details: '',
					description: '',
				},
				competitor: {
					competitor_name: '',
					competitor_mode: 1,
					url: [],
					scrape_mode: 'singlepage',
					additional_details: '',
					description: '',
				},
				analysis: {},
			},
		});

		dispatch({ type: SET_PATH_NAME, payload: location.pathname });
	};
	const filterData = (data) => {
		return data.filter((item) =>
			item.foundation_name.toLowerCase().includes(searchTerm.toLowerCase())
		);
	};
	const filteredData = filterData(foundationsData);
	const pageCount = Math.ceil(filteredData.length / itemsPerPage);
	const displayedData = filteredData.slice(
		currentPage * itemsPerPage,
		(currentPage + 1) * itemsPerPage
	);

	return (
		<>
			<Modal
				styles={'width:300px'}
				open={openUsageModal}
				onClose={() => setOpenUsageModal(false)}>
				<UsageLimitModal closeModal={() => setOpenUsageModal(false)} />
			</Modal>

			<PrimaryModal
				title={' '}
				open={deleteModal}
				modalStyleClass='w-[750px] h-[500px] m-auto top-1/2 transform -translate-y-1/2'
				setOpen={setDeleteModal}>
				<DeleteFoundationModal
					setDeleteModal={setDeleteModal}
					currentDeleteFoundation={currentDeleteFoundation}
					handleDeleteFoundation={handleDeleteFoundation}
					deleteLoading={deleteLoading}
				/>
			</PrimaryModal>

			<PrimaryModal
				primaryModalChildrenClass={styles.addEnrichmentChildren}
				title={' '}
				open={successModal}
				setOpen={handleSuccessCloseModal}>
				<SuccessRoundModal />
			</PrimaryModal>
			<PrimaryModal
				primaryModalChildrenClass={styles.addEnrichmentChildren}
				title={'New Round'}
				open={openDatapoints}
				setOpen={handleCloseModal}>
				<NewRound
					handleCloseParentModal={handleCloseModal}
					setOpenDatapoints={setOpenDatapoints}
					setSuccessModal={setSuccessModal}
				/>
			</PrimaryModal>
			<PrimaryModal
				primaryModalChildrenClass={styles.addEnrichmentChildren}
				title={
					currentModal == 1 ? (
						`Saved Competitors — ${foundationObj?.foundation_name} (${
							compData?.[foundationObj?.foundation_id]?.count
						})`
					) : (
						<div className={styles.titleWithIcon}>
							<span>
								{' '}
								<ChartRadarIcon
									size={18}
									color={'#7CA1CB'}
									variant={'stroke'}
								/>
							</span>{' '}
							<span>{`Associated Rounds — ${foundationObj?.foundation_name} (${
								activeRoundObj?.[foundationObj?.foundation_id]?.count
							})`}</span>
						</div>
					)
					//  `Associated Rounds — ${foundationObj?.foundation_name} (${activeRoundObj?.[foundationObj?.foundation_id]?.count
					// })`
				}
				open={savedModal}
				setOpen={setSavedModal}>
				<div className={styles.currentModal}>
					{
						<SavedCompetitorViewOnly
							currentModal={currentModal}
							foundation={foundationObj}
							data={compData?.[foundationObj?.foundation_id]?.data}
							activeData={activeRoundObj?.[foundationObj?.foundation_id]?.data}
						/>
					}
					<div className={styles.buttonActions}>
						<Button
							onClick={() => {
								setSavedModal(false);
							}}
							variant='outlined'
							sx={{ margin: '10px', textTransform: 'none' }}>
							{' '}
							Close{' '}
						</Button>
					</div>
				</div>
			</PrimaryModal>
			<PrimaryModal
				primaryModalChildrenClass={styles.addEnrichmentChildren}
				title={!edit ? 'Add Foundation' : editTitle()}
				open={openModal}
				setOpen={setOpenModal}>
				<div className={styles.currentModal}>
					<RoundForm
						style={style}
						isThird={false}
						des={true}
						RoundTitle={'lkjhgfghjkl;kjhgfd'}
					/>
					<div className={styles.buttonActions}>
						<Button
							onClick={() => {
								setOpenModal(false);
								setEdit(false);
							}}
							variant='outlined'
							sx={{ margin: '10px' }}>
							{' '}
							Back{' '}
						</Button>
						<Button
							variant='contained'
							sx={{ margin: '10px' }}
							onClick={handleSaveEdit}
							disabled={apiLoading}>
							Save
						</Button>
					</div>
				</div>
			</PrimaryModal>
			{loading ? (
				<div
					style={{
						alignItems: 'center',
						display: 'flex',
						justifyContent: 'center',
						padding: '300px 0px',
					}}>
					<CircularProgress />
				</div>
			) : foundationsData && foundationsData?.length > 0 ? (
				<div className={styles.container}>
					<div
						style={{
							alignItems: 'center',
							display: 'flex',
							justifyContent: 'center',
							padding: ' 0px',
						}}></div>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'flex-start',
						}}>
						<div
							style={{
								cursor: 'pointer',
							}}
							onClick={() => handleOpenModal(false)}
							className={styles.add01Parent}>
							<img className={styles.add01Icon} alt='' src={Add} />
							<div id='createNewFoundation' className={styles.createANew}>
								Create a New Foundation
							</div>
						</div>
						<div className={styles.search01Parent}>
							<img className={styles.search01Icon} alt='' src={SearchIcon} />
							<input
								type='text'
								placeholder='Search Foundation Name'
								className={styles.searchInput}
								value={searchTerm}
								onChange={(e) => setSearchTerm(e.target.value)}
							/>
						</div>
					</div>

					<div
						style={{
							width: '100%',
							display: 'flex',
							flexDirection: 'column',
							gap: '30px',
							alignItems: 'center',
							marginTop: '20px',
						}}>
						<div className={styles.draftList}>
							{displayedData.map((foundation, index) => (
								<FoundationCard
									createLoading={createLoading}
									handleCreateRound={handleStepperModal}
									data={compData}
									setCurrentDeleteFoundation={setCurrentDeleteFoundation}
									compLoading={compLoading}
									key={index}
									foundation={foundation}
									handleCurrentModal={handleCurrentModal}
									handleOpenModal={handleOpenModal}
									activeRoundObj={activeRoundObj}
									setDeleteModal={setDeleteModal}
								/>
							))}
						</div>
						{filteredData.length > itemsPerPage && (
							<ReactPaginate
								previousLabel={'Previous'}
								nextLabel={'Next'}
								breakLabel={'...'}
								breakClassName={'break-me'}
								pageCount={pageCount}
								marginPagesDisplayed={2}
								pageRangeDisplayed={5}
								onPageChange={({ selected }) => setCurrentPage(selected)}
								containerClassName={styles.pagination}
								activeClassName={styles.activePage}
								previousClassName={styles.pageItem}
								nextClassName={styles.pageItem}
								disabledClassName={styles.disabled}
								breakLinkClassName={styles.break}
							/>
						)}
					</div>
				</div>
			) : (
				<NoFoundationsBlocker
					text='No saved foundation'
					// Description='A Foundation is your products data and information used to create Competitor Feature Comparison Rounds'
					handleButtonFn={handleOpenModal}
					ButtonData='Create Foundation'
				/>
			)}
		</>
	);
};

export default Foundations;
