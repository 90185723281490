const RoundsIcon = ({ fill = '#A0A8BE' }) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.98561 2.96819C6.4354 1.87807 7.1603 1.33301 8.00016 1.33301C8.84003 1.33301 9.5649 1.87807 11.0147 2.96819L12.3416 3.96589C13.6726 4.96667 14.338 5.46705 14.5756 6.19872C14.8132 6.93041 14.566 7.71794 14.0715 9.29301L13.466 11.2217C12.9448 12.8819 12.6841 13.712 12.0269 14.1892C11.3697 14.6663 10.487 14.6663 8.7215 14.6663H7.27883C5.51336 14.6663 4.63064 14.6663 3.97342 14.1892C3.3162 13.712 3.05559 12.8819 2.53436 11.2217L1.92882 9.29301C1.43432 7.71794 1.18708 6.93041 1.42468 6.19872C1.66228 5.46705 2.32776 4.96667 3.65872 3.96589L4.98561 2.96819Z" stroke="#0069E5" />
            <path d="M6.7445 6.55151C7.3541 6.17405 7.65896 5.98532 7.9939 6.00089C8.32876 6.01645 8.61443 6.23262 9.1857 6.66496L9.6443 7.012C10.2348 7.45886 10.53 7.68233 10.6308 8.0092C10.7316 8.33606 10.6128 8.6848 10.3752 9.38226L10.2364 9.78953C10.0216 10.4202 9.9141 10.7356 9.66696 10.9306C9.41983 11.1257 9.08556 11.159 8.41703 11.2256L8.03496 11.2637C7.17523 11.3493 6.74536 11.3921 6.42187 11.173C6.0984 10.9539 5.98276 10.5415 5.75149 9.71673L5.57138 9.07446C5.36465 8.3372 5.26128 7.9686 5.39047 7.6392C5.51966 7.30986 5.84715 7.10713 6.50213 6.7016L6.7445 6.55151Z" stroke="#0069E5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M1.6665 6.33301L5.33317 7.66634" stroke="#0069E5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M9.6665 11L11.9998 14" stroke="#0069E5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M10.6665 7.99967L14.3332 6.33301" stroke="#0069E5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M4.3335 14L6.00016 11" stroke="#0069E5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M8 5.99967V1.33301" stroke="#0069E5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>


    )
}

export default RoundsIcon