import React, { useState, useEffect } from 'react';
import CrashedScreen from '../CrashedScreen';

const ErrorBoundary = ({ children }) => {
	const [hasError, setHasError] = useState(false);

	useEffect(() => {
		// A function to catch errors from child components
		const errorListener = (error) => {
			setHasError(true);
			console.error('ErrorBoundary caught an error:', error);
		};

		// Listen for uncaught errors
		window.addEventListener('error', errorListener);

		return () => {
			// Cleanup on component unmount
			window.removeEventListener('error', errorListener);
		};
	}, []);

	if (hasError) {
		return <CrashedScreen hasError={hasError} />; // Show CrashedScreen if an error occurs
	}

	return <>{children}</>;
};

export default ErrorBoundary;
