import React from 'react';
import styles from './index.module.css';
import success from '../../../assets/icons/successright.svg';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { useTheme } from '../../../Context/ThemContext/ThemeContext';
import { ColorPattern } from '../../../constant/Color';
import Lottie from 'lottie-react';
import trashAnimation from './trashAnimation.json';
import pauseAnimation from './pauseAnimation.json';
import checkAnimation from './checkAnimation.json';

const SuccessRoundModal = ({ heading, trackinJob }) => {
	const navigate = useNavigate();
	const { colorMode } = useTheme();

	return (
		<div className={styles.frameParent + ' !h-full'}>
			<div className={styles.frameGroup}>
				{/* <img className={styles.frameChild} alt='' src={success} /> */}
				<Lottie
					style={{
						height: '150px',
						width: '150px',
					}}
					animationData={checkAnimation}
					loop={false}
				/>
				<div className={styles.roundSuccessfullyCreated}>
					{heading ? heading : 'Round successfully created'}
				</div>
				{trackinJob ? (
					<button
						onClick={() => navigate('/')}
						className={`${styles.button1} hover:scale-105`}>
						View Round Dashboard
					</button>
				) : (
					<div className={styles.accessEmbeddingGuides}>
						Access embedding guides, update tracking, and more from the Round's
						dashboard
					</div>
				)}
			</div>
		</div>
	);
};

const UpdateSuccessRoundModal = ({ heading, closeAction }) => {
	const navigate = useNavigate();
	const { colorMode } = useTheme();

	return (
		<div className={styles.frameParent + ' !h-full'}>
			<div className={styles.frameGroup}>
				{/* <img className={styles.frameChild} alt='' src={success} /> */}
				<Lottie
					style={{
						height: '150px',
						width: '150px',
					}}
					animationData={checkAnimation}
					loop={false}
				/>
				<div className={styles.roundSuccessfullyCreated}>{heading}</div>
				<button
					onClick={() => closeAction()}
					className={`${styles.button1} hover:scale-105`}>
					Close
				</button>
			</div>
		</div>
	);
};

const ResumeTrackingModal = ({ setResumeModal }) => {
	const { colorMode } = useTheme();

	return (
		<div
			className={`!h-full px-24 w-full relative overflow-hidden text-center text-2xl flex items-center ${
				colorMode == 'light'
					? 'bg-[#F8F8F8] text-[#1e1e1e]'
					: 'bg-[#131314] text-[#e1e1e1]'
			}`}>
			<div className={'w-full flex flex-col items-center justify-start gap-5'}>
				<img className={styles.frameChild} alt='' src={success} />
				<div className={styles.roundSuccessfullyCreated}>
					Round Tracking has been successfully resumed
				</div>
				{
					<button
						onClick={() => setResumeModal(false)}
						className={`${styles.button1} hover:scale-105  mt-10`}>
						Done
					</button>
				}
			</div>
		</div>
	);
};

const PauseTrackingModal = ({
	setPauseModal,
	setTrackStatuspause,
	pauseLoading,
	handlePauseActive,
}) => {
	const { colorMode } = useTheme();
	return (
		<div
			className={`!h-full px-24 w-full relative overflow-hidden text-center text-2xl flex items-center ${
				colorMode == 'light'
					? 'bg-[#F8F8F8] text-[#1e1e1e]'
					: 'bg-[#131314] text-[#e1e1e1]'
			}`}>
			<div className={'w-full flex flex-col items-center justify-start gap-5'}>
				{/* <svg
					xmlns='http://www.w3.org/2000/svg'
					width='81'
					height='81'
					viewBox='0 0 81 81'
					fill='none'>
					<path
						opacity='0.4'
						d='M13.3867 23.833C13.3867 19.119 13.3867 16.7619 14.8512 15.2975C16.3157 13.833 18.6727 13.833 23.3867 1Caution:
3.833C28.1008 13.833 30.4578 13.833 31.9223 15.2975C33.3867 16.7619 33.3867 19.119 33.3867 23.833V57.1663C33.3867 61.8803 33.3867 64.2373 31.9223 65.702C30.4578 67.1663 28.1008 67.1663 23.3867 67.1663C18.6727 67.1663 16.3157 67.1663 14.8512 65.702C13.3867 64.2373 13.3867 61.8803 13.3867 57.1663V23.833Z'
						fill='#3F628B'
					/>
					<path
						d='M13.3867 23.833C13.3867 19.119 13.3867 16.7619 14.8512 15.2975C16.3157 13.833 18.6727 13.833 23.3867 13.833C28.1008 13.833 30.4578 13.833 31.9223 15.2975C33.3867 16.7619 33.3867 19.119 33.3867 23.833V57.1663C33.3867 61.8803 33.3867 64.2373 31.9223 65.702C30.4578 67.1663 28.1008 67.1663 23.3867 67.1663C18.6727 67.1663 16.3157 67.1663 14.8512 65.702C13.3867 64.2373 13.3867 61.8803 13.3867 57.1663V23.833Z'
						stroke='#3F628B'
						stroke-width='5'
					/>
					<path
						opacity='0.4'
						d='M46.7227 23.833C46.7227 19.119 46.7227 16.7619 48.187 15.2975C49.6517 13.833 52.0087 13.833 56.7227 13.833C61.4367 13.833 63.7937 13.833 65.2583 15.2975C66.7227 16.7619 66.7227 19.119 66.7227 23.833V57.1663C66.7227 61.8803 66.7227 64.2373 65.2583 65.702C63.7937 67.1663 61.4367 67.1663 56.7227 67.1663C52.0087 67.1663 49.6517 67.1663 48.187 65.702C46.7227 64.2373 46.7227 61.8803 46.7227 57.1663V23.833Z'
						fill='#3F628B'
					/>
					<path
						d='M46.7227 23.833C46.7227 19.119 46.7227 16.7619 48.187 15.2975C49.6517 13.833 52.0087 13.833 56.7227 13.833C61.4367 13.833 63.7937 13.833 65.2583 15.2975C66.7227 16.7619 66.7227 19.119 66.7227 23.833V57.1663C66.7227 61.8803 66.7227 64.2373 65.2583 65.702C63.7937 67.1663 61.4367 67.1663 56.7227 67.1663C52.0087 67.1663 49.6517 67.1663 48.187 65.702C46.7227 64.2373 46.7227 61.8803 46.7227 57.1663V23.833Z'
						stroke='#3F628B'
						stroke-width='5'
					/>
				</svg> */}
				<Lottie
					style={{
						height: '70px',
						width: '70px',
					}}
					animationData={pauseAnimation}
					loop={true}
				/>
				<div className={styles.roundSuccessfullyCreated}>
					Pause this Round's Tracking
				</div>
				<p className={styles.roundSuccessfullyCreatedpara}>
					All scheduled track jobs for this Round will be temporarily paused. No
					tracking will occur until you reactivate it
				</p>
				<div className='flex justify-center gap-4 mt-10'>
					<button
						onClick={() => setPauseModal(false)}
						// className={styles.button2}
						className='w-[100px] h-[35px] px-2.5 bg-transparent rounded-[5px] border border-[#3f628b] justify-center items-center gap-1.5 inline-flex text-[#3e618a] text-xs font-medium hover:scale-105'>
						Cancel
					</button>
					<button
						onClick={async () => {
							await handlePauseActive();
							setPauseModal(false);
						}}
						disabled={pauseLoading}
						className={`${styles.button3} hover:scale-105 flex gap-2`}>
						{pauseLoading && (
							<CircularProgress
								sx={{
									color: 'white',
									height: '16px !important',
									width: '16px !important',
									position: 'relative',
								}}
							/>
						)}
						Pause Tracking
					</button>
				</div>
			</div>
		</div>
	);
};

const SaveUpdatedFoundationResources = ({
	setPauseModal,
	setTrackStatuspause,
}) => {
	return (
		<div className={styles.frameParent}>
			<div className={styles.frameGroup}>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='53'
					height='53'
					viewBox='0 0 53 53'
					fill='none'>
					<path
						opacity='0.4'
						d='M26.4072 52.374C12.1401 52.374 0.574219 40.8083 0.574219 26.541C0.574219 12.2738 12.1401 0.708008 26.4072 0.708008C40.6745 0.708008 52.2402 12.2738 52.2402 26.541C52.2402 40.8083 40.6745 52.374 26.4072 52.374Z'
						fill='#0069E5'
						fill-opacity='0.6'
					/>
				</svg>
				<div className={styles.roundSuccessfullyCreated}>
					Save Updated Foundation Sources
				</div>
				<p className={styles.roundSuccessfullyCreatedpara}>
					This will trigger an update of all analyses and page embeddings for
					all Rounds associated with this Foundation
				</p>
				<div style={{ display: 'flex', justifyContent: 'center', gap: '15px' }}>
					<button
						//onClick={() => setPauseModal(false)}
						className={`${styles.button2} hover:scale-105`}>
						Discard
					</button>
					<button
						onClick={() => {
							// setTrackStatuspause(true);
							// setPauseModal(false);
						}}
						className={`${styles.button3} hover:scale-105`}>
						Save
					</button>
				</div>
			</div>
		</div>
	);
};

const DeleteConfigurationModal = ({
	setDeleteModal,
	setDeleteSuccessModal,
	handleDeleteRound,
	deleteLoading,
	roundName,
}) => {
	const { colorMode } = useTheme();
	return (
		<div
			className={`!h-full px-24 w-full relative overflow-hidden text-center text-2xl flex items-center ${
				colorMode == 'light'
					? 'bg-[#F8F8F8] text-[#1e1e1e]'
					: 'bg-[#131314] text-[#e1e1e1]'
			}`}>
			<div className={'w-full flex flex-col items-center justify-start gap-5'}>
				{/* <svg
          width="49"
          height="55"
          viewBox="0 0 49 55"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Frame 1538">
            <path
              id="Vector"
              d="M43.3047 11.2178L41.7554 36.2805C41.3594 42.6838 41.1617 45.8855 39.5567 48.1875C38.7629 49.3255 37.7414 50.286 36.5564 51.0078C34.1599 52.4678 30.9522 52.4678 24.5364 52.4678C18.1125 52.4678 14.9004 52.4678 12.5023 51.005C11.3167 50.282 10.2947 49.3198 9.50139 48.1798C7.89689 45.8743 7.70331 42.668 7.31621 36.2558L5.80469 11.2178"
              stroke="#F24F4F"
              stroke-width="3.75"
              stroke-linecap="round"
            />
            <path
              id="Vector_2"
              d="M47.0547 11.2178H2.05469"
              stroke="#F24F4F"
              stroke-width="3.75"
              stroke-linecap="round"
            />
            <path
              id="Vector_3"
              d="M34.6984 11.2178L32.9917 7.6971C31.8579 5.35842 31.2912 4.18907 30.3134 3.4598C30.0964 3.29802 29.8669 3.15412 29.6267 3.02952C28.5439 2.46777 27.2444 2.46777 24.6454 2.46777C21.9812 2.46777 20.6489 2.46777 19.5483 3.05307C19.3043 3.1828 19.0716 3.33252 18.8523 3.5007C17.8632 4.25952 17.3107 5.47165 16.2056 7.89592L14.6914 11.2178"
              stroke="#F24F4F"
              stroke-width="3.75"
              stroke-linecap="round"
            />
            <path
              id="Vector_4"
              opacity="0.4"
              d="M18.3047 38.7178V23.7178"
              stroke="#F24F4F"
              stroke-width="3.75"
              stroke-linecap="round"
            />
            <path
              id="Vector_5"
              opacity="0.4"
              d="M30.8047 38.7178V23.7178"
              stroke="#F24F4F"
              stroke-width="3.75"
              stroke-linecap="round"
            />
          </g>
        </svg> */}
				<Lottie
					style={{
						height: '70px',
						width: '70px',
					}}
					animationData={trashAnimation}
					loop={true}
				/>
				<div className={styles.roundSuccessfullyCreated}>
					Delete Round - {roundName}
				</div>
				<p
					style={{
						display: 'flex',
						alignItems: 'center',
						color: '#797B7E',
						fontSize: '16px',
						letterSpacing: '.32px',
						margin: '0 0 10px',
					}}
					className={styles.roundSuccessfullyCreatedpara}>
					<svg
						style={{ marginRight: '6px' }}
						xmlns='http://www.w3.org/2000/svg'
						width='19'
						height='19'
						viewBox='0 0 19 19'
						fill='none'>
						<path
							fill-rule='evenodd'
							clip-rule='evenodd'
							d='M9.54639 2.9446C9.44716 2.76603 9.25899 2.65527 9.05469 2.65527C8.85039 2.65527 8.66221 2.76603 8.56299 2.9446L1.06297 16.4446C0.966185 16.6188 0.96881 16.8312 1.06989 17.003C1.17096 17.1748 1.35538 17.2803 1.55469 17.2803H16.5547C16.754 17.2803 16.9384 17.1748 17.0395 17.003C17.1406 16.8312 17.1432 16.6188 17.0464 16.4446L9.54639 2.9446ZM8.49219 13.3428V14.4678H9.61719V13.3428H8.49219ZM9.61719 12.2178V8.46777H8.49219V12.2178H9.61719Z'
							fill='#FFAC0A'
						/>
					</svg>
					<span
						style={{
							fontWeight: 500,
							color: ColorPattern[colorMode].textColor,
							marginRight: '8px',
						}}>
						Caution:
					</span>{' '}
					Cannot be undone
				</p>
				<p
					style={{
						color: '#797B7E',
						fontSize: '16px',
						letterSpacing: '.32px',
						margin: 0,
					}}
					className={styles.roundSuccessfullyCreatedpara}>
					The round and its embedded content will be deleted. However, saved
					foundations and competitors will not be affected
				</p>
				<div className='flex justify-center gap-4 mt-10'>
					<button
						onClick={() => setDeleteModal(false)}
						className='w-[100px] h-[35px] px-2.5 bg-transparent rounded-[5px] border border-[#3f628b] justify-center items-center gap-1.5 inline-flex text-[#3e618a] text-xs font-medium hover:scale-105'>
						Cancel
					</button>
					<button
						id='deleteRoundBtn'
						style={{
							backgroundColor: '#F24F4F',
							color: 'white',
							position: 'relative',
						}}
						onClick={handleDeleteRound}
						className={`${styles.button3} hover:scale-105`}
						disabled={deleteLoading}>
						{deleteLoading && (
							<CircularProgress
								sx={{
									color: 'gray',
									height: '20px !important',
									width: '20px !important',
									position: 'absolute',
									top: '5px',
								}}
							/>
						)}
						Delete Round
					</button>
				</div>
			</div>
		</div>
	);
};

const DeleteFoundationModal = ({
	setDeleteModal,
	handleDeleteFoundation,
	deleteLoading,

	currentDeleteFoundation,
}) => {
	const { colorMode } = useTheme();
	return (
		<div
			className={`!h-full px-24 w-full relative overflow-hidden text-center text-2xl flex items-center ${
				colorMode == 'light'
					? 'bg-[#F8F8F8] text-[#1e1e1e]'
					: 'bg-[#131314] text-[#e1e1e1]'
			}`}>
			<div className={'w-full flex flex-col items-center justify-start gap-5'}>
				<Lottie
					style={{
						height: '70px',
						width: '70px',
					}}
					animationData={trashAnimation}
					loop={true}
				/>
				<div className={styles.roundSuccessfullyCreated}>
					Delete Foundation - {currentDeleteFoundation.foundation_name}
				</div>
				<p
					style={{
						display: 'flex',
						alignItems: 'center',
						color: '#797B7E',
						fontSize: '16px',
						letterSpacing: '.32px',
						margin: '0 0 10px',
					}}
					className={styles.roundSuccessfullyCreatedpara}>
					<svg
						style={{ marginRight: '6px' }}
						xmlns='http://www.w3.org/2000/svg'
						width='19'
						height='19'
						viewBox='0 0 19 19'
						fill='none'>
						<path
							fill-rule='evenodd'
							clip-rule='evenodd'
							d='M9.54639 2.9446C9.44716 2.76603 9.25899 2.65527 9.05469 2.65527C8.85039 2.65527 8.66221 2.76603 8.56299 2.9446L1.06297 16.4446C0.966185 16.6188 0.96881 16.8312 1.06989 17.003C1.17096 17.1748 1.35538 17.2803 1.55469 17.2803H16.5547C16.754 17.2803 16.9384 17.1748 17.0395 17.003C17.1406 16.8312 17.1432 16.6188 17.0464 16.4446L9.54639 2.9446ZM8.49219 13.3428V14.4678H9.61719V13.3428H8.49219ZM9.61719 12.2178V8.46777H8.49219V12.2178H9.61719Z'
							fill='#FFAC0A'
						/>
					</svg>
					<span
						style={{
							fontWeight: 500,
							color: ColorPattern[colorMode].textColor,
							marginRight: '8px',
						}}>
						Caution:
					</span>{' '}
					Cannot be undone
				</p>
				<p
					style={{
						color: '#797B7E',
						fontSize: '16px',
						letterSpacing: '.32px',
						margin: 0,
					}}
					className={styles.roundSuccessfullyCreatedpara}>
					The foundation and its embedded content will be deleted.
				</p>
				<div className='flex justify-center gap-4 mt-10'>
					<button
						onClick={() => setDeleteModal(false)}
						className='w-[100px] h-[35px] px-2.5 bg-transparent rounded-[5px] border border-[#3f628b] justify-center items-center gap-1.5 inline-flex text-[#3e618a] text-xs font-medium hover:scale-105'>
						Cancel
					</button>
					<button
						id='deleteRoundBtn'
						style={{
							backgroundColor: '#F24F4F',
							color: 'white',
							position: 'relative',
						}}
						onClick={() =>
							handleDeleteFoundation(currentDeleteFoundation.foundation_id)
						}
						className={`${styles.button3} hover:scale-105`}
						disabled={deleteLoading}>
						{deleteLoading && (
							<CircularProgress
								sx={{
									color: 'gray',
									height: '20px !important',
									width: '20px !important',
									position: 'absolute',
									top: '5px',
								}}
							/>
						)}
						Delete Foundation
					</button>
				</div>
			</div>
		</div>
	);
};

const DeleteConfigurationModalSuccess = ({ navigateToDeleteRound }) => {
	const { colorMode } = useTheme();
	return (
		<div
			className={`!h-full px-24 w-full relative overflow-hidden text-center text-2xl flex items-center ${
				colorMode == 'light'
					? 'bg-[#F8F8F8] text-[#1e1e1e]'
					: 'bg-[#131314] text-[#e1e1e1]'
			}`}>
			<div className={'w-full flex flex-col items-center justify-start gap-5'}>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='120'
					height='120'
					viewBox='0 0 82 100'
					fill='none'>
					<path
						d='M39.9072 66.8418C25.6401 66.8418 14.0742 55.2761 14.0742 41.0088C14.0742 26.7416 25.6401 15.1758 39.9072 15.1758C54.1745 15.1758 65.7402 26.7416 65.7402 41.0088C65.7402 55.2761 54.1745 66.8418 39.9072 66.8418Z'
						fill='#F24F4F'
						fill-opacity='0.1'
					/>
					<path
						d='M49.6554 32.7129L48.8208 46.2149C48.6075 49.6645 48.5009 51.3894 47.6363 52.6295C47.2086 53.2426 46.6583 53.76 46.0199 54.1489C44.7289 54.9354 43.0008 54.9354 39.5444 54.9354C36.0837 54.9354 34.3533 54.9354 33.0613 54.1474C32.4226 53.7579 31.872 53.2395 31.4446 52.6253C30.5803 51.3833 30.476 49.656 30.2674 46.2016L29.4531 32.7129'
						stroke='#F24F4F'
						stroke-width='2.02023'
						stroke-linecap='round'
					/>
					<path
						d='M51.6763 32.7129H27.4336'
						stroke='#F24F4F'
						stroke-width='2.02023'
						stroke-linecap='round'
					/>
					<path
						d='M45.0205 32.7129L44.1011 30.8162C43.4903 29.5563 43.1849 28.9263 42.6582 28.5335C42.5413 28.4463 42.4177 28.3688 42.2882 28.3017C41.7049 27.999 41.0049 27.999 39.6047 27.999C38.1694 27.999 37.4517 27.999 36.8587 28.3143C36.7273 28.3842 36.6019 28.4649 36.4838 28.5555C35.9509 28.9643 35.6532 29.6173 35.0579 30.9233L34.2422 32.7129'
						stroke='#F24F4F'
						stroke-width='2.02023'
						stroke-linecap='round'
					/>
					<path
						opacity='0.4'
						d='M36.1875 47.5282V39.4473'
						stroke='#F24F4F'
						stroke-width='2.02023'
						stroke-linecap='round'
					/>
					<path
						opacity='0.4'
						d='M42.9219 47.5282V39.4473'
						stroke='#F24F4F'
						stroke-width='2.02023'
						stroke-linecap='round'
					/>
					<path
						d='M70.2422 12.9678C70.4838 12.9678 70.6797 13.1637 70.6797 13.4053C70.6797 14.5755 71.2692 15.7823 72.1922 16.7053C73.1152 17.6283 74.322 18.2178 75.4922 18.2178C75.7338 18.2178 75.9297 18.4137 75.9297 18.6553C75.9297 18.8969 75.7338 19.0928 75.4922 19.0928C74.322 19.0928 73.1152 19.6823 72.1922 20.6052C71.2692 21.5282 70.6797 22.735 70.6797 23.9053C70.6797 24.1469 70.4838 24.3428 70.2422 24.3428C70.0006 24.3428 69.8047 24.1469 69.8047 23.9053C69.8047 22.735 69.2152 21.5282 68.2922 20.6052C67.3692 19.6823 66.1624 19.0928 64.9922 19.0928C64.7506 19.0928 64.5547 18.8969 64.5547 18.6553C64.5547 18.4137 64.7506 18.2178 64.9922 18.2178C66.1624 18.2178 67.3692 17.6283 68.2922 16.7053C69.2152 15.7823 69.8047 14.5755 69.8047 13.4053C69.8047 13.1637 70.0006 12.9678 70.2422 12.9678Z'
						fill='#F24F4F'
					/>
					<path
						d='M11.5547 60.9678C11.7671 60.9678 11.9393 61.14 11.9393 61.3524C11.9393 62.3811 12.4576 63.4421 13.2689 64.2535C14.0804 65.0649 15.1413 65.5832 16.1701 65.5832C16.3825 65.5832 16.5547 65.7554 16.5547 65.9678C16.5547 66.1802 16.3825 66.3524 16.1701 66.3524C15.1413 66.3524 14.0804 66.8706 13.2689 67.682C12.4576 68.4935 11.9393 69.5544 11.9393 70.5832C11.9393 70.7956 11.7671 70.9678 11.5547 70.9678C11.3423 70.9678 11.1701 70.7956 11.1701 70.5832C11.1701 69.5544 10.6518 68.4935 9.84042 67.682C9.02901 66.8706 7.96806 66.3524 6.9393 66.3524C6.72689 66.3524 6.55469 66.1802 6.55469 65.9678C6.55469 65.7554 6.72689 65.5832 6.9393 65.5832C7.96806 65.5832 9.02901 65.0649 9.84042 64.2535C10.6518 63.4421 11.1701 62.3811 11.1701 61.3524C11.1701 61.14 11.3423 60.9678 11.5547 60.9678Z'
						fill='#F24F4F'
					/>
					<path
						d='M74.7839 5.96777C74.9084 5.96777 75.0163 6.05407 75.0437 6.17556L75.1803 6.78211C75.2736 7.19628 75.597 7.51971 76.0112 7.61301L76.6177 7.74965C76.7392 7.77701 76.8255 7.88491 76.8255 8.00944C76.8255 8.13397 76.7392 8.24187 76.6177 8.26923L76.0112 8.40587C75.597 8.49917 75.2736 8.8226 75.1803 9.23676L75.0437 9.84332C75.0163 9.96481 74.9084 10.0511 74.7839 10.0511C74.6593 10.0511 74.5515 9.96481 74.524 9.84332L74.3874 9.23676C74.2941 8.8226 73.9707 8.49917 73.5565 8.40587L72.95 8.26923C72.8285 8.24187 72.7422 8.13397 72.7422 8.00944C72.7422 7.88491 72.8285 7.77701 72.95 7.74965L73.5565 7.61301C73.9707 7.51971 74.2941 7.19629 74.3874 6.78211L74.524 6.17556C74.5515 6.05407 74.6593 5.96777 74.7839 5.96777Z'
						fill='#F24F4F'
					/>
					<path
						d='M17.5547 69.9678C17.7377 69.9678 17.8962 70.0945 17.9364 70.2731L18.1372 71.1643C18.2743 71.7729 18.7495 72.2482 19.3581 72.3853L20.2494 72.586C20.4279 72.6263 20.5547 72.7848 20.5547 72.9678C20.5547 73.1508 20.4279 73.3093 20.2494 73.3495L19.3581 73.5503C18.7495 73.6873 18.2743 74.1626 18.1372 74.7712L17.9364 75.6625C17.8962 75.841 17.7377 75.9678 17.5547 75.9678C17.3717 75.9678 17.2132 75.841 17.173 75.6625L16.9722 74.7712C16.8351 74.1626 16.3598 73.6873 15.7513 73.5503L14.86 73.3495C14.6815 73.3093 14.5547 73.1508 14.5547 72.9678C14.5547 72.7848 14.6815 72.6263 14.86 72.586L15.7513 72.3853C16.3598 72.2482 16.8351 71.7729 16.9722 71.1643L17.173 70.2731C17.2132 70.0945 17.3717 69.9678 17.5547 69.9678Z'
						fill='#F24F4F'
					/>
					<path
						d='M61.5547 9.96777C61.7377 9.96777 61.8962 10.0945 61.9364 10.2731L62.1372 11.1643C62.2743 11.7729 62.7495 12.2482 63.3581 12.3853L64.2494 12.586C64.4279 12.6263 64.5547 12.7848 64.5547 12.9678C64.5547 13.1508 64.4279 13.3093 64.2494 13.3495L63.3581 13.5503C62.7495 13.6873 62.2743 14.1626 62.1372 14.7712L61.9364 15.6625C61.8962 15.841 61.7377 15.9678 61.5547 15.9678C61.3717 15.9678 61.2132 15.841 61.173 15.6625L60.9722 14.7712C60.8351 14.1626 60.3598 13.6873 59.7513 13.5503L58.86 13.3495C58.6815 13.3093 58.5547 13.1508 58.5547 12.9678C58.5547 12.7848 58.6815 12.6263 58.86 12.586L59.7513 12.3853C60.3598 12.2482 60.8351 11.7729 60.9722 11.1643L61.173 10.2731C61.2132 10.0945 61.3717 9.96777 61.5547 9.96777Z'
						fill='#F24F4F'
					/>
				</svg>

				<div className={styles.roundSuccessfullyCreated}>Round Deleted</div>

				<div style={{ display: 'flex', justifyContent: 'center', gap: '15px' }}>
					<button
						onClick={navigateToDeleteRound}
						className='w-[100px] h-[35px] px-2.5 bg-transparent rounded-[5px] border border-[#3f628b] justify-center items-center gap-1.5 inline-flex text-[#3e618a] text-xs font-medium hover:scale-105'>
						Done
					</button>
				</div>
			</div>
		</div>
	);
};

export {
	PauseTrackingModal,
	ResumeTrackingModal,
	DeleteConfigurationModal,
	DeleteConfigurationModalSuccess,
	DeleteFoundationModal,
};
export default SuccessRoundModal;
