import { styled, Switch } from "@mui/material";
import { useTheme } from "../../../Context/ThemContext/ThemeContext";

export const CustomSwitch = styled((props) => {
  const { colorMode } = useTheme();
  return (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  );
})(({ theme, colorMode }) => ({
  width: 32,
  height: 18,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(14px)",
      color: "#fff",
      backgroundColor: "#fff",
      " .MuiSwitch-thumb": {
        backgroundColor: colorMode == "dark" ? "#000" : "#fff",
        opacity: 1,
        border: 0,
      },
      "& + .MuiSwitch-track": {
        backgroundColor: colorMode == "dark" ? "#2ECA45" : "#0069E5",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 14,
    height: 14,
    backgroundColor: colorMode === "light" ? "#797B7E" : "#828386",
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: colorMode === "light" ? "#797B7E" : "#828386",
    opacity: 0.4,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
