import '../PlansComponent/index.css';

const PlansComponent = ({
	Free,
	Starter,
	para1,
	para2,
	para3,
	para4,
	para5,
	image,
	alt,
	customButton,
}) => {
	return (
		<div className='starterDiv'>
			<div className='starter-align'>
				<img
					src={image}
					alt={alt}
					width={20}
					height={20}
					style={{ marginRight: '8px' }}
				/>
				<strong className='starter' style={{ marginRight: '8px' }}>
					{Starter}{' '}
				</strong>
				<h4 className='free'>{Free}</h4>
			</div>
			<div className='paraContent'>
				{para1 && <li>{para1}</li>}
				{para2 && <li>{para2}</li>}
				{para3 && <li>{para3}</li>}
				{para4 && <li>{para4}</li>}
				{para5 && <li>{para5}</li>}
			</div>
		</div>
	);
};

export default PlansComponent;
