import React from 'react';
import NoDrafts from '../CrashedScreen/index.module.css';
import ErrorIcon from '../../../assets/icons/blockers/erroricon.svg';
import { Grow } from '@mui/material';

const CrashedScreen = ({ hasError }) => {
	return (
		<Grow in={hasError}>
			<div className={NoDrafts.rounds_container}>
				<div className={NoDrafts.intel_rounds_container}>
					<div className={NoDrafts.drafts_container}>
						<div className={NoDrafts.drafts_icon_wrapper}>
							<img
								src={ErrorIcon}
								alt={ErrorIcon}
								className={NoDrafts.drafts_icon}
								width={100}
								height={100}
							/>
						</div>
						<div className={NoDrafts.drafts_message_wrapper}>
							<div className={NoDrafts.no_drafts_message}>
								We Apologize for the Inconvenience
							</div>
							<div className={NoDrafts.no_drafts_description}>
								Part of the system encountered an issue, but don't worry—your
								progress has been saved. You can resume your activities at any
								time. For current updates on the Sharbo platform's status,
								please visit status.sharbo.ioss{' '}
							</div>
						</div>
					</div>
					<div className={NoDrafts.active_rounds_button_wrapper}>
						<div style={{ cursor: 'pointer' }}
							onClick={() => {
								window.location.href = '/';
							}}
							className={NoDrafts.active_rounds_button}>

							Return to Main Screen
						</div>
					</div>
				</div>
			</div>
		</Grow>
	);
};

export default CrashedScreen;
