import React from "react";
import "../AnalysisPage/css/FeatureComparison.css";

const FeatureComparison = ({ sectionData, styles }) => {
  if (!sectionData) return null;

  const features = sectionData.section_description;
  const competitorName = sectionData.competitor_name || 'Competitor Name';
  const foundationName = sectionData.foundation_name || 'Foundation Name';
  return (
    <div className="feature-comparison">
      <h2 className="comparison-title" style={{ fontSize: styles.heading_fontSize }}>Feature Comparison: {foundationName} vs {competitorName}</h2>
      <table >
        <thead className="thead-bg">
          <tr className="thead-bg">
            <th style={{ fontSize: styles.heading_fontSize, fontFamily: styles.heading_fontFamily, color: styles.heading_textColor, fontStyle: styles.heading_fontStyle }} className="attribute-text">Attributes</th>
            <th style={{ fontSize: styles.heading_fontSize, fontFamily: styles.heading_fontFamily, color: styles.heading_textColor, fontStyle: styles.heading_fontStyle }} className="">{foundationName}</th>
            <th style={{ fontSize: styles.heading_fontSize, fontFamily: styles.heading_fontFamily, color: styles.heading_textColor, fontStyle: styles.heading_fontStyle }} className="">{competitorName}</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(features) && features.map((feature, index) => (
            <tr key={index}>
              <td style={{ fontSize: styles.paragraph_fontSize, fontFamily: styles.paragraph_fontFamily, color: styles.paragraph_textColor, fontStyle: styles.paragraph_fontStyle }} className="">{feature.attribute_data}</td>
              <td style={{ fontSize: styles.paragraph_fontSize, fontFamily: styles.paragraph_fontFamily, color: styles.paragraph_textColor, fontStyle: styles.paragraph_fontStyle }} className="">{feature.foundation_data === "true" ? "Available" : feature.foundation_data === "false" ? "Not Available" : feature.foundation_data}</td>
              <td style={{ fontSize: styles.paragraph_fontSize, fontFamily: styles.paragraph_fontFamily, color: styles.paragraph_textColor, fontStyle: styles.paragraph_fontStyle }} className="">{feature.competitor_data === "true" ? "Available" : feature.competitor_data === "false" ? "Not Available" : feature.competitor_data}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default FeatureComparison;