import React, { useContext, useEffect, useState } from 'react';
import styles from './index.module.css';
import {
	LineChart,
	Line,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Area,
	AreaChart,
	ResponsiveContainer,
} from 'recharts';
import { OutpointContext } from '../../Context/OutpointsContext/Index';
import { getGraphData } from '../../services';
import { SET_GRAPH_DATA } from '../../Context/OutpointsContext/action';
import { CircularProgress, useMediaQuery } from '@mui/material';

const RoundGraph = () => {
	const {
		state: { graph_data: data, data: roundsData },
		dispatch,
	} = useContext(OutpointContext);

	const [filterGraph, setFilterGraph] = useState(1);
	const [loading, setLoading] = useState(false);
	const [counts, setCounts] = useState({ total: 0, active: 0, draft: 0 });
	const [trackJobsCount, setTrackJobsCount] = useState(0);

	const calculateCounts = (data) => {
		if (!data || !Array.isArray(data)) return { total: 0, active: 0, draft: 0 };

		const activeCount = data.filter((e) => e.active === true).length;
		const draftCount = data.filter((e) => e.active === false).length;
		const totalCount = activeCount + draftCount;

		return { total: totalCount, active: activeCount, draft: draftCount };
	};

	useEffect(() => {
		if (roundsData?.data) {
			const newCounts = calculateCounts(roundsData.data);
			setCounts(newCounts);
		}
	}, [roundsData]);

	let getData = async () => {
		setLoading(true);
		try {
			let res = await getGraphData(filterGraph);
			console.log(res);

			dispatch({
				type: SET_GRAPH_DATA,
				payload: res,
			});
			if (res && res.length > 0) {
				const latestData = res[res.length - 1];
				setTrackJobsCount(latestData['Track Jobs'] || 0);
			}
		} catch (err) {
			console.log(err);
		}
		setLoading(false);
	};

	useEffect(() => {
		if (!data?.length || loading) {
			getData();
		}
	}, [filterGraph]);

	const xAxisTickFormatter = (value, index) => {
		if (index === data?.length - 1) {
			return 'Today';
		}
		if (index === 0) {
			return value;
		}
		return '';
	};

	const handleFilter = (id) => {
		setLoading(true);
		setFilterGraph(id);
	};

	const isLessThan1300px = useMediaQuery(`(max-width:1300px)`);

	const getActiveRoundsGData = (data) => {
		return data.map((item) => ({
			date: item.date,
			value: item['Active Rounds'],
		}));
	};

	// Function to create the Track Jobs array
	const getTrackJobsGData = (data) => {
		return data.map((item) => ({ date: item.date, value: item['Track Jobs'] }));
	};

	const activeRoundsGData = getActiveRoundsGData(data);
	const trackJobsGData = getTrackJobsGData(data);

	return (
		<div className={styles.container}>
			<div className={styles.charHeader}>
				{/* <h2 className={styles.header}>
					Active Competitor Feature Comparison Rounds ({counts.total})
				</h2> */}
				<div className={styles.buttonGroup}>
					<button
						disabled={loading}
						onClick={() => handleFilter(1)}
						className={`${styles.button} hover:scale-105 ${
							filterGraph === 1 && styles.active
						}`}>
						This Month
					</button>
					<button
						disabled={loading}
						onClick={() => handleFilter(0)}
						className={`${styles.button} hover:scale-105 ${
							filterGraph === 0 && styles.active
						}`}>
						All Time
					</button>
				</div>
			</div>

			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				{isLessThan1300px ? (
					loading ? (
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								width: '50%',
								height: '272px',
							}}>
							<CircularProgress />
						</div>
					) : (
						<div
							style={{
								width: '100%',
								height: '350px',
							}}
							className={styles.chartWrapper}>
							<h3 className={styles.chartTitle}>
								Active Rounds ({counts.active}) | Track Jobs ({trackJobsCount})
							</h3>
							<ResponsiveContainer width='100%' height={300}>
								<AreaChart
									width={500}
									height={400}
									data={data}
									margin={{
										top: 10,
										right: 30,
										left: 0,
										bottom: 0,
									}}>
									{/* <CartesianGrid strokeDasharray='3 3' /> */}
									<XAxis dataKey='date' tick={{ fontSize: 9 }} />
									<YAxis tick={{ fontSize: 9 }} />
									<Tooltip />
									<Area
										type='monotone'
										dataKey='value'
										stroke='#db6b6b'
										fill='#ebb7b780'
										strokeWidth={3}
									/>
									<Area
										type='monotone'
										dataKey='Active Rounds'
										stroke='#db6b6b'
										fill='#ebb7b780'
										strokeWidth={3}
									/>
									<Area
										type='monotone'
										dataKey='Track Jobs'
										stroke='#8884d8'
										fill='#8884d880'
										strokeWidth={3}
									/>
								</AreaChart>
							</ResponsiveContainer>
						</div>
					)
				) : (
					<>
						{loading ? (
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									width: '50%',
									height: '272px',
								}}>
								<CircularProgress />
							</div>
						) : (
							<div className={styles.chartWrapper}>
								<h3 className={styles.chartTitle}>
									Active Rounds ({counts.active})
								</h3>
								<ResponsiveContainer width='100%' height={200}>
									<AreaChart
										width={500}
										height={400}
										data={activeRoundsGData}
										margin={{
											top: 10,
											right: 30,
											left: 0,
											bottom: 0,
										}}>
										{/* <CartesianGrid strokeDasharray='3 3' /> */}
										<XAxis dataKey='date' tick={{ fontSize: 9 }} />
										<YAxis dataKey={'value'} tick={{ fontSize: 9 }} />
										<Tooltip />
										<Area
											type='monotone'
											dataKey='value'
											stroke='#db6b6b'
											fill='#ebb7b780'
											strokeWidth={3}
										/>
									</AreaChart>
								</ResponsiveContainer>
							</div>
						)}
						{loading ? (
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									width: '50%',
									height: '272px',
								}}>
								<CircularProgress />
							</div>
						) : (
							<div className={styles.chartWrapper}>
								<h3 className={styles.chartTitle}>
									Track Jobs ({trackJobsCount})
								</h3>
								<ResponsiveContainer width='100%' height={200}>
									<AreaChart
										width={500}
										height={400}
										data={trackJobsGData}
										margin={{
											top: 10,
											right: 30,
											left: 0,
											bottom: 0,
										}}>
										{/* <CartesianGrid strokeDasharray='3 3' /> */}
										<XAxis dataKey='date' tick={{ fontSize: 9 }} />
										<YAxis dataKey={'value'} tick={{ fontSize: 9 }} />
										<Tooltip />
										<Area
											type='monotone'
											dataKey='value'
											stroke='#8884d8'
											fill='#8884d8'
											strokeWidth={3}
										/>
									</AreaChart>
								</ResponsiveContainer>
							</div>
						)}
					</>
				)}
			</div>
		</div>
	);
};

export default RoundGraph;
