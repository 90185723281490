import React, { useState } from 'react';
import styles from './index.module.css';
import BigRegenerateImagge from '../../../../assets/icons/big_regenerate.svg';
import Property from '../../../../assets/icons/property.svg';
import Alert from '../../../../assets/icons/alert-02.svg';
import Lottie from 'lottie-react';
import regenerateAnimation from './regenerateAnimation.json';

import RegenerateButton from '../RegenerateButton';
const RegenrateModal = ({
	handleRegenerate,
	loading,
	showWarning,
	activeTab,
	handleAddtionalOptions,
}) => {
	// const [ showWarning , setShowWarning] = useState()
	// const [ showFinalRegenarateModal , setFinalModal] = useState()

	return (
		<div
			id='reger'
			className={
				'w-full flex flex-col relative px-32 text-center items-center justify-center'
			}>
			<div className={styles.arrowReloadHorizontalParent}>
				{/* <img
          className={styles.arrowReloadHorizontalIcon}
          alt=""
          src={BigRegenerateImagge}
        /> */}
				<Lottie
					style={{
						height: '100px',
						width: '100px',
					}}
					animationData={regenerateAnimation}
					loop={true}
				/>
				{showWarning ? (
					<div className={styles.regenerateSectionNameContainer}>
						<b>{`Regenerate All`}</b>
					</div>
				) : (
					<div className={styles.regenerateSectionNameContainer}>
						<b>{`Regenerate `}</b>
						<span>{`{`}</span>
						<span className={styles.sectionNameOr}>{activeTab}</span>
						<span>{`}`}</span>
					</div>
				)}

				{!showWarning && (
					<div className={styles.youCanAlso}>
						You can also add instructions to guide Sharbo AI in refining the
						regenerated analysis (Optional)
					</div>
				)}
			</div>
			{showWarning && (
				<div className={styles.warningContainer + ' mt-10 text-left gap-1'}>
					<span className='inline-block min-w-4 w-4 h-4 pt-1 '>
						<img
							src={Alert}
							className='inline-block w-4 h-4 object-contain mr-1.5 align-text-top'
						/>
					</span>
					<span style={{ marginRight: '4px' }}>Warning: </span>
					All existing analysis in all sections, including Feature Comparison
					Attributes, will be regenerated
				</div>
			)}
			<div className={styles.frameContainer + ' mt-10'}>
				{!showWarning && (
					<div
						onClick={handleAddtionalOptions}
						className={`${styles.propertyAddParent} hover:scale-105`}>
						<img className={styles.propertyAddIcon} alt='' src={Property} />
						<div
							style={{ cursor: 'pointer' }}
							className={styles.additionalInstructionOptionContainer}>
							<span
								className={
									styles.sectionNameOr
								}>{`Additional Instruction `}</span>
							<span>(Optional)</span>
						</div>
					</div>
				)}
				<div className={styles.frameChild} />
				<RegenerateButton
					isOnly={true}
					loading={loading}
					whiteIcon
					handleRegenerate={handleRegenerate}
					dark={true}
					title='Regenerate Now'
				/>
			</div>
		</div>
	);
};

export default RegenrateModal;
