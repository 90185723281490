const FoundationIcon = ({ fill = '#A0A8BE' }) => {
    return (
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="3d-scale">
                <path id="Vector" d="M8.05599 8.66634L11.3893 11.6663M8.05599 8.66634L4.72266 11.6663M8.05599 8.66634V5.33301" stroke="#A0A8BE" />
                <path id="Vector_2" d="M12.1824 10.9641C12.5268 10.766 12.699 10.667 12.888 10.667C13.0771 10.667 13.2493 10.766 13.5937 10.9641L14.0157 11.2068C14.3601 11.4049 14.5323 11.5039 14.6268 11.667C14.7214 11.8301 14.7214 12.0282 14.7214 12.4243V12.9097C14.7214 13.3058 14.7214 13.5039 14.6268 13.667C14.5323 13.8301 14.3601 13.9291 14.0157 14.1272L13.5937 14.3699C13.2493 14.568 13.0771 14.667 12.888 14.667C12.699 14.667 12.5268 14.568 12.1824 14.3699L11.7604 14.1272C11.416 13.9291 11.2438 13.8301 11.1492 13.667C11.0547 13.5039 11.0547 13.3058 11.0547 12.9097V12.4243C11.0547 12.0282 11.0547 11.8301 11.1492 11.667C11.2438 11.5039 11.416 11.4049 11.7604 11.2068L12.1824 10.9641Z" stroke="#A0A8BE" />
                <path id="Vector_3" d="M2.5144 10.9641C2.85879 10.766 3.03097 10.667 3.22005 10.667C3.40913 10.667 3.58132 10.766 3.92571 10.9641L4.34773 11.2068C4.69212 11.4049 4.86431 11.5039 4.95885 11.667C5.05339 11.8301 5.05339 12.0282 5.05339 12.4243V12.9097C5.05339 13.3058 5.05339 13.5039 4.95885 13.667C4.86431 13.8301 4.69212 13.9291 4.34773 14.1272L3.92571 14.3699C3.58132 14.568 3.40913 14.667 3.22005 14.667C3.03097 14.667 2.85879 14.568 2.5144 14.3699L2.09237 14.1272C1.74799 13.9291 1.5758 13.8301 1.48126 13.667C1.38672 13.5039 1.38672 13.3058 1.38672 12.9097V12.4243C1.38672 12.0282 1.38672 11.8301 1.48126 11.667C1.5758 11.5039 1.74799 11.4049 2.09237 11.2068L2.5144 10.9641Z" stroke="#A0A8BE" />
                <path id="Vector_4" d="M7.35032 1.63009C7.69472 1.43203 7.86692 1.33301 8.05599 1.33301C8.24506 1.33301 8.41726 1.43203 8.76166 1.63009L9.18366 1.87281C9.52806 2.07087 9.70026 2.16989 9.79479 2.33301C9.88932 2.49612 9.88932 2.69417 9.88932 3.09029V3.57572C9.88932 3.97184 9.88932 4.16989 9.79479 4.33301C9.70026 4.49612 9.52806 4.59515 9.18366 4.79321L8.76166 5.03592C8.41726 5.23398 8.24506 5.33301 8.05599 5.33301C7.86692 5.33301 7.69472 5.23398 7.35032 5.03592L6.92832 4.79321C6.58392 4.59515 6.41174 4.49612 6.3172 4.33301C6.22266 4.16989 6.22266 3.97184 6.22266 3.57572V3.09029C6.22266 2.69417 6.22266 2.49612 6.3172 2.33301C6.41174 2.16989 6.58392 2.07087 6.92832 1.87281L7.35032 1.63009Z" stroke="#A0A8BE" />
            </g>
        </svg>

    )
}

export default FoundationIcon