import "./instrument";
import React from "react";
import * as Sentry from "@sentry/react";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import "./index.css";
import { ClerkProvider } from "@clerk/clerk-react";
import { AuthPorvider } from "./Context/AuthContext";
import { OutpointProvider } from "./Context/OutpointsContext/Index.jsx";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "./Context/ThemContext/ThemeContext.jsx";
import { SubscriptionProvider } from "./Context/SubscriptionContext/index.jsx";
const PUBLISHABLE_KEY = import.meta.env.VITE_CLERK_KEY;
if (!PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key");
}
ReactDOM.createRoot(document.getElementById("root"), {
  // Callback called when an error is thrown and not caught by an ErrorBoundary.
  onUncaughtError: Sentry.reactErrorHandler((error, errorInfo) => {
    console.warn("Uncaught error", error, errorInfo.componentStack);
  }),
  // Callback called when React catches an error in an ErrorBoundary.
  onCaughtError: Sentry.reactErrorHandler(),
  // Callback called when React automatically recovers from errors.
  onRecoverableError: Sentry.reactErrorHandler(),
}).render(
  <ThemeProvider>
      <ClerkProvider publishableKey={PUBLISHABLE_KEY}>
        <AuthPorvider>
          <OutpointProvider>
          <SubscriptionProvider>
              <App />
          </SubscriptionProvider>
            <ToastContainer className={"toast-container"} />
          </OutpointProvider>
        </AuthPorvider>
      </ClerkProvider>
  </ThemeProvider>
);
