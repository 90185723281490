import React, { useContext, useEffect, useState } from "react";
import styles from "./index.module.css";
import RegenerateImagge from "../../../../assets/icons/regenerate.svg";
import RegenerateButton from "../RegenerateButton";
import { OutpointContext } from "../../../../Context/OutpointsContext/Index";
import LoadingPage from "../../../Common/Loading";
import Markdown from "react-markdown";
import { CircularProgress } from "@mui/material";
import RichTextEditor from "../../TextEditor";
import {
  updateAboutCompetitor,
  updateAboutFoundation,
} from "../../../../services";
import { toast } from "react-toastify";
import { PencilEdit02Icon } from "@hugeicons/react";
import EditAnalysisModal from "../EditAnalysisModal";

const About = ({
  handleRegenerate,
  analysisData,
  activeTab,
  existingRound = false,
  setShowLoaderText,
  previewEnabled = false,
}) => {
  const [currentLoading, setCurrentLoading] = useState([]);
  const { state, selectedStep, dispatch } = useContext(OutpointContext);
  const [editCompany, setEditCompany] = useState(false);
  const [editCompetitor, setEditCompetitor] = useState(false);
  const [markdownCompany, setMarkdownCompany] = useState(
    analysisData?.analysis?.about_foundation
  );
  const [markdownCompetitor, setMarkdownCompetitor] = useState(
    analysisData?.analysis?.about_competitor
  );

  useEffect(() => {
    setMarkdownCompany(analysisData?.analysis?.about_foundation);
    setMarkdownCompetitor(analysisData?.analysis?.about_competitor);
  }, [analysisData?.analysis]);

  const saveAboutFoundation = async () => {
    setEditCompany(false);
    setShowLoaderText(false);
    setCurrentLoading([...currentLoading, `foundation`]);
    const res = await updateAboutFoundation(
      analysisData?.analysis?.analysis_id,
      markdownCompany
    );
    if (res?.data) {
      toast.success("Your edits are saved successfully");
      if (existingRound)
        dispatch({
          type: "SET_SINGLE_ROUND",
          payload: {
            ...state.singleRound,
            analysis: {
              ...analysisData,
              analysis: {
                ...analysisData?.analysis,
                about_foundation: markdownCompany,
              },
            },
          },
        });
      setCurrentLoading(currentLoading.filter((item) => item !== `foundation`));
      setShowLoaderText(true);
    }
  };

  const saveAboutCompetitor = async () => {
    setEditCompetitor(false);
    setShowLoaderText(false);
    setCurrentLoading([...currentLoading, `competitor`]);
    const res = await updateAboutCompetitor(
      analysisData?.analysis?.analysis_id,
      markdownCompetitor
    );
    if (res?.data) {
      toast.success("Your edits are saved successfully");
      if (existingRound)
        dispatch({
          type: "SET_SINGLE_ROUND",
          payload: {
            ...state.singleRound,
            analysis: {
              ...analysisData,
              analysis: {
                ...analysisData?.analysis,
                about_competitor: markdownCompetitor,
              },
            },
          },
        });
      setCurrentLoading(currentLoading.filter((item) => item !== `competitor`));
      setShowLoaderText(true);
    }
  };

  const discardFoundationEdits = () => {
    setEditCompany(false);
    setMarkdownCompany(analysisData?.analysis?.about_foundation);
  };

  const discardCompetitorEdits = () => {
    setEditCompetitor(false);
    setMarkdownCompetitor(analysisData?.analysis?.about_competitor);
  };

  return (
    <div id="aboutsection_tab" className={styles.frameWrapper + " h-[390px]"}>
      <div className={styles.frameContainer + " max-h-[340px]"}>
        <div className={styles.aboutFoundationNameParent}>
          <div className={styles.aboutFoundationNameContainer}>
            <span className="inline-block pr-1">About</span>
            <span className={styles.foundationName}>
              (
              {!existingRound
                ? state.roundsForm?.foundation?.foundation_name
                : state.singleRound?.foundation?.foundation_name}
              )
            </span>
          </div>
          <div className={styles.frameDiv}>
            <div
              className={
                styles.loremIpsumDolorSitAmetConWrapper + " pb-0 border-b-0"
              }
            >
              <div className={styles.loremIpsumDolor}>
                {currentLoading.includes(`foundation`) ? (
                  <div
                    style={{
                      alignItems: "center",
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                      height: "20%",
                      padding: "40px 0px",
                    }}
                  >
                    <CircularProgress />
                  </div>
                ) : (
                  <div
                    className="markdownDiv break-keep"
                    onDoubleClick={() => setEditCompany(true)}
                  >
                    <Markdown>{markdownCompany}</Markdown>
                  </div>
                )}
              </div>
            </div>

            {!previewEnabled && (
              <div className="flex gap-4">
                <RegenerateButton
                  id="regenerateFoundationBtn"
                  handleRegenerate={handleRegenerate}
                  type="foundation"
                  dark={false}
                  title="Regenerate"
                />
                <button
                  onClick={() => setEditCompany(true)}
                  className={`${styles.saveEditBtn} disabled:pointer-events-none disabled:cursor-default hover:scale-105 origin-bottom`}
                >
                  <PencilEdit02Icon
                    size={16}
                    color={"currentColor"}
                    variant={"stroke"}
                    type={"rounded"}
                  />
                  Edit Manually
                </button>
              </div>
            )}
          </div>
        </div>

        <div className={styles.aboutFoundationNameParent}>
          <div className={styles.aboutFoundationNameContainer}>
            <span className="inline-block pr-1">About</span>
            <span className={styles.foundationName}>
              (
              {!existingRound
                ? state.roundsForm?.competitor?.competitor_name
                : state.singleRound?.competitor?.competitor_name}
              )
            </span>
          </div>
          <div className={styles.frameDiv}>
            <div
              className={
                styles.loremIpsumDolorSitAmetConWrapper + " pb-0 border-b-0"
              }
            >
              <div className={styles.loremIpsumDolor}>
                {currentLoading.includes(`competitor`) ? (
                  <div
                    style={{
                      alignItems: "center",
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                      height: "20%",
                      padding: "40px 0px",
                    }}
                  >
                    <CircularProgress />
                  </div>
                ) : (
                  <div
                    className="markdownDiv break-keep"
                    onDoubleClick={() => setEditCompetitor(true)}
                  >
                    <Markdown>{markdownCompetitor}</Markdown>
                  </div>
                )}
              </div>
            </div>

            {!previewEnabled && (
              <div className="flex gap-4">
                <RegenerateButton
                  id="regenerateCompetitorBtn"
                  handleRegenerate={handleRegenerate}
                  type="competitor"
                  dark={false}
                  title="Regenerate"
                />
                <button
                  onClick={() => setEditCompetitor(true)}
                  className={`${styles.saveEditBtn} disabled:pointer-events-none disabled:cursor-default hover:scale-105 origin-bottom`}
                >
                  <PencilEdit02Icon
                    size={16}
                    color={"currentColor"}
                    variant={"stroke"}
                    type={"rounded"}
                  />
                  Edit Manually
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      {!previewEnabled && editCompany && (
        <EditAnalysisModal
          openModal={editCompany}
          closeModal={() => setEditCompany(false)}
          markdownData={markdownCompany}
          setMarkdownData={setMarkdownCompany}
          disableBtn={currentLoading.includes(`foundation`)}
          saveFunction={saveAboutFoundation}
          discardFunction={discardFoundationEdits}
        />
      )}
      {!previewEnabled && editCompetitor && (
        <EditAnalysisModal
          openModal={editCompetitor}
          closeModal={() => setEditCompetitor(false)}
          markdownData={markdownCompetitor}
          setMarkdownData={setMarkdownCompetitor}
          disableBtn={currentLoading.includes(`competitor`)}
          saveFunction={saveAboutCompetitor}
          discardFunction={discardCompetitorEdits}
        />
      )}
    </div>
  );
};

export default About;
