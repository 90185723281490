import React, { useContext, useEffect, useState } from 'react';
import styles from './index.module.css';
import UpwardImage from '../../assets/icons/upwardDashboard.svg';
import UpwardImageDark from '../../assets/icons/upwardDashboardDark.svg';
import DownwardImage from '../../assets/icons/downDashboard.svg';
import DownwardImageDark from '../../assets/icons/downDashboardDark.svg';
import Add from '../../assets/icons/add.svg';
import { PrimaryModal } from '../../Components/Common/PrimaryModal';
import NewRound from '../../Components/NewRound/index.jsx';
import { OutpointContext } from '../../Context/OutpointsContext/Index.jsx';
import { CircularProgress, Modal } from '@mui/material';

import {
	RESET_ROUNDS_FORM,
	UPDATE_ROUNDS_DASHBOARD,
} from '../../Context/OutpointsContext/action.js';
import useSaveRoundsForm from '../../hooks/useSaveRoundsForm.js';
import { stepperData } from '../../Components/StepperNewRound/stepperData.js';
import SuccessRoundModal from '../../Components/NewRound/SuccessRoundModal/index.jsx';
import DraftFoundationCard from '../../Components/DraftFoundationCard/index.jsx';
import RoundsHeader from '../../Components/RoundsHeader/index.jsx';
import RoundGraph from '../../Components/RoundGraph/index.jsx';
import RoundCard from '../../Components/RoundCard/index.jsx';
import ReactPaginate from 'react-paginate';
import { getUserLimit } from '../../services/index.js';
import { SubscriptionFeatures } from '../../constant/constants.js';
import UsageLimitModal from '../../Components/Common/UsageLimitModal/index.jsx';
import { useTheme } from '../../Context/ThemContext/ThemeContext.jsx';
import { SubscriptionContext } from '../../Context/SubscriptionContext/index.jsx';
import { useNavigate } from 'react-router-dom';

const Rounds = () => {
	const navigate = useNavigate();
	const [openDatapoints, setOpenDatapoints] = useState(false);
	const [active, setActive] = useState(true);
	const [showLoader, setShowLoader] = useState(true);
	const [openModal, setOpenModal] = useState(false);
	const [currentActivePage, setCurrentActivePage] = useState(0);
	const [currentDraftPage, setCurrentDraftPage] = useState(0);
	const [searchTerm, setSearchTerm] = useState('');
	const itemsPerPage = 6;
	const itemsPerPageInDrafts = 6;
	const { state, dispatch, setCurrentStep } = useContext(OutpointContext);
	const { fetchSubscriptionData } = useContext(SubscriptionContext);
	const { handleRoundsForm } = useSaveRoundsForm();
	const [successModal, setSuccessModal] = useState(false);
	const [newRound, setNewRound] = useState({});
	const { colorMode } = useTheme();
	// const navigateConfig = navigate(`/round/${roundsForm.round_name}?round_id=${roundsForm.round_id}`);


	const handleStepperModal = async (
		id = false,
		item = {},
		sourceNew = false
	) => {
		const access = await getUserLimit(SubscriptionFeatures.TOTAL_ROUNDS);
		if ((access && sourceNew) || !sourceNew) {
			if (id) {
				setCurrentStep(stepperData[item?.creation_step - 1]);
				handleRoundsForm(item, 'round_id');
			} else {
				setCurrentStep(stepperData[0]);
			}
			setOpenDatapoints(true);
		} else setOpenModal(true);
	};

	useEffect(() => {
		if (!openDatapoints) {
			dispatch({
				type: RESET_ROUNDS_FORM,
				payload: {
					foundation: {
						foundation_name: '',
						foundation_mode: 1,
						url: [],
						scrape_mode: 'singlepage',
						additional_details: '',
						description: '',
					},
					competitor: {
						competitor_name: '',
						competitor_mode: 1,
						url: [],
						scrape_mode: 'singlepage',
						additional_details: '',
						description: '',
					},
					analysis: {},
				},
			});
		}
	}, [openDatapoints]);

	const handleActive = () => {
		setActive(!active);
	};

	const filterData = (data) => {
		return data.filter((item) => {
			const searchableFields = [
				item.round_name,
				item.foundation?.foundation_name,
				item.foundation?.description,
				item.competitor?.competitor_name,
				item.competitor?.description,
			];

			return searchableFields.some((field) => {
				if (typeof field === 'string') {
					return field.toLowerCase().includes(searchTerm.toLowerCase());
				}
				return false;
			});
		});
	};

	const activeData = filterData(
		state.data?.data?.filter((e) => e.active === true) || []
	);
	const draftData = filterData(
		state.data?.data?.filter((e) => e.active === false) || []
	);

	useEffect(() => {
		if (!draftData.length && searchTerm?.length == 0) {
			setActive(true);
		}
	}, [draftData]);

	const handleCloseModal = () => {
		dispatch({
			type: UPDATE_ROUNDS_DASHBOARD,
			payload: 1,
		});

		setOpenDatapoints(false);
		console.log('false');
		window.dispatchEvent(new Event('subscription-updated'));
		fetchSubscriptionData();

	};

	const handleSuccessCloseModal = (id) => {
		dispatch({
			type: UPDATE_ROUNDS_DASHBOARD,
			payload: 1,
		});
		setActive(true);
		setSuccessModal(id);
		window.dispatchEvent(new Event('subscription-updated'));
		fetchSubscriptionData();
		navigate(`/round/${newRound?.round_name}?round_id=${newRound?.round_id}`);
	};

	const activePageCount = Math.ceil(activeData?.length / itemsPerPage);
	const displayedActiveData = activeData?.slice(
		currentActivePage * itemsPerPage,
		(currentActivePage + 1) * itemsPerPage
	);

	const draftPageCount = Math.ceil(draftData?.length / itemsPerPageInDrafts);
	const displayedDraftData = draftData?.slice(
		currentDraftPage * itemsPerPageInDrafts,
		(currentDraftPage + 1) * itemsPerPageInDrafts
	);
	const hasNoData = !state.data?.data?.length;
	const hasOnlyDrafts = state.data?.data?.some((e) => !e.active);
	const hasActiveRounds = state.data?.data?.some((e) => e.active);

	return (
		<>
			<PrimaryModal
				primaryModalChildrenClass={styles.addEnrichmentChildren}
				title={' '}
				open={successModal}
				setOpen={handleSuccessCloseModal}
				fetchSubscriptionData={fetchSubscriptionData}
				modalStyleClass='w-[750px] h-[500px] m-auto top-1/2 transform -translate-y-1/2'>
				<SuccessRoundModal />
			</PrimaryModal>
			<PrimaryModal
				primaryModalChildrenClass={styles.addEnrichmentChildren}
				title={'New Round'}
				open={openDatapoints}
				setOpen={handleCloseModal}>
				<NewRound
					handleCloseParentModal={handleCloseModal}
					setOpenDatapoints={setOpenDatapoints}
					setSuccessModal={setSuccessModal}
					setNewRound={setNewRound}
					fetchSubscriptionData={fetchSubscriptionData}
				/>
			</PrimaryModal>

			<Modal
				styles={'width:300px'}
				open={openModal}
				onClose={() => setOpenModal(false)}>
				<UsageLimitModal closeModal={() => setOpenModal(false)} />
			</Modal>

			<div className={styles.container}>
				{state.loading && showLoader && (
					<div
						style={{
							alignItems: 'center',
							display: 'flex',
							justifyContent: 'center',
							padding: '300px 0px',
						}}>
						<CircularProgress />
					</div>
				)}
				{(!showLoader || !state.loading) && !hasActiveRounds && active && (
					<div className={styles.frameParent}>
						<div className={styles.frameGroup}>
							<div className={styles.group1}>
								<img
									className={styles.frameChild}
									alt=''
									src={colorMode == 'light' ? UpwardImage : UpwardImageDark}
								/>
							</div>
							<div className={styles.welcomeToSharboParent}>
								<div
									className={
										styles.welcomeToSharbo
									}>{`Create your first Competitor Feature Comparison Round with Sharbo AI`}</div>
								<div className={styles.getStartedBy}>
									Track, sync, and embed up-to-date intel and product
									comparisons to capture customers from your competition
								</div>
							</div>
							<div className={styles.group2}>
								<img
									className={styles.frameChild}
									alt=''
									src={colorMode == 'light' ? DownwardImage : DownwardImageDark}
								/>
							</div>
						</div>
						<div className={styles.frameWrapper}>
							<div
								className={styles.frameWrapper}
								onClick={() => handleStepperModal(false, {}, true)}>
								<button
									id='createRound'
									className={`${styles.add01Parent} hover:scale-105`}>
									<img className={styles.add01Icon} alt='' src={Add} />
									<span className={styles.createANew}>Create a New Round</span>
								</button>
							</div>
							{draftData?.length > 0 && (
								<div
									onClick={() => {
										setActive(false);
									}}
									className={`${styles.add01Parent} hover:scale-105`}>
									<div className={styles.createANew}>{`Go To Draft `}</div>
								</div>
							)}
						</div>
					</div>
				)}
				{(!showLoader || !state.loading) &&
					((active && hasActiveRounds) || (!active && hasOnlyDrafts)) && (
						<div
							style={{
								width: '100%',
								display: 'flex',
								flexDirection: 'column',
								gap: '30px',
								marginTop: '20px',
							}}>
							{active && hasActiveRounds && <RoundGraph />}
							{((active && hasActiveRounds) ||
								(!active && draftData?.length > 0) ||
								(!active && draftData?.length === 0 && searchTerm)) && (
									<RoundsHeader
										setShowLoader={setShowLoader}
										goToDraftsData={hasOnlyDrafts}
										handleActive={handleActive}
										active={active}
										handleStepperModal={handleStepperModal}
										searchTerm={searchTerm}
										setSearchTerm={setSearchTerm}
									/>
								)}
							{active && activeData?.length === 0 && searchTerm && (
								<div className={styles.noResults}>
									No active rounds found for "{searchTerm}".
								</div>
							)}
							{!active && draftData?.length === 0 && searchTerm && (
								<div className={styles.noResults}>
									No draft rounds found for "{searchTerm}".
								</div>
							)}

							{!active && (
								<div className='w-full h-full relative flex flex-col gap-30'>
									{state.loading ? (
										<div className='relative inset-0 flex items-center justify-center w-full h-60'>
											<CircularProgress size={'40px'} />
										</div>
									) : (
										<div className={styles.draftList}>
											{displayedDraftData?.map((e) => (
												<DraftFoundationCard
													handleStepperModal={handleStepperModal}
													key={e.round_id}
													item={e}
												/>
											))}
										</div>
									)}
									{!active && draftData?.length > itemsPerPageInDrafts && (
										<ReactPaginate
											previousLabel={'Previous'}
											nextLabel={'Next'}
											breakLabel={'...'}
											breakClassName={'break-me'}
											pageCount={draftPageCount}
											marginPagesDisplayed={2}
											pageRangeDisplayed={5}
											onPageChange={({ selected }) =>
												setCurrentDraftPage(selected)
											}
											containerClassName={styles.pagination}
											activeClassName={styles.activePage}
											previousClassName={styles.pageItem}
											nextClassName={styles.pageItem}
											disabledClassName={styles.disabled}
											breakLinkClassName={styles.break}
										/>
									)}
								</div>
							)}
							<div className={styles.roundsCardContainer}>
								<div className={styles.roundsContainer}>
									<div className={styles.activeCardScroll + ' relative'}>
										{active && (
											<>
												{state.loading ? (
													<div className='absolute inset-0 flex items-center justify-center w-full h-60'>
														<CircularProgress size={'40px'} />
													</div>
												) : (
													activeData?.length > 0 && (
														<div
															className={styles.draftList}
															style={{
																marginBottom: '4px',
																scrollBehavior: 'smooth',
															}}>
															{displayedActiveData?.map((e) => (
																<RoundCard key={e.round_id} item={e} />
															))}
														</div>
													)
												)}
											</>
										)}
										{active && activeData?.length > itemsPerPage && (
											<div
												className={styles.paginationContainer}
												style={{ height: '14vh' }}>
												<ReactPaginate
													previousLabel={'Previous'}
													nextLabel={'Next'}
													breakLabel={'...'}
													breakClassName={'break-me'}
													pageCount={activePageCount}
													marginPagesDisplayed={2}
													pageRangeDisplayed={5}
													onPageChange={({ selected }) =>
														setCurrentActivePage(selected)
													}
													containerClassName={styles.pagination}
													activeClassName={styles.activePage}
													previousClassName={styles.pageItem}
													nextClassName={styles.pageItem}
													disabledClassName={styles.disabled}
													breakLinkClassName={styles.break}
												/>
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					)}
			</div>
		</>
	);
};

export default Rounds;
