import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Navigate,
} from "react-router-dom";
import Landing from "../../layout/Landing";
import Rounds from "../../Pages/Rounds";
import Settings from "../../Pages/Settings";
import SingleRound from "../../Pages/SingleRound";
import Foundations from "../../Pages/Foundations";
import AnalysisPage from "../../Pages/AnalysisPage";
import NoDraftsBlocker from "../../Components/Blockers/NoDrafts/Index";
import MobileBlocker from "../../Components/Blockers/MobileBlocker";
import SessionTimeout from "../../assets/icons/blockers/SessionTimeout";
import CrashedScreen from "../../Components/Blockers/CrashedScreen";

export const PrivateRoutes = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<Landing />}>
        <Route index element={<Rounds />} />
        <Route path="round/:roundname" element={<SingleRound />} />
        <Route path="foundations" element={<Foundations />} />
        <Route path="settings" element={<Settings />} />
        <Route path="no-drafts" element={<NoDraftsBlocker />} />
        <Route path="mobile-blocker" element={<MobileBlocker />} />
        <Route path="crashed-screen" element={<CrashedScreen />} />
      </Route>
      <Route
        path="analysis"
        element={<AnalysisPage />}
      />
    </>
  )
);
