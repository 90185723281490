import React from "react";
import "../AnalysisPage/css/Sidebar.css";

const Sidebar = ({ sideBarData, activeSection, setActiveSection, darkMode, styles }) => {

  // useEffect(() => {
  //   const visibleSections = sideBarData.filter(section => shouldDisplaySection(section));
  //   if (visibleSections.length > 0 && !activeSection) {
  //     setActiveSection(visibleSections[0].analysis_section_id.toString());
  //   }
  // }, [sideBarData, activeSection, setActiveSection]);

  const handleClick = (id) => {
    setActiveSection(id.toString());
  };

  // const shouldDisplaySection = (section) => {
  //   if (section.section_name === "Feature Comparison") {
  //     return section.sectionData && Object.keys(section.sectionData).length > 0;
  //   }
  //   return true;
  // };


  return (
    <div className={`side-border ${darkMode ? 'dark-mode' : 'light-mode'}`}>
      <div className="sidebar">
        {sideBarData?.map((section) => (
          <div
            key={section.analysis_section_id}
            className={`link ${activeSection === section.analysis_section_id.toString() ? "active" : ""}`}
            onClick={() => handleClick(section.analysis_section_id)}
          >
            <div className="link-text" style={{ fontSize: styles.nav_fontSize, lineHeight: '20px', color: styles.nav_textColor, fontFamily: styles.nav_fontFamily, fontStyle: styles.nav_fontStyle }} >{section.section_name}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Sidebar;