import './index.css';
import BotImage from '../../assets/icons/botProfile.svg';
import ThreeDotMenu from '../../assets/icons/threeDotMenu.svg';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import RoundsIcon from '../../assets/icons/SidePanel/RoundsIcon';
import RoundsNonActiveIcon from '../../assets/icons/SidePanel/rounds-nonactive.svg';
import FoundationIcon from '../../assets/icons/SidePanel/FoundationIcon';
import FoundationActiveIcon from '../../assets/icons/SidePanel/active-foundation.svg';
import SettingsIconNew from '../../assets/icons/SidePanel/settingsIconNew';
import SettingsActiveIcon from '../../assets/icons/SidePanel/settings-active.svg';
import RoundArrowIcon from '../../assets/icons/SidePanel/RoundArrowIcon';
import LightModeIcon from '../../assets/icons/SidePanel/LightModeIcon';
import DarkModeIcon from '../../assets/icons/SidePanel/DarkmodeIcon';
import { Menu, MenuItem, IconButton, Switch, Typography } from '@mui/material';
import SettingsIcon from '../../assets/icons/SidePanel/SettingsIcon';
import HelpIcon from '../../assets/icons/help.svg';
import StartIcon from '../../assets/icons/start.svg';
import LogoutIcon from '../../assets/icons/logout.svg';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { AccountCircle } from '@mui/icons-material';
import { useMemo, useState, useEffect, useContext } from 'react';
import { useClerk, useUser } from '@clerk/clerk-react';
import { AuthContext } from '../../Context/AuthContext';
import { useTheme } from '../../Context/ThemContext/ThemeContext';
import { setToken } from '../../Context/AuthContext/action';
import { clearStorage } from '../../utils/Storage';
import PlanUsage from '../SidebarSubscriptions/PlanUsage';
import ToggleThemeBtn from './ToggleButton';

const SidePanel = () => {
	const [dropDown, setDropdown] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const navigate = useNavigate();
	const location = useLocation();
	const { user } = useUser();
	const { colorMode, toggleColorMode } = useTheme();
	const { signOut } = useClerk();
	const { dispatch } = useContext(AuthContext);

	const handleSignOut = async () => {
		clearStorage();
		dispatch({ type: setToken, payload: '' });
		await signOut();
	};

	const handleRefresh = () => {
		window.location.reload();
	};

	const toggleMenuBorder = {
		border: `2px solid ${colorMode === 'dark' ? '#666' : '#fff'}`,
	};

	const menuItems = useMemo(() => {
		return [
			{
				value: 'rounds',
				icon: (
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
							width: '100%',
						}}>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							{location.pathname === '/' ? (
								<RoundsIcon width={30} height={30} />
							) : (
								<img
									src={RoundsNonActiveIcon}
									alt='Rounds Non Active'
									// width={30}
									// height={30}
								/>
							)}
							<span style={{ marginLeft: '12px', fontSize: '14px' }}>
								Rounds
							</span>
						</div>
						{location.pathname === '/' && (
							<RoundArrowIcon
								style={{ marginLeft: 'auto', color: '#0069E5' }}
							/>
						)}
					</div>
				),
				onClick: { handleRefresh }, // Add onClick handler
				to: '/',
			},
			{
				value: 'foundations',
				icon: (
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
							width: '100%',
						}}>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							{location.pathname === '/foundations' ? (
								<img
									src={FoundationActiveIcon}
									alt='Foundation Active'
									// width={30}
									// height={30}
								/>
							) : (
								<FoundationIcon />
							)}
							<span style={{ marginLeft: '12px', fontSize: '14px' }}>
								Foundations
							</span>
						</div>
						{location.pathname === '/foundations' && (
							<RoundArrowIcon
								style={{ marginLeft: 'auto', color: '#0069E5' }}
							/>
						)}
					</div>
				),
				to: '/foundations',
			},
			{
				value: 'settings',
				icon: (
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
							width: '100%',
						}}>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							{location.pathname === '/settings' ? (
								<img
									src={SettingsActiveIcon}
									alt='Settings Active'
									// width={30}
									// height={30}
								/>
							) : (
								<SettingsIconNew />
							)}
							<span style={{ marginLeft: '12px', fontSize: '14px' }}>
								Settings
							</span>
						</div>
						{location.pathname === '/settings' && (
							<RoundArrowIcon
								style={{ marginLeft: 'auto', color: '#0069E5' }}
							/>
						)}
					</div>
				),
				to: '/settings',
			},
		];
	}, [location.pathname]);

	useEffect(() => {
		const shouldOpenDropdown = menuItems.some(
			(menuItem) =>
				menuItem.isDropDown &&
				menuItem.dropDownArray.some(
					(dropMenuItem) => dropMenuItem.to === location.pathname
				)
		);
		setDropdown(shouldOpenDropdown);
	}, [location.pathname, menuItems]);

	const handleDropdownClick = (currentMenu) => {
		if (currentMenu.isDropDown) {
			setDropdown(!dropDown);
			navigate(currentMenu.dropDownArray[0].to);
		} else {
			setDropdown(false);
			navigate(currentMenu.to);
		}
	};

	const handleMenuOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	const handleAccountClick = () => {
		navigate('/settings');
		handleMenuClose();
	};

	return (
		<div className='main-side-panel-contents'>
			<div>
				<div className={`side-user-panel-contents side-head-bg`}>
					<div className='user-profile'>
						<img className='user-pic' src={user?.imageUrl} alt='User Profile' />
						<div className='user-profile-name'>
							Hi, {user?.firstName || user?.emailAddresses?.[0]?.emailAddress}
						</div>
					</div>
					<IconButton onClick={handleMenuOpen}>
						<img src={ThreeDotMenu} alt='Menu' />
					</IconButton>
					<Menu
						anchorEl={anchorEl}
						open={Boolean(anchorEl)}
						onClose={handleMenuClose}
						style={toggleMenuBorder}>
						<MenuItem
							className={
								colorMode == 'dark' && 'hover:!bg-[--active-sidebar-bg]'
							}
							onClick={handleAccountClick}>
							Go to Account
						</MenuItem>
						<div className='flex justify-between items-center py-1.5 px-4 gap-4'>
							Theme:
							<ToggleThemeBtn />
						</div>
					</Menu>
				</div>
				<div className='more-side-options'>
					{menuItems.map((currentMenu, idx) => (
						<div key={currentMenu.value}>
							{!currentMenu.isDropDown ? (
								<NavLink
									to={currentMenu.to}
									className={({ isActive }) =>
										`side-option ${
											isActive ||
											(location.pathname.includes('round/') && idx == 0)
												? 'selected-side-option'
												: ''
										}`
									}
									style={{ textDecoration: 'none', cursor: 'pointer' }}>
									{currentMenu.icon}
									<div>{currentMenu.label}</div>
								</NavLink>
							) : (
								<div>
									<div
										className='side-option'
										style={{ textDecoration: 'none', cursor: 'pointer' }}
										onClick={() => handleDropdownClick(currentMenu)}>
										{currentMenu.icon}
										<div>{currentMenu.label}</div>
										{dropDown ? (
											<ExpandLessIcon style={{ marginLeft: 'auto' }} />
										) : (
											<ExpandMoreIcon style={{ marginLeft: 'auto' }} />
										)}
									</div>
									<div
										className={`dropdown-content ${
											dropDown ? 'dropdown-open' : 'dropdown-closed'
										}`}>
										{currentMenu.dropDownArray?.map((dropMenuItem) => (
											<NavLink
												key={dropMenuItem.value}
												to={dropMenuItem.to}
												className={({ isActive }) =>
													`side-option ${
														isActive || location.pathname.includes('round/')
															? 'selected-side-option'
															: ''
													}`
												}
												style={{
													textDecoration: 'none',
													cursor: 'pointer',
												}}>
												{dropMenuItem.icon}
												<div>{dropMenuItem.label}</div>
											</NavLink>
										))}
									</div>
								</div>
							)}
						</div>
					))}
				</div>
			</div>
			<div>
				<PlanUsage />
			</div>
			<div className='side-footer-items'>
				<div
					className='side-option'
					style={{ fontSize: '14px'}}
					onClick={() => window.open('https://help.sharbo.io/en/articles/10051263-quick-start', '_blank')}>
					<img src={StartIcon} alt='Start Icon' />
					{'Quick Start'}
				</div>
				<div className='side-option' 
					style={{ fontSize: '14px' }}
					onClick={() => window.open('https://help.sharbo.io/', '_blank')}>
					<img src={HelpIcon} alt='Help Icon' />
					{'Help Center'}
				</div>
				<div onClick={handleSignOut} className='side-option'>
					<img src={LogoutIcon} alt='Logout Icon' />
					{'Logout'}
				</div>
			</div>
		</div>
	);
};

export default SidePanel;
