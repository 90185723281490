import React, { useCallback, useState, useContext, useEffect } from 'react';
import styles from './index.module.css';
import RegenerateImagge from '../../../assets/icons/regenerate.svg';
import About from './About';
import { PrimaryModal } from '../../Common/PrimaryModal';
import RegenrateModal from './RegenerateModal';
import RegenerateButton from './RegenerateButton';
import AdditionalOptions from './AdditoinalOptions';
import FeatureComparison from './FeatureComparison';
import TabSectionTemplate from './TabSectionTemplate';
import Info from '../../../assets/icons/info-circle.svg';
import { OutpointContext } from '../../../Context/OutpointsContext/Index';
import {
	generateCustomAnalysis,
	getAttributeList,
	getCompetitor_from_foundation_id,
	getFeatureComparison,
	RegenerateAnalysisSection,
} from '../../../services';
import useSaveRoundsForm from '../../../hooks/useSaveRoundsForm';
import { generateAnalysis } from '../../../services';
import LoadingPage from '../../Common/Loading';
import { CircularProgress } from '@mui/material';
import AnalysisLoader from './AnalysisLoader';
import { border, padding, width } from '@mui/system';
import { SET_API_LOADING } from '../../../Context/OutpointsContext/action';
import { toast } from 'react-toastify';

const Analysis = ({ analysisLoading, setAnalysisLoading }) => {
	const { state, selectedStep, dispatch } = useContext(OutpointContext);
	console.log(state, 'state');
	const [metaChecked, setMetaChecked] = useState({
		command_id: null,
		command_text: '',
	});

	const tabDataArray = [
		{
			id: 1,
			title: 'About',
		},
		{
			id: 2,
			title: 'Feature Comparison',
		},
		{
			id: 5,
			title: 'Key Distinctions',
		},
		{
			id: 3,
			title: `Benefits of using (${
				state.roundsForm.foundation?.foundation_name || 'Foundation Name'
			})`,
		},
		{
			id: 4,
			title: `Limitations of using (${
				state.roundsForm.competitor?.competitor_name || 'Competitor Name'
			})`,
		},
		{
			id: 6,
			title: 'Custom Section',
		},
	];

	const linkData = [
		{
			tabName: 'about-foundation',
			dataName: 'about_foundation',
			loading: 'About-foundation',
		},
		{
			tabName: 'about-competitor',
			dataName: 'about_competitor',
			loading: 'About-competitor',
		},
		{
			tabName: 'key-distinctions',
			dataName: 'key_distinction',
			loading: 'Key Distinctions',
		},
		{
			tabName: 'limitations',
			dataName: 'limitationscompetitor',
			loading: `Limitations of using (${
				state.roundsForm.competitor?.competitor_name || 'Competitor Name'
			})`,
		},
		{
			tabName: 'benefits',
			dataName: 'benefitsfoundation',
			loading: `Benefits of using (${
				state.roundsForm.foundation?.foundation_name || 'Foundation Name'
			})`,
		},
	];

	const initialLoadingState = {
		key_distinction: false,
		benefitsfoundation: false,
		limitationscompetitor: false,
	};

	const [tabData, setTabData] = useState(tabDataArray);
	const [activeTab, setActiveTab] = useState(tabData?.[0]?.title);
	const [regenerateModal, setRegenerateModal] = useState(false);
	const [regenerateAnalysisF, setRegenerateAnalysisF] = useState(0);
	const [additoinalModal, setAdditionalModal] = useState(false);
	const [currentLoading, setCurrentLoading] = useState([]);
	const [sectionsLoading, setSectionsLoading] = useState(initialLoadingState);
	const [regenerateType, setRegenerateType] = useState('');
	const [reLoading, setReLoading] = useState(false);
	const [showLoaderText, setShowLoaderText] = useState(true);

	const [analysisData, setAnalysisData] = useState(null);

	const { handleRoundsForm } = useSaveRoundsForm();

	const fetchCompetitors = useCallback(async () => {
		if (
			state.roundsForm.foundation &&
			state.roundsForm.foundation.foundation_id
		) {
			try {
				const result = await getCompetitor_from_foundation_id(
					state.roundsForm.foundation.foundation_id
				);
				if (result && Array.isArray(result.data)) {
					// save it to the global state
					console.log(result.data[0], 'result');
					handleRoundsForm(result.data[0], 'competitor');
					setTabData((prev) =>
						prev.map((e) =>
							e.id == 4
								? {
										...e,
										title: `Limitations of using (${result.data[0]?.competitor_name})`,
								  }
								: e
						)
					);
				} else {
					console.error('Unexpected response format:', result);
				}
			} catch (error) {
				console.error('Error fetching competitors:', error);
			}
		} else {
			console.error('No foundation ID available. Unable to fetch competitors.');
			// Optionally, you could set an error state here to display to the user
			// setError('No foundation ID available. Please select a foundation first.');
		}
	}, [state.roundsForm.foundation]);

	useEffect(() => {
		if (regenerateAnalysisF == 1) {
			handleRengenarateAll(true, 2, analysisData.attributes);
		}
	}, [regenerateAnalysisF]);

	const generateAnalysisData = async () => {
		dispatch({ type: SET_API_LOADING, payload: true });
		setAnalysisLoading(true);
		if (
			state.roundsForm.round_id &&
			state.roundsForm.competitor?.competitor_id
		) {
			try {
				const result = await generateAnalysis(
					state.roundsForm.round_id,
					state.roundsForm.competitor.competitor_id
				);

				if (result?.data) {
					console.log('Analysis generated:', result.data);
					setAnalysisData(result.data);
					if (result?.data?.analysis?.key_distinction?.length <= 5) {
						await setRegenerateAnalysisF(1);
					} else {
						let custTab = [];
						if (result.data?.custom_section?.custom_section?.length > 0) {
							for (let key of result.data?.custom_section?.custom_section) {
								custTab.push({
									id: key.customsectionid,
									title: key.customsectiontitle,
									type: 'custom',
								});
							}
						}
						setTabData((prev) => {
							let lastElement = prev[prev.length - 1];
							let newarr = [...prev];
							newarr.pop();
							return [...newarr, ...custTab, lastElement];
						});
					}
					dispatch({ type: SET_API_LOADING, payload: false });
				} else {
					console.error('Failed to generate analysis');
					dispatch({ type: SET_API_LOADING, payload: false });
				}
			} catch (error) {
				console.error('Error generating analysis:', error);
				dispatch({ type: SET_API_LOADING, payload: false });
			} finally {
				setAnalysisLoading(false);
				dispatch({ type: SET_API_LOADING, payload: false });
			}
		} else {
			console.error(
				'Missing foundation_id or competitor_id. Unable to generate analysis.'
			);
			dispatch({ type: SET_API_LOADING, payload: false });
		}
	};

	useEffect(() => {
		if (
			!(
				state.roundsForm.competitor &&
				'competitor_id' in state.roundsForm.competitor
			)
		)
			fetchCompetitors();
	}, [state]);

	useEffect(() => {
		generateAnalysisData();
	}, []);

	useEffect(() => {
		if (currentLoading.length == 0) {
			dispatch({ type: SET_API_LOADING, payload: false });
		}
	}, [currentLoading]);

	const onFeatureComparisonContainerClick = (id) => {
		setActiveTab(id);
	};
	const handleRegenerateModal = (type = '') => {
		setRegenerateModal(true);
		setRegenerateType(type);
	};

	const handleRegenerate = async (isOnly) => {
		dispatch({ type: SET_API_LOADING, payload: true });
		setRegenerateModal(false);
		setAdditionalModal(false);
		if (regenerateType == 'regenerateall') await handleRengenarateAll(isOnly);
		else await handleRegenerateSection(isOnly);
	};

	const handleRegenerateSection = async (isOnly) => {
		let id = metaChecked?.command_id;
		let text = metaChecked?.command_text;
		if (!id) {
			(id = null), (text = '');
		}

		setReLoading(true);
		if (regenerateType == 'custom') {
			setCurrentLoading(`${activeTab}`);
			let result = await generateCustomAnalysis(
				analysisData.analysis.analysis_id,
				activeTab,
				id,
				text
			);
			if (result?.data) {
				setAnalysisData({
					...analysisData,
					custom_section: {
						...analysisData.custom_section,
						custom_section: analysisData.custom_section.custom_section.map(
							(e) => (e.customsectiontitle === activeTab ? result.data : e)
						),
					},
				});
			}
			setRegenerateType('');
			setCurrentLoading('');
			setReLoading(false);
			setAdditionalModal(false);
			setRegenerateModal(false);
			return;
		}
		if (regenerateType) {
			setCurrentLoading(`${activeTab}-${regenerateType}`);
		} else {
			setCurrentLoading(`${activeTab}`);
		}

		function getcurrentTabName() {
			let name;

			switch (activeTab) {
				case 'About': {
					name = 'about';
					break;
				}
				case `Benefits of using (${
					state.roundsForm.foundation?.foundation_name || 'Foundation Name'
				})`: {
					name = 'benefits';
					break;
				}

				case `Limitations of using (${
					state.roundsForm.competitor?.competitor_name || 'Competitor Name'
				})`: {
					name = 'limitations';
					break;
				}

				case 'Key Distinctions': {
					name = 'key';
					break;
				}

				default:
					return;
			}
			return name;
		}

		let tabName = getcurrentTabName();
		let dataName = getcurrentTabName();
		if (regenerateType) {
			tabName = `${tabName}-${regenerateType}`;
			dataName = `${dataName}_${regenerateType}`;
		}
		if (tabName === 'benefits') {
			dataName = `${dataName}foundation`;
		}
		if (tabName === 'limitations') {
			dataName = `${dataName}competitor`;
		}
		if (tabName === 'key') {
			tabName = `${tabName}-distinctions`;
			dataName = `${dataName}_distinction`;
		}

		let res = await RegenerateAnalysisSection(
			tabName,
			analysisData.analysis.analysis_id,
			id,
			text
		);
		if (res?.data) {
			setAnalysisData({
				...analysisData,
				analysis: {
					...analysisData.analysis,
					[dataName]: res.data,
				},
			});
		}

		dispatch({ type: SET_API_LOADING, payload: false });
		setRegenerateType('');
		setCurrentLoading('');
		setReLoading(false);
		setAdditionalModal(false);
		setRegenerateModal(false);
		setMetaChecked({});
	};

	console.log(currentLoading);

	async function handleRengenarateAll(isOnly, slc = 0, attributeList = []) {
		dispatch({ type: SET_API_LOADING, payload: true });

		try {
			// Set initial loading state for all items to regenerate
			setCurrentLoading((prevLoading) => [
				...prevLoading,
				...linkData.slice(slc).map((key) => key.loading),
				'Feature Comparison',
				...tabData
					.filter((key) => key.type === 'custom')
					.map((key) => key.title),
			]);

			let resA = analysisData.feature_comparison.map((e) => e.attribute_data);
			if (attributeList.length > 0) {
				resA = attributeList;
			}

			// Clear 'Feature Comparison' from loading if no attributes remain
			if (resA.length <= 0) {
				setCurrentLoading((prevLoading) =>
					prevLoading?.filter((e) => e !== 'Feature Comparison')
				);
			}

			// Regenerate custom sections in parallel
			const customPromises = tabData
				.filter((key) => key.type === 'custom')
				.map(async (key) => {
					const res = await generateCustomAnalysis(
						analysisData.analysis.analysis_id,
						key.title,
						null,
						null
					);
					if (res?.data) {
						setAnalysisData((prevAnalysisData) => ({
							...prevAnalysisData,
							custom_section: {
								...prevAnalysisData.custom_section,
								custom_section:
									prevAnalysisData.custom_section.custom_section.map((e) =>
										e.customsectiontitle === key.title ? res.data : e
									),
							},
						}));
						toast.success(key?.title + ' is now available.');
					}

					// Remove custom section from loading state using functional update
					setCurrentLoading((prevLoading) =>
						prevLoading?.filter((e) => e !== key.title)
					);
				});

			// Regenerate predefined sections in linkData in parallel
			const predefinedPromises = linkData.slice(slc).map(async (key) => {
				const res = await RegenerateAnalysisSection(
					key.tabName,
					analysisData.analysis.analysis_id,
					null,
					null
				);
				if (res?.data) {
					setAnalysisData((prevData) => ({
						...prevData,
						analysis: {
							...prevData.analysis,
							[key.dataName]: res.data,
						},
					}));
					toast.success(key?.loading + ' is now available.');
				}
				// Remove predefined section from loading state
				setCurrentLoading((prevLoading) =>
					prevLoading.filter((e) => e !== key.loading)
				);
			});

			// Run feature comparison concurrently if attributes are available
			const featureComparisonPromise =
				resA.length > 0
					? getFeatureComparison(analysisData.analysis.analysis_id, resA).then(
							(res) => {
								if (res?.data) {
									setAnalysisData((prevData) => ({
										...prevData,
										feature_comparison: res.data,
									}));
								}
								toast.success('Feature Comparison is now available.');
								// Remove "Feature Comparison" from loading state
								setCurrentLoading((prevLoading) =>
									prevLoading.filter((e) => e !== 'Feature Comparison')
								);
							}
					  )
					: Promise.resolve(); // Skip if no attributes to compare

			// Run all promises concurrently
			await Promise.allSettled([
				...customPromises,
				...predefinedPromises,
				featureComparisonPromise,
			]);

			// Final regeneration of feature comparison if needed
			// if (resA.length > 0) {
			//   const res = await getFeatureComparison(
			//     analysisData.analysis.analysis_id,
			//     resA
			//   );
			//   if (res?.data) {
			//     setAnalysisData((prevData) => ({
			//       ...prevData,
			//       feature_comparison: res.data,
			//     }));
			//   }
			// }

			// Reset additional states after completion
			dispatch({ type: SET_API_LOADING, payload: false });
			setRegenerateType('');
			setReLoading(false);
			setAdditionalModal(false);
			setRegenerateModal(false);
		} catch (error) {
			dispatch({ type: SET_API_LOADING, payload: false });
			console.error('Error in regeneration:', error);
		}
	}

	const handleAddtionalOptions = () => {
		setAdditionalModal(true);
	};

	const renderValue = () => {
		switch (activeTab) {
			case 'About':
				return (
					<About
						currentLoading={currentLoading}
						handleRegenerate={handleRegenerateModal}
						analysisData={analysisData}
						activeTab={activeTab}
						setShowLoaderText={setShowLoaderText}
					/>
				);

			case 'Feature Comparison':
				return (
					<FeatureComparison
						setAnalysisData={setAnalysisData}
						handleRegenerate={handleRegenerateModal}
						analysisData={analysisData}
						activeTab={activeTab}
					/>
				);

			case `Benefits of using (${
				state.roundsForm.foundation?.foundation_name || 'Foundation Name'
			})`:
				return (
					<TabSectionTemplate
						sectionLoading={sectionsLoading.benefitsfoundation}
						section_id='benefits_of_using_foundation'
						handleRegenerate={handleRegenerateModal}
						analysisData={analysisData}
						setAnalysisData={setAnalysisData}
						heading={activeTab}
						activeTab={activeTab}
						setShowLoaderText={setShowLoaderText}
					/>
				);

			case `Limitations of using (${
				state.roundsForm.competitor?.competitor_name || 'Competitor Name'
			})`:
				return (
					<TabSectionTemplate
						sectionLoading={sectionsLoading.limitationscompetitor}
						section_id='limitations_of_using_competitor'
						handleRegenerate={handleRegenerateModal}
						analysisData={analysisData}
						setAnalysisData={setAnalysisData}
						heading={activeTab}
						activeTab={activeTab}
						setShowLoaderText={setShowLoaderText}
					/>
				);
			case 'Key Distinctions':
				return (
					<TabSectionTemplate
						sectionLoading={sectionsLoading.key_distinction}
						section_id='key_distinction'
						handleRegenerate={handleRegenerateModal}
						analysisData={analysisData}
						setAnalysisData={setAnalysisData}
						heading={activeTab}
						activeTab={activeTab}
						setShowLoaderText={setShowLoaderText}
					/>
				);

			case 'Custom Section':
				return (
					<TabSectionTemplate
						key='custom'
						setActiveTab={setActiveTab}
						tabData={tabData}
						heading='Add Custom Analysis Sections (Max 5)'
						custom
						setTabData={setTabData}
						analysisData={analysisData}
						setAnalysisData={setAnalysisData}
						section_id='custom'
						handleRegenerate={handleRegenerateModal}
						activeTab={activeTab}
					/>
				);

			default:
				return (
					<TabSectionTemplate
						activeTab={activeTab}
						key={activeTab}
						type='custom'
						setActiveTab={setActiveTab}
						tabData={tabData}
						// heading="Add Custom Analysis Sections (Max 5)"
						custom
						setTabData={setTabData}
						section_id='customgen'
						analysisData={analysisData}
						setAnalysisData={setAnalysisData}
						handleRegenerate={handleRegenerateModal}
						setShowLoaderText={setShowLoaderText}
					/>
				);
		}
	};

	return (
		<>
			<PrimaryModal
				primaryModalChildrenClass={styles.addEnrichmentChildren}
				title={' '}
				open={additoinalModal}
				setOpen={setAdditionalModal}
				modalStyleClass='w-[750px] h-[500px] m-auto top-1/2 transform -translate-y-1/2'
				primaryModalContainerClass='primary_sort_class'>
				<AdditionalOptions
					metaChecked={metaChecked}
					setMetaChecked={setMetaChecked}
					activeTab={activeTab}
					setAdditionalModal={setAdditionalModal}
					handleRegenerate={handleRegenerate}
					loading={reLoading}
					showWarning={regenerateType === 'regenerateall'}
				/>
			</PrimaryModal>
			<PrimaryModal
				primaryModalChildrenClass={styles.addEnrichmentChildren}
				title={' '}
				open={regenerateModal}
				setOpen={setRegenerateModal}
				modalStyleClass='w-[750px] h-[500px] m-auto top-1/2 transform -translate-y-1/2'
				primaryModalContainerClass='primary_sort_class'>
				<RegenrateModal
					activeTab={activeTab}
					loading={reLoading}
					handleRegenerate={handleRegenerate}
					handleAddtionalOptions={handleAddtionalOptions}
					showWarning={regenerateType === 'regenerateall'}
				/>
			</PrimaryModal>

			{analysisLoading ? (
				<AnalysisLoader isNotShow={true} showLoaderText={true} />
			) : (
				<div className={styles.frameParent}>
					<div className={styles.foundationNameVsCompetitoParent}>
						<div
							className={
								styles.foundationNameVs
							}>{`${state.roundsForm.foundation.foundation_name} vs ${state.roundsForm.competitor?.competitor_name}`}</div>
						<RegenerateButton
							id='regenerateAllBtn'
							type='regenerateall'
							dark={false}
							title='Regenerate All'
							handleRegenerate={handleRegenerateModal}
						/>
						<div className={styles.playVideo}>
							<img className={styles.informationCircleIcon} alt='' src={Info} />
							<div className={styles.youCanAlso}>
								You can also directly edit the AI analysis text and save the
								changes
							</div>
						</div>
					</div>

					<div className={styles.frameGroup}>
						<div className={styles.aboutParent + ' scrollbar-none'}>
							{tabData?.map((e) => (
								<div
									key={e.id}
									onClick={() => onFeatureComparisonContainerClick(e.title)}
									className={
										activeTab === e.title
											? styles.about
											: styles.featureComparison
									}>
									<div
										className={
											activeTab === e.title
												? styles.about1
												: styles.keyDistinctions
										}>
										{e.title}
									</div>
									{activeTab === e.title && (
										<div className={styles.frameChild} />
									)}
								</div>
							))}
						</div>
						{currentLoading.includes(activeTab) ||
						(activeTab.includes('About') &&
							currentLoading?.length > 0 &&
							typeof currentLoading != 'string' &&
							currentLoading?.some((itr) => itr?.includes('About'))) ? (
							<div className='w-full h-400'>
								<AnalysisLoader showLoaderText={showLoaderText} />
							</div>
						) : (
							renderValue()
						)}
					</div>
				</div>
			)}
		</>
	);
};

export default Analysis;
