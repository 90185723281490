import React, {
	Fragment,
	useContext,
	useEffect,
	useState,
	useRef,
} from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './index.css';
import UseUrl from '../UseUrl';
import TextAreaDescription from './TextAreaDescription';
import UserSavedFoundation from './UserSavedFoundation';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import useSaveRoundsForm from '../../../hooks/useSaveRoundsForm';
import { OutpointContext } from '../../../Context/OutpointsContext/Index';
import { useLocation } from 'react-router-dom';
import { getFoundation, getUserLimit } from '../../../services';
import { ColorPattern } from '../../../constant/Color';
import { useTheme } from '../../../Context/ThemContext/ThemeContext';
import RemoveCircle from '../../../assets/icons/clearbtn.svg';
import { SubscriptionFeatures } from '../../../constant/constants';
import {
	Link03Icon,
	PencilEdit02Icon,
	ThreeDScaleIcon,
} from '@hugeicons/react';

export default function AccordianData({
	isThird,
	bgColor,
	textColor,
	borderColor,
}) {
	const { colorMode } = useTheme();
	const urlProductNameRef = useRef(null);

	const {
		state: { roundsForm, foundation_page, pathName },
		selectedStep,
	} = useContext(OutpointContext);
	let onlyFoundation = pathName === '/foundations';
	function checkKey() {
		let id = onlyFoundation ? 'foundation' : selectedStep.value;
		let key;
		switch (id) {
			case 'foundation': {
				return (key = 'foundation_mode');
			}
			case 'competitor': {
				return (key = 'competitor_mode');
			}
			default: {
				return (key = '');
			}
		}
	}

	const [foundationLimit, setFoundationLimit] = useState(true);
	const [foundationData, setFoundationData] = useState([]);
	const [foundationLoading, setFoundationLoading] = useState(false);
	const getFoundationData = async (value = 0) => {
		setFoundationLoading(true);
		try {
			let result = await getFoundation(value);
			console.log(result);
			if (result?.message === 'No foundations found') {
				setFoundationLoading(false);
				return;
			}
			setFoundationData(result);
		} catch (err) {
			console.log(err);
		}
		setFoundationLoading(false);
	};

	const getUserLimitAccess = async () => {
		let access = await getUserLimit(SubscriptionFeatures.FOUNDATION);
		setFoundationLimit(access);
		if (!access)
			handleRoundsForm({
				foundation_mode: 3,
			});
	};

	useEffect(() => {
		getFoundationData();
		if (isThird) getUserLimitAccess();
		else setFoundationLimit(true);
	}, []);

	const handleChangeRemove = () => {
		handleRoundsForm({
			description: '',
		});
	};
	const accordionData = [
		{
			id: 1,
			title: (
				<strong
					style={{
						fontSize: '14px',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'flex-start',
					}}>
					<span style={{ marginRight: '8px' }}>
						{' '}
						<Link03Icon size={18} color={'#3F628B'} variant={'solid'} />
					</span>
					Use URLs
				</strong>
			),
			content: <UseUrl />,
		},

		{
			id: 2,
			title: (
				<strong
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'flex-start',
						fontSize: '14px',
					}}>
					<span style={{ marginRight: '8px' }}>
						{' '}
						<PencilEdit02Icon size={18} color={'#3F628B'} variant={'stroke'} />
					</span>
					Provide a Description
				</strong>
			),
			content: <TextAreaDescription />,
		},
		{
			id: 3,
			title: (
				<strong
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'flex-start',
						fontSize: '14px',
					}}>
					<span style={{ marginRight: '8px' }}>
						<ThreeDScaleIcon
							size={18}
							color='#3F628B'
							variant='solid'
							style={{ marginRight: '8px' }}
						/>
					</span>{' '}
					Use a Saved Foundation
				</strong>
			),
			content: (
				<UserSavedFoundation
					foundationLoading={foundationLoading}
					data={foundationData}
					getFoundationData={getFoundationData}
				/>
			),
		},
	];
	const { handleRoundsForm } = useSaveRoundsForm();

	const handleChange = (id) => {
		if (openTab === id) {
			setOpenTab(0);
		} else {
			setOpenTab(id);
		}

		setTimeout(() => {
			if (id === 1 && urlProductNameRef.current) {
				urlProductNameRef.current.scrollIntoView({
					behavior: 'smooth',
					block: 'end',
				});
			}
		}, 500);

		// If the first accordion (Use URLs) is clicked, scroll to the product name input

		if (foundationLimit) {
			let key = checkKey();
			handleRoundsForm({
				[key]: id,
			});
		}
	};

	isThird ? accordionData : accordionData.splice(2);

	const [openTab, setOpenTab] = useState(0);

	return (
		<div className='accordianContainer'>
			{accordionData.map((item, index) => {
				if (!foundationLimit ? index === 2 : true)
					return (
						<div
							id={index}
							ref={item.id === 1 ? urlProductNameRef : null}
							style={{ height: 'fit-content' }}
							className={
								!foundationLimit && index < 2
									? 'disabled opacity-60 pointer-events-none'
									: ''
							}>
							<Accordion
								key={item.id}
								expanded={openTab === item.id}
								onChange={() => handleChange(item.id)}
								sx={{
									boxShadow: 'none !important', // Remove shadow
									borderTop: `1px solid ${ColorPattern[colorMode].borderColor}`, // Top border
									borderBottom: `1px solid ${ColorPattern[colorMode].borderColor}`, // Bottom border
									backgroundColor: 'transparent', // Background color
									'&:before': {
										display: 'none', // Remove default before pseudo-element
									},
									'&.Mui-expanded': {
										margin: 0,
										boxShadow: 'none !important',
										padding: '0px',
										border: 'none',
										borderBottom: `1px solid ${ColorPattern[colorMode].borderColor}`,
									},
									'& .MuiAccordionSummary-root': {
										minHeight: '48px',
										padding: '0px',
									},
									'& .MuiAccordionSummary-content': {
										margin: '0px', // Remove margin from AccordionSummary content
									},
									'& .MuiRadio-root': {
										color: ColorPattern[colorMode].textColor, // Set Radio button color
									},
									'& .MuiRadio-root.Mui-checked': {
										color: '#3D87DD !important', // Set checked Radio button color
									},
									'& .MuiAccordionSummary-expandIconWrapper': {
										color: '#3F51B5', // Set color for expand icon
									},
									'& .MuiAccordionDetails-root': {
										padding: '10px 0px 0px 0px', // Set padding for AccordionDetails
									},
									'& .MuiPaper-root': {
										boxShadow: 'none !important', // No shadow for Paper component
										margin: 0, // Ensure no margin
										backgroundColor: ColorPattern[colorMode].bgColor, // Background color
									},
									'& .MuiTextField-root': {
										width: '480px !important', // Set TextField width
										height: '150px !important', // Set TextField height
										border: 'none', // Remove border for TextField
										background: 'transparent !important', // Transparent background
										transform: 'translate(10rem, 0rem) !important', // Transform TextField
										color: ColorPattern[colorMode].textColor, // Set TextField color
									},
									'& .Mui-focused': {
										border: 'none !important', // Remove border on focus
										outline: 'none !important', // Remove outline on focus
									},
									'& .MuiInputBase-input': {
										color: ColorPattern[colorMode].textColor, // Set input color
									},
								}}>
								<AccordionSummary
									sx={{ minHeight: '48px' }}
									expandIcon={<ExpandMoreIcon />}
									aria-controls={`${item.id}bh-content`}
									id={`${item.id}bh-header`}>
									<div className='customRadio'>
										<RadioGroup
											value={
												onlyFoundation
													? foundation_page?.[checkKey()]
													: roundsForm?.[selectedStep?.value]?.[checkKey()]
											}
											// sx={{ color: textColor, }}
										>
											<FormControlLabel
												className='form-label'
												checked={
													openTab === 0
														? false
														: !foundationLimit
														? index === 2
														: onlyFoundation
														? foundation_page?.[checkKey()] === item.id
														: roundsForm?.[selectedStep.value]?.[checkKey()] ===
														  item.id
												}
												control={<Radio />}
											/>
										</RadioGroup>
										<Typography
											sx={{
												color: ColorPattern[colorMode].textColor,
											}}>
											{item.title}
										</Typography>
									</div>
								</AccordionSummary>
								<AccordionDetails style={{ paddingBottom: '20px' }}>
									{item.content}
								</AccordionDetails>
							</Accordion>
						</div>
					);
			})}
		</div>
	);
}
