import React, { createContext, useState, useEffect, useCallback } from 'react';
import { getCurrentSubscriptions } from '../../services';

export const SubscriptionContext = createContext();

export const SubscriptionProvider = ({ children }) => {
    const [subscriptionUpdatedData, setSubscriptionUpdatedData] = useState(null);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);

    const fetchSubscriptionData = useCallback(async () => {
        const returnUrl = window.location.href;
        try {
            const result = await getCurrentSubscriptions(returnUrl);
            if (result.message === 'Current subscription found') {
                setSubscriptionUpdatedData(result.data);
            }
        } catch (err) {
            setError(' ');
            console.error('Fetch subscription error:', err);
        } finally {
            setLoading(false);
        }
    }, []);
       return (
        <SubscriptionContext.Provider value={{ subscriptionUpdatedData, error, loading, fetchSubscriptionData }}>
            {children}
        </SubscriptionContext.Provider>
    );
};
