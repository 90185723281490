import { useMemo } from "react";
import "./index.css";
import TickIcon from "../../assets/icons/tick.png";


const Stepper = (props) => {
    const { steps, selectedStep} = props

    const allStepsIdIndexMap = useMemo(() => {
        let returned = {};

        steps.forEach((step, index) => {
            returned[step.id] = index
        })

        return returned
    }, [steps])

    const selectedStepIndex = useMemo(() => allStepsIdIndexMap[selectedStep.id], [steps, selectedStep]);

    const getLabelContainerClass = (currentStepIndex) => {
        let classList = 'stepper-label';

        if(currentStepIndex <= selectedStepIndex) {
            classList += ' selected-stepper-label'
        }

        return classList
    }

    const getNumberContainerLabel = (currentStepIndex) => { 
        let classList = 'stepper-number';

        if(currentStepIndex == selectedStepIndex) {
            classList += ' selected-stepper-number'
        }

        return classList
    }

    const getStepperDividerClassList = (currentStepIndex) => {
        let classList = 'divider-stepper';

        if(currentStepIndex < selectedStepIndex) {
            classList += ' selected-divider-stepper'
        }

        return classList
    }


    const getStepContent = (currentStepIndex) => {
        if(currentStepIndex < selectedStepIndex) {
            return <img src={TickIcon} />
        }
        return currentStepIndex + 1
    }

    return (
        <div className="stepper-container">
            {
                steps.map((currentStep) => {
                    const currentStepIndex = allStepsIdIndexMap[currentStep.id]

                    return (
                        <div className="stepper-item">
                            <div className={getNumberContainerLabel(currentStepIndex)}>
                                {getStepContent(currentStepIndex)}
                            </div>
                            <div  className={getLabelContainerClass(currentStepIndex)}>
                                {currentStep.label}
                            </div>
                            {currentStepIndex !== steps.length - 1 && <div className={getStepperDividerClassList(currentStepIndex)}></div>}
                        </div>
                    )
                })
            }
        </div>
    )
}

export default Stepper