import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import MainContent from "../../Components/AnalysisPage/MainContent";
import { Container, CircularProgress } from "@mui/material";
import { useTheme } from "../../Context/ThemContext/ThemeContext";
import { getAccessToken } from "../../utils/Storage";
import { BASE_URL } from "../../services";
import "./AnalysisPage.css";
import ErrorBlockerPage from "../../Components/Blockers/404ErrorPage";
import { Helmet } from "react-helmet";
import { ColorPattern } from "../../constant/Color";

const AnalysisPage = ({ bgColor }) => {
	const { colorMode, toggleColorMode } = useTheme();

	const defaultStyles = {
		card_image_width: "100%",
		card_image_height: "auto",

		heading_fontSize: "16px",
		heading_fontStyle: "normal",
		heading_fontFamily: "Arial, sans-serif",
		heading_textColor: ColorPattern[colorMode].textColor,

		paragraph_fontSize: "16px",
		paragraph_fontStyle: "normal ",
		paragraph_fontFamily: "Arial, sans-serif ",
		paragraph_textColor: ColorPattern[colorMode].textColor,

		nav_fontSize: "13px",
		nav_fontStyle: "normal",
		nav_fontFamily: "Arial, sans-serif",
		nav_textColor: ColorPattern[colorMode].textColor,
	};

	const [applyStyles, setApplyStyles] = useState({
		card_image_width: "",
		card_image_height: "",

		heading_fontSize: "",
		heading_fontStyle: "",
		heading_fontFamily: "",
		heading_textColor: "",

		paragraph_fontSize: "",
		paragraph_fontStyle: "",
		paragraph_fontFamily: "",
		paragraph_textColor: "",

		nav_fontSize: "",
		nav_fontStyle: "",
		nav_fontFamily: "",
		nav_textColor: "",
	});

	const finalStyles = {
		card_image_width:
			applyStyles.card_image_width || defaultStyles.card_image_width,
		card_image_height:
			applyStyles.card_image_height || defaultStyles.card_image_height,

		heading_fontSize:
			applyStyles.heading_fontSize || defaultStyles.heading_fontSize,
		heading_fontStyle:
			applyStyles.heading_fontStyle || defaultStyles.heading_fontStyle,
		heading_fontFamily:
			applyStyles.heading_fontFamily || defaultStyles.heading_fontFamily,
		heading_textColor:
			applyStyles.heading_textColor || defaultStyles.heading_textColor,

		paragraph_fontSize:
			applyStyles.paragraph_fontSize || defaultStyles.paragraph_fontSize,
		paragraph_fontStyle:
			applyStyles.paragraph_fontStyle || defaultStyles.paragraph_fontStyle,
		paragraph_fontFamily:
			applyStyles.paragraph_fontFamily || defaultStyles.paragraph_fontFamily,
		paragraph_textColor:
			applyStyles.paragraph_textColor || defaultStyles.paragraph_textColor,

		nav_fontSize: applyStyles.nav_fontSize || defaultStyles.nav_fontSize,
		nav_fontStyle: applyStyles.nav_fontStyle || defaultStyles.nav_fontStyle,
		nav_fontFamily: applyStyles.nav_fontFamily || defaultStyles.nav_fontFamily,
		nav_textColor: applyStyles.nav_textColor || defaultStyles.nav_textColor,
	};

	const [isSidebarVisible, setIsSidebarVisible] = useState(
		window.innerWidth > 1000
	);
	const [searchParams, setSearchParams] = useSearchParams();
	const [analysisData, setAnalysisData] = useState(null);
	const [lastUpdatedData, setLastUpdatedData] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [displayMode, setDisplayMode] = useState(null);

	let jwt_token = getAccessToken();
	const headers = {
		Authorization: `Bearer ${jwt_token}`,
		"Content-Type": "application/json",
	};

	useEffect(() => {
		const receiveMessage = (event) => {
			console.log("Received message from iframe:", event.data);
			if (event.data.action === "applyStyles") {
				const styles = event.data.styles;
				console.log("Styles from user", styles);
				setApplyStyles(styles);
			}
		};

		window.addEventListener("message", receiveMessage);
		return () => {
			window.removeEventListener("message", receiveMessage);
		};
	}, []);

	useEffect(() => {
		let roundId = searchParams.get("round_id");
		if (!roundId) {
			roundId = searchParams.get("round_id");
			setSearchParams({ round_id: roundId }, { replace: true });
		}

		const fetchAnalysisSections = async () => {
			setIsLoading(true);
			try {
				const response = await fetch(
					`${BASE_URL}/page/analysis-sections?round_id=${roundId}`,
					{
						method: "GET",
						headers: headers,
					}
				);

				if (!response.ok) {
					throw new Error(`Failed to fetch data: ${response.statusText}`);
				}

				const responseData = await response.json();

				setAnalysisData(responseData.data);
				setLastUpdatedData(responseData);
				setDisplayMode(responseData.display_mode);
				localStorage.setItem("lastUpdated", responseData.last_updated);
				localStorage.setItem("SEOKeywords", responseData.seo_keywords);
				localStorage.setItem("PageTitle", responseData.page_title);
				localStorage.setItem("metaDescription", responseData.meta_description);
			} catch (error) {
				console.error("Error fetching analysis sections:", error);
				setAnalysisData(null);
			} finally {
				setIsLoading(false);
			}
		};

		fetchAnalysisSections();
		const handleResize = () => {
			setIsSidebarVisible(window.innerWidth > 1000);
		};

		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, [searchParams]);

	useEffect(() => {
		if (displayMode !== null) {
			const shouldBeDarkMode = displayMode;
			if (shouldBeDarkMode !== (colorMode === "dark")) {
				toggleColorMode();
			}
		}
	}, [displayMode, colorMode, toggleColorMode]);

	const navigationList = lastUpdatedData?.navigation_list ?? true;
	const lastUpdatedBool = lastUpdatedData?.last_updated_bool ?? false;
	const lastUpdated = lastUpdatedData?.last_updated;
	const SEOKeywords = lastUpdatedData?.seo_keywords;
	const PageTitle = lastUpdatedData?.page_title;
	const metaDescription = lastUpdatedData?.meta_description;

	return (
		<div className={`web ${colorMode}`} style={{ background: bgColor }}>
			<Helmet>
				<title>{PageTitle}</title>
				<meta name="description" content={metaDescription} />
				<meta name="keywords" content={SEOKeywords} />
			</Helmet>
			<div
				style={{
					height: "100vh",
					display: "flex",
					flexDirection: "column",
					background: bgColor,
				}}>
				<div
					className="main-content"
					style={{
						flex: 1,
						display: "flex",
						flexDirection: "row",
						background: bgColor,
					}}>
					{isLoading ? (
						<div
							style={{
								flex: 1,
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								padding: "300px",
							}}>
							<CircularProgress />
						</div>
					) : analysisData ? (
						<MainContent
							analysisContent={analysisData}
							lastUpdated={lastUpdated}
							lastUpdatedBool={lastUpdatedBool}
							navigationList={navigationList}
							darkMode={colorMode === "dark"}
							pageTitle={PageTitle}
							metaDescription={metaDescription}
							styles={finalStyles}
						/>
					) : (
						<div className="no-data-container">
							<Container>
								<ErrorBlockerPage />
							</Container>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default AnalysisPage;
