import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import '../MobileBlocker/index.css';
import Sharbo from '../../../assets/icons/blockers/Sharbo.svg';
import SharboBlue from '../../../assets/icons/blockers/sharbo-blue.svg';
import BlockerIcon from '../../../assets/icons/blockers/mobile-blocker.svg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTheme } from '../../../Context/ThemContext/ThemeContext';
import Lottie from 'lottie-react';
import laptopAnimation from './laptopAnimation.json';

const MobileBlocker = () => {
	const copyLink = () => {
		const currentURL = window.location.href;
		navigator.clipboard
			.writeText(currentURL)
			.then(() => {
				toast.success('Link copied to clipboard!', {
					position: 'top-right',
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
			})
			.catch((err) => {
				toast.error('Failed to copy the link', {
					position: 'top-right',
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
				console.error('Failed to copy the link: ', err);
			});
	};

	const returnToSharbo = () => {
		window.location.href = '/';
	};

	const { colorMode } = useTheme();

	return (
		<div
			style={{
				zIndex: 9999,
			}}
			className={`mobile-warning ${colorMode}`}>
			<img
				className={`sharbo-logo`}
				src={colorMode === 'dark' ? Sharbo : SharboBlue}
				alt='Sharbo logo'
			/>
			<div className={`warning-frame ${colorMode}`}>
				<div className='warning-content'>
					<div className={`warning-text ${colorMode}`}>
						{/* <img
							className='laptop-performance-icon'
							src={BlockerIcon}
							alt='Mobile Blocker icon'
						/> */}
						<Lottie
							style={{
								height: '150px',
								width: '150px',
							}}
							animationData={laptopAnimation}
							loop={true}
						/>
						<h2>
							Please access the Sharbo Dashboard using a laptop, desktop, or
							tablet
						</h2>
						<p>Mobile browsers are currently not supported</p>
					</div>
					<div className='action-buttons'>
						<button
							className={`normal-button copy-link-button ${colorMode} hover:scale-105`}
							onClick={copyLink}>
							Copy Link
						</button>
						<button
							className={`normal-button return-button ${colorMode} hover:scale-105`}
							onClick={returnToSharbo}>
							Return to Sharbo
						</button>
					</div>
				</div>
			</div>
			<ToastContainer />
		</div>
	);
};

export default MobileBlocker;
