import { useContext, useEffect, useState } from 'react';
import './Colors.css';
import {
	BrowserRouter,
	Route,
	RouterProvider,
	Routes,
	useLocation,
} from 'react-router-dom';
import { AuthContext } from './Context/AuthContext';
import { PrivateRoutes } from './routes/PrivateRoutes';
import { PublicRoutes } from './routes/PublicRoutes';
import MobileBlocker from './Components/Blockers/MobileBlocker';
import AnalysisPage from './Pages/AnalysisPage';
import ErrorBoundary from './Components/Blockers/ErrorBoundary';

function App() {
	const {
		state: { token },
	} = useContext(AuthContext);
	const [shouldShowBlocker, setShouldShowBlocker] = useState(false);

	useEffect(() => {
		const checkMobileBlocker = () => {
			const isAnalysisPage = window.location.pathname.includes('/analysis');
			const isMobileWidth = window.innerWidth <= 1024;
			setShouldShowBlocker(isMobileWidth && !isAnalysisPage);
		};

		// Initial check
		checkMobileBlocker();

		// Check on resize
		window.addEventListener('resize', checkMobileBlocker);

		// Check on route changes
		const observer = new MutationObserver(checkMobileBlocker);
		observer.observe(document.body, { childList: true, subtree: true });

		return () => {
			window.removeEventListener('resize', checkMobileBlocker);
			observer.disconnect();
		};
	}, []);

	if (shouldShowBlocker) {
		return <MobileBlocker />;
	}

	return (
		<ErrorBoundary>
			<div className='app-wrapper'>
				{token ? (
					<RouterProvider router={PrivateRoutes} />
				) : (
					<RouterProvider router={PublicRoutes} />
				)}
				{/* <BrowserRouter>
				<Routes>
					<Route
						path="analysis"
						element={<AnalysisPage />}
					/>
				</Routes>

			</BrowserRouter> */}
			</div>
		</ErrorBoundary>
	);
}

export default App;
