import React from 'react';
import './css/ContentSection.css';
import Markdown from 'react-markdown';

const ContentSection = ({ title, content, styles }) => {
	const containsImage = (text) => {
		return text.includes('![');
	};

	return (
		<div className='content-section'>
			<h2
				className='section-title'
				style={{
					fontSize: styles.heading_fontSize,
					fontFamily: styles.heading_fontFamily,
					color: styles.heading_textColor,
					fontStyle: styles.heading_fontStyle,
				}}>
				{title}
			</h2>
			{content.map((paragraph, index) => (
				<div
					key={index}
					className='section-content'
					style={{
						fontSize: styles.paragraph_fontSize,
						fontFamily: styles.paragraph_fontFamily,
						color: styles.paragraph_textColor,
						fontStyle: styles.paragraph_fontStyle,
						height: containsImage(paragraph)
							? styles.card_image_height
							: '100%',
						width: containsImage(paragraph) ? styles.card_image_width : '100%',
					}}>
					<Markdown
						components={{
							p: ({ node, children, ...props }) => (
								<p
									{...props}
									style={{
										fontSize: styles.paragraph_fontSize,
										fontFamily: styles.paragraph_fontFamily,
										color: styles.paragraph_textColor,
										fontStyle: styles.paragraph_fontStyle,
										margin: '0.5em 0',
									}}>
									{children}
								</p>
							),
							pre: ({ node, children, ...props }) => (
								<pre
									{...props}
									style={{
										whiteSpace: 'pre-wrap',
										fontSize: styles.paragraph_fontSize,
										fontFamily: styles.paragraph_fontFamily,
										color: styles.paragraph_textColor,
										fontStyle: styles.paragraph_fontStyle,
									}}>
									{children}
								</pre>
							),
							img: ({ node, ...props }) => (
								<img
									{...props}
									style={{
										height: styles.card_image_height,
										width: styles.card_image_width,
										objectFit: 'cover',
									}}
								/>
							),
							ul: ({ node, children, ...props }) => (
								<ul
									{...props}
									style={{
										listStyleType: 'disc',
										paddingLeft: '2em',
										marginTop: '0.5em',
										marginBottom: '0.5em',
									}}>
									{children}
								</ul>
							),
							ol: ({ node, children, ...props }) => (
								<ol
									{...props}
									style={{
										listStyleType: 'decimal',
										paddingLeft: '2em',
										marginTop: '0.5em',
										marginBottom: '0.5em',
									}}>
									{children}
								</ol>
							),
							li: ({ node, children, ...props }) => (
								<li
									{...props}
									style={{
										fontSize: styles.paragraph_fontSize,
										fontFamily: styles.paragraph_fontFamily,
										color: styles.paragraph_textColor,
										fontStyle: styles.paragraph_fontStyle,
										marginBottom: '0.25em',
									}}>
									{children}
								</li>
							),
						}}>
						{paragraph.trim()}
					</Markdown>
				</div>
			))}
		</div>
	);
};

export default ContentSection;
