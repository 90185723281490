import { Modal } from "@mui/material";
import React from "react";
import RichTextEditor from "../TextEditor";
import styles from "./About/index.module.css";
import { useTheme } from "../../../Context/ThemContext/ThemeContext";

const EditAnalysisModal = ({
  title = "",
  openModal,
  closeModal,
  markdownData,
  setMarkdownData,
  disableBtn,
  saveFunction,
  discardFunction,
}) => {
  const { colorMode } = useTheme();
  console.log("colorMode", colorMode);
  return (
    <Modal styles={"width:300px"} open={openModal} onClose={closeModal}>
      <div
        className={`fixed w-[calc(100vw-100px)] max-w-7xl min-h-[calc(100vh-160px)] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 ${
          colorMode == "dark" ? "bg-[#131314]" : "bg-white"
        } flex flex-col justify-center items-center gap-5 p-20 rounded-lg`}
      >
        <button
          onClick={closeModal}
          className="absolute top-8 right-8 cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="23"
            height="23"
            viewBox="0 0 23 23"
            fill="none"
          >
            <path
              d="M18.1415 4.77441L4.77344 18.1424M4.77344 4.77441L18.1415 18.1424"
              stroke="#797B7E"
              stroke-width="1.43229"
              stroke-linejoin="round"
            />
          </svg>
        </button>
        {title?.length > 0 && (
          <h1 className="text-lg text-[--dark-text] text-left w-full">{title}</h1>
        )}
        <div className="w-full h-[calc(100vh-300px)] relative flex flex-col gap-4 justify-between rounded border border-[--light-border] p-4">
          <RichTextEditor data={markdownData} setData={setMarkdownData} />
          <div className="flex flex-row items-center justify-start gap-4 bottom-0 z-10 w-full">
            <button
              disabled={disableBtn}
              onClick={saveFunction}
              className={`${styles.saveEditBtn} disabled:pointer-events-none disabled:cursor-default`}
            >
              Save manual edits
            </button>
            <button
              disabled={disableBtn}
              onClick={discardFunction}
              className={`${styles.discardBtn} disabled:pointer-events-none disabled:cursor-default`}
            >
              Discard edits
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EditAnalysisModal;
