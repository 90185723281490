import { useState, useMemo, useContext, useEffect } from 'react';
import { Button, CircularProgress, Modal } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import RoundForm from './RoundForm/index.jsx';
import './index.css';
import { stepperData } from '../StepperNewRound/stepperData.js';
import Stepper from '../Stepper/index.jsx';
import TextAreaDescription from './AccordianData/TextAreaDescription.jsx';
import Competitor from './Competitor/index.jsx';
import SearchSignals from './SearchSignal/index.jsx';
import SavedFoundations from './SearchSignal/savedFoundations.jsx';
import Analysis from './Analysis/index.jsx';
import { useForm } from 'react-hook-form';
import { OutpointContext } from '../../Context/OutpointsContext/Index.jsx';
import {
	RESET_ROUNDS_FORM,
	SET_API_LOADING,
	TEMP_FOUNDATION,
} from '../../Context/OutpointsContext/action.js';
import { useTheme } from '../../Context/ThemContext/ThemeContext.jsx';
import { ColorPattern } from '../../constant/Color.js';
import {
	createCompetitor,
	createFoundation,
	createPageUpdate,
	createRoundWithFoundation,
	createSteps,
	createTrackAndUpdate,
	getUserLimit,
	updateCompetitor,
	updatePageConfiguration,
	updateTrack,
} from '../../services/index.js';
import useSaveRoundsForm from '../../hooks/useSaveRoundsForm.js';
import CompetitionReview from './CompetitionReview';
import { toast } from 'react-toastify';
import TrackPage from './Track/index.jsx';
import { SubscriptionFeatures } from '../../constant/constants.js';
import UsageLimitModal from '../Common/UsageLimitModal/index.jsx';
import { SubscriptionContext } from '../../Context/SubscriptionContext';

const NewRound = ({
	setOpenDatapoints,
	setSuccessModal,
	textColor,
	handleCloseParentModal,
	setNewRound
}) => {
	const navigate = useNavigate();
	const {
		state: { roundsForm, apiLoading, temp_foundation },
		setCurrentStep,
		selectedStep,
		dispatch,
	} = useContext(OutpointContext);
	const { handleRoundsForm } = useSaveRoundsForm();
	const [openModal, setOpenModal] = useState(false);
	const [analysisLoading, setAnalysisLoading] = useState(false);
	const [pageLoading, setPageLoading] = useState(false);
	const [backLoading, setBackLoading] = useState(false);
	const [updatedData, setUpdatedData] = useState([]);
	const [roundID, setRoundID] = useState();
	const { fetchSubscriptionData } = useContext(SubscriptionContext);
	const steps = useMemo(() => {
		return stepperData;
	}, []);

	const handleMoveOneStep = () => {
		console.log('selectedStep.id', selectedStep.id);
		if (selectedStep.id < 4) {
			setCurrentStep((prev) => ({
				...prev,
				id: selectedStep.id + 1,
				label: stepperData[selectedStep.id + 1].label,
				value: stepperData[selectedStep.id + 1].value,
			}));
		}
		// else {
		// 	console.log("roundsForm.round_name", roundsForm.round_name);
		// 	navigate(`/round/${roundsForm.round_name}?round_id=${roundsForm.round_id}`);
		// }
	};
	const { colorMode } = useTheme();

	const handleMoveOneStepBack = async () => {
		setBackLoading(true);
		if (selectedStep.id > 0) {
			await createSteps(selectedStep.id, roundsForm.round_id);
			if (selectedStep.id == 3) {
				dispatch({
					type: RESET_ROUNDS_FORM,
					payload: {
						...roundsForm,
						pages: {},
					},
				});
			}
			if (selectedStep.id == 2) {
				dispatch({
					type: RESET_ROUNDS_FORM,
					payload: {
						...roundsForm,
						analysis: {},
					},
				});
			}
			setCurrentStep((prev) => ({
				...prev,
				id: selectedStep.id - 1,
				label: stepperData[selectedStep.id - 1].label,
				value: stepperData[selectedStep.id - 1].value,
			}));
		}
		setBackLoading(false);
	};

	const handleRoundFormPage = async () => {
		dispatch({ type: SET_API_LOADING, payload: true });
		if (selectedStep.id === 0) {
			let obj = { ...roundsForm.foundation };

			if (obj.foundation_mode == 3) {
				if (!temp_foundation?.foundation_id) {
					toast.info('Please select any one foundation');
					dispatch({ type: SET_API_LOADING, payload: false });

					return;
				}
				let data = await createRoundWithFoundation(
					temp_foundation?.foundation_id,
					roundsForm.round_id
				);
				console.log(data);
				if (data?.data) {
					toast.success(data?.message);
					handleRoundsForm(data?.data, 'round_id');
					handleRoundsForm(temp_foundation);
					dispatch({ type: TEMP_FOUNDATION, payload: {} });
					handleMoveOneStep();
				}

				dispatch({ type: SET_API_LOADING, payload: false });

				return;
			}
			if (obj.foundation_mode == 1) {
				obj.description = null;
			} else {
				obj.url = null;
				obj.scrape_mode = null;
				obj.additional_details = null;
			}

			let data = await createFoundation(obj, true, roundsForm?.round_id);

			if (data?.data) {
				toast.success(data.message);
				handleRoundsForm(data.data);
				if (!roundsForm?.round_id) {
					handleRoundsForm({ round_id: data.round_id }, 'round_id');
				}

				handleMoveOneStep();
			} else {
				toast.error(data?.detail?.[0]?.msg);
			}
			dispatch({ type: SET_API_LOADING, payload: false });
			return;
		}
		if (selectedStep.id === 1) {
			let obj = { ...roundsForm.competitor };

			if (obj.competitor_mode == 1) {
				obj.description = null;
			} else {
				obj.url = null;
				obj.scrape_mode = null;
				obj.additional_details = null;
			}

			let data;
			if (roundsForm.competitor.competitor_id) {
				let id = roundsForm.competitor.competitor_id;
				let objData = roundsForm.competitor;
				// Extract the required fields
				const extractedData = {
					competitor_name: objData.competitor_name,
					competitor_mode: objData.competitor_mode,
					url: objData.url,
					scrape_mode: objData.scrape_mode,
					additional_details: objData.additional_details,
					description: objData.description,
				};

				data = await updateCompetitor(
					extractedData,
					id,
					roundsForm.foundation.foundation_id
				);
			} else {
				data = await createCompetitor(obj, roundsForm.foundation.foundation_id);
			}

			console.log(data);

			if (data?.data) {
				let res = await createSteps(3, roundsForm?.round_id);
				toast.success(data.message);
				handleRoundsForm(data.data);
				handleMoveOneStep();
			} else {
				toast.error(data?.detail?.[0]?.msg || 'something error occured');
			}
			dispatch({ type: SET_API_LOADING, payload: false });
			return;
		}
		if (selectedStep.id === 2) {
			let res = await createSteps(4, roundsForm.round_id);
			if (res?.detail?.[0]?.msg) {
				toast.error(data?.detail?.[0]?.msg);
				return;
			}
		}
		if (selectedStep.id === 3) {
			let obj = { ...roundsForm.pages };
			const analysis_sections = obj?.items?.map(
				({ analysis_section_id, visibility }, i) => ({
					analysis_section_id,
					display_order: i + 1,
					visibility,
				})
			);
			let res = await createPageUpdate(roundsForm.round_id, {
				analysis_sections,
			});

			let display_mode = obj.display_mode == 'dark';
			let newRes = await updatePageConfiguration(
				roundsForm.round_id,
				obj.page_navigation_display,
				obj.last_implemented_display,
				display_mode
			);
			if (res?.message) {
				await createSteps(5, roundsForm.round_id);
				toast.success(res.message);
			}
			console.log(res);
		}
		// if (selectedStep.id === 4) {
		//   let obj = {
		//     round_name: roundsForm.round_name,
		//     track_competitor: roundsForm.track_competitor,
		//     track_foundation: roundsForm.track_foundation,
		//     track_frequency: roundsForm.track_frequency,
		//     track_configuration_mode: 1,
		//   };

		//   if (obj.track_competitor == false) {
		//     obj.track_frequency = null;
		//   }
		//   let access = await getUserLimit(SubscriptionFeatures.ACTIVE_ROUNDS);
		//   if (access) {
		//     let data = await updateTrack(roundsForm.round_id, obj);
		//     if (data?.message) {
		//       setSuccessModal(true);
		//       setOpenDatapoints(false);
		//       navigate('round');
		//       return;
		//     } else {
		//       toast.error(data?.detail?.[0]?.msg);
		//     }

		//     dispatch({ type: SET_API_LOADING, payload: false });
		//     return;
		//   } else {
		//     setOpenModal(true);
		//   }
		// }
		if (selectedStep.id === 4) {
			try {
				console.log('Starting final step processing...');
				if (roundsForm.round_name?.length === 0) {
					toast.error("Round name is required");
					throw new Error("Round name is required");
				}
				let obj = {
					round_name: roundsForm.round_name,
					track_competitor: roundsForm.track_competitor,
					track_foundation: roundsForm.track_foundation,
					track_frequency: roundsForm.track_frequency,
					track_configuration_mode: roundsForm.track_configuration_mode,
					cron_expression: roundsForm.cron_expression,
				};

				if (obj.track_competitor === false) {
					obj.track_frequency = null;
				}

				let access = await getUserLimit(SubscriptionFeatures.ACTIVE_ROUNDS);

				if (!access) {
					setOpenModal(true);
					dispatch({ type: SET_API_LOADING, payload: false });
					return;
				}

				console.log('Checking track status:', window.trackEnabled);
				console.log('Track API handler exists:', !!window.handleTrackApiCall);

				const trackUpdateResponse = await updateTrack(roundsForm.round_id, obj);
				console.log('Track update response:', trackUpdateResponse);
				setNewRound(trackUpdateResponse?.data);
				if (window.trackEnabled && window.handleTrackApiCall) {
					try {
						console.log('Calling track API...');
						await window.handleTrackApiCall();
						console.log('Track API call successful');
					} catch (error) {
						console.error('Error calling track API:', error);
						toast.error('Failed to initialize tracking');
						dispatch({ type: SET_API_LOADING, payload: false });
						return;
					}
				}
				if (trackUpdateResponse?.message) {
					toast.success(trackUpdateResponse.message);
					setSuccessModal(true);
					setOpenDatapoints(false);
				} else {
					toast.error(
						trackUpdateResponse?.detail?.[0]?.msg ||
						'Failed to update tracking configuration'
					);
				}
			} catch (error) {
				console.error('Final step error:', error);
				toast.error('An error occurred while completing the process');
			}

			dispatch({ type: SET_API_LOADING, payload: false });

			return;
		}

		handleMoveOneStep();
		dispatch({ type: SET_API_LOADING, payload: false });
	};
	const handleUpdateData = (data) => {
		setUpdatedData(data);
	};

	const getButtonText = () => {
		return selectedStep.id === 4 ? 'Finish' : 'Confirm & Next';

	};

	const renderStepperContent = () => {
		switch (selectedStep.id) {
			case 0:
				return (
					<RoundForm
						inputId='foundationNameInput'
						isThird={true}
						RoundTitle='Enter details about your product or company by selecting one of the three input methods'
					/>
				);
			case 1:
				return (
					<Competitor
						handleCloseParentModal={handleCloseParentModal}
						handleRoundFormPage={handleRoundFormPage}
					/>
				);
			case 2:
				return (
					<Analysis
						analysisLoading={analysisLoading}
						setAnalysisLoading={setAnalysisLoading}
					/>
				);
			case 3:
				return (
					<CompetitionReview
						pageLoading={pageLoading}
						setPageLoading={setPageLoading}
						isShowActiveRoundsButton={false}
						handleUpdateNewData={handleUpdateData}
					/>
				);

			case 4:
				return (
					<TrackPage newData={updatedData} roundId={roundsForm.round_id} />
				);

			default:
				return 'TBD';
		}
	};

	return (
		<div className='new-round-container'>
			<div className='main'>
				<Stepper
					steps={steps}
					selectedStep={selectedStep}
					setCurrentStep={setCurrentStep}
				/>
			</div>
			{renderStepperContent()}
			<div className='buttonActions'>
				<Button
					variant='outlined'
					sx={{
						m: 0,
						color: selectedStep.id === 0 ? 'transparent' : '#0069E5',
						borderColor: selectedStep.id === 0 ? 'transparent' : '#0069E5',
						opacity: selectedStep.id === 0 ? 0.5 : 1,
					}}
					onClick={handleMoveOneStepBack}
					disabled={backLoading || selectedStep.id === 0}
					className='hover:scale-105'>
					{backLoading && (
						<CircularProgress
							sx={{
								color: ColorPattern[colorMode].blue,
								height: '10px !important',
								width: '10px !important',
							}}
						/>
					)}
					Back
				</Button>

				{!analysisLoading && !pageLoading && selectedStep.id + 1 !== 2 && (
					<Button
						id='createRoundNextStepBtn'
						disabled={apiLoading}
						variant='contained'
						sx={{
							m: 0,
							backgroundColor: ColorPattern[colorMode].blue,
							color: '#fff !important',
							textTransform: 'none',
							border: `0.5px solid ${ColorPattern[colorMode].blue}`,
						}}
						onClick={handleRoundFormPage}
						className='hover:scale-105'>
						{apiLoading && (
							<CircularProgress
								sx={{
									color: '#fff !important',
									height: '10px !important',
									width: '10px !important',
								}}
							/>
						)}
						{getButtonText()}
					</Button>
				)}
			</div>
			<Modal
				styles={'width:300px'}
				open={openModal}
				onClose={() => setOpenModal(false)}>
				<UsageLimitModal closeModal={() => setOpenModal(false)} />
			</Modal>
		</div>
	);
};

export default NewRound;
