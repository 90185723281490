export const SET_OUTPOINT_DATA = 'SET_OUTPOINT_DATA';
export const SET_OUTPOINT_LOADING = 'SET_OUTPOINT_LOADING';
export const SET_ROUNDS_FORM = 'SET_ROUNDS_FORM';
export const SET_API_LOADING = 'SET_API_LOADING';
export const UPDATE_ROUNDS_FORM = 'UPDATE_ROUNDS_FORM';
export const RESET_ROUNDS_FORM = 'RESET_ROUNDS_FORM';
export const TEMP_FOUNDATION = 'TEMP_FOUNDATION';
export const SET_FOUNDATION_ONLY = 'SET_FOUNDATION_ONLY';
export const SET_FOUNDATION_ONLY_RESET = 'SET_FOUNDATION_ONLY_RESET';
export const SET_PATH_NAME = 'SET_PATH_NAME';
export const SET_GRAPH_DATA = 'SET_GRAPH_DATA';
export const UPDATE_ROUNDS_DASHBOARD = 'UPDATE_ROUNDS_DASHBOARD';
export const UPDATE_FOUNDATIONS_DASHBOARD = 'UPDATE_FOUNDATIONS_DASHBOARD';
export const SET_FOUNDATION = 'SET_FOUNDATION';
export const SET_SINGLE_ROUND = 'SET_SINGLE_ROUND';
