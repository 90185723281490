import React from "react";
import styles from "./index.module.css";
import RegenerateImagge from "../../../../assets/icons/regenerate.svg";
import RegenerateImaggeWhite from "../../../../assets/icons/regenerate-white.svg";
import { CircularProgress } from "@mui/material";
const RegenerateButton = ({
  id,
  dark,
  title,
  handleRegenerate,
  whiteIcon,
  type,
  icon,
  loading,
  isOnly,  
}) => {  

  return (
    <>
      <div
        id={id}
        style={{ cursor: "pointer", pointerEvents: loading ? "none" : "auto" }}
        onClick={() => handleRegenerate(type, isOnly)}
        className={`${
          dark
            ? styles.arrowReloadHorizontalGroup
            : styles.arrowReloadHorizontalParentWhite
        } hover:scale-105 origin-bottom`}
      >
        {icon ? (
          icon
        ) : (
          <img
            className={styles.arrowReloadHorizontalIcon}
            alt=""
            src={whiteIcon ? RegenerateImaggeWhite : RegenerateImagge}
          />
        )}
        {loading && (
          <CircularProgress
            sx={{
              height: "10px !important",
              width: "10px !important",
              color: "white !important",
            }}
          />
        )}
        <div className={styles.regenerateAll}>{title}</div>
      </div>
    </>
  );
};

export default RegenerateButton;
