import React, { useState, useEffect, useContext } from "react";
import "../UpdateReportModal/index.css";
import { ArrowLeft02Icon, LeftToRightListTriangleIcon } from "@hugeicons/react";
import { CapProjectingIcon } from "@hugeicons/react";
import { getPreviewUpdate } from "../../../../services";
import { OutpointContext } from "../../../../Context/OutpointsContext/Index";
import Markdown from "react-markdown";
import LoadingPage from "../../../Common/Loading";
import { Modal } from "@mui/material";

const UpdateReportModal = ({ open, onClose, data }) => {
  const summarizedData = data?.summarized_update;
  const keyBenifitsData = data?.key_updates_in_analysis;
  return (
    <Modal open={open} onClose={onClose}>
      <div className="shadow-[0px_7px_15px_rgba(0,0,0,0.07)] relative w-[calc(100vw-100px)] max-w-7xl max-h-[calc(100vh-160px)]  top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 overflow-y-auto pb-8 pt-12 px-24 bg-[--dark-bg] rounded-lg">
        <button
          onClick={onClose}
          className="absolute top-8 right-8 cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="23"
            height="23"
            viewBox="0 0 23 23"
            fill="none"
          >
            <path
              d="M18.1415 4.77441L4.77344 18.1424M4.77344 4.77441L18.1415 18.1424"
              stroke="#797B7E"
              stroke-width="1.43229"
              stroke-linejoin="round"
            />
          </svg>
        </button>
        <div className=" flex flex-col gap-6 max-h-full">
          <div className="report-title">
            <h2>Detected Updates Report</h2>
          </div>
          <div className="report-summary-section">
            <div className="section-header">
              <div className="icon-container">
                <CapProjectingIcon
                  size={24}
                  color={"#7CA1CB"}
                  variant={"stroke"}
                />
              </div>
              <h3>Summary of Detected Updates</h3>
            </div>
            <div className="summary-content">
              <p>{summarizedData}</p>
            </div>
          </div>
          <div className="key-updates-section grow">
            <div className="section-header">
              <div className="icon-container">
                <LeftToRightListTriangleIcon
                  size={24}
                  color="#7CA1CB"
                  variant="duotone"
                />
              </div>
              <h3>Key Updates List</h3>
            </div>
            <div className="updates-content">
              {keyBenifitsData?.length > 0 ? (
                keyBenifitsData.map((update, index) => {
                  // const { title, content } = formatKeyUpdates(update);
                  return (
                    <div key={index} className="update-item">
                      {/* {title && <h4>{title}:</h4>} */}
                      <div className="update-content">
                        {/* {content.startsWith('- ') ? (
                                                        <ul>
                                                            {content.split('\n').map((item, i) => (
                                                                <li key={i}>{item.replace(/^- /, '')}</li>
                                                            ))}
                                                        </ul>
                                                    ) : ( */}
                        <Markdown>{update}</Markdown>
                        {/* )} */}
                      </div>
                    </div>
                  );
                })
              ) : (
                <p>No key updates available</p>
              )}
            </div>
          </div>
          <div className="modal-footer">
            <button className="back-button hover:scale-105 origin-right" onClick={onClose}>
              <span className="back-icon">
                <ArrowLeft02Icon
                  size={16}
                  color={"#ffffff"}
                  variant={"stroke"}
                />
              </span>
              Go Back
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default UpdateReportModal;
