import "./index.css";
import { useContext } from "react";
import { TextField } from "@mui/material";
import { OutpointContext } from "../../../Context/OutpointsContext/Index";
import useSaveRoundsForm from "../../../hooks/useSaveRoundsForm";
import { ColorPattern } from "../../../constant/Color";
import { useTheme } from "../../../Context/ThemContext/ThemeContext";
// import RemoveCircle from "../../../assets/icons/"; // Update with actual path
import RemoveCircle from "../../../assets/icons/clearbtn.svg";

const TextAreaDescription = () => {
  const { colorMode } = useTheme();
  const {
    state: {
      roundsForm,
      foundation_page,
      pathName,
    },
    selectedStep,
  } = useContext(OutpointContext);

  const onlyFoundation = pathName === "/foundations";
  const { handleRoundsForm } = useSaveRoundsForm();

  const handleChange = (type, event, remove = false) => {
    if (remove) {
      handleRoundsForm({
        description: "",
      });
    } else {
      handleRoundsForm({
        description: event.target.value,
      });
    }
  };

  return (
    <div className="titleContainer">
      <div className="title" style={{textAlign: 'center'}}>
        Description:
      </div>
      <div className="input_container">
        <textarea
          type="text"
          className="primaryTextArea"
          style={{
            border: `1px solid ${ColorPattern[colorMode].borderColor}`,
          }}
          value={
            onlyFoundation
              ? foundation_page?.description
              : roundsForm[selectedStep.value]?.description || ""
          }
          onChange={(event) => handleChange("description", event)}
          placeholder="Describe your product/company, including key features, target market, and unique selling points..."
        />
        <img
          onClick={() => handleChange("description", null, true)}
          className="removeIcon"
          src={RemoveCircle}
          alt="Remove"
        />
      </div>
    </div>
  );
};

export default TextAreaDescription;