import React from "react";
import { useTheme } from "../../../Context/ThemContext/ThemeContext";

const AccessBlocked = ({ locked, source="Round" }) => {
  const { colorMode } = useTheme();
  return (
    <div
      className={
        locked
          ? `absolute w-full h-full flex flex-col p-4 ${
              colorMode == "dark" ? "bg-[#1e1e1e]/90" : "bg-neutral-100/90"
            } rounded-[5px] text- z-20 pointer-events-none inset-0 items-center justify-center gap-3`
          : "hidden"
      }
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="61"
        height="60"
        viewBox="0 0 61 60"
        fill="none"
      >
        <g id="locked">
          <path
            id="Vector"
            d="M6.75 20.4669C7.01015 15.2176 7.78843 11.9448 10.1166 9.6166C12.4448 7.28843 15.7176 6.51015 20.9669 6.25M54.25 20.4669C53.9898 15.2176 53.2115 11.9448 50.8835 9.6166C48.5553 7.28843 45.2822 6.51015 40.033 6.25M40.033 53.7499C45.2822 53.4897 48.5553 52.7114 50.8835 50.3834C53.2115 48.0552 53.9898 44.7822 54.25 39.5329M20.9669 53.7499C15.7176 53.4897 12.4448 52.7114 10.1166 50.3834C7.78843 48.0552 7.01015 44.7822 6.75 39.5329"
            stroke="#797B7E"
            stroke-width="3.75"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            id="Vector_2"
            d="M24.2436 26.3859C24.2436 24.2817 23.9132 21.0331 25.1837 19.1389C27.9899 14.9551 33.9436 15.5109 36.0436 19.5946C37.0744 21.5989 36.6859 24.4011 36.7336 26.3859M24.2436 26.3859C21.0003 26.3859 20.3397 28.2427 19.8437 29.6989C19.3858 31.3372 18.9197 35.2489 19.6337 39.5352C20.1679 42.2657 22.2556 43.4667 24.0511 43.6189C25.7681 43.7644 33.0179 43.7089 35.1166 43.7089C38.3689 43.7089 40.3996 42.9939 41.3536 39.7172C41.8116 37.1687 41.9369 32.6114 41.1736 29.6989C40.1626 26.7862 38.1244 26.3859 36.7336 26.3859M24.2436 26.3859C27.6776 26.2497 34.7704 26.2767 36.7336 26.3859"
            stroke="#797B7E"
            stroke-width="3.75"
            stroke-linecap="round"
          />
        </g>
      </svg>
      <h1
        className={`text-center ${
          colorMode == "dark" ? "text-[#797B7E]" : "text-[#1e1e1e]"
        } text-sm font-semibold tracking-tight`}
      >
        Access Restricted: {source} Limit Reached on Current Plan
      </h1>
      <p
        className={`text-center ${
          colorMode == "dark" ? "text-[#797B7E]" : "text-[#141b34]"
        } text-xs font-normal `}
      >
        Upgrade your subscription to restore full access
      </p>
    </div>
  );
};

export default AccessBlocked;
