import { createContext, useState, useContext, useEffect } from 'react';

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
	const [colorMode, setColorMode] = useState('dark');

	useEffect(() => {
		const savedMode = localStorage.getItem('theme') || 'dark';
		setColorMode(savedMode);
		document.body.classList.add(savedMode);
	}, []);

	useEffect(() => {
		document.body.classList.remove(colorMode === 'light' ? 'dark' : 'light');
		document.body.classList.add(colorMode);
		localStorage.setItem('theme', colorMode);
	}, [colorMode]);

	const toggleColorMode = () => {
		setColorMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
	};

	return (
		<ThemeContext.Provider value={{ colorMode, toggleColorMode }}>
			{children}
		</ThemeContext.Provider>
	);
};

export const useTheme = () => useContext(ThemeContext);
