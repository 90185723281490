import React, { useEffect, useState, useContext, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import styles from './index.module.css';
import Add from '../../assets/icons/add.svg';
import SearchIcon from '../../assets/icons/search.svg';
import { OutpointContext } from '../../Context/OutpointsContext/Index';
import debounce from 'lodash/debounce';

const RoundsHeader = ({
	handleStepperModal,
	active,
	handleActive,
	goToDraftsData,
	searchTerm,
	setSearchTerm,
	setShowLoader,
}) => {
	const [selectFilter, setSelectFilter] = useState('Newest');
	const [SEOKeywords, setSEOKeywords] = useState('');
	const [metaDescription, setMetaDescription] = useState('');
	const [PageTitle, setPageTitle] = useState('');
	const [localSearchTerm, setLocalSearchTerm] = useState(searchTerm);
	const { getData } = useContext(OutpointContext);

	useEffect(() => {
		const storedSEOKeywords = localStorage.getItem('SEOKeywords');
		const storedMetaDescription = localStorage.getItem('metaDescription');
		const storedPageTitle = localStorage.getItem('PageTitle');

		setSEOKeywords(storedSEOKeywords);
		setMetaDescription(storedMetaDescription);
		setPageTitle(storedPageTitle);
	}, []);

	const handleSearch = useCallback(
		(value) => {
			const cleanedValue = value.trim();
			setSearchTerm(cleanedValue);
		},
		[setSearchTerm]
	);

	const debouncedSearch = useCallback(
		debounce((value) => {
			handleSearch(value);
		}, 300),
		[handleSearch]
	);

	useEffect(() => {
		return () => {
			debouncedSearch.cancel();
		};
	}, [debouncedSearch]);

	const handleSearchChange = (e) => {
		const value = e.target.value;
		setLocalSearchTerm(value);
		debouncedSearch(value);
	};
	const searchPlaceholder = active
		? 'Search Round Name'
		: 'Search Foundation Name';

	return (
		<div className={styles.frameParent}>
			<Helmet>
				<meta name='description' content={metaDescription} />
				<meta name='keywords' content={SEOKeywords} />
			</Helmet>

			<div className={styles.frameGroup}>
				<div className={styles.newestParentCont}>
					<select
						value={selectFilter}
						onChange={(e) => {
							setSelectFilter(e.target.value);
							setShowLoader(false);
							if (e.target.value === 'Newest') {
								getData(0);
							} else {
								getData(1);
							}
						}}
						className={styles.newestParent}>
						<option value='Newest' className={styles.newest}>
							Newest
						</option>
						<option value='Oldest' className={styles.newest}>
							Oldest
						</option>
					</select>
				</div>
				<div className={styles.search01Parent}>
					<img className={styles.search01Icon} alt='' src={SearchIcon} />
					<input
						type='text'
						placeholder={searchPlaceholder}
						className={styles.searchInput}
						value={localSearchTerm}
						onChange={handleSearchChange}
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								e.preventDefault();
							}
						}}
					/>
				</div>
			</div>
			<div className={styles.frameContainer}>
				{goToDraftsData && (
					<div
						style={{ cursor: 'pointer' }}
						onClick={handleActive}
						className={`${styles.viewActiveRoundsWrapper} hover:scale-105`}>
						<div className={styles.newest}>
							{!active ? 'View Active Rounds' : 'Go To Draft'}
						</div>
					</div>
				)}
				<button
					id='createRound'
					onClick={() => handleStepperModal(false, {}, true)}
					className={`${styles.add01Parent} hover:scale-105 cursor-pointer`}>
					<img className={styles.add01Icon} alt='add' src={Add} />
					<span className={styles.newest}>Create New Round</span>
				</button>
			</div>
		</div>
	);
};

export default RoundsHeader;
