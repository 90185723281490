import React from 'react';
import './index.css';
import { clearStorage } from '../../utils/Storage';
import { useClerk } from '@clerk/clerk-react';
import { setToken } from '../../Context/AuthContext/action';
import TimeOut from '../../assets/icons/blockers/security-check.svg';
import ArrowReload from '../../assets/icons/arrow-reload-horizontal.svg';
import Lottie from 'lottie-react';
import shieldAnimation from './shieldAnimation.json';

const SessionModal = ({ setIsSessionExpired, dispatch }) => {
	const { signOut } = useClerk();

	const handleSignOut = async () => {
		clearStorage();
		dispatch({ type: setToken, payload: '' });
		await signOut();
		setIsSessionExpired(false);
		console.log('@logout');
	};

	return (
		<div className='session-modal-overlay'>
			<div className='upgrade'>
				<div className='frame294'>
					<div className='frame258'>
						<div className='frame264'>
							<div className='frame262'>
								<div className='security-check'>
									<Lottie
										style={{
											height: '150px',
											width: '150px',
										}}
										animationData={shieldAnimation}
										loop={true}
									/>
								</div>
								<h2 className='session-timeout-title'>Session Timeout</h2>
								<p className='session-timeout-message'>
									For your account security, we have logged you out after long
									inactivity. Please log in again.
								</p>
							</div>
							<div className='frame56'>
								<button className='frame28' onClick={handleSignOut}>
									<div className='arrow-reload-horizontal'>
										<img src={ArrowReload} alt='Reload' />
									</div>
									<span className='log-in-again'>Log In Again</span>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SessionModal;
