import React from 'react';

const RoundArrowIcon = ({ fill = '#A0A8BE' }) => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.75 13.5L11.25 8.99992L6.75 4.5" stroke="#0069E5" stroke-width="1.5" stroke-miterlimit="16" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};

export default RoundArrowIcon;
