import React, { useState, useEffect } from "react";
import styles from "./index.module.css";
import AdditionalButton from "./AdditonalButton";
import { getAdditionalOptionsCommands } from "../../../../services";
import { CircularProgress } from "@mui/material";
import { PropertyAddIcon } from "@hugeicons/react";

const AdditionalOptions = ({
  setAdditionalModal,
  metaChecked,
  setMetaChecked,
  handleRegenerate,
  loading,
  showWarning,
  activeTab,
}) => {
  const [load, setLoad] = useState(true);
  const [commands, setCommands] = useState([]);
  let responseData = {};

  const getCommandsForAddtionalOptions = async () => {
    try {
      let section = "";      
      if (activeTab.includes("About")) {
        section = "about_foundation";
      }
      if (activeTab.includes("Key Distinction")) {
        section = "key_distinctions";
      }
      if (activeTab.includes("Benefits")) {
        section = "benefits_of_foundations";
      }
      if (activeTab.includes("Limitations")) {
        section = "limitations_of_competitor";
      }
      if (activeTab.includes("Competitor")) {
        section = "about_competitor";
      }
      // if (activeTab.includes("all")) {
      //   section = "all";
      // }

      if (!section) {
        section = "custom_sections";
      }

      responseData = await getAdditionalOptionsCommands(section);
      if (responseData?.data) setCommands(responseData?.data ?? []);
      setLoad(false);
    } catch (error) {
      setCommands([]);
    }
  };

  useEffect(() => {
    getCommandsForAddtionalOptions();
  }, [activeTab]);

  return (
    <>
      <div className={styles.frameParent}>
        <div className={styles.additionalInstructionsForReWrapper}>
          <div className={styles.additionalInstructionsFor + " flex gap-1"}>
            <PropertyAddIcon size={16} color={"#7CA1CB"} variant={"stroke"} />{" "}
            <strong>
              Add Additional Instructions to fine-tune the regeneration.
            </strong>
          </div>
          <div className={styles.or}>Select a command from below</div>
        </div>
        {load ? (
          <div className="w-full h-full flex items-center justify-center">
            <CircularProgress />
          </div>
        ) : (
          <div className={styles.quickCommandParent}>
            <div className={styles.frameContainer}>
              {commands?.length > 0 ? (
                commands?.map((command, index) => (
                  <div key={index} className={styles.checkmarkCircle01Parent+ ' hover:border-2'}>
                    <input
                    id={"cmd"+index}
                      type="radio"
                      name="radio-button"
                      onChange={() => {
                        setMetaChecked(command);
                      }}
                      checked={metaChecked.command_id == command.command_id}
                      className={styles.checkmarkCircle01Icon}
                    />
                    <img
                      src={`data:image/svg+xml;utf8,${encodeURIComponent(
                        command?.command_icon
                      )}`}
                    />
                    <label htmlFor={"cmd"+index} className={styles.enterCustomInstructions}>
                      {command.command_text}
                    </label>
                  </div>
                ))
              ) : (
                <div className="flex items-center justify-center w-full h-full text-[--dark-text] text-lg">
                  No commands available
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <AdditionalButton
        disabled={commands?.length == 0}
        setMetaChecked={setMetaChecked}
        setAdditionalModal={setAdditionalModal}
        loading={loading}
        handleRegenerate={handleRegenerate}
      />
    </>
  );
};

export default AdditionalOptions;
