export const ColorPattern = {
  light: {
    bgColor: "#F8F8F8",
    textColor: "#1E1E1E",
    borderColor: "rgba(0, 0, 0, 0.10)",
    borderBottom2: "#3F628B",
    sidebarBorder: 'rgba(255, 255, 255, 0)',
    boxShadow: '0px 18px 5px 0px rgba(0, 0, 0, 0.05), 0px 11px 4px 0px rgba(0, 0, 0, 0.02), 0px 6px 4px 0px rgba(0, 0, 0, 0.05), 0px 3px 3px 0px rgba(0, 0, 0, 0.03), 0px 1px 2px 0px rgba(0, 0, 0, 0.04)',
    blue: "#0069E5",
    featureText: '#3F628B',
    featureHeadText: '#14203C'
  },
  dark: {
    featureText: '#BDBDBD',
    featureHeadText: '#fff',
    bgColor: "black",
    textColor: "#e1e1e1",
    borderColor: "#474C5C",
    borderBottom2: "#3F628B",
    sidebarBorder: '#ffffff',
    boxShadow: '0px 44px 12px 0px rgba(255, 255, 255, 0.00), 0px 28px 11px 0px rgba(255, 255, 255, 0.01), 0px 16px 9px 0px rgba(255, 255, 255, 0.03), 0px 7px 7px 0px rgba(255, 255, 255, 0.05), 0px 2px 4px 0px rgba(255, 255, 255, 0.05)',
    blue: "#3D87DD"
  },
};
