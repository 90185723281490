const LightModeIcon = ({ fill = '#A0A8BE' }) => {
    return (
        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.0546875" y="0.347656" width="22.3" height="22.3043" rx="11.15" fill="#0069E5" />
            <path d="M14.8734 11.4992C14.8734 13.5288 13.228 15.1741 11.1984 15.1741C9.16878 15.1741 7.52344 13.5288 7.52344 11.4992C7.52344 9.46956 9.16878 7.82422 11.1984 7.82422C13.228 7.82422 14.8734 9.46956 14.8734 11.4992Z" fill="white" fill-opacity="0.54" stroke="white" stroke-width="1.10249" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M11.2015 4.14941C10.9242 4.39441 10.536 5.3989 11.2015 5.9869M11.2015 17.0118C11.4788 17.2568 11.867 18.2613 11.2015 18.8493M16.3996 6.30202C16.0302 6.27917 15.0454 6.71491 15.1003 7.60132M7.30451 15.3971C7.32737 15.7664 6.89162 16.7512 6.00522 16.6964M18.5514 11.4993C18.3064 11.222 17.3019 10.8338 16.7139 11.4993M5.68904 11.4993C5.44405 11.7767 4.43956 12.1649 3.85156 11.4993M16.3981 16.6967C16.421 16.3274 15.9852 15.3425 15.0988 15.3975M7.30302 7.60165C6.93368 7.6245 5.94887 7.18876 6.00372 6.30235" stroke="white" stroke-width="1.10249" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}

export default LightModeIcon;