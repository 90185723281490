import React, {
	useState,
	useRef,
	useEffect,
	useContext,
	useCallback,
	useMemo,
} from 'react';
import { debounce } from 'lodash';
import styles from './index.module.css';
import { Button, CircularProgress, Paper } from '@mui/material';
import DragIcon from '../../../assets/icons/drag-table.svg';
import EyeIcon from '../../../assets/icons/eye.svg';
import ViewOff from '../../../assets/icons/view-off-slash.svg';
import { OutpointContext } from '../../../Context/OutpointsContext/Index';
import {
	createPageUpdate,
	generatePage,
	getAnalysisSection,
	updatePageConfiguration,
} from '../../../services';
import useSaveRoundsForm from '../../../hooks/useSaveRoundsForm';
import Markdown from 'react-markdown';
import { ColorPattern } from '../../../constant/Color';
import { useTheme } from '../../../Context/ThemContext/ThemeContext';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { SET_SINGLE_ROUND } from '../../../Context/OutpointsContext/action';
import ReactMarkdown from 'react-markdown';

const buttonStyle = (isActive, isDark) => ({
	position: 'relative',
	marginTop: '0',
	padding: '4px 15px',
	fontSize: '12px',
	textTransform: 'none',
	fontWeight: '600',
	borderWidth: '1px !important',
	background: isDark ? '#1E1E1E !important' : '#fff !important',
	borderRadius: '5px 5px 0px 0px',
	borderTop: '1px solid rgba(30, 30, 30, 0.10) !important',
	borderRight: '1px solid rgba(30, 30, 30, 0.10) !important',
	borderLeft: '1px solid rgba(30, 30, 30, 0.10) !important',
	borderBottom: 'none !important',
	color: isDark ? '#FFFFFF' : '#1E1E1E',
});

const findClosestIndex = (array, index, condition) => {
	const length = array.length;
	if (condition(array[index])) return index;
	for (let i = 1; i < length; i++) {
		const leftIndex = index - i;
		const rightIndex = index + i;
		if (leftIndex >= 0 && condition(array[leftIndex])) return leftIndex;
		if (rightIndex < length && condition(array[rightIndex])) return rightIndex;
	}
	return -1;
};

const CompetitionReview = ({
	handleUpdateNewData,
	setPageLoading = () => { },
	pageLoading = false,
	isSingleRound,
	isShowActiveRoundsButton,
}) => {
	const { state, dispatch } = useContext(OutpointContext);
	const data = state?.singleRound?.page;
	const roundId = state?.singleRound?.round_id;
	const [isActiveRoundsPage, setIsActiveRoundsPage] = useState(false);
	const [isFormChange, setIsFormChange] = useState(false);
	const [sectionLoading, setSectionLoading] = useState(false);
	const [showLeftNav, setShowLeftNav] = useState(() => {
		const stored = localStorage.getItem('showLeftNav');
		return stored !== null ? JSON.parse(stored) : true;
	});
	const [lastUpdate, setLastUpdate] = useState(() => {
		const stored = localStorage.getItem('lastUpdate');
		return stored !== null ? JSON.parse(stored) : true;
	});
	const [editPage, setEditPage] = useState(false);
	const [items, setItems] = useState([]);
	const [activeIndex, setActiveIndex] = useState(0);
	const [dragEnd, setDragEnd] = useState(true);
	const [isActivetabdragged, setIsActivetabdragged] = useState(false);
	const [previewMode, setPreviewMode] = useState(() => {
		isSingleRound
			? state?.singleRound?.display_mode
				? 'dark'
				: 'light'
			: 'light'
		const stored = localStorage.getItem('previewMode');
		return stored !== null ? JSON.parse(stored) : true;
	});
	// const [previewMode, setPreviewMode] = useState(
	// 	isSingleRound
	// 		? state?.singleRound?.display_mode
	// 			? 'dark'
	// 			: 'light'
	// 		: 'light'
	// );
	const [lastUpdatedDate, setLastUpdatedDate] = useState(null);
	const { handleRoundsForm } = useSaveRoundsForm();
	const dragItem = useRef(null);
	const dragOverItem = useRef(null);
	const sectionRefs = useRef({});
	const mainContentRef = useRef(null);
	const { colorMode } = useTheme();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const paramsRoundId = queryParams.get('round_id');

	const initializeItems = useCallback((data) => {
		if (data && data.length > 0) {
			setItems(data);
			const firstVisibleIndex = data.findIndex((item) => item.visibility);
			setActiveIndex(firstVisibleIndex !== -1 ? firstVisibleIndex : 0);
		}
	}, []);

	useEffect(() => {
		const options = {
			root: mainContentRef.current,
			rootMargin: '0px',
			threshold: 0.5,
		};

		const observer = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					const index = parseInt(entry.target.getAttribute('data-index'));
					if (!isNaN(index)) {
						setActiveIndex(index);
					}
				}
			});
		}, options);

		const visibleItems = items.filter(
			(item) => item.visibility && hasContent(item)
		);

		visibleItems.forEach((_, index) => {
			if (sectionRefs.current[index]) {
				observer.observe(sectionRefs.current[index]);
			}
		});

		return () => {
			observer.disconnect();
		};
	}, [items, sectionRefs.current]);

	useEffect(() => {
		const handleScroll = () => {
			const scrollPosition = mainContentRef.current.scrollTop;
			const visibleSections = items.filter(
				(item) => item.visibility && hasContent(item)
			);
			const activeSection = visibleSections.find((section, index) => {
				const sectionTop = sectionRefs.current[index].offsetTop;
				const sectionBottom =
					sectionTop + sectionRefs.current[index].offsetHeight;
				return scrollPosition >= sectionTop && scrollPosition < sectionBottom;
			});
			if (activeSection) {
				const index = visibleSections.indexOf(activeSection);
				setActiveIndex(index);
			}
		};

		const mainContent = mainContentRef.current;
		if (mainContent) {
			mainContent.addEventListener('scroll', handleScroll);
		}

		return () => {
			if (mainContent) {
				mainContent.removeEventListener('scroll', handleScroll);
			}
		};
	}, [items]);

	useEffect(() => {
		localStorage.setItem('showLeftNav', JSON.stringify(showLeftNav));
	}, [showLeftNav]);

	useEffect(() => {
		localStorage.setItem('lastUpdate', JSON.stringify(lastUpdate));
	}, [lastUpdate]);
	useEffect(() => {
		localStorage.setItem('previewMode', JSON.stringify(previewMode));
	}, [previewMode]);

	const getPageData = async () => {
		setSectionLoading(true);
		setPageLoading(true);
		try {
			let result;
			result = await generatePage(
				isSingleRound
					? state?.singleRound?.round_id
					: state.roundsForm?.round_id
			);
			if (result?.data) {
				setLastUpdatedDate(result.last_updated);
				setItems(result.data);
				const firstVisibleIndex = result.data.findIndex(
					(item) => item.visibility
				);
				setActiveIndex(firstVisibleIndex !== -1 ? firstVisibleIndex : 0);
			}
		} catch (err) {
			console.error('Error fetching page data:', err);
		}
		setSectionLoading(false);
		setPageLoading(false);
	};

	useEffect(() => {
		getPageData();
	}, []);

	useEffect(() => {
		if (editPage) fetchData();
	}, [showLeftNav, lastUpdate, previewMode,]);

	const saveFormData = useCallback(async () => {
		try {
			handleRoundsForm(
				{
					items,
					page_navigation_display: showLeftNav,
					last_implemented_display: lastUpdate,
					display_mode: previewMode,
					last_updated: items.last_updated,
					foundation_name: items.foundation_name,
					competitor_name: items.competitor_name,
				},
				roundId
			);
		} catch (error) {
			console.error('Error saving form data:', error);
			toast.error('Failed to save changes. Please try again.');
		}
	}, [items, showLeftNav, lastUpdate, previewMode, handleRoundsForm, roundId]);

	const fetchData = async () => {
		if (paramsRoundId) {
			let recheckboxResponse = await updatePageConfiguration(
				paramsRoundId,
				showLeftNav,
				lastUpdate,
				previewMode !== 'light'
			);
			const oldData = state.singleRound;
			const data = {
				...oldData,
				page_navigation_display: showLeftNav,
				display_mode: previewMode !== 'light',
				last_implemented_display: lastUpdate,
			};
			dispatch({ type: SET_SINGLE_ROUND, payload: data });
			setEditPage(false);
			setShowLeftNav(recheckboxResponse.data.page_navigation_display);
			setLastUpdate(recheckboxResponse.data.last_implemented_display);
		}
	};
	const handleGetUpdatedData = async () => {
		try {
			let response = await getAnalysisSection(roundId);
			console.log('updated res', response);
		} catch (err) {
			console.log('erro in  getting updated data', err);
		}
	};
	const handleShowLeftNavCheckboxChange = () => {
		setIsActiveRoundsPage(true);
		setIsFormChange(true);
		setShowLeftNav((prev) => !prev);
		setEditPage(true);
		handleGetUpdatedData();
	};

	const handleShowLastUpdateCheckboxChange = () => {
		setIsFormChange(true);
		setIsActiveRoundsPage(true);
		setLastUpdate((prev) => !prev);
		setEditPage(true);
		handleGetUpdatedData();
	};

	const debouncedSaveFormData = useMemo(
		() => debounce(saveFormData, 500),
		[saveFormData]
	);

	useEffect(() => {
		debouncedSaveFormData();
		return () => debouncedSaveFormData.cancel();
	}, [debouncedSaveFormData, showLeftNav, lastUpdate]);

	useEffect(() => {
		// handleUpdateNewData(items)
	}, [items]);

	const onDiscardChanges = () => {
		getPageData();
		setShowLeftNav(
			state.roundsForm?.foundation?.page_navigation_display ?? true
		);
		setLastUpdate(
			state.roundsForm?.foundation?.last_implemented_display ?? true
		);
		setIsFormChange(false);
	};

	const onSavedChanges = async () => {
		setSectionLoading(true);
		setPageLoading(true);
		try {
			const storedShowLeftNav = JSON.parse(localStorage.getItem('showLeftNav'));
			const storedLastUpdate = JSON.parse(localStorage.getItem('lastUpdate'));
			const storedDisplayMode = JSON.parse(localStorage.getItem('previewMode'));
			let res = await createPageUpdate(paramsRoundId, {
				analysis_sections: items,
				showLeftNav: storedShowLeftNav,
				lastUpdate: storedLastUpdate,
				displayMode: storedDisplayMode,
			});
			if (res?.message) {
				await saveFormData();
				toast.success(res.message);
				await getPageData();
			}
		} catch (error) {
			console.error('Error saving changes:', error);
			toast.error('Failed to save changes. Please try again.');
		} finally {
			setPageLoading(false);
			setSectionLoading(false);
		}
	};

	const toggleSectionVisibility = (id) => {
		setItems((prevItems) => {
			const updatedItems = prevItems.map((item) =>
				item.analysis_section_id === id
					? { ...item, visibility: !item.visibility }
					: item
			);
			const currentIndex = prevItems.findIndex(
				(item) => item.analysis_section_id === id
			);
			if (
				currentIndex === activeIndex &&
				!updatedItems[currentIndex].visibility
			) {
				const nextVisibleIndex = findClosestIndex(
					updatedItems,
					currentIndex,
					(item) => item.visibility
				);
				setActiveIndex(
					nextVisibleIndex !== -1
						? nextVisibleIndex
						: updatedItems.findIndex((item) => item.visibility)
				);
			}
			setIsFormChange(true);
			setIsActiveRoundsPage(true);
			return updatedItems;
		});
	};

	// const handleDragStart = useCallback(
	//   (index) => {
	//     setIsActivetabdragged(true);
	//     setDragEnd(false);
	//     dragItem.current = index;
	//   },
	//   [activeIndex]
	// );

	// const handleDragEnter = useCallback(
	//   (index) => {
	//     let prevIndex = dragItem.current;
	//     dragOverItem.current = index;
	//     setItems((prevItems) => {
	//       const itemsCopy = [...prevItems];
	//       const draggedItem = itemsCopy[dragItem.current];
	//       const temp = itemsCopy[dragItem.current].display_order;
	//       itemsCopy[dragItem.current].display_order =
	//         itemsCopy[dragOverItem.current].display_order;
	//       itemsCopy[dragOverItem.current].display_order = temp;
	//       itemsCopy.splice(dragItem.current, 1);
	//       itemsCopy.splice(dragOverItem.current, 0, draggedItem);
	//       return itemsCopy;
	//     });

	//     setIsFormChange(true);
	//     setIsActiveRoundsPage(true);
	//     if (isActivetabdragged) {
	//       setActiveIndex(index);
	//     }
	//     if (index === activeIndex) {
	//       setActiveIndex(prevIndex);
	//     }
	//   },
	//   [isActivetabdragged, activeIndex]
	// );

	// const handleDragEnd = useCallback(() => {
	//   dragItem.current = null;
	//   dragOverItem.current = null;
	//   setDragEnd(true);
	//   setIsActivetabdragged(false);
	// }, []);

	const handleDragStart = useCallback(
		(index) => {
			if (index === activeIndex) {
				setIsActivetabdragged(true);
			}
			setDragEnd(false);
			dragItem.current = index;
		},
		[activeIndex]
	);

	const handleDragEnter = useCallback(
		(index) => {
			let prevIndex = dragItem.current;
			dragOverItem.current = index;
			setItems((prevItems) => {
				const itemsCopy = [...prevItems];
				const draggedItem = itemsCopy[dragItem.current];
				const temp = itemsCopy[dragItem.current].display_order;
				itemsCopy[dragItem.current].display_order =
					itemsCopy[dragOverItem.current].display_order;
				itemsCopy[dragOverItem.current].display_order = temp;
				itemsCopy.splice(dragItem.current, 1);
				itemsCopy.splice(dragOverItem.current, 0, draggedItem);
				dragItem.current = dragOverItem.current;
				return itemsCopy;
			});

			setIsFormChange(true);
			setIsActiveRoundsPage(true);
			if (isActivetabdragged) {
				setActiveIndex(index);
			}
			if (index === activeIndex) {
				setActiveIndex(prevIndex);
			}
		},
		[isActivetabdragged, activeIndex]
	);

	const handleDragEnd = useCallback(() => {
		dragItem.current = null;
		dragOverItem.current = null;
		setDragEnd(true);
		setIsActivetabdragged(false);
	}, []);

	const scrollToSection = (index) => {
		if (sectionRefs.current[index]) {
			sectionRefs.current[index].scrollIntoView({ behavior: 'smooth' });
		}
	};

	const sectionStyle = {
		alignSelf: 'stretch',
		padding: '20px',
		boxShadow:
			previewMode === 'dark'
				? '0 0 10px rgba(255, 255, 255, 0.1)'
				: '0 0 10px rgba(0, 0, 0, 0.1)',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
		margin: '10px 20px 20px 20px',
	};

	const hasContent = useCallback((section) => {
		if (section.section_name === 'Feature Comparison') {
			return (
				Array.isArray(section.section_description) &&
				section.section_description.length > 0
			);
		}
		return (
			typeof section.section_description === 'string' &&
			section.section_description.trim() !== ''
		);
	}, []);

	const truncateText = (text, maxLength = 35) => {
		if (text.length <= maxLength) return text;
		return `${text.substring(0, maxLength)}...`;
	};

	const renderSection = useCallback(
		(section, index) => {
			if (!section.visibility || !hasContent(section)) {
				return null;
			}
			if (section.section_name === 'Feature Comparison') {
				const features = section.section_description;
				const foundationTitle = section.foundation_name;
				const competitorTitle = section.competitor_name;

				return (
					<div
						ref={(el) => (sectionRefs.current[index] = el)}
						data-index={index}
						className={`${styles.competitionReviewWrapper_feature_comparison} ${previewMode === 'dark' ? styles.darkMode : styles.lightMode
							}`}
						style={sectionStyle}>
						<h2 className={styles.competitionReviewWrapper_comparison_title}>
							Feature Comparison: {foundationTitle || 'Foundation Name'} vs{' '}
							{competitorTitle || 'Competitor Name'}
						</h2>
						<div className={styles.competitionReviewWrapper_comparison_table}>
							<div
								className={`${styles.competitionReviewWrapper_table_header} ${previewMode === 'dark'
									? styles.darkHeader
									: styles.lightHeader
									}`}>
								<div className={styles.competitionReviewWrapper_header_cell}>
									Attributes
								</div>
								<div className={styles.competitionReviewWrapper_header_cell}>
									{foundationTitle || 'Foundation Name'}
								</div>
								<div className={styles.competitionReviewWrapper_header_cell}>
									{competitorTitle || 'Competitor Name'}
								</div>
							</div>
							{features.map((feature, idx) => (
								<div
									key={idx}
									className={`${styles.competitionReviewWrapper_table_row} ${previewMode === 'dark'
										? idx % 2 === 0
											? styles.darkRowEven
											: styles.darkRowOdd
										: idx % 2 === 0
											? styles.lightRowEven
											: styles.lightRowOdd
										}`}>
									<div className={styles.competitionReviewWrapper_row_cell}>
										{feature.attribute_data}
									</div>
									<div className={styles.competitionReviewWrapper_row_cell}>
										{feature.foundation_data === 'true'
											? 'Available'
											: feature.foundation_data === 'false'
												? 'Not Available'
												: feature.foundation_data}
									</div>
									<div className={styles.competitionReviewWrapper_row_cell}>
										{feature.competitor_data === 'true'
											? 'Available'
											: feature.competitor_data === 'false'
												? 'Not Available'
												: feature.competitor_data}
									</div>
								</div>
							))}
						</div>
					</div>
				);
			}

			// return (
			//   <div
			//     ref={(el) => (sectionRefs.current[index] = el)}
			//     data-index={index}
			//     style={sectionStyle}
			//   >
			//     <h4>{section.section_name}</h4>
			//     {typeof section.section_description === "string" ? (
			//       section.section_description ? (
			//         <Markdown style={{ lineHeight: '90px' }}>{section.section_description}</Markdown>
			//       ) : (
			//         <p>No Content Available</p>
			//       )
			//     ) : (
			//       <p>Invalid Content Format</p>
			//     )}
			//   </div>
			// );

			return (
				<div
					ref={(el) => (sectionRefs.current[index] = el)}
					data-index={index}
					style={sectionStyle}>
					<h4 style={{ fontWeight: 'bold' }}>{section.section_name}</h4>
					{typeof section.section_description === 'string' ? (
						section.section_description ? (
							<ReactMarkdown
								components={{
									p: ({ children }) => (
										<p style={{ lineHeight: '1.6' }}>{children}</p>
									),
									ul: ({ children }) => (
										<ul style={{ paddingLeft: '20px' }}>{children}</ul>
									),
									li: ({ children }) => (
										<li style={{ listStyleType: 'disc', marginLeft: '20px' }}>
											{children}
										</li>
									),
									strong: ({ children }) => (
										<strong style={{ fontWeight: 'bold' }}>{children}</strong>
									),
									// Add the link component styling
									a: ({ children, href }) => (
										<a
											href={href}
											style={{
												textDecoration: 'underline',
												transition: 'color 0.2s ease',
											}}
											className='hover:text-blue-500'
											target='_blank'
											rel='noopener noreferrer'>
											{children}
										</a>
									),
								}}>
								{section.section_description}
							</ReactMarkdown>
						) : (
							<p>No Content Available</p>
						)
					) : (
						<p>Invalid Content Format</p>
					)}
				</div>
			);
		},
		[previewMode, sectionStyle, hasContent]
	);

	const previewStyle = useMemo(
		() => ({
			backgroundColor: previewMode === 'dark' ? '#1E1E1E' : 'white',
			color: previewMode === 'dark' ? 'white' : 'black',
			padding: '20px',
			borderRadius: '5px',
			transition: 'all 0.3s ease',
			border:
				previewMode === 'dark'
					? '1px solid rgba(255, 255, 255, 0.1)'
					: '1px solid rgba(0, 0, 0, 0.1)',
		}),
		[previewMode]
	);

	const contentWrapperStyle = {
		display: 'flex',
		height: '380px',
	};

	const sidebarStyle = useMemo(
		() => ({
			overflowY: 'auto',
			paddingRight: '14px',
			borderLeft:
				previewMode === 'dark'
					? '1px solid rgba(255, 255, 255, 0.1)'
					: '1px solid rgba(0, 0, 0, 0.1)',
			display: showLeftNav ? 'block' : 'none',
		}),
		[previewMode, showLeftNav]
	);

	const mainContentStyle = useMemo(
		() => ({
			flex: 1,
			overflowY: 'auto',
			paddingLeft: showLeftNav ? '20px' : '20px',
		}),
		[showLeftNav]
	);

	// useEffect(() => {
	// 	const handleResize = () => {
	// 		const width = window.innerWidth;
	// 		if (width > 1024 && width < 1250) {
	// 			setShowLeftNav(false);
	// 		} else {
	// 			setShowLeftNav(true);
	// 		}
	// 	};

	// 	window.addEventListener('resize', handleResize);
	// 	handleResize();

	// 	return () => {
	// 		window.removeEventListener('resize', handleResize);
	// 	};
	// }, []);

	if (pageLoading || sectionLoading) {
		return (
			<div className='w-full h-full min-h-60 flex items-center justify-center'>
				<CircularProgress
					sx={{
						color: 'blue',
						height: '30px !important',
						width: '30px !important',
						position: 'relative',
					}}
				/>
			</div>
		);
	}

	return (
		<div
			id='competitionReview'
			className={`${styles.competitionReviewWrapper} ${colorMode === 'dark' ? styles.darkTheme : styles.lightTheme
				}`}>
			<div className={styles.competitionReviewWrapper__left}>
				<div>
					<h3>Comparison Page Preview</h3>
					<div>
						{['light', 'dark'].map((mode) => (
							<Button
								key={mode}
								variant='outlined'
								sx={buttonStyle(previewMode === mode, mode === 'dark')}
								onClick={() => {
									setPreviewMode(mode);
									setEditPage(true);
								}}
								className='hover:scale-105'>
								{mode === 'light' ? 'Bright' : 'Dark'}
							</Button>
						))}
					</div>
				</div>
				<Paper
					className={`${previewMode} ${styles.competitionReviewWrapper__content}`}
					style={previewStyle}>
					<div
						className={styles.competitionReviewWrapper__content__inner}
						style={contentWrapperStyle}>
						{showLeftNav && (
							<div
								className={styles.competitionReviewWrapper__content__left}
								style={sidebarStyle}>
								{items
									.filter((item) => item.visibility && hasContent(item))
									.map((item, idx) => (
										<a
											key={idx}
											className={`${styles.navLink} ${activeIndex === idx ? styles.active : ''
												}`}
											onClick={() => {
												setActiveIndex(idx);
												scrollToSection(idx);
											}}
											style={{
												color: previewMode === 'dark' ? 'white' : 'black',
											}}>
											{/* {item.section_name} */}
											{truncateText(item.section_name)}
										</a>
									))}
							</div>
						)}
						<div
							ref={mainContentRef}
							style={mainContentStyle}
							className={styles.competitionReviewWrapper__content__right}>
							{items
								.filter((item) => item.visibility && hasContent(item))
								.map((item, index) => (
									<div key={index}>{renderSection(item, index)}</div>
								))}
							{lastUpdate && lastUpdatedDate && (
								<div
									style={{
										marginTop: 'auto',
										padding: ' 0px 20px',
										color: previewMode === 'dark' ? '#ffffff80' : '#00000080',
										fontWeight: 'bolder',
									}}>
									<p>Last Updated: {lastUpdatedDate}</p>
								</div>
							)}
						</div>
					</div>
				</Paper>
			</div>
			<div className={styles.competitionReviewWrapper__right}>
				<h3 style={{ color: ColorPattern[colorMode].textColor }}>Customize:</h3>
				<div className={styles.competitionReviewWrapper__right__inner}>
					<ul>
						<li>Section Visibility</li>
						{items.filter(hasContent).map((item, index) => (
							<li
								key={index}
								draggable
								onDragStart={() => handleDragStart(index)}
								onDragEnter={() => handleDragEnter(index)}
								onDragEnd={handleDragEnd}
								onDragOver={(e) => e.preventDefault()}
								className={`${styles['draggable-item']} ${dragOverItem.current === index && !dragEnd
									? styles['drag-over']
									: ''
									}`}
								style={{ cursor: dragEnd ? 'grab ' : 'grabbing ' }}>
								<span className='flex'>
									<img
										style={{
											marginRight: '15px',
											cursor: 'grab',
										}}
										src={DragIcon}
										alt='drag'
									/>
									<img
										src={item.visibility ? EyeIcon : ViewOff}
										alt='hide'
										style={{
											cursor: 'pointer',
										}}
										onClick={() =>
											toggleSectionVisibility(item.analysis_section_id)
										}
									/>
								</span>
								<span
									style={{
										color: item.visibility ? '' : 'red',
										maxWidth: '25ch',
										overflow: 'hidden',
										whiteSpace: 'nowrap',
										textOverflow: 'ellipsis',
										display: 'inline-block',
										marginLeft: '20px',
									}}>
									{item.section_name}
								</span>
							</li>
						))}
					</ul>
				</div>
				<span key='showLeftNav'>
					<input
						onChange={() => handleShowLeftNavCheckboxChange()}
						checked={showLeftNav}
						id='showClickable'
						type='checkbox'
					/>
					<label htmlFor='showClickable'> Show clickable content list</label>
				</span>
				<span key='lastUpdate'>
					<input
						onChange={() => handleShowLastUpdateCheckboxChange()}
						checked={lastUpdate}
						id='showLastTime'
						type='checkbox'
					/>
					<label htmlFor='showLastTime'>
						Display last updated timestamp at page bottom
					</label>
				</span>
				{isShowActiveRoundsButton === true ? (
					isFormChange === true && isActiveRoundsPage === true ? (
						<div
							className={
								styles.competitionReviewWrapper_buttonAlignment_activeRound
							}>
							<button
								className={styles.competitionReviewWrapper_discardChanges}
								onClick={onDiscardChanges}>
								Discard Changes
							</button>
							<button
								className={styles.competitionReviewWrapper_savedChanges}
								onClick={onSavedChanges}>
								Save Changes
							</button>
						</div>
					) : (
						''
					)
				) : (
					''
				)}
			</div>
		</div>
	);
};

export default React.memo(CompetitionReview);
