import React from "react";
import styles from "./index.module.css";
import AiEraser from "../../../../../assets/icons/ai-eraser-blue.svg";
import RegenarateImage from "../../../../../assets/icons/regenerate-white.svg";
import { CircularProgress } from "@mui/material";
const AdditionalButton = ({
  setAdditionalModal,
  setMetaChecked,
  loading,
  handleRegenerate,
  disabled = false,
}) => {
  // console.log("AdditionalButton -> disabled", disabled);
  return (
    <div className={styles.frameParent}>
      <div
        onClick={() => setAdditionalModal(false)}
        className={`${styles.backWrapper} hover:scale-105`}
        style={{
          cursor: "pointer",
        }}
      >
        <div className={styles.back}>Back</div>
      </div>
      <div className={styles.frameGroup}>
        <button
          disabled={disabled}
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            setMetaChecked({ command_id: null, command_text: "" });
          }}
          className={`${styles.aiEraserParent} ${
            disabled ? "pointer-events-none opacity-55" : " hover:scale-105"
          }`}
        >
          <img className={styles.aiEraserIcon} alt="" src={AiEraser} />
          <div className={styles.back}>Clear All</div>
        </button>
        <button
          style={{
            pointerEvents: loading ? "none" : "auto",
            cursor: "pointer",
          }}
          disabled={disabled}
          onClick={handleRegenerate}
          className={`${styles.arrowReloadHorizontalParent} ${
            disabled ? "pointer-events-none opacity-55" : " hover:scale-105"
          }`}
        >
          <img className={styles.aiEraserIcon} alt="" src={RegenarateImage} />
          {loading && (
            <CircularProgress
              sx={{
                height: "10px !important",
                width: "10px !important",
                color: "white !important",
              }}
            />
          )}

          <div className={styles.back}>Regenerate Now</div>
        </button>
      </div>
    </div>
  );
};

export default AdditionalButton;
