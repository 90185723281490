import React from 'react';
import { Box, LinearProgress, Typography } from '@mui/material';
import { ColorPattern } from "../../../constant/Color";
import { useTheme } from "../../../Context/ThemContext/ThemeContext";
const ProgressItem = ({ label, value, max }) => {
    const isUnlimited = value === 0 && max === 0;
    const progress = isUnlimited ? 100 : (value / max) * 100;
    const { colorMode } = useTheme();

    return (
        <Box display="flex" alignItems="center" borderLeft='2px solid #d5d5d5' padding='12px'>
            <Box width="100%" mr={1} sx={{ borderRadius: '12px' }}>
                <LinearProgress
                    variant="determinate"
                    value={progress}
                    sx={{
                        background: 'transparent',
                        border: `0.5px solid ${ColorPattern[colorMode].borderColor}`,
                        borderRadius: '12px',
                        height: '8px',
                        '& .MuiLinearProgress-bar': {
                            backgroundColor: '#0069E5',
                        }
                    }}
                />
            </Box>
            <Box minWidth={50}>
                <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{
                        fontSize: '12px',
                        color: ColorPattern[colorMode].textColor,
                        fontWeight: '400',
                        border: 'transparent',
                    }}
                >
                    {isUnlimited ? 'Unlimited' : `${value} / ${max}`}
                </Typography>
            </Box>
        </Box>
    );
};

export default ProgressItem;