import { Modal, Grow } from "@mui/material";
import React from "react";
import styles from "./index.module.css";
import CloseIcon from "@mui/icons-material/Close";
import image from "../../../../assets/icons/rocket.svg";
import rocketLaunch from "./roketLaunch.json";
import Lottie from "lottie-react";
import { ColorPattern } from "../../../../constant/Color";
import { useTheme } from "../../../../Context/ThemContext/ThemeContext";
import { Alert02Icon } from "@hugeicons/react";

const TrackandUpdateModal = ({ open, closeModal, onClick }) => {
  const { colorMode } = useTheme();
  return (
    <Modal
      className={styles.modalContainer}
      open={open}
      onClose={closeModal}
      sx={{
        "& .MuiBackdrop-root": {
          background: ColorPattern[colorMode].bgColor,
          opacity: "0.8 !important",
        },
      }}
    >
      <Grow in={open}>
        <div className={styles.modalWrapper}>
          <button
            className={`${styles.closeIcon} hover:scale-105`}
            onClick={closeModal}
          >
            <CloseIcon />
          </button>
          <div className={styles.modalContentWrapper + " w-[600px]"}>
            <Lottie
              style={{
                height: "90px",
                width: "90px",
              }}
              animationData={rocketLaunch}
              loop={true}
            />
            <h2 className={styles.modalHeader}>Run a Track Job Now</h2>
            <p className={styles.modalContent}>
              A Track Job typically takes 5-10 minutes for Sharbo AI to check,
              sync, and analyze product and competitor data sources. You'll
              receive an email when the Track Job and New Analysis are ready for
              your review and approval.
            </p>
            <p className="flex gap-1.5">
              <span className="min-w-5">
                <Alert02Icon
                  size={18}
                  color="#FFAC0A"
                  variant="solid"
                  type="sharp"
                />
              </span>
              <span className="text-[--botton-span-text] text-sm font-normal font-['Inter'] tracking-tight leading-4">
                While a new Track Job is running, previous track job updates can
                no longer be applied
              </span>
            </p>
            <div className={styles.btnWrapper}>
              <button
                className={`${styles.cancelBtn} hover:scale-105`}
                onClick={closeModal}
              >
                Cancel
              </button>
              <button
                id="runTrackJob"
                className={`${styles.runBtn} hover:scale-105`}
                onClick={onClick}
              >
                Run Track Job Now
              </button>
            </div>
          </div>
        </div>
      </Grow>
    </Modal>
  );
};

export default TrackandUpdateModal;
