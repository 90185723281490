import React, { useState, useEffect, useCallback, useContext } from 'react';
import './index.css';
import ProgressItem from '../ProgressItem';
import { getCurrentSubscriptions } from '../../../services';
import StarterImage from '../../../assets/icons/Subscriptions/rocket-starter.svg';
import AdvancedImage from '../../../assets/icons/Subscriptions/advanced.svg';
import ElevateImage from '../../../assets/icons/Subscriptions/elevate.svg';
import UltraImage from '../../../assets/icons/Subscriptions/ultra.svg';
import EnterpriseImage from '../../../assets/icons/Subscriptions/enterprise.svg';
import { Link } from 'react-router-dom';
import { useTheme } from '../../../Context/ThemContext/ThemeContext';
import { SubscriptionContext } from '../../../Context/SubscriptionContext';

const PlanUsage = () => {
	const [subscriptionData, setSubscriptionData] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const { colorMode } = useTheme();
	const { subscriptionUpdatedData } = useContext(SubscriptionContext);
	const planImages = {
		Starter: StarterImage,
		Advanced: AdvancedImage,
		Elevate: ElevateImage,
		Ultra: UltraImage,
		Enterprise: EnterpriseImage,
	};
	const fetchData = useCallback(async () => {
		const returnUrl = window.location.href;
		try {
			const result = await getCurrentSubscriptions(returnUrl);
			if (result.message === 'Current subscription found') {
				setSubscriptionData(result.data);
			}
		} catch (err) {
			setError(' ');
			console.error('Fetch error:', err);
		} finally {
			setLoading(false);
		}
	}, []);
	useEffect(() => {
		fetchData();
		const handleCustomUpdate = () => {
			fetchData();
		};
		window.addEventListener('subscription-updated', handleCustomUpdate);
		window.addEventListener('card-deleted', handleCustomUpdate);
		window.addEventListener('plan-changed', handleCustomUpdate);
		window.addEventListener('subscription-deleted', handleCustomUpdate);
		window.addEventListener('active-round-created', handleCustomUpdate);

		return () => {
			window.removeEventListener('subscription-updated', handleCustomUpdate);
			window.removeEventListener('card-deleted', handleCustomUpdate);
			window.removeEventListener('plan-changed', handleCustomUpdate);
			window.removeEventListener('subscription-deleted', handleCustomUpdate);
			window.removeEventListener('active-round-created', handleCustomUpdate);
		};
	}, [fetchData, subscriptionUpdatedData]);

	if (!subscriptionData) return <div> </div>;

	const progressItems = Object.entries(subscriptionData.features).map(
		([label, [value, max]]) => ({
			label,
			value,
			max,
		})
	);

	return (
		<Link to='/settings?tab=subscription'>
			<div className={`plan-usage-container ${colorMode}`}>
				<div className='plan-usage-header'>
					<div className='plan-usage-title'>Plan Usage</div>
					<div className='plan-usage-advanced'>
						<img
							className='icon-solar-system'
							src={planImages[subscriptionData.current_tier] || StarterImage}
							alt={subscriptionData.current_tier}
							style={{ width: '20px', height: '20px', margin: '0px 6px' }}
						/>
						<div className='advanced-text'>{subscriptionData.current_tier}</div>
					</div>
				</div>
				{progressItems.map((item, index) => (
					<ProgressItem
						key={index}
						label={item.label}
						value={item.value}
						max={item.max}
					/>
				))}
			</div>
		</Link>
	);
};

export default PlanUsage;
