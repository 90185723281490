import { useEffect } from "react";
import * as Sentry from "@sentry/react";

Sentry.init({
    dsn: "https://c8c90c1dcf3eeff2b1dbe26abed31ce4@o4508047158673408.ingest.us.sentry.io/4508166350962688",
    integrations: [
    Sentry.replayIntegration(),
    Sentry.feedbackIntegration({
      // Additional SDK configuration goes in here, for example:
      colorScheme: "system",
      isEmailRequired: true,
    }),
  ],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});
