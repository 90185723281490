import React, {
	useContext,
	useState,
	useEffect,
	forwardRef,
	useImperativeHandle,
} from 'react';
import {
	Box,
	Select,
	MenuItem,
	Radio,
	RadioGroup,
	FormControlLabel,
	FormControl,
	Typography,
	Tooltip,
} from '@mui/material';
import SearchSvg from '../../../assets/icons/search-01.svg';
import InfoIcon from '../../../assets/icons/alert-circle.svg';
import LoadingSvg from '../../../assets/icons/loading-03.svg';
import LoadingLightSvg from '../../../assets/icons/loading-03-light.svg';
import { OutpointContext } from '../../../Context/OutpointsContext/Index';
import {
	generateCompetitors,
	getCompetitorByFoundationId,
} from '../../../services/index';
import { getOutpoint } from '../../../services/index';
import useSaveRoundsForm from '../../../hooks/useSaveRoundsForm';
import { TEMP_FOUNDATION } from '../../../Context/OutpointsContext/action';
import LoadingPage from '../../Common/Loading';
import { ColorPattern } from '../../../constant/Color';
import { useTheme } from '../../../Context/ThemContext/ThemeContext';
import { GroupItemsIcon } from '@hugeicons/react';
import { ArrowDropDown, Info } from '@mui/icons-material';
import PieChart from './CustomPercentage';

/**
 * Component is reused thrice in the NewRound flow:
 * 1. Foundation => Select Saved Foundation
 * 2. Competitor
 *    2.1 Select Saved Competitor
 *    2.2 Select Generated Competitor
 *
 * props for SAVED FOUNDATION -
 *   -  getFoundationData
 *   -  foundationLoading
 *   -  foundationData
 *   -  onSavedFoundation
 *
 * props for Saved Competitor -
 *    onCompetitorModel3 - boolean to show/hide note
 *    headingText = false - text for heading
 *
 * props for Generated Competitor -
 *    searchSignals - search signals for competitor
 *    notShowSelect = true - boolean to show/hide select dropdown
 *    headingText = true - text for heading
 *
 * props common for both Competitor cases:
 * 1. refreshCompetitor: state to refresh the competitor search
 * 2. showInput: boolean to show/hide search input
 * 3. currentModal
 * 4. showHeading: boolean to show/hide heading
 * 5. containerWidth = different width for different containers
 * 6. ref
 * **/

const SelectCompetitor = forwardRef(
	(
		{
			showMore,
			refreshCompetitor,
			showInput,
			showHeading,
			onSavedFoundation,
			onCompetitorModel3,
			searchSignals,
			foundationData,
			foundationLoading,
			currentModal,
			textColor,
			showHeaderText,
			bgColor,
			getFoundationData,
			notShowSelect,
		},
		innerRef
	) => {
		const {
			state: { roundsForm },
		} = useContext(OutpointContext);

		const [selectedFilter, setSelectedFilter] = useState('Newest');
		const [competitors, setCompetitors] = useState([]);
		const [loading, setLoading] = useState(false);
		const [searchTerm, setSearchTerm] = useState('');

		const { handleRoundsForm } = useSaveRoundsForm();

		const { state, selectedStep, dispatch } = useContext(OutpointContext);
		const [selectedRadio, setSelectedRadio] = useState(null);

		const { colorMode } = useTheme();

		const fetchCompetitors = async (loadMore = false) => {
			setLoading(true);
			const result = await generateCompetitors(
				roundsForm.foundation.foundation_id,
				searchSignals,
				loadMore
			);
			setCompetitors(result.data || []);
			setLoading(false);
		};

		useEffect(() => {
			if (
				selectedStep.value === 'competitor' &&
				refreshCompetitor &&
				currentModal === 2
			) {
				fetchCompetitors();
			}
			if (currentModal === 3) {
				const fetchCompetitors = async () => {
					setLoading(true);
					let value = 0;
					console.log(value);
					if (selectedFilter == 'Newest') {
						value = 0;
					} else {
						value = 1;
					}
					console.log(value);
					const result = await getCompetitorByFoundationId(
						roundsForm.foundation.foundation_id,
						value
					);
					setCompetitors(result.data || []);
					setLoading(false);
				};

				fetchCompetitors();
			}
		}, [refreshCompetitor, selectedFilter]);

		const handleRadioChange = (value) => {
			setSelectedRadio(value);
			let data = {};

			if (selectedStep.value === 'foundation') {
				data = foundationData.find((item) => item.foundation_id == value);
				dispatch({
					type: TEMP_FOUNDATION,
					payload: data,
				});
			} else if (selectedStep.value === 'competitor') {
				data = competitors.find((item) => item.competitor_id == value);
				handleRoundsForm(data);
			}
		};

		useImperativeHandle(innerRef, () => ({
			getSelectValue: () => {
				return selectedRadio;
			},
		}));

		const getFilteredData = () => {
			if (selectedStep.value === 'foundation') {
				return foundationData?.filter((item) =>
					item.foundation_name.toLowerCase().includes(searchTerm.toLowerCase())
				);
			} else {
				return competitors?.filter((item) =>
					item.competitor_name.toLowerCase().includes(searchTerm.toLowerCase())
				);
			}
		};

		const filteredData = getFilteredData();

		return (
			<div
				style={{
					// For Saved Foundation table
					paddingTop: onSavedFoundation ? '0px' : '',
					width: !showHeaderText ? '100%' : '60%',
				}}
				className={`signal-container signal-container--select-competitor ${
					// For Saved Competitors table
					'h-[95%] !max-h-none'
					}`}>
				{showHeading && (
					<p
						className='signal-title block'
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'flex-start',
						}}>
						<span style={{ marginRight: '6px' }}>
							{' '}
							<GroupItemsIcon size={16} color={'#7CA1CB'} variant={'stroke'} />
						</span>
						Select a Competitor for Analysis
					</p>
				)}
				<div
					style={{
						height: 'calc(100% - 30px)',
						width: showHeaderText ? '100% !important' : '80% !important',
						// maxWidth: showHeading ? '1000px' : '750px',
					}}
					className={'titleContainer'}>
					{/* Only in the Saved Competitors section */}
					{onCompetitorModel3 && (
						<p className='text-xs min-w-max mr-5 mt-2.5 font-normal text-[#797b7e]'>
							<span
								className='font-medium inline-block mr-1'
								style={{ color: bgColor }}>
								<img
									className='inline-block mr-2.5'
									src={InfoIcon}
									alt={InfoIcon}
								/>
								Note:{' '}
							</span>
							Select a competitor
						</p>
					)}
					<Box
						sx={{
							display: onSavedFoundation ? 'block' : 'flex',
							flexDirection: 'column',
							alignItems: 'flex-start',
							justifyContent: 'flex-start',
							padding: onSavedFoundation
								? '10px 10px 0px 10px'
								: '10px 10px 10px 10px',
							width: onCompetitorModel3 ? 'calc(100% - 200px)' : '100%',
							// margin: "10px 0 0",
							borderRadius: '5px',
							border: `1px solid ${ColorPattern[colorMode].borderColor}`,
							background: 'transparent',
							height:
								onCompetitorModel3 || onSavedFoundation
									? 'calc(100% - 30px)'
									: '95%',
							// overflow: 'auto'
						}}>
						{/* Header above table => sort dropdown + search input */}
						<div className='w-full'>
							{/* Sorting dropdown */}
							{!notShowSelect && (
								<FormControl
									fullWidth
									className='signal-container-input-left'
									sx={{
										mb: 2,
										display: 'inline-block',
										width: 'auto',
										height: '36px',
									}}>
									<Select
										labelId='filter-select-label'
										value={selectedFilter}
										IconComponent={(props) => (
											<ArrowDropDown
												{...props}
												style={{
													color: colorMode === 'dark' ? 'white' : 'grey',
												}}
											/>
										)}
										onChange={(e) => {
											setSelectedFilter(e.target.value);
											if (selectedStep.value === 'foundation') {
												if (e.target.value == 'Newest') {
													getFoundationData(0);
												} else {
													getFoundationData(1);
												}
											}
										}}
										sx={{ width: '120px', height: '40px', float: 'right' }}>
										<MenuItem value='Newest'>Newest</MenuItem>
										<MenuItem value='Oldest'>Oldest</MenuItem>
									</Select>
								</FormControl>
							)}
							{/* Search competitor input */}
							{showInput && (
								<div
									className='signal-container--select-competitor-input-left'
									style={{ display: 'inline-block', position: 'relative' }}>
									<input
										style={{ height: '36px' }}
										className='primary-input'
										placeholder={`Search ${selectedStep.value === 'foundation'
											? 'Foundation'
											: 'Competitor'
											} Name`}
										value={searchTerm}
										onChange={(e) => setSearchTerm(e.target.value)}
									/>
									<img src={SearchSvg} />
								</div>
							)}
						</div>

						<FormControl
							style={{
								maxHeight: onSavedFoundation ? '179px' : '',
								width: '100%',
								overflowX: 'hidden',
								height: onCompetitorModel3 || onSavedFoundation ? '' : '90%',
							}}
							component='fieldset'
							sx={{
								'&::-webkit-scrollbar': {
									width: '8px',
								},
								'&::-webkit-scrollbar-track': {
									background: '#f1f1f1',
									borderRadius: '10px',
								},
								'&::-webkit-scrollbar-thumb': {
									background: '#888',
									borderRadius: '10px',
								},
								'&::-webkit-scrollbar-thumb:hover': {
									background: '#555',
								},
								scrollbarWidth: 'thin',
								scrollbarColor: ' #f1f1f1',
							}}>
							{showHeaderText && (
								<div
									className='flex flex-col flex-wrap border-0 box-border mt-2'
									style={{
										color: `${ColorPattern[colorMode].textColor}`,
									}}>
									<label className='inline-flex items-center align-middle m-0'>
										<span className='block min-w-[46px] h-[42px]' />
										<span className='w-full '>
											<div
												className={`signal-container__left-table-item ${colorMode} heading-row ${onSavedFoundation ? 'onsavedFound' : ''
													} ${onCompetitorModel3 ? 'onCompetitorModel3' : ''}`}
												style={{
													border: 'none',
												}}>
												<div className='!border-0 competitior__name min-w-[64px]'>
													Competitor
												</div>
												<div className='!border-0 competitior__website min-w-[142px]'>
													Website
												</div>
												<div
													className='competitior__progress !border-0 scrollbar-none'
													title='Strategic Proximity Metric'>
													<div className='percent_piechart'>
														Affinity
														{/* <Tooltip title='Strategic Proximity Metric'>
															<img
																src={InfoIcon}
																alt=''
																style={{ marginLeft: '8px' }}
															/>
														</Tooltip> */}
													</div>
													<div className='spm-heading'>
														Affinity
													</div>
												</div>
											</div>
										</span>
									</label>
								</div>
							)}
							<RadioGroup
								className='signal-container--select-competitor-left-radio'
								aria-label='options'
								name='radio-buttons-group'
								value={selectedRadio}
								onChange={(e) => handleRadioChange(e.target.value)}
								sx={{ marginBottom: 10 }}>
								{selectedStep.value === 'foundation' &&
									(foundationLoading ? (
										<div style={{ padding: '200px', height: '100vh' }}>
											{' '}
											<LoadingPage />
										</div>
									) : // <LoadingPage />
										filteredData?.length > 0 ? (
											filteredData.map((e) => (
												<FormControlLabel
													key={e.foundation_id}
													value={e.foundation_id}
													control={<Radio />}
													style={{
														marginBottom: '18px',
														marginLeft: '-5px',
														marginRight: '0px',
														color: ColorPattern[colorMode].textColor,
													}}
													label={
														<div
															className={`signal-container__left-table-item ${colorMode} ${onSavedFoundation ? 'onsavedFound' : ''
																} ${onCompetitorModel3 ? 'onCompetitorModel3' : ''
																}`}>
															{/* <div
																title={`${e.competitor_name}`}
																className='competitior__name'>
																{e.foundation_name}
															</div> */}
															<div
																title={`${e.competitor_name}`}
																className='competitior__name'
																style={{
																	whiteSpace: 'nowrap',
																	overflow: 'hidden',
																	textOverflow: 'ellipsis',
																	maxWidth: '150px'
																}}>
																{e.foundation_name}
															</div>
															<div
																title={`${e.competitior__website}`}
																className='competitior__website'>
																{e?.url?.[0]}
															</div>
														</div>
													}
												/>
											))
										) : (
											<div
												style={{
													textAlign: 'center',
													padding: '20px',
													color: ColorPattern[colorMode].textColor,
												}}>
												No data found
											</div>
										))}
								{selectedStep.value === 'competitor' &&
									(loading ? (
										<div
											style={{
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
											}}>
											<LoadingPage />
										</div>
									) : filteredData?.length > 0 ? (
										filteredData.map((e) => (
											<FormControlLabel
												key={e.competitor_id}
												value={e.competitor_id}
												control={<Radio />}
												style={{
													marginBottom: '12px',
													marginLeft: '0px',
													marginRight: '0px',
													height: '40px',
													width: '100%',
												}}
												label={
													<div
														className={`signal-container__left-table-item ${colorMode} ${onSavedFoundation ? 'onsavedFound' : ''
															} ${onCompetitorModel3 ? 'onCompetitorModel3' : ''
															}`}>
														<div
															title={`${e.competitor_name}`}
															className='competitior__name'>
															{e.competitor_name}
														</div>
														<div
															title={`${e.competitior__website}`}
															className='competitior__website'>
															{e?.url?.[0]}
														</div>
														{/*  */}
														{!onCompetitorModel3 && (
															<div className='competitior__progress'>
																<div className='percent_piechart'>
																	<PieChart percentage={60} />
																</div>
																<div
																	style={{ width: e.proximity + '%' }}
																	className='competitior__progress-percent'></div>

																<span>{e.proximity}%</span>
															</div>
														)}
													</div>
												}
											/>
										))
									) : (
										<div
											style={{
												textAlign: 'center',
												padding: '20px',
												color: ColorPattern[colorMode].textColor,
											}}>
											No data found
										</div>
									))}
							</RadioGroup>
						</FormControl>
						{showMore && (
							<button
								onClick={() => fetchCompetitors(true)}
								className='signal-container--select-competitor__load_more hover:scale-105'
								style={{
									border: `1px solid ${ColorPattern[colorMode].borderColor}`,
								}}>
								Load More
								<img
									style={{
										width: '20px',
										height: '20px',
									}}
									src={colorMode == 'light' ? LoadingSvg : LoadingLightSvg}
								/>
							</button>
						)}
					</Box>
				</div>
			</div>
		);
	}
);

export default SelectCompetitor;
