import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
} from "react-router-dom";
import Login from "../../Pages/Login";
import AnalysisPage from "../../Pages/AnalysisPage";

// Define a Root component
const Root = () => {
  return (
    <>
      <Outlet />
    </>
  );
};
// Create PublicRoutes
export const PublicRoutes = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<Root />}>
        <Route index element={<Login />} />
      </Route>
      <Route path="/analysis" element={<AnalysisPage />} />
    </>
  )
);
