import React from "react";
import {SignInButton } from "@clerk/clerk-react";
import "./index.css";

const Login = () => {
  return (
    <div className="login-container">
      <div className="sign-in-container">
        <SignInButton className="clerkButton" signUpUrl={import.meta.env.CLERK_SIGN_IN_URL} />
      </div>
    </div>
  );
};
export default Login;
