import { Modal, Grow } from "@mui/material";
import React from "react";
import styles from "./index.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { ColorPattern } from "../../../../constant/Color";
import { useTheme } from "../../../../Context/ThemContext/ThemeContext";
import Lottie from "lottie-react";
import checkAnimation from "./checkAnimation.json";
import { Link } from "react-router-dom";
const TrackRunningModal = ({ open, closeModal }) => {
  const { colorMode } = useTheme();
  return (
    <Modal
      className={styles.modalContainer}
      open={open}
      onClose={closeModal}
      sx={{
        "& .MuiBackdrop-root": {
          background: ColorPattern[colorMode].bgColor,
          opacity: "0.8 !important",
        },
      }}
    >
      <Grow in={open}>
        <div className={styles.modalWrapper}>
          <button
            className={`${styles.closeIcon} hover:scale-105`}
            onClick={closeModal}
          >
            <CloseIcon />
          </button>
          <div className={styles.modalContentWrapper + " w-[600px]"}>
            <Lottie
              style={{
                height: "150px",
                width: "150px",
              }}
              animationData={checkAnimation}
              loop={false}
            />
            <h2 className={styles.modalHeader}>
              Track Job has started running
            </h2>
            <p className={styles.modalContent}>
              The new Track Job will appear in Tracking History shortly. You’ll
              receive an email once it’s complete.
            </p>

            <Link to='/' className={styles.btnWrapper + " w-auto"}>
              <button
                className={`${styles.cancelBtn} hover:scale-105`}
                onClick={closeModal}
              >
                Go to Main Dashboard
              </button>
            </Link>
          </div>
        </div>
      </Grow>
    </Modal>
  );
};

export default TrackRunningModal;
