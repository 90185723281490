import { createContext, useEffect, useReducer, useState } from "react";
import { SET_ANALYSIS_META_DATA, setLoading, setToken } from "./action";
import { getAccessToken, setAccessToken } from "../../utils/Storage";
import { useSession, useAuth } from "@clerk/clerk-react";
import { jwtDecode } from "jwt-decode";
import { getAnalysisQuickCommands, verifySession } from "../../services";
import SessionModal from "../../Components/SessionModal";
import { CircularProgress } from "@mui/material";
export const AuthContext = createContext();
const initialState = {
  token: localStorage.getItem("token") || "",
  loading: false,
  colorMode: "dark",
  analysisMetaData: {},
};
const reducer = (state, action) => {
  switch (action.type) {
    case setLoading: {
      return { ...state, loading: action.payload };
    }
    case setToken: {
      return { ...state, token: action.payload };
    }
    case SET_ANALYSIS_META_DATA: {
      return {
        ...state,
        analysisMetaData: action.payload,
      };
    }

    default:
      return state;
  }
};

export const AuthPorvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { getToken } = useAuth();
  // const { session } = useSession();
  const [isSessionExpired, setIsSessionExpired] = useState(false);
  const { isLoaded, session, isSignedIn } = useSession()
  const verifyCurrentSession = async () => {
    // debugger;
    dispatch({ type: setLoading, payload: state.token ? false : true });
    try {
      const lastActiveToken = await getToken();
      if (session.id && lastActiveToken) {
        const result = await verifySession(session.id, lastActiveToken);
        // This function creates visitors and accounts in Pendo
        // You will need to replace <visitor-id-goes-here> and <account-id-goes-here> with values you use in your app
        // Please use Strings, Numbers, or Bools for value types.
        pendo.initialize({
          visitor: {
            id: result.payload.userid,
            email: result?.payload?.email,
            full_name:
              result?.payload?.name + " " + result?.payload?.surname,
            role: "user",
          },
        });
        if (result?.access_token) {
          if (!state.token) {
            dispatch({ type: setToken, payload: result.access_token });

            setAccessToken(result.access_token);
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
    dispatch({ type: setLoading, payload: false });
  };
  useEffect(() => {
    if (session && getToken) {
      verifyCurrentSession();
    }
  }, [session]);
  const checkExpirationToken = () => {
    if (getAccessToken() && !isSessionExpired) {
      const decoded = jwtDecode(getAccessToken());
      const currentTime = Date.now() / 1000; // Current time in seconds
      const fiveMinutesInSeconds = 5 * 60; // 5 minutes in seconds
      if (decoded.exp - currentTime < fiveMinutesInSeconds) {
        setIsSessionExpired(true);
        dispatch({ type: setLoading, payload: false });
        console.log("@logout");
        return;
      }
    }
  };

  // const getMetaDataForAddtionalOptions = async () => {
  //   try {
  //     let res = await getAnalysisQuickCommands();
  //     if (res?.data) {
  //       dispatch({
  //         type: SET_ANALYSIS_META_DATA,
  //         payload: res,
  //       });
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };
  // useEffect(() => {
  //   if (state.token) {
  //     getMetaDataForAddtionalOptions()
  //   }
  // }, [state.token]);
  useEffect(() => {
    checkExpirationToken();
    const intervalId = setInterval(() => {
      checkExpirationToken();
    }, 60 * 1000);

    return () => clearInterval(intervalId);
  }, [state.token]);

  if (isSessionExpired && state.token) {
    return (
      <SessionModal
        dispatch={dispatch}
        setIsSessionExpired={setIsSessionExpired}
      />
    );
  }

  const value = { state, dispatch };

  return (
    <AuthContext.Provider value={value}>
      {state.loading ? (
        <div
          style={{
            height: "100vh",
            width: "100vw",
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {" "}
          <CircularProgress
            sx={{
              color: "blue",
              height: "30px !important",
              width: "30px !important",
            }}
          />
        </div>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
};
