import React from 'react';
import { useTheme } from '../../../Context/ThemContext/ThemeContext';
import LightModeIcon from '../../../assets/icons/SidePanel/LightModeIcon';
import DarkModeIcon from '../../../assets/icons/SidePanel/DarkmodeIcon';

const ToggleThemeBtn = () => {
	const { colorMode, toggleColorMode } = useTheme();
	const isDark = colorMode === 'dark';

	return (
		<div
			className={`relative border-2 border-[#0069E5] rounded-[20px] w-36 h-8 cursor-pointer transition duration-500 px-4 hover:bg-blue-700/30`}
			onClick={toggleColorMode}>
			<p
				className={`mr-1 font-base font-normal font-['Inter'] leading-7 transition duration-200  ${
					isDark ? 'text-right right-0' : 'text-left left-0'
				}`}>
				{!isDark ? 'Dark mode' : 'Light mode'}
			</p>
			<span
				className={`absolute w-[23px] h-[23px] inset-0 transition-all duration-500 mt-[2.5px] mb-[3px] ${
					isDark ? 'translate-x-1' : 'translate-x-[113px]'
				}`}>
				{isDark ? <LightModeIcon /> : <DarkModeIcon />}
			</span>
		</div>
	);
};

export default ToggleThemeBtn;
