import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import info from "../../assets/icons/info-circle.svg";
import search from "../../assets/icons/search-01.svg";
import CompetitorImage from "../../assets/icons/competitorround.svg";
import LoadingPage from "../Common/Loading";
import { getActiveRound_from_foundation_id, getCompetitorByFoundationId } from "../../services";

const SavedCompetitorViewOnly = ({ currentModal, data, activeData, foundation }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState(data || []);
  const [filteredActiveData, setFilteredActiveData] = useState(activeData || []);
  const [selectedFilter, setSelectedFilter] = useState("Newest");
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [localData, setLocalData] = useState(data || []);
  const [localActiveData, setLocalActiveData] = useState(activeData || []);

  // Initialize data when props change
  useEffect(() => {
    setLocalData(data || []);
    setFilteredData(data || []);
    setLocalActiveData(activeData || []);
    setFilteredActiveData(activeData || []);
  }, [data, activeData]);

  // Handle filter changes and API calls
  useEffect(() => {
    const fetchSortedData = async () => {
      setLoading(true);
      try {
        const sortValue = selectedFilter === "Newest" ? 0 : 1;
        let result;

        if (currentModal === 1 && foundation?.foundation_id) {
          result = await getCompetitorByFoundationId(foundation.foundation_id, sortValue);
          if (result?.data) {
            setLocalData(result.data);
            setFilteredData(result.data);
          }
        } else if (currentModal === 2 && foundation?.foundation_id) {
          result = await getActiveRound_from_foundation_id(foundation.foundation_id, sortValue);
          if (result?.data) {
            setLocalActiveData(result.data);
            setFilteredActiveData(result.data);
          }
        }
      } catch (error) {
        console.error("Error fetching sorted data:", error);
      }
      setLoading(false);
    };

    if (count > 0) {
      fetchSortedData();
    }
  }, [selectedFilter, count, foundation?.foundation_id, currentModal]);

  // Handle search filtering
  useEffect(() => {
    if (currentModal === 1) {
      const filtered = localData?.filter((e) =>
        e?.competitor_name?.toLowerCase()?.includes(searchTerm?.toLowerCase())
      );
      setFilteredData(filtered || []);
    } else if (currentModal === 2) {
      const filtered = localActiveData?.filter((e) =>
        e?.competitor_name?.toLowerCase()?.includes(searchTerm?.toLowerCase())
      );
      setFilteredActiveData(filtered || []);
    }
  }, [searchTerm, localData, localActiveData, currentModal]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <div className={styles.frameParent}>
      <div className={styles.frameWrapper}>
        <div className={styles.frameGroup}>
          <div className={styles.viewOnlyParent}>
            <div className={styles.viewOnly}>View Only</div>
            <img className={styles.informationCircleIcon} alt="" src={info} />
          </div>
          <div className={styles.frameWithBox}>
            <div className={styles.frameContainer}>
              <div className={styles.frameDiv}>
                {currentModal == 1 && (
                  <div className={styles.search01Parent}>
                    <img className={styles.search01Icon} alt="" src={search} />
                    <input
                      placeholder="Search Competitor Name"
                      className={styles.searchCompetitorName}
                      value={searchTerm}
                      onChange={handleSearch}
                    />
                  </div>
                )}
                {currentModal == 2 && <div></div>}
                <select
                  value={selectedFilter}
                  onChange={(e) => {
                    setCount((prev) => prev + 1);
                    setSelectedFilter(e.target.value);
                  }}
                  className={styles.newestParent}
                >
                  <option value="Newest" className={styles.newest}>
                    Newest
                  </option>
                  <option value="Oldest" className={styles.newest}>
                    Oldest
                  </option>
                </select>
              </div>
              {currentModal == 1 &&
                (loading ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <LoadingPage />
                  </div>
                ) : (
                  <div className={styles.frameParent1}>
                    {filteredData?.length > 0 ? (
                      filteredData.map((e) => (
                        <div className={styles.frameWrapper1} key={e.competitor_id}>
                          <div className={styles.microsoftParent}>
                            <div className={styles.microsoft}>
                              {e?.competitor_name}
                            </div>
                            <div className={styles.frameChild} />
                            <input
                              value={e.url?.[0]}
                              readOnly
                              className={styles.searchCompetitorName}
                            />
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className={styles.noData}>No data found</div>
                    )}
                  </div>
                ))}
              {currentModal == 2 &&
                (loading ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <LoadingPage />
                  </div>
                ) : (
                  <div className={styles.roundGroup}>
                    {filteredActiveData?.length > 0 ? (
                      filteredActiveData.map((e) => (
                        <div className={styles.frameContaine} key={e.id}>
                          <div className={styles.roundNameParent}>
                            <div className={styles.roundName}>
                              {e.round_name}
                            </div>
                            <div className={styles.frameChild} />
                            <div className={styles.vsParent}>
                              <div className={styles.vs}>Vs.</div>
                              <div className={styles.comet01Parent}>
                                <img
                                  className={styles.comet01Icon}
                                  alt=""
                                  src={CompetitorImage}
                                />
                                <div className={styles.competitorName}>
                                  {e.competitor_name}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className={styles.noData}>No data found</div>
                    )}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SavedCompetitorViewOnly;