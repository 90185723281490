import * as React from "react";
import ArrowRight from "../../assets/icons/arrow-right.svg";
import ArrowLeft from "../../assets/icons/arrow-left.svg";
import "./index.css";
import SidePanel from "../../Components/SidePannel";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { OutpointContext } from "../../Context/OutpointsContext/Index";
import { SET_PATH_NAME } from "../../Context/OutpointsContext/action";
import { ColorPattern } from "../../constant/Color";
import { useTheme } from "../../Context/ThemContext/ThemeContext";
export default function Landing() {
  const location = useLocation();
  const navigate = useNavigate()
  const { colorMode } = useTheme();

  const { state, dispatch } = React.useContext(OutpointContext)
  React.useEffect(() => {
    let isGet = true;
    if (isGet) {
      dispatch({ type: SET_PATH_NAME, payload: location.pathname });
    }
    return () => {
      isGet = false;
    };
  }, [location.pathname]);

  // Get the path segments from the current location
  const pathSegments = decodeURIComponent(location.pathname).split("/").filter(Boolean);
  // const pathSegments = location.pathname.split("/").filter(Boolean);
  return (
    <div className="sharbo-dashboard-container">
      <div className="side-panel-container">
        <SidePanel />
      </div>
      <div className="main-content-container">
        <div className="dashboard-main-header">
          <div className="breadcrumbs-header">
            {pathSegments.length === 0 ? (
              // If the path is '/', show "Rounds"
              <>
                <Link to='/' style={{ color: ColorPattern[colorMode].textColor, textDecoration: 'none' }}>  <span className="breadcrumb-segment">Active Competitor Feature Comparison Rounds </span></Link>
                <img
                  src={ArrowRight}
                  alt="Arrow Right"
                  className="breadcrumb-arrow"
                />
              </>
            ) : (
              // Otherwise, display each segment with an arrow
              <>
                <img style={{ height: '10px' }} src={ArrowLeft} />
                <span onClick={() => navigate('/')} className="goback-arrow" style={{ fontWeight: 400, marginLeft: '5px', cursor: 'pointer' }}>

                  Go back
                </span>
                <span style={{ width: '.5px', fontWeight: 300, margin: '0 17px 0 14px' }}>|</span>
                {
                  pathSegments.map((segment, index) => (
                    <React.Fragment key={index}>
                      <span style={{ fontWeight: index !== pathSegments.length - 1 ? '400' : '600' }} className="breadcrumb-segment">
                        {segment.charAt(0).toUpperCase() + segment.slice(1)}
                      </span>
                      {
                        index !== pathSegments.length - 1 ? <img
                          src={ArrowRight}
                          alt="Arrow Right"
                          className="breadcrumb-arrow"
                        /> : ''
                      }

                    </React.Fragment>
                  ))
                }
              </>
            )}
          </div>
        </div>
        {/* TODO: Maybe add Routing within signedIn pages */}
        <Outlet />
      </div>
    </div>
  );
}
