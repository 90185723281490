import React from 'react';
import '../CustomComponent/index.css';

const CustomComponent = ({
	Free,
	Starter,
	para1,
	image,
	alt,
	customButton,
	onCustomButtonClick,
}) => {
	return (
		<div className='customDiv'>
			<div className='starter-align'>
				<img
					src={image}
					alt={alt}
					width={20}
					height={20}
					style={{ marginRight: '8px' }}
				/>
				<strong className='starter' style={{ marginRight: '8px' }}>
					{Starter}{' '}
				</strong>
				<h4 className='free'>{Free}</h4>
			</div>
			<div className='paraContent'>
				<p>{para1}</p>
			</div>
			<div className='buttonContainer' style={{ margin: '12px 0' }}>
				<button
					className='customButton hover:scale-105'
					onClick={onCustomButtonClick}
					style={{
						display: 'block',
						padding: '10px 20px',
						backgroundColor: '#007bff',
						color: 'white',
						border: 'none',
						borderRadius: '5px',
						cursor: 'pointer',
					}}>
					{customButton}
				</button>
			</div>
		</div>
	);
};

export default CustomComponent;
