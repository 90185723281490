import { CircularProgress } from '@mui/material';
import React from 'react';
import styles from './index.module.css';
import Lottie from 'lottie-react';
import AnalysisAniamtion from './AnalysisLoaderAnimation.json';

const AnalysisLoader = ({ isNotShow, showLoaderText }) => {
	return (
		<div className={styles.loading02Parent}>
			<Lottie
				style={{
					height: '150px',
					width: '150px',
				}}
				animationData={AnalysisAniamtion}
				loop={true}
			/>
			{showLoaderText && (
				<>
					<div className={styles.sharboAiIs}>
						Sharbo AI is analyzing data, crafting comparisons and insights
					</div>
					{!isNotShow && (
						<div className={styles.thisProcessMight}>
							This process might take a few seconds. Feel free to explore other
							sections while waiting.
						</div>
					)}
				</>
			)}
		</div>
	);
};

export default AnalysisLoader;
