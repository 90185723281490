
import NoDrafts from "../NoFoundations/index.module.css";
import NoDraftsIcon from "../../../assets/icons/blockers/nodrafts.svg";
// import NoDraftsIcon from "../../../assets/icons/no-drafts.svg";

const NoFoundationsBlocker = ({ ButtonData, text, Description, handleButtonFn }) => {


    return (
        <div>
            <div className={NoDrafts.intel_rounds_container}>
                <div className={NoDrafts.drafts_container}>
                    <div className={NoDrafts.drafts_icon_wrapper}>
                        <img src={NoDraftsIcon} alt={NoDraftsIcon} className={NoDrafts.drafts_icon} width={95} height={95} />
                    </div>
                    <div className={NoDrafts.drafts_message_wrapper}>
                        <div className={NoDrafts.no_drafts_message}>
                            {text || "No saved drafts from incomplete intel rounds"}
                        </div>
                        <div className={NoDrafts.no_drafts_description}>
                            {/* {Description || "A Foundation is your product's data and information used to create Competitor Feature Comparison Rounds"} */}
                            <p className={NoDrafts.no_drafts_description}>A Foundation is your product's data and information used to create </p>
                            <p className={NoDrafts.no_drafts_description}>Competitor Feature Comparison Rounds</p>
                        </div>
                    </div>
                </div>
                <div  onClick={() => handleButtonFn(false)} className={NoDrafts.active_rounds_button_wrapper}>
                    <div className={NoDrafts.active_rounds_button}>
                        <div className={NoDrafts.button_text}>{ButtonData}</div>
                    </div>
                </div>
            </div>
        </div>
    )
};
export default NoFoundationsBlocker